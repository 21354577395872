import React, { useEffect, useState } from "react";
import "./Popup.css";
import { Link, Navigate, useNavigate } from "react-router-dom";

const Popup = ({ onContinue, message, isAlert, performApiBinding }) => {
  // console.log("---- its calling ");
  const navigate = useNavigate();
  // --------------start----
  const [count, setCount] = useState(0);

  const [timeLeft, setTimeLeft] = useState(45); // Countdown timer (20 seconds)
  const [timerExpired, setTimerExpired] = useState(false); // Flag to check if the timer has expired

  useEffect(() => {
    const storedCount = sessionStorage.getItem("buttonClickCount");
    if (storedCount) {
      const count = parseInt(storedCount, 10);
      if (count >= 1) {
        // navigateToTwo();
        // console.log(count, "countcountcountcount");
        setCount(count);
        sessionStorage.setItem("buttonClickCount", 0);
        localStorage.setItem("buttonClickCount", 0);
      } else {
        // console.log("lalitha-----", count);
        setCount(count);
      }
    }

    // Reset count when the tab is closed
    const handleTabClose = () => {
      sessionStorage.setItem("buttonClickCount", 0);
      localStorage.setItem("buttonClickCount", 0);
    };
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  useEffect(() => {
    let timer; // Declare the timer variable inside useEffect
    // Timer to automatically submit after 45 seconds
    if (count >= 1) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setTimerExpired(true);
            // Automatically call navigateToPreviousScreen after 45 seconds
            navigateToPreviousScreen();
            return 0; // Stop the countdown
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [count, timeLeft]);

  // ---------------------
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const navigateToPreviousScreen = () => {
    performApiBinding();
    // navigate(-3); // Navigate back by one step in the history
  };
  const navigateToTwo = () => {
    navigate(-2); // Navigate back by one step in the history
  };

  return (
    <>
      <div className="popup-overlay">
        <div className="popup-content">
          <div className="popup-header">
            <h2 className="full-title">
              Attention: Full Screen Mode Online Exam Instructions
            </h2>
          </div>
          <div className="popup-body">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {count >= 1 ? (
                    <>
                      <div className="row mb-3 ms-1">
                        <div className="col-lg-12 col-md-12 ps-0">
                          <div
                            className="Warning_Popup h-0"
                            style={{ textAlign: "center" }}
                          >
                            <strong>WARNING: </strong> <br />
                            You have performed a restricted action, and your
                            test will be terminated and submitted shortly in  <strong style={{fontSize:"18px"}}>{timeLeft} seconds</strong>. <br /> Do you want to continue your test?
                          </div>
                        </div>
                      </div>
                      {/* <div className="row mb-3 ms-1">
                        <div className="col-lg-12 col-md-12 ps-0">
                          <div className="Note_Popup h-0">
                            <strong>NOTE: </strong> Keep your browser in
                            <b>full-screen mode</b> throughout the exam.
                          </div>
                        </div>
                      </div> */}
                    </>
                  ) : (
                    <div className="row mb-3 ms-1">
                      <div className="col-lg-12 col-md-12 ps-0">
                        <div className="Note_Popup h-0">
                          <strong>NOTE: </strong> Keep your browser in
                          <b> full-screen mode</b> throughout the exam.
                        </div>
                      </div>
                    </div>
                  )}
                  {count === 0 && (
                    <p className="fullscreen-paragraph">
                      <ul>
                        <li>
                          Do not press any keys, especially restricted keys:{" "}
                          <b>
                            F1, F3, F5, F11, F12, CTRL, SHIFT, ALT, Tab, or ESC
                          </b>
                          .
                        </li>
                        <li>Do not reload, close, or minimize the browser.</li>
                        <li>
                          Do not switch tabs or applications on any device.
                        </li>
                        <li>Do not use the back button in the browser.</li>
                        <li>Do not swipe left/right on the touchpad.</li>
                        <li>
                          Set your device's screen sleep time to "Never" to
                          prevent interruptions.
                        </li>
                      </ul>
                      <div className="row mb-3 ms-1">
                        <div className="col-lg-12 col-md-12 ps-0">
                          <div className="Note_Popup h-0">
                            <strong>NOTE: </strong> Any violation will result in
                            your exam being automatically submitted or
                            terminated, and you may be marked absent. Follow
                            these commands strictly to ensure your exam is
                            valid.
                          </div>
                        </div>
                      </div>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="popup-footer">
            <ul class="List-Button">
              {count >= 1 ? (
                <>
                  <li class="ListBtnItems">
                    <Link
                      onClick={navigateToPreviousScreen}
                      to="javascript:void(0)"
                      className="secondaryButton "
                    >
                      No, Submit Test
                    </Link>
                  </li>
                  <li class="ListBtnItems">
                    <Link
                      onClick={onContinue}
                      to={"javascript:void(0)"}
                      className="primaryButton "
                      variant="primary"
                    >
                      Yes, Continue test
                    </Link>
                  </li>
                </>
              ) : (
                <li class="ListBtnItems">
                  <Link
                    onClick={onContinue}
                    to={"javascript:void(0)"}
                    className="primaryButton "
                    variant="primary"
                  >
                    I Understand and Accept
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
