import React, { useState, useEffect, useRef } from "react";
import { AxiosInstance } from "../../../../axios";
import Loader from "../../Loader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../../ReusuableComponent/Pagination/Pagination";
import moment from "moment";
import styles from "./Announcement.module.css";
import { styled } from "@mui/material/styles";
// import style from "../CourseInfo/ListCourseInfo.module.css";
// import ListInfo from "./CourseInfo/Course/CourseInfoCreate.module.css";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
// import { faL } from "@fortawesome/free-solid-svg-icons";

const ListAnnouncementInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [updateListData, setUpdateListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10); // You can adjust this number as needed
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryId, setCategoryId] = useState([]); // Category list for filter checkboxes
  const txtBSearch = useRef(null);
  const filterRef = useRef(null);
  const [isOpen, setIsOpen] = useState({
    category: false,
    reward_status: false,
  });
  const filterBoxRef = useRef(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const navigate = useNavigate();
  const [updateChipData, setUpdateChipData] = useState([]);
  const location = useLocation();
  const { filCateCode, filCateStatus, filCateSearch } = location.state || {};

  const [category_on, setCategory_on] = useState([]);
  const [reward_status, setReward_status] = useState([]);
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData?.slice(start, end);
  const formatDate = (start_date, end_date) => {
    return `${moment(start_date).format("DD/MM/YYYY")} - ${moment(
      end_date
    ).format("DD/MM/YYYY")}`;
  };

  const filterCategoryRef = useRef(null);
  const filterStatusRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterCategoryRef.current &&
        !filterCategoryRef.current.contains(event.target)
      ) {
        setIsOpen((prev) => ({ ...prev, category: false }));
      }
      if (
        filterStatusRef.current &&
        !filterStatusRef.current.contains(event.target)
      ) {
        setIsOpen((prev) => ({ ...prev, reward_status: false }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    filterRewardStatus = reward_status;
  }, [reward_status]);

  useEffect(() => {
    const temChip = updateChipData?.filter((ui) => ui.type === "category_on");
    const temArr = [];
    categoryId.map((item) => {
      let dataChip = { ...item, checked: false, type: "category_on" };
      temChip.map((itemChip) => {
        if (item.name === itemChip.name) {
          dataChip = { ...item, checked: true, type: "category_on" };
        }
      });
      temArr.push(dataChip);
    });
    setCategoryId(temArr);
    loadListdata(temChip);
  }, [updateChipData]);

  // Handles Search Input and Updates Filter Chips
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      let val = event.target.value;
      setSearchTerm(val);
      if (val.length > 0) {
        setUpdateChipData((prev) => [
          ...prev,
          { name: String(val), type: "search" },
        ]);
      } else {
        setSearchTerm("");
      }
    }
  };

  const handleCheckboxChange = (event, typename) => {
    const { value, checked, name } = event.target;
    if (typename === "reward_status") {
      if (checked) {
        setReward_status([
          ...reward_status,
          { id: value, name: name, type: "reward_status" },
        ]);
      } else {
        setReward_status(reward_status.filter((item) => item.id !== value));
      }
    } else if (typename === "category") {
      if (checked) {
        setCategory_on([
          ...category_on,
          { id: value, name: name, type: "category" },
        ]);
      } else {
        setCategory_on(category_on.filter((item) => item.id !== value));
      }
    }
  };
  // ------chipdata listitem------
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));
  let filterCategory = [];
  let filterSearch = [];

  function updateStateData() {
    filterCategory = [...category_on];
    filterRewardStatus = [...reward_status];

    if (searchTerm.length > 0) {
      filterSearch = [{ name: searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "reward_status") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        reward_status: false,
      });
    } else if (name === "category") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        category: false,
      });
    }
  };
  // categoryList portion inside the category popup //
  useEffect(() => {
    AxiosInstance.get("api/course/category_list")
      .then((res) => {
        if (res.status === 200 && Array.isArray(res.data.data)) {
          // setIsLoading(false);
          const tempCate = [];
          res.data.data.map((item) => {
            item.checked = false;
            tempCate.push(item);
          });
          setCategoryId(tempCate);
        } else if (res.data.status === false) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setUpdateListData([]);
        setIsLoading(false);
        return error;
      });
  }, []);

  useEffect(() => {
    if (filCateCode) {
      setCategory_on(filCateCode);
      setUpdateChipData((prev) => [...prev, ...filCateCode]);
      setIsLoading(false);
    }
    if (filCateStatus) {
      setReward_status(filCateStatus);
      setIsLoading(false);
      setUpdateChipData((prev) => [...prev, ...filCateStatus]);
    }
    if (filCateSearch) {
      setSearchTerm(filCateSearch);
      setIsLoading(false);
      setUpdateChipData((prev) => [
        ...prev,
        { name: filCateSearch, type: "search" },
      ]);
    }
  }, [filCateCode, filCateSearch, filCateStatus]);

  // loadlistData annoouncement list portion //
  const loadListdata = (chipCategory) => {
    let tempstatus = [];
    let tempCate = [];
    category_on.map((obj) => {
      tempCate.push(Number(obj.id));
    });
    reward_status.map((obj) => {
      tempstatus.push(Number(obj.id));
    });
    const filters = {
      search: searchTerm,
      category_id: category_on.length > 0 ? tempCate : "",
      status: reward_status.length > 0 ? tempstatus : "",
    };
    setIsLoading(true);
    AxiosInstance.post("api/all/announcement/list", filters)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          const fiata = res.data.data;
          const serialNumberAddedData = fiata.map((data, index) => ({
            ...data,
            serialNumber: index + 1,
          }));
          setCurrentPage(1);
          setUpdateListData(serialNumberAddedData);
        } else {
          setUpdateListData([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setUpdateListData([]);
        setIsLoading(false);
        return error;
        console.error("Error loading data:", error);
      });
  };
  let filterRewardStatus = [];

  function loadChipdata() {
    let tempArr = [];
    filterCategory.map((obj) => {
      tempArr.push(obj);
    });
    filterRewardStatus.map((obj) => {
      tempArr.push(obj);
    });
    if (searchTerm.length > 0) {
      tempArr.push({ name: searchTerm, type: "search" });
    }
    setUpdateChipData(tempArr);
  }

  // filter clear button
  const handleClear = (name) => {
    if (name === "category") {
      const temArr = [];
      categoryId.map((item) => {
        temArr.push({ ...item, checked: false });
      });
      setCategoryId(temArr);
    }
    if (name === "reward_status") {
      setReward_status([]);
      filterRewardStatus = [];
    }
  };

  const handleDelete = (chipToDelete, index) => () => {
    if (chipToDelete.type === "category") {
      setCategory_on(category_on.filter((item) => item.id !== chipToDelete.id));
      setUpdateChipData((prev) => {
        return prev.filter(
          (e) => !(e.type === "category" && e.id === chipToDelete.id)
        );
      });
    } else if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
      setUpdateChipData((prev) => {
        const filteredData = prev.filter((e) => e.type !== "search");
        return filteredData;
      });
    } else if (chipToDelete.type === "reward_status") {
      setReward_status(
        reward_status.filter((item) => item.id !== chipToDelete.id)
      );
      setUpdateChipData((prev) => {
        return prev.filter(
          (e) => !(e.type === "reward_status" && e.id === chipToDelete.id)
        );
      });
    }
  };

  console.log("----", updateChipData);

  const handleFilter = (e, boxName) => {
    if (boxName === "category") {
      setIsOpen((prevState) => ({
        ...prevState, // Retain the current state values
        category: !prevState.category,
        reward_status: false, // Toggle the value
      }));
      setCategory_on(updateChipData?.filter((ui) => ui.type === "category"));
    } else if (boxName === "reward_status") {
      setIsOpen((prevState) => ({
        category: false,
        reward_status: !prevState.reward_status,
      }));
      setReward_status(
        updateChipData?.filter((ui) => ui.type === "reward_status")
      );
    }
  };
  const handleAllClear = () => {
    setSearchTerm("");
    setReward_status([]);
    setCategory_on([]);
    setUpdateChipData([]);
    loadListdata();
  };

  const handleSearchFocus = () => {
    if (txtBSearch.current) {
      txtBSearch.current.focus();

      // Create a new KeyboardEvent
      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        keyCode: 13,
        which: 13,
        code: "Enter",
        bubbles: true,
      });
      txtBSearch.current.dispatchEvent(event);
    }
  };

  // total table data  with pages
  const totalPages = Math.ceil(updateListData?.length / rowsPerPage);
  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };

  const handlePageCount = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1);
    setUpdateListData([]);
    loadListdata();
  };

  // Sorting Handler
  const handleSort = (event, sortKey) => {
    event.preventDefault();

    // Toggle the sort order
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);

    // Clone and sort the list
    const sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      if (a[sortKey] == b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });

    setUpdateListData(sortedList);
  };

  const handleReadView = (id) => {
    navigate(`/ViewAnnouncement/${id}`, {
      state: {
        FCAT: category_on,
        FSTATUS: reward_status,
        FSEARCH: searchTerm,
      },
    });
  };

  return (
    <div
      className={`${styles.CourseInfoSec} ${styles.cm_ListViewForm} container-fluid  global_container p-0`}
    >
      <div className={`${styles.cm_container} BathHeader`}>
        <div className="col-md-6 col-12">
          <h2 className="TitleHeader">Announcements</h2>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
            <div className="input-group">
              <input
                ref={txtBSearch}
                type="text"
                class={styles.Inputs}
                // class={styles.Inputs}
                // className="form-control"
                id="SearchInput"
                placeholder="Search by description"
                onKeyDown={handleSearch}
              />
              <span
                class={styles.input_group_text}
                id="SearchInput"
                onClick={handleSearchFocus}
              >
                <span className="material-icons">search</span>
              </span>
            </div>
          </div>

          <div className="col-md-8 col-12 ListBtn">
            <ul className="List-Button">
              <li className="ListBtnItems">
                <Link to="/CreateAnnouncement" className="primaryButton">
                  Create New Announcement
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {updateChipData?.filter((kl) => kl.name !== "ActiveTab").length > 0 ? (
          <div className="row mt-3">
            <div className="col-md-12 col-sm-12 col-xs-12 paperil_global ">
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  listStyle: "none",
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                {updateChipData
                  ?.filter((kl) => kl.name !== "ActiveTab")
                  ?.map((data, index) => {
                    let icon;
                    let key = "" + data.name;
                    return (
                      <ListItem key={data}>
                        <Chip
                          icon={icon}
                          label={key}
                          onDelete={handleDelete(data, index)}
                        />
                      </ListItem>
                    );
                  })}

                <ListItem className="clearAll_list">
                  <a className="clearAll_listItem" onClick={handleAllClear}>
                    Clear All Filters
                  </a>
                </ListItem>
              </Paper>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        {/* Table Section */}
        <div className="row mt-2">
          <div className="col-md-12 table-responsive tableMinHeight p-0 px-lg-2">
            <table className="table" id="tableListHeader">
              <thead>
                <tr>
                  {/* S.No */}
                  <th id="th">
                    <span
                      className="textHead_th"
                      onClick={(e) => handleSort(e, "serialNumber")}
                    >
                      S.No
                    </span>
                    <span
                      className="textHead_th material-icons align-middle"
                      onClick={(e) => handleSort(e, "serialNumber")}
                    >
                      import_export
                    </span>
                  </th>
                  {/* Description */}
                  <th id="th">
                    <span
                      className="textHead_th"
                      onClick={(e) => handleSort(e, "description")}
                    >
                      Description
                    </span>
                    <span
                      className="textHead_th material-icons align-middle"
                      onClick={(e) => handleSort(e, "description")}
                    >
                      import_export
                    </span>
                  </th>
                  {/* URL */}
                  <th id="th">
                    <span
                      className="textHead_th"
                      onClick={(e) => handleSort(e, "url")}
                    >
                      URL
                    </span>
                    <span
                      className="textHead_th material-icons align-middle"
                      onClick={(e) => handleSort(e, "url")}
                    >
                      import_export
                    </span>
                  </th>
                  {/* Category */}
                  <th id="th" className={styles.tableThPadding}>
                    <span
                      onClick={(e) => handleSort(e, "category_name")}
                      className="textHead_th"
                    >
                      Category
                    </span>
                    <span
                      onClick={(e) => handleSort(e, "category_name")}
                      className={`textHead_th material-icons align-middle`}
                    >
                      import_export{" "}
                    </span>
                    <span
                      className={`textHead_th align-middle material-icons ${
                        category_on.length > 0 ? styles.active_icons : ""
                      } ${isOpen.category ? styles.active_icons : ""}`} // Change here
                      onClick={(e) => handleFilter(e, "category")}
                    >
                      filter_list
                    </span>

                    {isOpen.category && (
                      <>
                        <div
                          className="filterBoxs_testlist mt-3"
                          ref={filterCategoryRef}
                        >
                          <div className="col-md-12 form-check filterStatus_list">
                            <ul className="ulFilterListData">
                              {categoryId.map((el) => (
                                <li className="filterListData">
                                  <input
                                    className="ListCheckbox"
                                    type="checkbox"
                                    name={el.name}
                                    value={el.id}
                                    id={el.name}
                                    checked={category_on.some((element) => {
                                      if (element.name == el.name) {
                                        return true;
                                      }
                                      return false;
                                    })}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, "category")
                                    }
                                  />
                                  <label
                                    className="form-check-label filterChipText_withoutBG "
                                    htmlFor={el.name}
                                  >
                                    {el.name}
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="borderWidth row">
                            <ul className="List-Button">
                              <Link
                                to={"javascript:void(0)"}
                                onClick={() => handleClear("category")}
                                className="secondaryButton "
                              >
                                Clear
                              </Link>
                              <li className="ListBtnItems">
                                <Link
                                  disabled={
                                    category_on.length === 0 ? true : false
                                  }
                                  onClick={() => handleApplyFilter("category")}
                                  className="primaryButton"
                                >
                                  Apply Filter
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                  </th>
                  {/* Display Date */}
                  <th id="th">
                    <span className="textHead_th">Display Date</span>
                  </th>
                  <th id="th" ref={filterRef}>
                    <span
                      className="textHead_th"
                      onClick={(e) => handleSort(e, "status")}
                    >
                      Status
                    </span>
                    <span
                      className="textHead_th material-icons align-middle"
                      onClick={(e) => handleSort(e, "status")}
                    >
                      import_export
                    </span>
                    <span
                      className={`textHead_th align-middle material-icons ${
                        reward_status.length > 0 ? styles.active_icons : ""
                      } ${isOpen.reward_status ? styles.active_icons : ""}`} // Change here
                      onClick={(e) => handleFilter(e, "reward_status")}
                    >
                      filter_list
                    </span>
                    {isOpen.reward_status === true && (
                      <div
                        className="filterBoxs_testlist"
                        ref={filterStatusRef}
                      >
                        <div className="col-md-12 form-check filterStatus_list">
                          <ul className="ulFilterListData">
                            <li className="filterListData">
                              <input
                                className="ListCheckbox"
                                type="checkbox"
                                name={"Completed"}
                                value={"0"}
                                checked={reward_status.some((element) => {
                                  if (element.id == "0") {
                                    return true;
                                  }
                                  return false;
                                })}
                                id={"Completed"}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    e,
                                    "reward_status",
                                    "reward_status"
                                  )
                                }
                              />
                              <label
                                className="form-check-label filterChipText bg-danger"
                                htmlFor={"Completed"}
                              >
                                Completed
                              </label>
                            </li>
                            <li className="filterListData">
                              <input
                                className="ListCheckbox"
                                type="checkbox"
                                name={"Ongoing"}
                                value={"1"}
                                checked={reward_status.some((element) => {
                                  if (element.id == "1") {
                                    return true;
                                  }
                                  return false;
                                })}
                                id={"Ongoing"}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    e,
                                    "reward_status",
                                    "reward_status"
                                  )
                                }
                              />
                              <label
                                className="form-check-label filterChipText bg-green"
                                htmlFor={"Ongoing"}
                              >
                                Ongoing
                              </label>
                            </li>

                            <li className="filterListData">
                              <input
                                className="ListCheckbox"
                                type="checkbox"
                                name={"Upcoming"}
                                value={"2"}
                                checked={reward_status.some((element) => {
                                  if (element.id == "2") {
                                    return true;
                                  }
                                  return false;
                                })}
                                id={"Upcoming"}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    e,
                                    "reward_status",
                                    "reward_status"
                                  )
                                }
                              />
                              <label
                                className="form-check-label bg-orange filterChipText  "
                                htmlFor={"Upcoming"}
                              >
                                Upcoming
                              </label>
                            </li>
                          </ul>
                        </div>
                        <div className="borderWidth row">
                          <ul className="List-Button">
                            <li className="ListBtnItems">
                              <Link
                                to={"javascript:void(0)"}
                                onClick={() => handleClear("reward_status")}
                                className="secondaryButton ms-2"
                              >
                                Clear
                              </Link>
                            </li>
                            <li className="ListBtnItems">
                              <Link
                                to={"javascript:void(0)"}
                                onClick={() =>
                                  handleApplyFilter("reward_status")
                                }
                                className="primaryButton"
                              >
                                Apply Filter
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </th>
                  {/* Action */}
                  <th id="th">
                    <span className="textHead_th">Action</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentData.length === 0 ? (
                  <tr>
                    <td colSpan="7" style={{ textAlign: "center" }}>
                      No records to display
                    </td>
                  </tr>
                ) : (
                  currentData?.map((course, index) => (
                    <tr key={index}>
                      {/* Dynamically calculate serial number */}
                      {/* <td id="td">
                        {(currentPage - 1) * rowsPerPage + index + 1}
                      </td> */}
                      <td id="td">{course.serialNumber}</td>
                      <td id={`${styles.td}tabTh`}>
                        <span
                          className={styles.ellipsis}
                          title={course.description}
                        >
                          {course.description}
                        </span>
                      </td>
                      <td id="td">
                        <a
                          href={course.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "blue", textDecoration: "underline" }}
                        >
                          <span className={styles.ellipsis} title={course.url}>
                            {course.url}
                          </span>
                        </a>
                      </td>
                      <td id="td">{course.category_name}</td>
                      <td id="td">
                        <span>
                          {formatDate(course.start_date, course.end_date)}
                        </span>
                      </td>
                      {course.status == 0 && (
                        <td id={`${styles.td}`}>
                          <span class="filterChipText bg-danger mx-0 ">
                            Completed
                          </span>
                        </td>
                      )}
                      {course.status == 1 && (
                        <td id={`${styles.td}`}>
                          <span class="filterChipText bg-green mx-0 ">
                            Ongoing
                          </span>
                        </td>
                      )}
                      {course.status == 2 && (
                        <td id={`${styles.td}`}>
                          <span class="filterChipText bg-orange mx-0">
                            Upcoming
                          </span>
                        </td>
                      )}
                      <td id="td">
                        <button
                          className={"view_icon_button"}
                          onClick={() =>
                            handleReadView(course.id, course.category)
                          }
                        >
                          <span className={`visibleicon material-icons`}>
                            visibility
                          </span>
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* Pagination */}
        <div className="row mt-2">
          <Pagination
            rowsPerPage={rowsPerPage}
            handlePageCount={handlePageCount}
            startPage={
              updateListData && updateListData?.length > 0
                ? currentPage * rowsPerPage + 1 - rowsPerPage
                : 0
            }
            endPage={
              updateListData && updateListData?.length > 0
                ? updateListData?.length < currentPage * rowsPerPage
                  ? updateListData?.length
                  : currentPage * rowsPerPage
                : 0
            }
            totalRecords={
              updateListData && updateListData?.length > 0
                ? updateListData?.length
                : 0
            }
            handlePrev={(e) => handlePrev(currentPage - 1)}
            currentPrevPage={currentPage === 1}
            prevButton={`${styles.prev_buttonViewEvaluator} ${styles.materialIconsRightAndLeft} me-2`}
            handleNext={() => handleNext(currentPage + 1)}
            currentNextPage={currentPage === totalPages}
          />
        </div>
      </div>
      {isLoading && (
        <div
          className="loaderApi"
          style={{
            display: isLoading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            zIndex: 999,
          }}
        >
          {isLoading && <Loader />}
        </div>
      )}
    </div>
  );
};
export default ListAnnouncementInfo;
