import React, { useContext, useEffect, useState, useRef } from "react";
import { UserContext } from "../../context/UserDetails";
// import banner from '../../img/banner/ban1.jpg';
import "../../Themes/themes.css";
import styles from "./IndexPage.module.css";
// import Top10squad from "../dashboard/Top10squad";
// import CourseCategory from "../dashboard/CourseCategory";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import LogoutListener from "../../context/LogoutListener";
import { Link } from "react-router-dom";
import ModelCompontent from "./ModelCompontent";
import Footer from "../../features/Core/Footer/Footer";
import PaymentDueModel from "../PaymentDueModel";
import { AxiosInstance } from "../../axios";
import Swal from "sweetalert2";
import ModelComForP from "../ModelComForP";
import PublicResult from "../../shared/Component/BackOffice/TestAdminWorkflow/PublicResult";
import WarningPopUp from "../../shared/Component/BackOffice/StudentTestWorkflow/WarningPopUp";
import RaiseQuery from "../../shared/Component/BackOffice/StudentTestWorkflow/RaiseQuery";
import ViewQuery from "../../shared/Component/BackOffice/StudentTestWorkflow/ViewQuery";
import ViewExplaination from "../../shared/Component/BackOffice/StudentTestWorkflow/ViewExplaination";
import { ImageUrlPrefix } from "../../labels";
import CourseCategory from "../dashboard/CourseCategory";
import "./CourseCategory.module.css";
import "./Top10squad.module.css";
import Top10squad from "../dashboard/Top10squad";
import "bootstrap/dist/css/bootstrap.min.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"; // Import ChevronLeftIcon
import ChevronRightIcon from "@mui/icons-material/ChevronRight"; // Import ChevronRightIcon
import listImage from "./ic_send_24px.png";

function IndexPage() {
  const { user } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [showModalData, setShowModalData] = useState({});
  const [images, setImages] = useState([]);
  const [updateListData, setUpdateListData] = useState([]);
  const [categoryId, setCategoryId] = useState([]); // Category list for filter checkboxes
  const [announcementList, setAnnouncementList] = useState("");
  const [isExpanded, setIsExpanded] = useState(true); // State to toggle the div
  const marqueeRef = useRef(null); // Ref for marquee control

  const toggleView = () => {
    setIsExpanded(!isExpanded); // Toggle the expanded state
  };

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;
  let role_id = null;
  let user_type = null;
  let menuData = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
      role_id = user.role_id;
      user_type = user.user_type;
      menuData = user.menu_list;
    }
  } else {
    console.log("User Id cannot available in session storage");
  }
  const [activeIndex, setActiveIndex] = useState(0);
  // announcement
  const [isOpen, setIsOpen] = useState(false); // State to manage collapse

  useEffect(() => {
    setShowModal(true); // Show the modal when the component loads
    if (userid) {
      AxiosInstance.post("api/user/reminder/byId", { user_id: userid })
        .then((res) => {
          if (res.data.status === true) {
            const dueDetailsData = res.data.data;
            // console.log(res.data.data)
            const dueDetailsString = JSON.stringify(dueDetailsData);
            sessionStorage.setItem("DueDetails", dueDetailsString);
            localStorage.setItem("DueDetails", dueDetailsString);
            setShowModalData(dueDetailsData);
          }
        })
        .catch((error) => {
          return error;
        });
    }
    AxiosInstance.get("api/all/get_banner_images")
      .then((res) => {
        if (res.data.data.length > 0) {
          setImages(res.data.data);
        }
      })
      .catch((error) => {
        return error;
      });
  }, []);

  const toggleCollapse = () => {
    setIsOpen(!isOpen); // Toggle collapse state
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 2000); // Slide every minute
    return () => {
      clearInterval(interval);
    };
  }, [activeIndex]);

  const nextSlide = () => {
    setActiveIndex((prevIndex) => {
      if (prevIndex === images.length - 1) {
        return prevIndex;
      }
      return prevIndex + 1;
    });
    if (activeIndex === images.length - 1) {
      setTimeout(() => {
        setActiveIndex(0);
      }, 2000);
    }
  };
  // main exam model box close

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // getAnnouncement api integration //
  useEffect(() => {
    AxiosInstance.get("api/all/getAnnouncement")
      .then((res) => {
        if (res.status === 200 && Array.isArray(res.data.data)) {
          const groupedData = res.data.data.reduce((acc, item) => {
            if (!acc[item.category_name]) {
              acc[item.category_name] = [];
            }
            acc[item.category_name].push(item);
            return acc;
          }, {});
          setAnnouncementList(groupedData);
        } else if (res.data.status === false) {
          console.error("Failed to fetch announcements:", res.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching announcements:", error);
      });
  }, []);

  const handleAnnouncementClick = (event, url, item) => {
    event.preventDefault(); // Prevents default navigation

    // Example check: Assume `user.registeredAnnouncements` contains URLs of registered announcements
    const registeredAnnouncements = sessionStorage.getItem(
      "registeredAnnouncements"
    );

    if (registeredAnnouncements && registeredAnnouncements.includes(url)) {
      // User is registered, load data
      loadAnnouncementData(item);
    } else {
      // Open the URL normally
      window.open(url, "_blank");
    }
  };

  const loadAnnouncementData = (item) => {
    // Implement your API call to fetch the specific announcement's data
    console.log("Loading announcement data for:", item);
    AxiosInstance.get("api/all/getAnnouncement", { id: item.id })
      .then((res) => {
        if (res.status === 200) {
          console.log("Loaded data:", res.data);
          // Handle the data as needed (update state, show modal, etc.)
        }
      })
      .catch((error) => {
        console.error("Error loading announcement details:", error);
      });
  };

  return (
    <div className="AdmissionForm IndexPage FooterLink">
      <LogoutListener />
      <div className="row">
        <div className="col-md-12  p-0">
          {user.user_id ? (
            <section className={`${styles.headerwel} StepOne`}>
              <h2 className="text-center">Welcome To Dashboard</h2>
              <div>
                {isExpanded ? (
                  // Expanded view with marquee content
                  <div className={styles.announcemt_container_user}>
                    <div
                      className={`${styles.Header_portion} d-flex justify-content-between p-lg-2 p-1`}
                    >
                      <span>Notice Board</span>
                      <span onClick={toggleView} style={{ cursor: "pointer" }}>
                        <ChevronRightIcon />
                      </span>
                    </div>
                    <div className={`${styles.marquee_portion}`}>
                      <marquee
                        className="p-2"
                        ref={marqueeRef}
                        width="100%"
                        direction="up"
                        height="240px"
                        behavior="scroll"
                        scrollamount="4"
                        onMouseEnter={() => marqueeRef.current?.stop()} // Stop scrolling on hover
                        onMouseLeave={() => marqueeRef.current?.start()} // Resume scrolling on leave
                      >
                        <div>
                          {Object.entries(announcementList).map(
                            ([categoryName, items], index) => (
                              <div key={index} style={{ marginBottom: "40px" }}>
                                <p className={styles.categoryName}>
                                  {categoryName}
                                </p>
                                <ul className={styles.decription}>
                                  {items.map((item, idx) => (
                                    <li key={idx} className={styles.decription}>
                                      <div className="row">
                                        <div className="col-1 align-content-center">
                                          <img
                                            className={styles.listImg}
                                            src={listImage}
                                            alt="Icon"
                                          />
                                        </div>
                                        <div className="col-11">
                                          <a
                                            href={item.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={(e) =>
                                              handleAnnouncementClick(
                                                e,
                                                item.url,
                                                item
                                              )
                                            }
                                            style={{
                                              textDecoration: "underline",
                                              color: "#2378e9",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            {item.description}
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )
                          )}
                        </div>
                      </marquee>
                    </div>
                  </div>
                ) : (
                  // Collapsed view with left icon
                  <div className={styles.marquee_left_icon_user}>
                    <span onClick={toggleView} style={{ cursor: "pointer" }}>
                      <ChevronLeftIcon />
                    </span>
                  </div>
                )}
              </div>

              {user_type === 2 &&
                showModalData.res_ids &&
                showModalData.res_ids.length > 0 &&
                showModalData.res_ids.map((el, index) => (
                  <PaymentDueModel
                    showModalData={el}
                    show={el.status !== false ? true : false}
                    admission_type={el.admission_type}
                    adm_pk={el.adm_pk}
                    user_id={userid}
                    section={el.section}
                    index={index}
                    key={index} // Don't forget to add a unique key for each item in the array
                  />
                ))}
              {user_type === 2 &&
                showModalData.res_idss &&
                showModalData.res_idss.length > 0 &&
                showModalData.res_idss.map((el, index) => {
                  <PaymentDueModel
                    showModalData={el}
                    show={el.status !== false ? true : false}
                    adm_pk={el.adm_pk}
                    admission_type={el.admission_type}
                    section={el.section}
                    user_id={userid}
                    index={index}
                  />;
                })}
            </section>
          ) : (
            <div
              id="carouselExampleIndicators"
              className="carousel slide "
              data-ride="carousel"
            >
              <ol className={`${styles.carousel_indicators}`}>
                {images.map((_, index) => (
                  <li
                    key={index}
                    title={activeIndex}
                    data-target="#carouselExampleIndicators"
                    data-slide-to={index}
                    className={index === activeIndex ? "active" : ""}
                  ></li>
                ))}
              </ol>
              <div
                className="carousel-container"
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="carousel-inner" style={{ flex: 1 }}>
                  {images.map((sd, index) => (
                    <div
                      key={index}
                      titleone={activeIndex}
                      className={`carousel-item ${
                        index === activeIndex ? "active" : ""
                      }`}
                    >
                      <img
                        style={{ maxHeight: "450px" }}
                        className="d-block w-100"
                        src={`${ImageUrlPrefix}/${sd}`}
                        alt={`Slide ${index + 1}`}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div>
                {isExpanded ? (
                  // Expanded view with marquee content
                  <div className={styles.announcemt_container}>
                    <div
                      className={`${styles.Header_portion} d-flex justify-content-between p-lg-2 p-1`}
                    >
                      <span>Notice Board</span>
                      <span onClick={toggleView} style={{ cursor: "pointer" }}>
                        <ChevronRightIcon />
                      </span>
                    </div>
                    <div className={`${styles.marquee_portion}`}>
                      <marquee
                        className="p-2"
                        ref={marqueeRef}
                        width="100%"
                        direction="up"
                        height="240px"
                        behavior="scroll"
                        scrollamount="4"
                        onMouseEnter={() => marqueeRef.current?.stop()} // Stop scrolling on hover
                        onMouseLeave={() => marqueeRef.current?.start()} // Resume scrolling on leave
                      >
                        <div>
                          {Object.entries(announcementList).map(
                            ([categoryName, items], index) => (
                              <div key={index} style={{ marginBottom: "40px" }}>
                                <p className={styles.categoryName}>
                                  {categoryName}
                                </p>
                                <ul className={styles.decription}>
                                  {items.map((item, idx) => (
                                    <li key={idx} className={styles.decription}>
                                      <div className="row">
                                        <div className="col-1 align-content-center">
                                          <img
                                            className={styles.listImg}
                                            src={listImage}
                                            alt="Icon"
                                          />
                                        </div>
                                        <div className="col-11">
                                          <a
                                            href={item.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={(e) =>
                                              handleAnnouncementClick(
                                                e,
                                                item.url,
                                                item
                                              )
                                            }
                                            style={{
                                              textDecoration: "underline",
                                              color: "#2378e9",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            {item.description}
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )
                          )}
                        </div>
                      </marquee>
                    </div>
                  </div>
                ) : (
                  // Collapsed view with left icon
                  <div className={styles.marquee_left_icon}>
                    <span onClick={toggleView} style={{ cursor: "pointer" }}>
                      <ChevronLeftIcon />
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <CourseCategory />
      <Top10squad />

      {/* announcement */}
    </div>
  );
}
export default IndexPage;
