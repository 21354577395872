import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { R_PER_PAGE } from "../../../labels";
import { AxiosInstance } from "../../../axios";
import ModelCompontent from "../../../pages/dashboard/ModelCompontent";
import Swal from "sweetalert2";
import Loader from "../Loader";
import html2pdf from "html2pdf.js";

const FILTER_MODE_OF_EXAM = 1;
const FILTER_MEDIUM_OF_EXAM = 2;
const FILTER_EXAM_STATUS = 3;

function DownloadHallTicket() {
  const moment = require("moment-timezone");
  const [listData, setListData] = useState([]);
  const userJSON = sessionStorage.getItem("userDetails");
  const txtBSearch = useRef(null); //search icon
  let userid = null;
  let user_type = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
      user_type = user.user_type;
    }
  } else {
    console.log("User Id cannot available in session storage");
  }
  // List data load table

  let page = R_PER_PAGE;

  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showModalData, setShowModalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const [dCourse, setDcourse] = useState([]);
  const [dCourses, setDcourses] = useState([]);
  const [ssubject, setSsubject] = useState([]);
  const [ssubjects, setSsubjects] = useState(ssubject);
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState([]);
  const [updateListData, setUpdateListData] = useState(listData);
  const [csvListData, setCsvListData] = useState([]);
  const [isOpen, setIsOpen] = useState({
    status: false,
    time: false,
    startDate: false,
    schedule: false,
    category: false,
    // course:false,
    medium: false,
    classMode: false,
    location: false,
    startDates: false,
  });
  const [appData, setAppData] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });
  const [appDatas, setAppDatas] = useState({
    FDate: "",
    TDate: "",
    Days: "",
    name: "",
  });
  const [timedata, setTimedate] = useState({
    STime: "",
    TTime: "",
    name: "",
  });

  const [updateChipData, setUpdateChipData] = useState([]);

  const [status, setStatus] = useState([]);

  const [location, setLocation] = useState([]);
  const [appOn, setAppOn] = useState([]);
  const [appOns, setAppOns] = useState([]);
  const [typeOn, setTypeOn] = useState([]);
  const [time, setTime] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [classModes, setClassModes] = useState([]);
  const [mediums, setMedium] = useState([]);

  //   laksh
  const [isModeOfExamFilterClicked, setIsModeOfExamFilterClicked] =
    useState(false);
  const [isMediumOfExamFilterClicked, setIsMediumOfExamFilterClicked] =
    useState(false);
  const [isExamStatusFilterClicked, setIsExamStatusFilterClicked] =
    useState(false);

  const [htmlContent, setHtmlContent] = useState("");
  const contentRef = useRef();
  // laksh
  let filterStatus = [];
  let filterType = [];
  let filterTime = [];
  let filterSchedule = [];
  let filterClassMode = [];
  let filterLocation = [];
  let filterMedium = [];
  let filterApplyed = [];
  let filterApplyeds = [];
  let filterSearch = [];

  useEffect(() => {
    filterStatus = status;
  }, [status]);
  useEffect(() => {
    filterLocation = location;
  }, [location]);
  useEffect(() => {
    filterClassMode = classModes;
  }, [classModes]);
  useEffect(() => {
    filterSchedule = schedule;
  }, [schedule]);

  useEffect(() => {
    filterMedium = mediums;
  }, [mediums]);

  useEffect(() => {
    filterType = typeOn;
  }, [typeOn]);

  useEffect(() => {
    filterApplyed = appOn;
  }, [appData]);

  useEffect(() => {
    filterApplyeds = appOns;
  }, [appDatas]);

  useEffect(() => {
    filterTime = time;
  }, [time]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);

  useEffect(() => {
    loadChipdata();
  }, []);

  useEffect(() => {
    loadListdata();
  }, [updateChipData]);

  // useEffect(() => {
  //   console.log(" updateListData : ", updateListData);
  //   currentData = updateListData.slice(start, end);
  //   // total table data  with pages
  //   totalPages = Math.ceil(updateListData.length / rowsPerPage);
  // }, [updateListData]);

  function updateStateData() {
    filterStatus = status;
    filterType = typeOn;
    filterApplyed = appOn;
    filterApplyeds = appOns;
    filterTime = time;
    filterSchedule = schedule;
    filterLocation = location;
    filterClassMode = classModes;
    filterMedium = mediums;
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  //sorting function

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  // filter checkbox update

  const handleCheckboxChange = (event, names, typename) => {
    // console.log(names, " : ", names);
    const { value, checked, name } = event.target;
    if (name === "Upcoming" || name === "Completed") {
      if (checked) {
        setStatus([...status, { id: value, name: name, type: "status" }]);
      } else {
        setStatus(status.filter((item) => item.id !== value));
      }
    } else if (names === "location") {
      if (checked) {
        // setStatus([...status, value]);
        setLocation([...location, { id: value, name: name, type: "location" }]);
      } else {
        setLocation(location.filter((item) => item.id !== value));
      }
    } else if (typename === "type") {
      if (checked) {
        setTypeOn([...typeOn, { name: value, type: "type" }]);
      } else {
        setTypeOn(typeOn.filter((item) => item.name !== value));
      }
    } else if (name === "Weekend" || name === "Regular") {
      if (checked) {
        setSchedule([...schedule, { id: value, name: name, type: "schedule" }]);
      } else {
        setSchedule(schedule.filter((item) => item.id !== value));
      }
    } else if (name === "Classroom" || name === "Online") {
      if (checked) {
        setClassModes([
          ...classModes,
          { id: value, name: name, type: "classMode" },
        ]);
      } else {
        setClassModes(classModes.filter((item) => item.id !== value));
      }
    } else if (name === "Tamil" || name === "English") {
      if (checked) {
        setMedium([...mediums, { id: value, name: name, type: "medium" }]);
      } else {
        setMedium(mediums.filter((item) => item.id !== value));
      }
    }
  };

  const handleCheckboxChanges = (event, names, typename) => {
    const { value, checked, name } = event.target;
    if (name === "Tamil" || name === "English") {
      if (checked) {
        setMedium([...mediums, { id: value, name: name, type: "medium" }]);
      } else {
        setMedium(mediums.filter((item) => item.id !== value));
      }
    }
  };
  // handle search function
  const handleSearch = (event) => {
    // console.log(event);
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };
  //---handel search icon function
  const handleSearchFocus = () => {
    if (txtBSearch.current) {
      txtBSearch.current.focus();

      // Create a new KeyboardEvent
      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        keyCode: 13,
        which: 13,
        code: "Enter",
        bubbles: true,
      });

      // Dispatch the event
      txtBSearch.current.dispatchEvent(event);
    }
  };
  //----------end
  const handleFilterCourseSearch = (event) => {
    const searchInput = event.target.value;

    if (searchInput != "") {
      const matchingObjects = dCourse.filter((item) =>
        item.toLowerCase().includes(searchInput.toLowerCase())
      );
      // console.log(matchingObjects, searchInput);

      // Do something with the matchingObjects, such as updating state or displaying them in the UI

      setDcourses(matchingObjects);
    } else {
      setDcourses(dCourse);
    }
  };

  const handleFilterSearch = (event) => {
    const searchInput = event.target.value;

    if (searchInput != "") {
      const matchingObjects = ssubject.filter((item) =>
        item.toLowerCase().includes(searchInput.toLowerCase())
      );
      // console.log(matchingObjects, searchInput);

      // Do something with the matchingObjects, such as updating state or displaying them in the UI

      setSsubjects(matchingObjects);
    } else {
      setSsubjects(ssubject);
    }
  };

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");
    setStatus([]);
    setSchedule([]);
    setTime([]);
    setLocation([]);
    setClassModes([]);
    setUpdateChipData([]);
    setAppOn([]);
    setMedium([]);
    setTypeOn([]);
    setAppData({
      FDate: "",
      TDate: "",
      Days: "",
    });
    setAppDatas({
      FDate: "",
      TDate: "",
      Days: "",
    });
    setUpdateListData(listData);
  };

  // filter apply button

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "status") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        status: false,
      });
    } else if (name === "location") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        location: false,
      });
    } else if (name === "appliedOn") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "appliedOns") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        startDates: false,
      });
    } else if (name === "type") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        category: false,
      });
    } else if (name === "time") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        time: false,
      });
    } else if (name === "schedule") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        schedule: false,
      });
    } else if (name === "classMode") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        classMode: false,
      });
    } else if (name === "medium") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        medium: false,
      });
    }
  };

  function loadListdata() {
    let tempstatus = [];
    let temptypeon = [];
    let tempsche = [];
    let tempclassMode = [];
    let tempLocanum = [];
    let templocation = [];
    let tempMedium = [];
    let tempenrollanum = [];
    let tempbookanum = [];
    let tempclassModeNum = [];

    status.map((obj) => {
      tempstatus.push(obj.id);
    });

    location.map((obj) => {
      templocation.push(obj.id);
    });

    classModes.map((obj) => {
      tempclassMode.push(obj.id);
    });

    mediums.map((obj) => {
      tempMedium.push(obj.name);
    });

    schedule.map((obj) => {
      tempsche.push(obj.name);
    });

    typeOn.map((obj) => {
      temptypeon.push(obj.name);
    });

    tempLocanum = templocation.map(Number);
    tempenrollanum = tempMedium.map(Number);
    tempclassModeNum = tempclassMode.map(Number);
    tempbookanum = tempstatus.map(Number);

    let statusFilter = {
      search: searchTerm.length > 0 ? searchTerm : "",
      user_id: userid,
      medium_of_exam: tempMedium ? tempMedium : "",
      mode_of_exam: tempclassMode ? tempclassModeNum : "",
      status: tempbookanum ? tempbookanum : "",
    };
    setIsLoading(true);
    AxiosInstance.post("api/modelexam/view_stud", statusFilter)
      .then((res) => {
        // console.log("editadasd ", res);
        if (res.data.status === true) {
          setIsLoading(false);
          const fiata = res.data.data;
          const serialNumberAddedData = fiata.map((data, index) => ({
            ...data,
            serialNumber: index + 1,
          }));
          setUpdateListData(serialNumberAddedData);
          const csvData = fiata.map((el) => {
            return {
              email: el.email,
              full_name: el.full_name,
              phone_no: el.phone_no,
              registered_on: el.registered_on,
              last_login: el.last_login,
              enrolled: el.enrolled,
              book_purchased: el.book_purchased,
            };
          });
          setCsvListData(csvData);
          const uniqueClassesSet = new Set(
            res.data.data.map((item) => item.category_class)
          );
          const uniqueClasses = [...uniqueClassesSet];
          setDcourse(uniqueClasses);
          setDcourses(uniqueClasses);
          const subjectSet = new Set(res.data.data.map((item) => item.subject));
          const subjectList = [...subjectSet];
          setSsubject(subjectList);
          setSsubjects(subjectList);
        } else if (res.data.status === false) {
          setUpdateListData([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setUpdateListData([]);
        setIsLoading(false);
        return error;
      });
  }

  function loadChipdata() {
    //setUpdateChipData([]);
    // console.log(location , filterLocation);
    let tempArr = [];
    // setUpdateChipData(status);
    filterSearch.map((obj) => {
      tempArr.push(obj);
    });
    filterStatus.map((obj) => {
      tempArr.push(obj);
    });

    filterLocation.map((obj) => {
      tempArr.push(obj);
    });

    filterType.map((obj) => {
      tempArr.push(obj);
    });
    filterTime.map((obj) => {
      tempArr.push(obj);
    });

    filterApplyed.map((obj) => {
      tempArr.push(obj);
    });

    filterApplyeds.map((obj) => {
      tempArr.push(obj);
    });

    filterSchedule.map((obj) => {
      tempArr.push(obj);
    });

    filterClassMode.map((obj) => {
      tempArr.push(obj);
    });

    filterMedium.map((obj) => {
      tempArr.push(obj);
    });

    // console.log(tempArr, "tempArr");
    setUpdateChipData(tempArr);
  }

  const handleTime = (e) => {
    const { name, value, checked } = e.target;
    if (name === "STime" || name === "TTime") {
      setTimedate((prevState) => ({ ...prevState, [name]: value }));
      // console.log(timedata.STime);
      // console.log(timedata.TTime);
      const from = name === "STime" ? value : timedata.STime;
      const to = name === "TTime" ? value : timedata.TTime;
      const dateRange = `${from} - ${to}`;

      setTime([
        {
          name: dateRange,
          STime: from,
          TTime: to,
          type: "time",
        },
      ]);
    }
  };

  const handleChangeDate = (date, name) => {
    const dateFilter = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateFilter);
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: dateFilter }));
      const from = name === "FDate" ? dateFilter : appData.FDate;
      const to = name === "TDate" ? dateFilter : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    }
  };

  const handleChangeDate_app = (date, name) => {
    const dateFilter = moment(date).format("YYYY-MM-DD");
    // console.log(name, "name", dateFilter);
    if (name === "FDate" || name === "TDate") {
      setAppDatas((prevState) => ({ ...prevState, [name]: dateFilter }));
      const from = name === "FDate" ? dateFilter : appDatas.FDate;
      const to = name === "TDate" ? dateFilter : appDatas.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOns([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRanges",
        },
      ]);
    }
  };

  const handleAppliedOn = (e) => {
    const { name, value, checked } = e.target;
    if (name === "FDate" || name === "TDate") {
      setAppData((prevState) => ({ ...prevState, [name]: value }));
      const from = name === "FDate" ? value : appData.FDate;
      const to = name === "TDate" ? value : appData.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOn([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRange",
        },
      ]);
    } else if (name === "Days") {
      if (checked) {
        setAppData((prevState) => ({ ...prevState, [name]: value }));
      }
      // else {
      //   setAppOn(appOn.filter((item) => item !== value));
      // }
      if (value === "Today") {
        const today = new Date();
        setAppOn([
          {
            name: value,
            fdate: moment(today.toDateString()).format("YYYY-MM-DD"),
            edate: moment(today.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Yesterday") {
        const today = new Date(); // Get today's date
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setAppOn([
          {
            name: value,
            fdate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            edate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tweek") {
        const today = new Date(); // Get today's date
        const thisWeekStartDate = moment(today)
          .subtract(0, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const thisWeekEndDate = moment(today)
          .subtract(0, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "This week",
            fdate: moment(thisWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lweek") {
        const today = new Date(); // Get today's date
        const lastWeekStartDate = moment(today)
          .subtract(1, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const lastWeekEndDate = moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate();
        setAppOn([
          {
            name: "Last week",
            fdate: moment(lastWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Tmonth") {
        const today = new Date(); // Get today's date
        const thisMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Get start date of this month
        const thisMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setAppOn([
          {
            name: "This month",
            fdate: moment(thisMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      } else if (value === "Lmonth") {
        const today = new Date();
        const lastMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setAppOn([
          {
            name: "Last month",
            fdate: moment(lastMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRange",
          },
        ]);
      }
    }
  };

  const handleAppliedOns = (e) => {
    const { name, value, checked } = e.target;
    if (name === "FDate" || name === "TDate") {
      setAppDatas((prevState) => ({ ...prevState, [name]: value }));
      const from = name === "FDate" ? value : appDatas.FDate;
      const to = name === "TDate" ? value : appDatas.TDate;
      const dateRange = `${from} - ${to}`;
      setAppOns([
        {
          name: dateRange,
          fdate: moment(from).format("YYYY-MM-DD"),
          edate: moment(to).format("YYYY-MM-DD"),
          type: "dateRanges",
        },
      ]);
    } else if (name === "Days") {
      if (checked) {
        setAppDatas((prevState) => ({ ...prevState, [name]: value }));
      }
      // else {
      //   setAppOn(appOn.filter((item) => item !== value));
      // }
      if (value === "Today") {
        const today = new Date();
        setAppOns([
          {
            name: value,
            fdate: moment(today.toDateString()).format("YYYY-MM-DD"),
            edate: moment(today.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      } else if (value === "Yesterday") {
        const today = new Date(); // Get today's date
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setAppOns([
          {
            name: value,
            fdate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            edate: moment(yesterday.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      } else if (value === "Tweek") {
        const today = new Date(); // Get today's date
        const thisWeekStartDate = moment(today)
          .subtract(0, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const thisWeekEndDate = moment(today)
          .subtract(0, "week")
          .endOf("week")
          .toDate();
        setAppOns([
          {
            name: "This week",
            fdate: moment(thisWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      } else if (value === "Lweek") {
        const today = new Date(); // Get today's date
        const lastWeekStartDate = moment(today)
          .subtract(1, "week")
          .startOf("week")
          .toDate(); // Get start date of last week
        const lastWeekEndDate = moment(today)
          .subtract(1, "week")
          .endOf("week")
          .toDate();
        setAppOns([
          {
            name: "Last week",
            fdate: moment(lastWeekStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastWeekEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      } else if (value === "Tmonth") {
        const today = new Date(); // Get today's date
        const thisMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        ); // Get start date of this month
        const thisMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setAppOns([
          {
            name: "This month",
            fdate: moment(thisMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(thisMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      } else if (value === "Lmonth") {
        const today = new Date();
        const lastMonthStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        setAppOns([
          {
            name: "Last month",
            fdate: moment(lastMonthStartDate.toDateString()).format(
              "YYYY-MM-DD"
            ),
            edate: moment(lastMonthEndDate.toDateString()).format("YYYY-MM-DD"),
            type: "dayRanges",
          },
        ]);
      }
    }
  };
  // filter clear button
  const handleClear = (name) => {
    updateStateData();
    if (name === "appliedOn") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
      setIsOpen({
        ...isOpen,
        startDate: false,
      });
    } else if (name === "appliedOns") {
      setAppDatas({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOns([]);
      filterApplyeds = [];
      setIsOpen({
        ...isOpen,
        startDates: false,
      });
    } else if (name === "status") {
      // Clear the status array
      setStatus([]);
      filterStatus = [];
      setIsOpen({
        ...isOpen,
        status: false,
      });
      // setUpdateListData(listData);
    } else if (name === "type") {
      setTypeOn([]);
      filterType = [];

      setIsOpen({
        ...isOpen,
        type: false,
      });
    } else if (name === "time") {
      setTime([]);
      filterTime = [];

      setIsOpen({
        ...isOpen,
        time: false,
      });
    } else if (name === "schedule") {
      setSchedule([]);
      filterSchedule = [];

      setIsOpen({
        ...isOpen,
        schedule: false,
      });
    } else if (name === "classMode") {
      setClassModes([]);
      filterClassMode = [];

      setIsOpen({
        ...isOpen,
        classMode: false,
      });
    } else if (name === "medium") {
      setMedium([]);
      filterMedium = [];

      setIsOpen({
        ...isOpen,
        medium: false,
      });
    } else if (name === "location") {
      // Clear the status array
      setLocation([]);
      filterLocation = [];
      setIsOpen({
        ...isOpen,
        location: false,
      });
      // setUpdateListData(listData);
    }
    loadChipdata();
  };

  // //   checking once again  -  handle delete function
  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "status") {
      setStatus(status.filter((item) => item.id !== chipToDelete.id));
      filterStatus = status.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "location") {
      setLocation(location.filter((item) => item.id !== chipToDelete.id));
      filterLocation = location.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "type") {
      setTypeOn(typeOn.filter((item) => item.name !== chipToDelete.name));
      filterType = typeOn.filter((item) => item.name !== chipToDelete.name);
    } else if (chipToDelete.type === "dayRange") {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
    } else if (chipToDelete.type === "dayRanges") {
      setAppDatas({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOns([]);
      filterApplyeds = [];
    } else if (chipToDelete.type === "time") {
      setTimedate({
        STime: "",
        TTime: "",
        name: "",
      });
      setTime([]);
      filterTime = [];
    } else if (chipToDelete.type === "schedule") {
      setSchedule(schedule.filter((item) => item.id !== chipToDelete.id));
      filterSchedule = schedule.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "classMode") {
      setClassModes(classModes.filter((item) => item.id !== chipToDelete.id));
      filterClassMode = classModes.filter(
        (item) => item.id !== chipToDelete.id
      );
    } else if (chipToDelete.type === "medium") {
      setMedium(mediums.filter((item) => item.id !== chipToDelete.id));
      filterMedium = mediums.filter((item) => item.id !== chipToDelete.id);
    } else {
      setAppData({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOn([]);
      filterApplyed = [];
      setAppDatas({
        FDate: "",
        TDate: "",
        Days: "",
      });
      setAppOns([]);
      filterApplyeds = [];
    }

    loadChipdata();
  };

  // const handleFilter = (e, filterType) => {
  //   e.preventDefault();
  //   if (filterType === FILTER_MODE_OF_EXAM) {
  //     setIsModeOfExamFilterClicked((clicked) => !clicked);
  //   } else if (filterType === FILTER_MEDIUM_OF_EXAM) {
  //     setIsMediumOfExamFilterClicked((clicked) => !clicked);
  //   } else if (filterType === FILTER_EXAM_STATUS) {
  //     setIsExamStatusFilterClicked((clicked) => !clicked);
  //   } else {
  //     console.error("un-handled condition in handleFilter function");
  //   }
  // };

  const handleFilter = (e, boxName) => {
    if (boxName === "status") {
      setIsOpen((prevState) => ({
        time: false,
        startDate: false,
        schedule: false,
        category: false,
        // course:false,
        medium: false,
        classMode: false,
        location: false,
        status: !prevState.status,
      }));
    } else if (boxName === "location") {
      setIsOpen((prevState) => ({
        time: false,
        startDate: false,
        schedule: false,
        category: false,
        // course:false,
        medium: false,
        classMode: false,
        status: false,
        location: !prevState.location,
      }));
    } else if (boxName === "time") {
      setIsOpen((prevState) => ({
        startDate: false,
        schedule: false,
        category: false,
        // course:false,
        medium: false,
        classMode: false,
        status: false,
        location: false,
        time: !prevState.time,
      }));
    } else if (boxName === "startDate") {
      setIsOpen((prevState) => ({
        schedule: false,
        category: false,
        // course:false,
        medium: false,
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: !prevState.startDate,
      }));
    } else if (boxName === "created_on") {
      setIsOpen((prevState) => ({
        schedule: false,
        category: false,
        // course:false,
        medium: false,
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        created_on: !prevState.created_on,
      }));
    } else if (boxName === "schedule") {
      setIsOpen((prevState) => ({
        category: false,
        created_on: false,
        medium: false,
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        schedule: !prevState.schedule,
      }));
    } else if (boxName === "category") {
      setIsOpen((prevState) => ({
        created_on: false,
        medium: false,
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        schedule: false,
        category: !prevState.category,
      }));
    }
    // else if (boxName === "course") {
    //   setIsOpen((prevState) => ({
    //     ...prevState,
    //     course: !prevState.course,
    //   }));
    // }
    else if (boxName === "medium") {
      setIsOpen((prevState) => ({
        category: false,
        created_on: false,
        classMode: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        schedule: false,
        medium: !prevState.medium,
      }));
    } else if (boxName === "classMode") {
      setIsOpen((prevState) => ({
        category: false,
        created_on: false,
        status: false,
        location: false,
        time: false,
        startDate: false,
        schedule: false,
        medium: false,
        classMode: !prevState.classMode,
      }));
    }
  };
  // generate pdf when user click download button
  // const generatePDF = () => {
  //   // Create a new jsPDF instance
  //   const doc = new jsPDF();

  //   // Add a title to the PDF document
  //   doc.text("Table Data", 10, 10);

  //   // Convert the table to a JavaScript array
  //   const tableData = [];

  //   // Add header row to tableData array
  //   const headers = [];
  //   const tableHeader = document.querySelectorAll("#example th");
  //   for (const header of tableHeader) {
  //     headers.push(header.innerText);
  //   }
  //   tableData.push(headers);

  //   // Add data rows to tableData array
  //   const tableRows = document.querySelectorAll("#example tbody tr");
  //   for (const row of tableRows) {
  //     const rowData = [];
  //     const rowCells = row.querySelectorAll("td");
  //     for (const cell of rowCells) {
  //       rowData.push(cell.innerText);
  //     }
  //     tableData.push(rowData);
  //   }

  //   // Add the table to the PDF document
  //   doc.autoTable({
  //     head: [tableData[0]], // Use the first row as the table header
  //     body: tableData.slice(1), // Use the remaining rows as the table body
  //   });
  //   // Save the PDF document
  //   doc.save("AdmissionData.pdf");
  // };
  const generatePDF = () => {
    // Create a new jsPDF instance
    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // Landscape orientation
      unit: "in", // Use inches as the unit of measurement
      format: [9.5, 11], // Set the dimensions to a standard letter size (9.5 x 11 inches)
    });

    doc.setFont("arabic"); // Set the font to 'arabic'
    doc.setFontSize(12); // Adjust the desired font size
    // Add a title to the PDF document
    doc.text("Register User data", 10, 10);

    // Convert the table to a JavaScript array
    const tableData = [];

    const headers = [
      "email",
      "full_name",
      "phone_no",
      "registered_on",
      "last_login",
      "enrolled",
      "book_purchased",
    ]; // Assuming updateListData is your state variable
    tableData.push(headers);

    updateListData.forEach((item) => {
      const rowData = [];
      headers.forEach((header) => {
        rowData.push(item[header]);
      });
      tableData.push(rowData);
    });

    // Define the column widths for the header columns (adjust as needed)
    // Calculate column widths based on the content of header and tbody cells
    const headerColumnStyles = {};
    const tbodyColumnStyles = {};

    headers.forEach((header, index) => {
      const maxHeaderWidth = Math.max(
        ...tableData
          .slice(1) // Exclude the header row
          .map((row) => {
            const cellValue =
              row[index] !== null ? row[index].toString() : "None"; // Replace null with an empty string
            return doc.getStringUnitWidth(cellValue) * 1 + 6; // Adjust the multiplier as needed
          })
      );
      const maxTbodyWidth = Math.max(
        ...tableData
          .slice(1) // Exclude the header row
          .map((row) => {
            const cellValue =
              row[index] !== null ? row[index].toString() : "None"; // Replace null with an empty string
            return doc.getStringUnitWidth(cellValue) * 2 + 6; // Adjust the multiplier as needed
          }) // Adjust the multiplier as needed
      );

      headerColumnStyles[index] = { columnWidth: maxHeaderWidth };
      tbodyColumnStyles[index] = { columnWidth: maxTbodyWidth };
    });

    // Add data rows to tableData array
    const tableOptions = {
      head: [tableData[0]], // Use the first row as the table header
      body: tableData.slice(1), // Use the remaining rows as the table body
      // columnStyles: { ...headerColumnStyles, ...tbodyColumnStyles }, // Specify dynamic column widths
    };

    // Add the table to the PDF document
    doc.autoTable(tableOptions);

    // Save the PDF document
    doc.save("UserListData.pdf");
  };

  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;

    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      setUpdateListData([]);
    }
    loadListdata();
  };
  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const currentData = updateListData.slice(start, end);

  const handleReadView = (details) => {
    if (details) {
      if (details.request_hall_ticket_status === 0) {
        if (details.hall_ticket_path !== null) {
          window.open(details.hall_ticket_path, "_blank");
        } else {
          const dat = {
            main_exam_reg_no: "",
            mainexam_hallticket_filepath: "",
            original_mainexam_hallticket: "",
            user_id: details.user_id,
            batch_id: details.batch_id,
            admission_id: details.admission_id,
            exam_id: details.exam_id,
          };
          AxiosInstance.post("api/user/mainExam/add", dat)
            .then((res) => {
              if (res.data.status === true) {
                // console.log(res.data.path,"LalithTwo")
                const path = res.data.path;
                if (path !== "") {
                  // console.log(res.data.path,"Lalithfour")
                  window.open(path, "_blank");
                }
              } else {
                console.log("Something Went Wrong");
              }
            })
            .catch((error) => {
              return error;
            });
        }
      } else if (
        details.request_hall_ticket_status === 1 &&
        details.hall_ticket_upload_status === 0
      ) {
        setShowModal(true);
        setShowModalData({
          user_id: details.user_id,
          batch_id: details.batch_id,
          admission_id: details.admission_id,
          exam_id: details.exam_id,
        });
      }
    } else {
      setShowModal(false);
      setShowModalData({});
    }
  };

  const handleClearPdf = (detail) => {
    AxiosInstance.post("api/modelexam/clear_pdf", {
      id: detail.id,
    })
      .then((res) => {
        if (res.data.status === true) {
          Swal.fire({
            title: "Success",
            text: res.data.message,
            icon: "Success",
            confirmButtonText: "Ok",
          });
          window.location.reload();
        } else {
          Swal.fire({
            title: "Warning",
            text: res.data.message,
            icon: "Warning",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const handleCreatePdf = (details_k) => {
    const dat = {
      main_exam_reg_no: "",
      mainexam_hallticket_filepath: "",
      original_mainexam_hallticket: "",
      user_id: details_k.user_id,
      batch_id: details_k.batch_id,
      admission_id: details_k.admission_id,
      exam_id: details_k.exam_id,
    };
    setIsLoading(true);
    AxiosInstance.post("api/modelexam/create_pdf", dat)
      // , {
      // responseType: 'blob', // important for downloading file
      // })
      .then(async (res) => {
        // if (res.data.status === true) {
        const path = res.data;
        if (path) {
          const modifiedHtml = await convertUrlsToBase64InHtml(path.path);
          setHtmlContent(modifiedHtml);

          // console.log("Something Went Wrong modifiedHtml ", modifiedHtml);
          // After setting the HTML content, generate the PDF
          setTimeout(() => {
            setIsLoading(false);
            const content = contentRef.current;
            const options = {
              margin: [0, 0, 0, 0],
              filename: details_k.model_exam_name + ".pdf",
              image: { type: "jpeg", quality: 0.7 },
              html2canvas: { width: 2480, scale: 1, dpi: 300 },
              jsPDF: { unit: "pt", format: "A4", orientation: "portrait" },
            };
            html2pdf().from(content.innerHTML).set(options).save();
          }, 2000);
        } else {
          setIsLoading(false);
          console.log("Something Went Wrong");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return error;
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Function to convert image URLs to Base64 and replace them in HTML content
  const convertUrlsToBase64InHtml = async (html) => {
    const imgUrlRegex = /<img[^>]+src="([^">]+)"/g;
    let match;
    let modifiedHtml = html;

    while ((match = imgUrlRegex.exec(html)) !== null) {
      const url = match[1];
      try {
        const base64 = await convertImageUrlToBase64(url);
        modifiedHtml = modifiedHtml.replace(url, base64);
      } catch (err) {
        // setError(`Failed to convert image URL to Base64: ${url}`);
        console.log("err", url);
      }
    }
    const bgUrlRegex = /background-image:\s*url\(['"]?([^'"\)]+)['"]?\)/g;
    // let match;
    // let modifiedHtml = html;

    while ((match = bgUrlRegex.exec(html)) !== null) {
      const url = match[1];
      try {
        const base64 = await convertImageUrlToBase64(url);
        modifiedHtml = modifiedHtml.replace(url, base64);
      } catch (err) {
        // setError(`Failed to convert image URL to Base64: ${url}`);
        console.log("err", url);
      }
    }

    return modifiedHtml;
  };

  // Function to convert a single image URL to Base64
  const convertImageUrlToBase64 = (url) => {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result); // Base64 string
          };
          reader.onerror = () => {
            reject("Error reading blob as Base64");
          };
          reader.readAsDataURL(blob);
        })
        .catch((err) => {
          reject("err");
        });
    });
  };

  //   useEffect(async ()=> {
  //     const content = contentRef.current;
  //     const options = {
  //       margin: [0,0,0,0],
  //       filename: 'my-document.pdf',
  //       image: { type: 'jpeg', quality: 0.7 },
  //       html2canvas: { width: 2480, scale: 1, dpi: 300 },
  //       jsPDF: { unit: 'pt', format: 'A4', orientation: 'portrait' }
  //       };
  //       html2pdf().from(content.innerHTML).set(options).save();

  // },[htmlContent]);

  // console.log(dCourse,"setFCourse" , ssubject , "ssubject");
  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      <div
        ref={contentRef}
        id="html-content"
        style={{ display: "none" }}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      ></div>
      {isLoading && <Loader />}
    </div>
  ) : (
    <div className=" BathDet ">
      <div
        ref={contentRef}
        id="html-content"
        style={{ display: "none" }}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      ></div>

      <ModelCompontent
        showModelData={showModalData}
        show={showModal}
        handleClose={handleCloseModal}
      />
      <h6 className="Title">Download Hall Ticket</h6>
      {user_type !== 2 && (
        <div className="row ">
          <div className="col-lg-5 col-md-6">
            <div class="input-group">
              <input
                ref={txtBSearch} //new
                type="text"
                class="Inputs_search"
                id="SearchInput"
                name="SearchInput"
                placeholder="Search email here."
                onKeyDown={handleSearch}
              />
              <span
                class="input-group-text"
                id="SearchInput"
                onClick={handleSearchFocus}
              >
                <span className="material-icons" onClick={handleSearchFocus}>
                  search
                </span>
              </span>
            </div>
          </div>
          <div className="col-lg-3 col-md-4"></div>
          <div className="col-lg-6  d-flex justify-content-evenly col-md-6"></div>
        </div>
      )}

      {updateChipData.length > 0 ? (
        <div className="row mt-3">
          <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {updateChipData.map((data, index) => {
                // console.log(data, " - data - ", index);
                let icon;
                let key = "" + data.name;
                return (
                  <ListItem key={data}>
                    <Chip
                      icon={icon}
                      label={key}
                      onDelete={handleDelete(data, index)}
                    />
                  </ListItem>
                );
              })}
              <ListItem>
                {/* <Chip
                      label=" Clear All Filters"
                      onClick={handleAllClear}
                    /> */}
                <a className="allclear" href="#" onClick={handleAllClear}>
                  Clear All Filters
                </a>
              </ListItem>
            </Paper>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="row mt-3 ms-1 me-1">
        <div className="col-md-12 table-responsive p-0">
          <table className="table" id="example">
            <thead>
              <tr>
                <th id="th" style={{ width: "5%" }}>
                  <span className="text_th">S.no</span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "serialNumber")}
                  >
                    {" "}
                    import_export{" "}
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "full_name")}
                  >
                    Batch
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "full_name")}
                  >
                    {" "}
                    import_export{" "}
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "model_exam_name")}
                  >
                    Model Exam Name
                  </span>
                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "model_exam_name")}
                  >
                    {" "}
                    import_export{" "}
                  </span>{" "}
                </th>
                {/* <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "mode_of_exam")}
                  >
                    Mode of Exam
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "mode_of_exam")}
                  >
                    import_export
                  </span>
                  <span
                    class={` align-middle material-icons  ${
                      classModes.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "classMode")}
                  >
                    filter_list
                  </span>
                  {isOpen.classMode === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Online"
                              value="1"
                              id="Online"
                              checked={classModes.some((element) => {
                                if (element.id === "1") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Online")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              Online
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Classroom"
                              value="0"
                              id="Classroom"
                              checked={classModes.some((element) => {
                                if (element.id === "0") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Classroom")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              Offline
                            </label>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("classMode")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("classMode")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th> */}
                {user_type !== 2 && (
                  <th id="th">
                    <span
                      className="text_th"
                      onClick={(e) => handleSort(e, "email")}
                    >
                      Email
                    </span>

                    <span
                      class="material-icons align-middle"
                      onClick={(e) => handleSort(e, "email")}
                    >
                      import_export
                    </span>

                    {/* {isOpen.medium === true && (
         <div className="filterBoxs p-1">
           <div className="row mt-4">
             <div className="col-md-12">
               <div class="form-check ms-3">
                 <input
                   class="form-check-input"
                   type="checkbox"
                   name="Tamil"
                   value="1"
                   id="Tamil"
                   checked={mediums.some((element) => {
                     if (element.id === "1") {
                       return true;
                     }
                     return false;
                   })}
                   onChange={(e) => handleCheckboxChange(e, "Tamil")}
                 />
                 <label
                   class="form-check-label "
                   for="defaultCheck1"
                 >
                   Tamil
                 </label>
               </div>
             </div>
           </div>
           <div className="row mt-2">
             <div className="col-md-12">
               <div class="form-check ms-3">
                 <input
                   class="form-check-input"
                   type="checkbox"
                   name="English"
                   value="0"
                   id="English"
                   checked={mediums.some((element) => {
                     if (element.id === "0") {
                       return true;
                     }
                     return false;
                   })}
                   onChange={(e) =>
                     handleCheckboxChange(e, "English")
                   }
                 />
                 <label
                   class="form-check-label "
                   for="defaultCheck1"
                 >
                   English
                 </label>
               </div>
             </div>
           </div>

           <hr />
           <div className="row mt-3">
             <div className="col-md-4  col-4 col-sm-4">
               <span
                 className="clearFilter ms-2 "
                 style={{ cursor: "pointer" }}
                 onClick={() => handleClear("medium")}
               >
                 Clear
               </span>
             </div>
             <div className="col-md-8  col-8 col-sm-8">
               <button
                 className="FilterButton"
                 onClick={() => handleApplyFilter("medium")}
               >
                 Apply Filter
               </button>
             </div>
           </div>
         </div>
       )} */}
                  </th>
                )}

                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "medium_of_exam")}
                  >
                    Medium of Exam
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "medium_of_exam")}
                  >
                    import_export
                  </span>
                  <span
                    class={` align-middle material-icons  ${
                      mediums.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "medium")}
                  >
                    filter_list
                  </span>
                  {isOpen.medium === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Tamil"
                              value="1"
                              id="Tamil"
                              checked={mediums.some((element) => {
                                if (element.id === "1") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) => handleCheckboxChange(e, "Tamil")}
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              Tamil
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="English"
                              value="0"
                              id="English"
                              checked={mediums.some((element) => {
                                if (element.id === "0") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "English")
                              }
                            />
                            <label
                              class="form-check-label "
                              for="defaultCheck1"
                            >
                              English
                            </label>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("medium")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("medium")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "phone_no")}
                  >
                    {" "}
                    Venue of Examination
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "phone_no")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "phone_no")}
                  >
                    {" "}
                    Exam Date
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "phone_no")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "phone_no")}
                  >
                    {" "}
                    Time
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "phone_no")}
                  >
                    import_export
                  </span>
                </th>
                <th id="th">
                  <span
                    className="text_th"
                    onClick={(e) => handleSort(e, "enrolled")}
                  >
                    Exam Status
                  </span>

                  <span
                    class="material-icons align-middle"
                    onClick={(e) => handleSort(e, "status")}
                  >
                    import_export
                  </span>
                  <span
                    class={` align-middle material-icons  ${
                      status.length > 0 ? "active_icons" : ""
                    }`}
                    onClick={(e) => handleFilter(e, "status")}
                  >
                    filter_list
                  </span>
                  {isOpen.status === true && (
                    <div className="filterBoxs p-1">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Upcoming"
                              value="0"
                              id="Upcoming"
                              checked={status.some((element) => {
                                if (element.id === "0") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Upcoming")
                              }
                            />
                            <label
                              class="form-check-label PinkHallStatusFilBox "
                              for="defaultCheck1"
                            >
                              Upcoming
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Ongoing"
                              value="1"
                              id="Ongoing"
                              checked={status.some((element) => {
                                if (element.id === "1") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Ongoing")
                              }
                            />
                            <label
                              class="form-check-label  GreenHallStatusFilBox"
                              for="defaultCheck1"
                            >
                              Ongoing
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div class="form-check ms-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="Completed"
                              value="2"
                              id="Completed"
                              checked={status.some((element) => {
                                if (element.id === "2") {
                                  return true;
                                }
                                return false;
                              })}
                              onChange={(e) =>
                                handleCheckboxChange(e, "Completed")
                              }
                            />
                            <label
                              class="form-check-label  GreyHallStatusFilBox"
                              for="defaultCheck1"
                            >
                              Completed
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mt-3">
                        <div className="col-md-4  col-4 col-sm-4">
                          <span
                            className="clearFilter ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClear("status")}
                          >
                            Clear
                          </span>
                        </div>
                        <div className="col-md-8  col-8 col-sm-8">
                          <button
                            className="FilterButton"
                            onClick={() => handleApplyFilter("status")}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </th>
                <th id="th">
                  <span className="text_th">Download hall ticket</span>
                  {/*  */}
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData.length === 0 ? (
                <tr>
                  <td colspan="10" style={{ textAlign: "center" }}>
                    No record to display
                  </td>
                </tr>
              ) : (
                currentData.map((course, index) => (
                  <tr key={index} title={course.pk}>
                    <td id="td">{course.serialNumber}</td>
                    <td id="td">{course.batch_name} </td>
                    <td id="td">{course.model_exam_name}</td>
                    {user_type !== 2 && <td id="td"> {course.email}</td>}

                    {/* <td id="td"> {course.mode_of_exam}</td> */}
                    <td id="td"> {course.medium_of_exam}</td>
                    <td id="td"> {course.venue_exam}</td>
                    <td id="td"> {course.exam_date}</td>
                    <td id="td">
                      {" "}
                      {`${moment(course.start_time, "HH:mm").format(
                        "h:mm A"
                      )} - ${moment(course.end_time, "HH:mm").format(
                        "h:mm A"
                      )}`}
                    </td>
                    <td id="td">
                      {course.status === 0 && (
                        <span className="PinkHallStatus">Upcoming</span>
                      )}
                      {course.status === 2 && (
                        <span className="GreyHallStatus">Completed</span>
                      )}
                      {course.status === 1 && (
                        <span className="GreenHallStatus">Ongoing</span>
                      )}
                    </td>
                    <td id="td">
                      <div className="d-flex justify-content-center">
                        {/* {course.hall_ticket_genrate_status === 2 && (
                          <button
                            className="button_download"
                            onClick={() => handleReadView(course)}
                          >
                            {" "}
                            <span class="material-icons">download_icon</span>
                          </button>
                        )} */}
                        {/* {course.hall_ticket_genrate_status === 2 && (
                          <button
                            className="buttonClear_download"
                            onClick={() => handleClearPdf(course)}
                          >
                            {" "}
                            Clear
                          </button>
                        )} */}
                        {course.hall_ticket_genrate_status === 2 && (
                          <button
                            className="button_download"
                            onClick={() => handleCreatePdf(course)}
                          >
                            {" "}
                            <span class="material-icons">download_icon</span>
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* pagination */}
      <div className="row mt-3 ms-1 me-1">
        <div className="col-md-12">
          <div className="pag w-100 dflexcenter">
            <span className="ipage">Items Per Page</span>
            <select
              name="pageCount"
              id="pageCount"
              defaultValue={rowsPerPage}
              onChange={handlePageCount}
              className="ms-2"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span className="pagenumber ms-2">
              {" "}
              {updateListData.length > 0
                ? currentPage * rowsPerPage + 1 - rowsPerPage
                : 0}{" "}
              -
              {updateListData.length < currentPage * rowsPerPage
                ? updateListData.length
                : currentPage * rowsPerPage}{" "}
              of {updateListData.length}
            </span>
            <button
              className="prev_button ms-2 me-2"
              onClick={(e) => handlePrev(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <span class="material-icons">chevron_left</span>
            </button>
            <button
              className="prev_button"
              onClick={() => handleNext(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <span class="material-icons">chevron_right</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadHallTicket;
