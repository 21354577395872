import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ICON_WARNING from "@mui/icons-material/Warning";
import SecondaryButton from "../../../../../ui/SecondaryButton";
import PrimaryButton from "../../../../../ui/PrimaryButton";
import NavigationButton from "../../../../../ui/NavigationButton";
import { ALLOWED_MINUTES, MEDIA_SIZE_LG } from "../../../../../utils/helper";
import ButtonContainer from "../../../../../ui/ButtonContainer";
import StyledTitle from "../../../../../ui/StyledTitle";
import Styles from "../../../../../ui/NavigationButton.module.css";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import CustomDownloadButton from "../../../../ReusuableComponent/CustomDownloadButton/CustomDownloadButton";
import { CSVLink } from "react-csv";
import { ImageUrlPrefix } from "../../../../../labels";
import { Download } from "@mui/icons-material";

// warning-section
const WarningSectionContainer = styled.section`
  background-color: var(--color-brand-200);
  color: var(--color-brand-800);
  padding: 15px;
  margin-top: 10px;
  border: 1px solid var(--color-brand-800);
  border-radius: var(--border-radius-md);
  margin-bottom: 20px;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    padding: 30px;
    margin-bottom: 30px;
  }
`;

const WarningSectionPara = styled.p`
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
`;

export default function ViewTestAlert({
  type_batch,
  sDate = new Date(),
  startTime = new Date(),
  endTime = new Date(),
  onClickJoinTest,
  onClickViewtestPerfomance,
  onClickReviewSubmission,
  onClickDownloadAnswerSheet,
  onClickDownloadAnswerBooklet,
  isResultsPublished,
  attendenceStatus,
  attempt_no_status,
  ans_sheet_download_req,
  onDownloadClick,
  hoursTime,
  minutesTime,
  secondsTime,
  isRunning,
  csvName,
  methodOfTestObjective,
  methodOfTestDesctiptive,
  que_paper_filepath,
  answer_booklet_path,
  AnswerBookletUploadPath,
  AnswerSheetUploadPath,
  QuestionPaperUploadPath,
  AnswerKeyUploadPath,
  AnswerBookletUploadOrignialPath,
  onclickUploadAnswerSheet,
  UPLOADSTTAUS,
  UPLOAD_STTAUS,
  UASTATUS,
  handleUpload,
  labelStatus,
  // onClickDownloadQuestionPaper,
}) {
  const navigate = useNavigate();

  // const vv = moment(sDate).format("YYYY/DD/MM");
  // console.log(vv, "vv", sDate);
  const TODAY = new Date(sDate);
  const [stHour, stMinute, stSecond] = startTime.split(":");

  const [endHour, endMinute, endSecond] = endTime.split(":");

  const startDateTime = new Date(
    TODAY.getFullYear(),
    TODAY.getMonth(),
    TODAY.getDate(),
    stHour,
    stMinute,
    stSecond
  );

  const endDateTime = new Date(
    TODAY.getFullYear(),
    TODAY.getMonth(),
    TODAY.getDate(),
    endHour,
    endMinute,
    endSecond
  );

  const calculatedStartTime = new Date(
    startDateTime.getFullYear(),
    startDateTime.getMonth(),
    startDateTime.getDate(),
    startDateTime.getHours(),
    startDateTime.getMinutes() - ALLOWED_MINUTES,
    startDateTime.getSeconds()
  );
  const calculatedEndTime = new Date(
    startDateTime.getFullYear(),
    startDateTime.getMonth(),
    startDateTime.getDate(),
    startDateTime.getHours(),
    startDateTime.getMinutes() + ALLOWED_MINUTES,
    startDateTime.getSeconds()
  );

  const [isAllowed, setIsAllowed] = useState(false);
  const [isAllowedstatus, setIsAllowedstatus] = useState(false);
  const [isAfterCalculatedEnd, setIsAfterCalculatedEnd] = useState(false);
  const [isAfterCalculatedEndFuture, setIsAfterCalculatedEndFuture] =
    useState(false);

  const [isAfterEnd, setIsAfterEnd] = useState(false);
  const [isAfterCalculatedEndFM, setIsAfterCalculatedEndFM] = useState(false);
  const [showTiming, setShowTiming] = useState(false);
  const [isBeforeHalfHour, setIsBeforeHalfHour] = useState(false);
  const [isAfterFTEEnMIn, setIsAfterFTEEnMIn] = useState(false);
  const [isAfterOneHour, setIsAfterOneHour] = useState(false);
  const [isRunningHourStatus, setIsRunningHourStatus] = useState(false);
  const [isRunningHour, setIsRunningHour] = useState("0 h 0 m 0 s");
  const [isOneHourAfterEnd, setIsOneHourAfterEnd] = useState(false);
  const currentDate = getDateOnly(new Date());

  const endDate = getDateOnly(calculatedEndTime);
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setIsAllowed(
        new Date() >= calculatedStartTime && new Date() <= calculatedEndTime
      );

      // console.log(new Date(), calculatedEndTime, "-----", endDateTime);

      setIsAfterCalculatedEnd(new Date() > calculatedEndTime); //tempToday > tempCendTime
      setIsAfterEnd(new Date() > endDateTime); //tempToday > tempEndTime
      // Calculate the end time minus 15 minutes
      const fifteenMinutes = 15 * 60 * 1000; // 15 minutes in milliseconds
      const endTimeMinusFifteenMinutes = new Date(
        calculatedEndTime.getTime() - fifteenMinutes
      );
      // Check if the current time is within 15 minutes before the calculated end time
      const isWithinFifteenMinutesBeforeEnd =
        new Date() >= endTimeMinusFifteenMinutes &&
        new Date() <= calculatedEndTime;
      // Set the state based on whether the current time is within the 15-minute window
      setIsAfterCalculatedEndFM(isWithinFifteenMinutesBeforeEnd);
      // console.log(currentDate, endDate, "hhhhhh", currentDate < endDate);
      setIsAfterCalculatedEndFuture(currentDate < endDate);
      // Check if the current time is within the 30 minutes before startDateTime
      setIsBeforeHalfHour(
        now >= new Date(startDateTime.getTime() - 30 * 60 * 1000) &&
        now < startDateTime
      );
      // Set showTiming to true after startDateTime has passed
      setShowTiming(now >= startDateTime);

      // Set isAfterOneHour to true one hour after the start time
      setIsAfterOneHour(
        now >= endDateTime &&
        now < new Date(endDateTime.getTime() + 60 * 60 * 1000)
      );

      // Update isAfterFTEEnMIn for 15 minutes after startDateTime
      setIsAfterFTEEnMIn(
        now >= startDateTime &&
        now < new Date(startDateTime.getTime() + 15 * 60 * 1000)
      );

      // Set isOneHourAfterEnd to true one hour after the end time
      setIsOneHourAfterEnd(
        now > new Date(endDateTime.getTime() + 60 * 60 * 1000) // Evaluates to true only after one hour
      );

      // Calculate remaining time until endDateTime
      const remainingTime = endDateTime - now;
      if (remainingTime > 0) {
        setIsRunningHourStatus(now >= startDateTime); // Starts running status at exact startDateTime
        // Calculate and set remaining hours, minutes, and seconds
        const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
        const seconds = Math.floor((remainingTime / 1000) % 60);

        setIsRunningHour(`${hours} h ${minutes} m ${seconds} s`);
      } else {
        // Timer ends here
        setIsRunningHour("0 h 0 m 0 s");
        setIsRunningHourStatus(false);
        clearInterval(interval);
      }
      setIsAllowedstatus(true);
    }, 100);

    return () => clearInterval(interval);
  }, [startDateTime]);

  function getDateOnly(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  const handleOnClickDownloadButton = (csvListData) => {
    window.open(`${ImageUrlPrefix}/${csvListData}`);
  };

  const navigateToPreviousScreen = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="container-fluid  BathHeader">
        <div className="row">
          <div className=" col-md-2 col-12 ">
            <h2 className="TitleHeader "> View Test</h2>
          </div>

          <div className="col-md-10 col-12 ListBtn">
            <ul class="List-Button ">
              <li class="ListBtnItems">
                <Link
                  onClick={navigateToPreviousScreen}
                  to="javascript:void(0)"
                  className="secondaryButton "
                >
                  {labelStatus === "1"
                    ? "Back to Test Performance "
                    : "Back to View Schedule"}
                </Link>
              </li>

              {isAllowedstatus === true &&
                !isRunningHourStatus &&
                !isAfterOneHour &&
                !isOneHourAfterEnd &&
                isBeforeHalfHour &&
                methodOfTestDesctiptive && (
                  <li className="ListBtnItems ">
                    <Link
                      onClick={
                        answer_booklet_path === null ||
                          answer_booklet_path === ""
                          ? (e) => e.preventDefault()
                          : AnswerBookletUploadPath
                      }
                      to={"javascript:void(0)"}
                      className={`${answer_booklet_path === null ||
                          answer_booklet_path === ""
                          ? "disabled_btnsss"
                          : ""
                        }
primaryButton download-pdf-link`}
                      variant="primary"
                    >
                      Download Answer Booklet
                    </Link>

                    {/* <button onClick={AnswerBookletUploadPath} className="primaryButton download-pdf-link">
                      <span className="material-icons align-middle text-white"></span>
                    
                    </button> */}
                  </li>
                )}

              {isAllowedstatus === true &&
                isAfterEnd &&
                !isRunningHourStatus &&
                isOneHourAfterEnd &&
                isResultsPublished &&
                methodOfTestDesctiptive && (
                  <li
                    className={`ListBtnItems ${!isAfterEnd || !isResultsPublished
                        ? "DisabledListviS"
                        : ""
                      }`}
                    onClick={onClickViewtestPerfomance}
                    disabled={!isAfterEnd || !isResultsPublished}
                  >
                    <Link className="primaryButton " variant="primary">
                      View Test Performance
                    </Link>
                  </li>
                )}

              {isAllowedstatus === true &&
                showTiming &&
                isAfterFTEEnMIn &&
                methodOfTestDesctiptive && (
                  <li
                    className={`ListBtnItems ${attendenceStatus === 0 ? "DisabledListviS" : ""
                      }`}
                  >
                    <Link
                      onClick={
                        que_paper_filepath === null || que_paper_filepath === ""
                          ? (e) => e.preventDefault()
                          : QuestionPaperUploadPath
                      }
                      className={`${que_paper_filepath === null || que_paper_filepath === ""
                          ? "disabled_btnsss"
                          : ""
                        } primaryButton download-pdf-link`}
                      variant="primary"
                    >
                      Download Question Paper
                    </Link>
                  </li>
                )}

              {isAllowedstatus === true &&
                !isRunningHourStatus &&
                isAfterOneHour &&
                UASTATUS === false &&
                methodOfTestDesctiptive && (
                  <li
                    className={`ListBtnItems ${attendenceStatus === 0 ||
                        que_paper_filepath === null ||
                        que_paper_filepath === ""
                        ? "DisabledListviS"
                        : ""
                      }`}
                    onClick={
                      attendenceStatus === 0 ||
                        que_paper_filepath === null ||
                        que_paper_filepath === ""
                        ? () => { }
                        : onclickUploadAnswerSheet
                    }
                  >
                    <Link
                      to="javascript:void(0)"
                      className="primaryButton "
                      variant="primary"
                    >
                      Upload Answer Sheet
                    </Link>
                  </li>
                )}

              {isAllowedstatus === true &&
                !isRunningHourStatus &&
                isAfterOneHour &&
                UASTATUS === true &&
                methodOfTestDesctiptive && (
                  <li
                    className={`ListBtnItems ${attendenceStatus === 0 ? "DisabledListviS" : ""
                      }`}
                    onClick={handleUpload}
                  >
                    <Link
                      to="javascript:void(0)"
                      className="primaryButton "
                      variant="primary"
                    >
                      Save Answer Sheet
                    </Link>
                  </li>
                )}

              {isAllowedstatus === true &&
                isRunningHourStatus &&
                methodOfTestDesctiptive && (
                  <div className="col-4 Timing_loading_Seconds ">
                    <div className="col-md-12 timeRead ">
                      <span className="Time">{isRunningHour}</span>
                    </div>
                  </div>
                )}

              {isAllowedstatus === true &&
                !isRunningHourStatus &&
                isOneHourAfterEnd &&
                isResultsPublished &&
                methodOfTestDesctiptive && (
                  <>
                    <li className={`ListBtnItems `}>
                      <Link
                        onClick={
                          attendenceStatus === 0
                            ? () => { }
                            : AnswerSheetUploadPath
                        }
                        to={"javascript:void(0)"}
                        disabled
                        className={
                          attendenceStatus === 0
                            ? "primaryButton download-pdf-link disabled_btn"
                            : "primaryButton download-pdf-link"
                        }
                        variant="primary"
                      >
                        Download Answer Sheet
                      </Link>
                    </li>
                    <li className={`ListBtnItems`}>
                      <Link
                        onClick={
                          attendenceStatus === 0
                            ? () => { }
                            : AnswerKeyUploadPath
                        }
                        to={"javascript:void(0)"}
                        disabled
                        className={
                          attendenceStatus === 0
                            ? "primaryButton download-pdf-link disabled_btn"
                            : "primaryButton download-pdf-link"
                        }
                        variant="primary"
                      >
                        Download Answer Key
                      </Link>
                    </li>
                  </>
                )}

              {isAllowedstatus === true &&
                isAfterEnd &&
                isResultsPublished &&
                methodOfTestDesctiptive && (
                  <li
                    className={`ListBtnItems `}
                    onClick={
                      attendenceStatus === 0
                        ? () => { }
                        : () => handleOnClickDownloadButton(que_paper_filepath)
                    }
                  >
                    <Link
                      onClick={
                        que_paper_filepath === null ||
                          que_paper_filepath === "" ||
                          attendenceStatus === 0
                          ? (e) => e.preventDefault()
                          : QuestionPaperUploadPath
                      }
                      className={`${que_paper_filepath === null ||
                          que_paper_filepath === "" ||
                          attendenceStatus === 0
                          ? "disabled_btnsss"
                          : ""
                        } primaryButton download-pdf-link`}
                      variant="primary"
                    >
                      Download Question Paper
                    </Link>
                  </li>
                )}

              {isAllowedstatus === true &&
                type_batch !== "4" &&
                attempt_no_status === 1 &&
                !isAfterCalculatedEnd &&
                UPLOADSTTAUS === 0 &&
                methodOfTestObjective &&
                isAfterCalculatedEndFuture === false && (
                  <PrimaryButton
                    disabled={!isAllowed}
                    onClick={onClickJoinTest}
                  >
                    <span className="material-icons align-middle me-2">
                      event_available
                    </span>
                    Join Test
                  </PrimaryButton>
                )}

              {isAllowedstatus === true &&
                type_batch !== "4" &&
                attempt_no_status === 1 &&
                methodOfTestObjective &&
                !isAfterCalculatedEnd &&
                UPLOADSTTAUS === 1 &&
                isAfterCalculatedEndFuture === false && (
                  <PrimaryButton disabled={isAllowed} onClick={onClickJoinTest}>
                    <span class="material-icons align-middle me-2">
                      event_available
                    </span>
                    Join Test
                  </PrimaryButton>
                )}

              {isAllowedstatus === true &&
                type_batch !== "4" &&
                attempt_no_status === 0 &&
                methodOfTestObjective &&
                !isAfterCalculatedEnd &&
                isAfterCalculatedEndFuture === false &&
                UPLOADSTTAUS === 0 && (
                  <PrimaryButton disabled={isAllowed} onClick={onClickJoinTest}>
                    <span class="material-icons align-middle me-2">
                      event_available
                    </span>
                    Join Test
                  </PrimaryButton>
                )}

              {isAllowedstatus === true &&
                type_batch !== "4" &&
                attempt_no_status === 0 &&
                methodOfTestObjective &&
                !isAfterCalculatedEnd &&
                UPLOADSTTAUS === 1 &&
                isAfterCalculatedEndFuture === false && (
                  <PrimaryButton
                    disabled={!isAllowed}
                    onClick={onClickJoinTest}
                  >
                    <span class="material-icons align-middle me-2">
                      event_available
                    </span>
                    Join Test
                  </PrimaryButton>
                )}

              {isAllowedstatus === true &&
                isAfterCalculatedEnd &&
                methodOfTestObjective && (
                  <>
                    <NavigationButton
                      className={`me-1  ${attendenceStatus === 0 ? "DisabledListviS" : ""
                        }`}
                      onClick={onClickViewtestPerfomance}
                      disabled={
                        attendenceStatus === 0 ||
                        !isAfterEnd ||
                        !isResultsPublished
                      }
                    >
                      View Test Performance{" "}
                    </NavigationButton>

                    {type_batch !== "4" && (
                      <NavigationButton
                        disabled={
                          !(attendenceStatus === 0 || attendenceStatus === 1) ||
                          !isAfterEnd || UPLOADSTTAUS === 0
                        }
                        className={
                          attendenceStatus !== 0 && attendenceStatus !== 1
                            ? " me-1 DisabledListviS"
                            : "  me-1"
                        }
                        onClick={onClickJoinTest}
                      >
                        Retake My Test
                      </NavigationButton>
                    )}

                    {isAllowedstatus === true &&
                      type_batch !== "4" &&
                      isResultsPublished &&
                      methodOfTestObjective && (
                        <>
                          {attendenceStatus === 1 ? (
                            <>
                              <NavigationButton
                                className=" me-1"
                                onClick={onClickReviewSubmission}
                              >
                                Review Submission
                              </NavigationButton>
                            </>
                          ) : (
                            <>
                              <NavigationButton
                                className=" me-1"
                                disabled
                                onClick={onClickReviewSubmission}
                              >
                                Review Submission
                              </NavigationButton>
                            </>
                          )}
                        </>
                      )}

                    {isAllowedstatus === true &&
                      type_batch !== "4" &&
                      methodOfTestObjective &&
                      isResultsPublished && (
                        <>
                          {attendenceStatus === 1 &&
                            ans_sheet_download_req === 1 ? (
                            <PrimaryButton  disabled onClick={onClickDownloadAnswerSheet}>
                              Download Answer Sheet
                            </PrimaryButton>
                          ) : (
                            <PrimaryButton
                              disabled
                              onClick={onClickDownloadAnswerSheet}
                            >
                              Download Answer Sheet
                            </PrimaryButton>
                          )}
                        </>
                      )}
                  </>
                )}
            </ul>
          </div>
        </div>

        <div>
          {type_batch !== "4" &&
            methodOfTestObjective &&
            attempt_no_status === 0 &&
            UPLOADSTTAUS === 0 &&
            !isAfterCalculatedEnd && (
              <WarningSectionContainer className="mb-0">
                <WarningSectionPara style={{ fontWeight: 700 }}>
                  <ICON_WARNING
                    style={{
                      width: "21px",
                      height: "21px",
                      marginRight: "0px",
                      verticalAlign: "middle",
                    }}
                  />{" "}
                  WARNING:
                </WarningSectionPara>
                <WarningSectionPara>
                  You’re unable to join the test right now, as the Join Test
                  button has been disabled by your administrator. Please reach
                  out to your administrator to enable it.
                </WarningSectionPara>
              </WarningSectionContainer>
            )}
          {isAfterCalculatedEnd &&
            !isAfterEnd &&
            methodOfTestObjective &&
            attendenceStatus === 0 && (
              <WarningSectionContainer>
                <WarningSectionPara style={{ fontWeight: 700 }}>
                  <ICON_WARNING
                    style={{
                      width: "21px",
                      height: "21px",
                      marginRight: "0px",
                      verticalAlign: "middle",
                    }}
                  />{" "}
                  WARNING:
                </WarningSectionPara>
                <WarningSectionPara>
                  {`The allotted time for attending the exam has ended, and you were
              marked absent.`}
                </WarningSectionPara>
                <WarningSectionPara>
                  {" "}
                  You now have the option to retake the exam by clicking the
                  'Retake My Test' button. However, please be aware that the
                  results will not be reflected in your performance.
                </WarningSectionPara>
              </WarningSectionContainer>
            )}
        </div>

        {type_batch !== "4" &&
          methodOfTestDesctiptive &&
          UPLOAD_STTAUS === 0 &&
          !isRunningHourStatus &&
          !isAfterOneHour &&
          !isOneHourAfterEnd &&
          isBeforeHalfHour && (
            <WarningSectionContainer className="mb-0">
              <WarningSectionPara
                style={{ fontWeight: 700, justifyContent: "left" }}
              >
                <ICON_WARNING
                  style={{
                    width: "21px",
                    height: "21px",
                    marginRight: "0px",
                    verticalAlign: "middle",
                  }}
                />{" "}
                <span>
                  WARNING:{" "}
                  <span class="text-regular">
                    You can’t download the question paper or answer booklet at
                    the moment, as these options have been disabled by your
                    administrator. Please reach out to your administrator to
                    enable them.
                  </span>
                </span>
              </WarningSectionPara>
              {/* <WarningSectionPara>
                You can’t download the question paper or answer booklet at the
                moment, as these options have been disabled by your
                administrator. Please reach out to your administrator to enable
                them.
              </WarningSectionPara> */}
            </WarningSectionContainer>
          )}

        {isAllowedstatus === true &&
          !isRunningHourStatus &&
          isAfterOneHour &&
          UASTATUS === false &&
          (que_paper_filepath === "" || que_paper_filepath === null) &&
          methodOfTestDesctiptive && (
            <WarningSectionContainer className="mb-0">
              <WarningSectionPara
                style={{ fontWeight: 700, justifyContent: "left" }}
              >
                <ICON_WARNING
                  style={{
                    width: "21px",
                    height: "21px",
                    marginRight: "0px",
                    verticalAlign: "middle",
                  }}
                />{" "}
                <span>
                  WARNING:{" "}
                  <span class="text-regular">
                    You can’t download the question paper or answer booklet at
                    the moment, as these options have been disabled by your
                    administrator. Please reach out to your administrator to
                    enable them.
                  </span>
                </span>
              </WarningSectionPara>
            </WarningSectionContainer>
          )}

        {isAllowedstatus === true &&
          showTiming &&
          isAfterFTEEnMIn &&
          (que_paper_filepath === "" || que_paper_filepath === null) &&
          methodOfTestDesctiptive && (
            <WarningSectionContainer className="mb-0">
              <WarningSectionPara
                style={{ fontWeight: 700, justifyContent: "left" }}
              >
                <ICON_WARNING
                  style={{
                    width: "21px",
                    height: "21px",
                    marginRight: "0px",
                    verticalAlign: "middle",
                  }}
                />{" "}
                <span>
                  WARNING:{" "}
                  <span class="text-regular">
                    You can’t download the question paper or answer booklet at
                    the moment, as these options have been disabled by your
                    administrator. Please reach out to your administrator to
                    enable them.
                  </span>
                </span>
              </WarningSectionPara>
            </WarningSectionContainer>
          )}
      </div>
    </div>
  );
}
