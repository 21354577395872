import React, { useState } from "react";

import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import TestPerformance from "./TestPerformance";
import { AxiosInstance } from "../../../../axios";

function TestPerformanceTablist() {
  const { active_tab, batchIds, batch_type } = useParams();

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    setActiveTab(Number(active_tab) || 1);
    setBatchId(batchIds);
    setBatchType(batch_type);
  }, [active_tab]);

  const handleTabClick = (index, batch_id, batch_type) => {
    setActiveTab(index);
    setBatchId(batch_id);
    setBatchType(batch_type);
    navigate(`/TestPerformanceListView/${index}/${batch_id}/${batch_type}`);
  };

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  const [tPer, setTPer] = useState([]);
  const [tPerstatus, setTPerstatus] = useState(null);
  const [batchId, setBatchId] = useState(null);
  const [batchType, setBatchType] = useState(null);

  // get batch for user

  useEffect(() => {
    if (userid) {
      AxiosInstance.post("api/batch/list/byUser", {
        user_id: userid,
      })
        .then((res) => {
          if (res.data.status === true) {
            let df = res.data.data;
            setTPer(df);
            setTPerstatus(res?.data?.view_performer);
            let batch_idd = res.data.data[0].batch_id;
            setBatchId(batch_idd);
            let btype = res.data.data[0].batch_type;
            setBatchType(btype);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, []);

  const handleNavigate = () => {
    navigate("/ViewTopTestPerformerAnswerSheet", {
      state: {
        BatchIDS: tPer.length > 0 ? tPer.map((ls) => ls.batch_id) : [],
      },
    });
  };

  return (
    <div className="BathDet TestPerformance BathHeader ">
      <div className="row">
        <div className=" col-md-6 col-12 ">
          <h6 className="TitleHeader "> Test Performance</h6>
        </div>
        {tPerstatus === 1 && (
          <div className="col-md-6 col-12 ListBtn">
            <ul class="List-Button">
              <li class="ListBtnItems">
                <Link
                  onClick={handleNavigate}
                  to={"javascript:void(0)"}
                  className="primaryButton "
                  variant="primary"
                >
                  View Top Performer Answer Sheet
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>

      <div className="row mt-3">
        <div className={tPer.length < 3 ? "col-md-12" : "col-md-12"}>
          {/* nav-tabs */}
          <ul className="nav  nav-fill mb-3" id="listViewTabs" role="tablist">
            {tPer.length > 0 ? (
              tPer.map((el, index) => (
                <li className="nav-item" role="presentation">
                  <a
                    className={
                      activeTab === index + 1 ? "nav-link active" : "nav-link"
                    }
                    // id="ex2-tab-1"
                    data-mdb-toggle="tab"
                    href="javascript:void(0)"
                    role="tab"
                    aria-controls="ex2-tabs-1"
                    aria-selected={activeTab === index + 1}
                    onClick={() =>
                      handleTabClick(index + 1, el.batch_id, el.batch_type)
                    }
                  >
                    {el.batch_name}
                  </a>
                </li>
              ))
            ) : (
              <li className="nav-item" role="presentation">
                <a
                  className={activeTab === 1 ? "nav-link active" : "nav-link"}
                  // id="ex2-tab-1"
                  data-mdb-toggle="tab"
                  href="javascript:void(0)"
                  role="tab"
                  aria-controls="ex2-tabs-1"
                  aria-selected={activeTab === 1}
                  //   onClick={() => handleTabClick(1)}
                >
                  No batch found
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
      {batchId && batchType !== null ? (
        <TestPerformance
          activeTab={activeTab ? activeTab : 1}
          batchId={batchId}
          batch_type={batchType}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default TestPerformanceTablist;
