import React, { useState, useRef, useEffect } from "react";
import "./ExamCenter.css";
import $, { data } from "jquery";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Chip from "@mui/material/Chip";
import {
  CloseOutlined,
  Download,
  Javascript,
  LocalConvenienceStoreOutlined,
  WidthFull,
} from "@mui/icons-material";
import IconAnalytics from "@mui/icons-material/Assignment";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix, R_PER_PAGE } from "../../../../labels";
import { Link } from "react-router-dom";
import { styled, withStyles } from "@mui/material/styles";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import MyCalendar from "./CalendarView";
import Swal from "sweetalert2";
import ReviewstatusCheck from "./ReviewstatusCheck";
import ReviewMockPanelMember from "./ReviewMockPanelMember";
import ReviewFeesDetails from "./ReviewFeesDetails";
import ReviewCounselingPanel from "./ReviewCounselingPanel";
import ModelComForP from "../../../../pages/ModelComForP";
import GenarateHallTicket from "./GenarateHallTicket";
import ModelExamStatus from "./ModelExamStatus";
import GenarateAttentenceSheet from "./GenarateAttentenceSheet";
import { Dialog, DialogContent, Switch } from "@mui/material";

import Loader from "../../Loader";

function ReadViewBath() {
  const moment = require("moment-timezone");
  const [listData, setListData] = useState([]);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const txtBSearch = useRef(null); //Search icon
  const [emails, setEmails] = useState([]);
  const { id, type } = useParams();
  // console.log(id, "vvvvvvvv");

  const { type: typeFromParam } = useParams();
  const { id: API_BATCH_ID } = useParams();

  const { FSF, FCAF, FCM, FME, FLO, FSP, FSTD, FCON, ftype, FSEAR } =
    location.state ? location.state : {};

  const userJSON = sessionStorage.getItem("userDetails");
  let user_type = null;
  let staff_id = null;
  let userid = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
      staff_id = user.staff_id;
    }
    if (user && user.user_type) {
      user_type = user.user_type;
    }
  } else {
    console.log("user not available in session storage");
  }

  const currentDate = moment();
  const startOfDay = moment(currentDate).startOf("day");
  const tenAM = startOfDay.clone().hour(10);
  const tenAMModelExam = startOfDay.clone().hour(10);
  const [selectedMem, setSelectedMem] = useState([]);
  const [supervisorData, setSupervisorData] = useState([]);

  const [formFields, setFormFields] = useState({
    Name: "",
    Section: "",
    Category: "",
    Course: "",
    category_course_id: "", //new change
    subCate: "",
    batchImg: null,
    original_img_url: null,
    ClassMode: "",
    MediumInstruction: "",
    SchedulePreference: "",
    StartDate: "",
    endDate: "",
    Time: "",
    endTime: "",
    Duration: "",
    NoStudents: "",
    NoTest: "",
    subject: "",
    TestPdf: null,
    original_test_img_url: null,
    exampassed: "",
    EnrollStu: "",
    Fees: "",
    Discount: "",
    OldsDiscount: "",
    OldSBatch: "",
    message: "",
    location: "",
    LastDateApply: false,
    ask_core_exam: "",
    subject_file_path: null,
    original_subject_file_path: null,
    pPayment: "",
    perOne: "",
    perTwo: "",
    perTwoDate: "",
    perThree: "",
    perThreeDate: "",
    Papers: "",
    NModelExam: "",
    MNPapers: "",
    MExamTitle: "",
  });
  const [csvListData, setCsvListData] = useState([]);
  const [selectedDistrict, setSelectDistrict] = useState([]);
  const [selectedDistricts, setSelectDistricts] = useState([]);
  const [examWiseEnroll, setExamWiseEnroll] = useState([]);
  const [openHallModal, setOpenHallModal] = useState(false);
  const [openAttenModal, setOpenAttenModal] = useState(false);
  const [hallTicketData, setHallTicketData] = useState([]);

  const [attentData, setAttentData] = useState([]);
  const [checked, setChecked] = useState(false);

  const handleChangeRadio = (pk) => {
    if (pk) {
      AxiosInstance.post("api/batch/meSupervisor/redwnld/approve", {
        batch_id: id,
        exam_center_cstm_pk: pk,
        updated_by: userid,
        type: parseInt(type),
      })
        .then((res) => {
          if (res.data.status === true) {
            FindById();
          } else {
            Swal.fire({
              title: "Warning",
              text: res.data.message,
              icon: "Warning",
              confirmButtonText: "Ok",
            });
          }
        })
        .catch((error) => {
          return error;
        });
    }
  };
  // panel details for mock interview

  const [mockPanel, setMockPanel] = useState([
    {
      id: 1,
      panel_name: "",
      available_dates: [],
      panelMem: [],
      staffMem: [],
      fees: "",
      error_1: "",
      error_2: "",
      error_3: "",
      error_4: "",
      error_5: "",
    },
  ]);

  // panel details for Counselling

  const [cPanel, setCPanel] = useState([
    {
      id: 1,
      panel_name: "",
      date: "",
      StartTime: "",
      EndTime: "",
      department: [],
      CPaneMem: [],
      CStaffM: [],
      fees: "",
      error_1: "",
      error_2: "",
      error_3: "",
      error_4: "",
      error_5: "",
      error_6: "",
      error_7: "",
      error_8: "",
    },
  ]);

  const [mExamSF, setMExamSF] = useState([
    {
      id: 1,
      NoStudentBatch: "",
      SOfferDis: 0,
      selectedOptions: [],
      ApplicableBatch: [],
      MEABatch: "",
      StudentBatchCount: "",
      error_1: "",
      error_2: "",
      error_3: "",
      error_4: "",
    },
  ]);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    FindById();
  }, []);
  const FindById = () => {
    if (id) {
      setIsLoading(true);
      AxiosInstance.post("/api/batch/findById", {
        id: id,
        start_date: moment().startOf("month").format("YYYY-MM-DD"),
        end_date: moment().endOf("month").format("YYYY-MM-DD"),
      })
        .then((res) => {
          if (res.data.status === true) {
            if (Object.keys(res.data.data.batch_info).length > 0) {
              let dateLast =
                res.data.data.batch_info.apply_last_date !== null
                  ? res.data.data.batch_info.apply_last_date
                  : "";
              const desiredDateTime = moment(dateLast);
              const currentDateTime = moment();
              const item = res.data.data.batch_info;
              const centreSupervisorName =
                res.data?.data?.batch_exam_center.map((data) => {
                  return data.exam_center_name;
                });
              setSupervisorData(centreSupervisorName);
              setFormFields({
                Name: item.name,
                Section: item.section,
                Papers: item.paper_type === 0 ? "0" : "1",
                Category: item.category,
                Course: item.category_class,
                subCate: item.subcategory,
                ClassMode: item.class_mode,
                category_course_id: item.category_course_id,
                batchImg: item.img_url != null ? item.img_url : null,
                original_img_url:
                  item.original_img_url != null ? item.original_img_url : null,
                MediumInstruction: item.medium,
                SchedulePreference: item.batch_type,
                StartDate:
                  item.started_dt != null
                    ? moment(item.started_dt).format("YYYY-MM-DD")
                    : "",
                endDate:
                  item.end_dt != null
                    ? moment(item.end_dt).format("YYYY-MM-DD")
                    : "",
                Time: item.start_time,
                endTime: item.end_time,
                pPayment:
                  item.partial_payment && item.partial_payment === 1
                    ? "Yes"
                    : "NO",
                perOne: item.pay_1_perc,
                perTwo: item.pay_2_perc,
                perTwoDate: item.pay_2_duedate
                  ? moment(item.pay_2_duedate, "DD/MM/YYYY").format(
                      "DD/MM/YYYY"
                    )
                  : "",
                perThree: item.pay_3_perc,
                perThreeDate: item.pay_3_duedate
                  ? moment(item.pay_3_duedate, "DD/MM/YYYY").format(
                      "DD/MM/YYYY"
                    )
                  : "",
                Duration: item.duration,
                NoStudents: item.no_of_students,
                NoTest: item.no_of_test,
                subject:
                  item.subject.length > 0
                    ? item.subject.map((el) => el.name).join(", ")
                    : null,
                ask_core_exam: item.ask_core_exam,
                exampassed: item.passed_exam_name,
                subject_file_path:
                  item.subject_file_path != null
                    ? item.subject_file_path
                    : null,
                original_subject_file_path:
                  item.original_subject_file_path != null
                    ? item.original_subject_file_path
                    : null,
                TestPdf: item.test_img_url != null ? item.test_img_url : null,
                original_test_img_url:
                  item.original_test_img_url != null
                    ? item.original_test_img_url
                    : null,
                EnrollStu:
                  res.data.data.batch_students_info.length > 0
                    ? res.data.data.batch_students_info.length
                    : "0",
                Fees: item.fees,
                Discount: item.discount,
                OldsDiscount: item.old_stud_disc,
                OldSBatch: item.old_stud_disc_batch,
                message: item.message,
                location: item.location_name,
                LastDateApply: desiredDateTime.isAfter(currentDateTime)
                  ? true
                  : false,
                // NModelExam: 0,
                NModelExam: item.no_of_modelexam,
              });

              const examCenterDistricts =
                item.district && item.district.length > 0
                  ? item.district.map((center) => ({
                      value: center.district_name,
                      district_id: center.district_id,
                      label: center.district_name,
                      code: center.district_code,
                      centreDetails: center.district_name,
                    }))
                  : [];

              setSelectDistricts(examCenterDistricts);
            }

            // panel members fill
            if (Object.keys(res.data.data.batch_panels).length > 0) {
              if (res.data.data.batch_panels.panel_data.length > 0) {
                const updatedMockPanel = [...mockPanel];
                const updatedCPanel = [...cPanel];
                res.data.data.batch_panels.panel_data.forEach((el, index) => {
                  let panelMem = [];
                  let staffMem = [];
                  let department = [];
                  let CPaneMem = [];
                  let CStaffM = [];
                  if (el.panel_mebers_list.length > 0) {
                    el.panel_mebers_list.map((ko) => {
                      if (ko.type === 3) {
                        // panelMem
                        panelMem.push({
                          staff_id: ko.panel_member_id,
                          name: ko.panel_member_name,
                          type: ko.type,
                          department:
                            ko.department !== ""
                              ? ko.department.split(",")
                              : [],
                        });
                        CPaneMem.push({
                          staff_id: ko.panel_member_id,
                          name: ko.panel_member_name,
                          type: ko.type,
                          department:
                            ko.department !== ""
                              ? ko.department.split(",")
                              : [],
                        });
                        if (ko.department !== "") {
                          const bbd = ko.department.split(",");
                          // console.log(bbd,"bbd");
                          department.push(bbd);
                        }
                      } else if (ko.type === 0) {
                        //  StaffMem
                        staffMem.push({
                          staff_id: ko.panel_member_id,
                          name: ko.panel_member_name,
                          type: ko.type,
                          department: "",
                        });
                        CStaffM.push({
                          staff_id: ko.panel_member_id,
                          name: ko.panel_member_name,
                          type: ko.type,
                          department: "",
                        });
                      }
                    });
                  }
                  updatedMockPanel[index] = {
                    ...updatedMockPanel[index],
                    id: index + 1,
                    panel_name: el.panel_name, // Update other properties as needed
                    available_dates: el.available_dates,
                    panelMem,
                    staffMem,
                    error_1: "",
                    error_2: "",
                    error_3: "",
                    error_4: "",
                    error_5: "",
                  };

                  updatedCPanel[index] = {
                    ...updatedCPanel[index],
                    id: index + 1,
                    panel_name: el.panel_name, // Update other properties as needed
                    date: el.available_dates,
                    department,
                    StartTime: el.start_time,
                    EndTime: el.end_time,
                    CPaneMem,
                    CStaffM,
                    error_1: "",
                    error_2: "",
                    error_3: "",
                    error_4: "",
                    error_5: "",
                    error_6: "",
                    error_7: "",
                    error_8: "",
                  };
                });
                setMockPanel(updatedMockPanel);
                setCPanel(updatedCPanel);
              }
            }

            // model exam special offer
            if (res.data.data.spc_offer.length > 0) {
              const speciModel = res.data.data.spc_offer.map((vb, index) => {
                let vhui = "";
                if (vb.discount_type === 0) {
                  vhui = "1";
                } else if (vb.discount_type === 1) {
                  vhui = "2";
                } else if (vb.discount_type === 2) {
                  vhui = "3";
                } else if (vb.discount_type === 3) {
                  vhui = "4";
                }

                const bhu =
                  vb.applicable_batches && vb.applicable_batches.length > 0
                    ? vb.applicable_batches.map((el) => ({
                        id: el.id,
                        label: el.batch_name,
                        value: el.id,
                      }))
                    : [];

                return {
                  id: index + 1,
                  pk: vb.id,
                  NoStudentBatch: vhui,
                  SOfferDis: vb.offer_perc,
                  selectedOptions: bhu,
                  ApplicableBatch: [],
                  MEABatch: "",
                  StudentBatchCount: vb.stud_count,
                  error_1: "",
                  error_2: "",
                  error_3: "",
                  error_4: "",
                };
              });
              setMExamSF(speciModel);
            }

            if (Object.keys(res.data.data.batch_panels).length > 0) {
              if (res.data.data.batch_panels.exam_fees.length > 0) {
                res.data.data.batch_panels.exam_fees.map((el, index) => {
                  setMockPanel((prevState) =>
                    prevState.map((q) => {
                      if (q.id === el.fees_count) {
                        return { ...q, fees: el.fees }; // Update the fees property
                      }
                      return q;
                    })
                  );
                  setCPanel((prevState) =>
                    prevState.map((q) => {
                      if (q.id === el.fees_count) {
                        return { ...q, fees: el.fees }; // Update the fees property
                      }
                      return q;
                    })
                  );
                });
              }
            }

            if (res.data.data.batch_old_student_discount.length > 0) {
              const promises = res.data.data.batch_old_student_discount.map(
                (obj) => getOldBatchView(obj.batch_start, obj.batch_end)
              );
              Promise.all(promises)
                .then((results) => {
                  let idCounter = 1; // Initialize the ID counter
                  const result = Object.values(
                    res.data.data.batch_old_student_discount.reduce(
                      (acc, obj, index) => {
                        const BacthList = results[index].map((item) => ({
                          value: item.category_class,
                          label: item.category_class,
                          is_disabled: false,
                          category: item.category,
                        }));

                        if (!acc[obj.pk]) {
                          acc[obj.pk] = {
                            id: idCounter,
                            OldDPercentage: obj.old_stud_disc,
                            BatchRFrom: moment(obj.batch_start).format(
                              "YYYY-MM-DD"
                            ),
                            BatchRTo: moment(obj.batch_end).format(
                              "YYYY-MM-DD"
                            ),
                            selectedOptions: [],
                            ApplicableBatch: [],
                          };
                          idCounter++; // Increment the ID counter
                        }

                        acc[obj.pk].ApplicableBatch = BacthList;
                        const applicableBatches =
                          obj.applicable_batches !== null
                            ? obj.applicable_batches.split(",")
                            : [];
                        acc[obj.pk].selectedOptions = applicableBatches.map(
                          (batch) => ({
                            value: batch.trim(),
                            label: batch.trim(),
                          })
                        );
                        return acc;
                      },
                      {}
                    )
                  );
                  // console.log(result, "smfksjdgd");
                  setOldBatch(result);
                })
                .catch((error) => {
                  console.error(error);
                });
            }

            if (res.data.data.batch_exam_center.length > 0) {
              const examCenterDistrict = res.data.data.batch_exam_center.map(
                (center) => ({
                  value: center.exam_center_district,
                  district_id: center.district_id,
                  label: center.exam_center_district,
                  code: center.exam_district_code,
                  centreDetails: center.exam_center_name,
                })
              );

              setSelectDistrict(examCenterDistrict);

              const result = res.data.data.batch_exam_center.map(
                (obj, index) => {
                  const id = index + 1;
                  const bbb = {
                    id: id,
                    pk: obj.pk,
                    error_1: "",
                    error_2: "",
                    district_id: obj.district_id,
                    districtCode: obj.exam_district_code,
                    district: {
                      value: obj.district_id,
                      district_id: obj.district_id,
                      label: obj.exam_center_district,
                    },
                    enroll_count:
                      obj.enroll_count.length > 0 ? obj.enroll_count : [],
                    examCenter: obj.exam_center_name.map((elo) => ({
                      examCenter_id: obj.id,
                      pk: elo.pk,
                      examCenterOption: [],
                      exam_center:
                        elo.name !== null && elo.name !== "null"
                          ? elo.name
                          : "", // Populate exam_center if exam_center_name is not null
                      MaxCount: elo.max_count,
                      staff_id: elo.staff_id,
                      redwnld_req_approved: elo.redwnld_req_approved,
                      full_name: elo.full_name,
                      mobile_no: elo.mobile_no,
                      locationUrl:
                        elo.location_url !== null || elo.location_url !== "null"
                          ? elo.location_url
                          : "",
                      enroll_count:
                        elo.enroll_count.length > 0 ? elo.enroll_count : [],
                      error_3: "",
                      error_4: "",
                      error_5: "",
                    })),
                  };
                  return bbb;
                },
                {}
              );

              const districtWiseData = Object.values(result); // Convert object to array
              setDistrictWise(districtWiseData);
            }

            if (Object.keys(res.data.data.batch_exam_details).length > 0) {
              if (res.data.data.batch_exam_details.exam_details.length > 0) {
                const sssss = res.data.data.batch_exam_details.exam_details
                  ?.sort(
                    (a, b) =>
                      parseInt(a.exam_title_id) - parseInt(b.exam_title_id)
                  )
                  ?.map((el, index) => {
                    return {
                      id: index + 1,
                      exam_title_id: el.exam_title_id,
                      pk: el.pk,
                      title: el.exam_title,
                      date:
                        el.started_dt !== null
                          ? moment(el.started_dt).format("DD/MM/YYYY")
                          : "",
                      duration: convertSecondsToTime(el.duration),
                      startTime: el.start_time,
                      endTime: el.end_time,
                      syllabus: el.syllabus_url,
                      original_syllabus_url: el.original_syllabus_url,
                      LastDateApply: el.last_dt_apply,
                      hall_ticket_status: el.hall_ticket_status,
                      NPapers: el.no_of_paper,
                      student_count: el.student_count,
                      PaperDetailsSection:
                        el.paper_detail.length > 0
                          ? el.paper_detail
                              ?.sort(
                                (a, b) =>
                                  parseInt(a.paper_id) - parseInt(b.paper_id)
                              )
                              ?.map((elll, inf) => ({
                                id: inf + 1,
                                pk: elll.paper_id,
                                title: elll.paper_title,
                                date: elll.start_dt
                                  ? moment(elll.start_dt).format("DD/MM/YYYY")
                                  : "", //New Changes
                                duration: convertSecondsToTime(elll.duration),
                                startTime: elll.start_time,
                                endTime: elll.end_time,
                                syllabus: elll.syllabus_path,
                                original_syllabus_url:
                                  elll.original_syllabus_filename,
                                error_1: "",
                                error_2: "",
                                error_3: "",
                                error_4: "",
                                error_5: "",
                                error_6: "",
                                error_7: "",
                              }))
                          : [
                              {
                                id: 1,
                                title: "",
                                date: "",
                                duration: "",
                                startTime: "",
                                endTime: "",
                                syllabus: null,
                                original_syllabus_url: null,
                                error_1: "",
                                error_2: "",
                                error_3: "",
                                error_4: "",
                                error_5: "",
                                error_6: "",
                                error_7: "",
                              },
                            ],
                      fees: "",
                      error_1: "",
                      error_2: "",
                      error_3: "",
                      error_4: "",
                      error_5: "",
                      error_6: "",
                      error_7: "",
                      error_8: "",
                      error_9: "",
                    };
                  })
                  .sort((a, b) =>
                    moment(a.LastDateApply, "DD-MM-YYYY").diff(
                      moment(b.LastDateApply, "DD-MM-YYYY")
                    )
                  );
                // setExamDetails(sssss); // Set the initial data
                // console.log(sssss, "finalDate");
                // const tempSortedExamDetails = sssss.map((ele) => {
                //   const tempFinalDate = ele.date ? ele.date.split("/") : null;
                //   const finalDate =
                //     tempFinalDate !== null
                //       ? new Date(
                //           tempFinalDate[2],
                //           +tempFinalDate[1] - 1,
                //           tempFinalDate[0]
                //         )
                //       : "";
                //   return { ...ele, date: finalDate };
                // });
                // const sortedExamDetails = tempSortedExamDetails.sort(
                //   (a, b) =>
                //     new Date(a.LastDateApply) - new Date(b.LastDateApply)
                // );
                // // console.log(sortedExamDetails, "sortedExamDetails");
                setExamDetails(sssss);
              }
            }

            if (Object.keys(res.data.data.batch_exam_details).length > 0) {
              res.data.data.batch_exam_details.exam_fees.map((el, index) => {
                setExamDetails((prevState) => {
                  const updatedState = prevState.map((q) => {
                    if (q.id === el.fees_count) {
                      return { ...q, fees: el.fees }; // Update the fees property
                    }
                    return q;
                  });
                  const sortedState = updatedState.sort(
                    (a, b) => a.fees_count - b.fees_count
                  );

                  return sortedState;
                });
              });
            }

            if (res.data.data.batch_students_info.length > 0) {
              const emails = res.data.data.batch_students_info.map(
                (student) => student.email
              );

              setEmails(emails);
              const Info_student = res.data.data.batch_students_info.map(
                (data, index) => ({
                  ...data,
                  serialNumber: index + 1,
                })
              );
              setListData(Info_student);
              setUpdateListData(Info_student);
            }

            if (
              res.data.data.event_list &&
              res.data.data.event_list.length > 0
            ) {
              const parsedEvents = res.data.data.event_list.map((event) => {
                const startDateStr = `${event.start_date.split("T")[0]}T${
                  event.start_time
                }`;
                const endDateStr = `${event.start_date.split("T")[0]}T${
                  event.end_time
                }`;
                const startDate = moment(startDateStr).toDate();
                const endDate = moment(endDateStr).toDate();
                return {
                  id: event.id,
                  title: event.name,
                  event_type: event.event_type,
                  start: startDate,
                  end: endDate,
                  url: event.teams_url,
                  subject: event.subject,
                  staff_name: event.staff_name,
                  staff_id: event.staff_id,
                  description: event.description,
                  syllabus_filepath: event.syllabus_filepath,
                };
              });
              setEventFields(parsedEvents);
            }

            if (res.data.data.model_exam_com.length > 0) {
              const model_exam_combination = res.data.data.model_exam_com;
              model_exam_combination.sort((a, b) =>
                a.name.localeCompare(b.name)
              );
              setExamWiseEnroll(model_exam_combination);
            }
            loadListdata();
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          return error;
        });
    } else {
      console.log("Batch id is missing");
    }
  };

  const [eventFields, setEventFields] = useState([]);

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  // List data load table

  // use state
  let page = R_PER_PAGE;

  // use state
  const [rowsPerPage, setRowsPerPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  // const [downloadCSV, setDownloadCSV] = useState([]);
  // const [downloadPDF, setDownloadPDF] = useState([]);
  const [updateListData, setUpdateListData] = useState(listData);
  const [updateChipData, setUpdateChipData] = useState([]);
  const [isOpen, setIsOpen] = useState({
    mode: false,
    gender: false,
    exam_district: false,
  });

  const [dexamD, setDexamD] = useState([]);
  const [dexamDS, setDexamDS] = useState([]);
  const csvLinkRef = useRef(null);
  const [examDetail, setExamDetails] = useState([
    {
      id: 1,
      title: "",
      exam_title_id: "",
      pk: "",
      date: "",
      duration: "",
      startTime: "",
      endTime: "",
      syllabus: null,
      original_syllabus_url: null,
      hall_ticket_status: "",
      LastDateApply: "",
      NPapers: "",
      student_count: "",
      PaperDetailsSection: [],
      fees: "",
      error_1: "",
      error_2: "",
      error_3: "",
      error_4: "",
      error_5: "",
      error_6: "",
      error_7: "",
    },
  ]);

  const [swalMsg, setSwalMsg] = useState({
    show: false,
    status: "",
    message: "",
    title: "",
    BottomOkBtn: "",
    BottomCancelBtn: "",
    BottomOkClose: "",
  });

  const [districtWise, setDistrictWise] = useState([
    {
      id: 1,
      pk: "",
      district: "",
      districtCode: "",
      enroll_count: [],
      examCenter: [
        {
          ExamCenter_id: 1,
          examCenterOption: [],
          exam_center: "",
          MaxCount: 0,
          locationUrl: "",
          enroll_count: [],
          error_3: "",
          error_4: "",
          error_5: "",
        },
      ],
      error_1: "",
      error_2: "",
    },
  ]);
  const [oldBatch, setOldBatch] = useState([
    {
      id: 1,
      OldDPercentage: "",
      BatchRFrom: "",
      BatchRTo: "",
      selectedOptions: [],
      ApplicableBatch: [],
    },
  ]);

  useEffect(() => {
    // Get all "Show More" buttons within elements having the class "rbc-today"
    const showMoreButtons = document.querySelectorAll(
      ".rbc-today .rbc-button-link.rbc-show-more"
    );

    // Iterate through the "Show More" buttons within "rbc-today" and add the class
    showMoreButtons.forEach((element) => {
      element.classList.add("rbc_show_more_today");
    });
  }, []);

  const [mode, setMode] = useState([]);
  const [gender, setGender] = useState([]);
  const [exam_district, setExam_district] = useState([]);
  let filterSearch = [];
  let filterMode = [];
  let filterGender = [];
  let filterexamD = [];

  useEffect(() => {
    filterMode = mode;
  }, [mode]);
  useEffect(() => {
    filterGender = gender;
  }, [gender]);
  useEffect(() => {
    filterexamD = exam_district;
  }, [exam_district]);
  useEffect(() => {
    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }, [searchTerm]);

  useEffect(() => {
    loadChipdata();
  }, []);

  useEffect(() => {
    loadListdata();
  }, [updateChipData]);

  useEffect(() => {
    if (csvLinkRef.current && csvListData.length > 0) {
      csvLinkRef.current.link.click();
    }
  }, [csvListData]);

  const handleSort = (event, sortKey) => {
    event.preventDefault();
    let order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    // setSortColumn(sortKey);
    let sortedList = updateListData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setUpdateListData(sortedList);
    setSortColumn(sortKey);
  };

  const handleFilterCourseSearch = (event) => {
    const searchInput = event.target.value;

    if (searchInput !== "") {
      // console.log(searchInput, "searchInput", dexamD);
      const matchingObjects = dexamDS.filter(
        (item) =>
          item.label &&
          item.label.toLowerCase().includes(searchInput.toLowerCase())
      );
      // console.log(matchingObjects, "matchingObjects");
      // Do something with the matchingObjects, such as updating state or displaying them in the UI
      setDexamD(matchingObjects);
    } else {
      // setDexamDS(dexamD);
      setDexamD(dexamDS);
    }
  };
  // filter clear button
  const handleClear = (name) => {
    updateStateData();
    if (name === "mode") {
      setMode([]);
      filterMode = [];
      setIsOpen({
        ...isOpen,
        mode: false,
      });
    } else if (name === "gender") {
      setGender([]);
      filterGender = [];
      setIsOpen({
        ...isOpen,
        gender: false,
      });
    } else if (name === "exam_district") {
      setExam_district([]);
      filterexamD = [];
      setIsOpen({
        ...isOpen,
        exam_district: false,
      });
    }
    loadChipdata();
  };
  //chips overall clear button
  const handleAllClear = () => {
    setSearchTerm("");
    setMode([]);
    setGender([]);
    setExam_district([]);
    loadChipdata();
    // setUpdateListData(listData);
  };

  const getExamCenterView = (value) => {
    return AxiosInstance.post("api/examcenter/name", { district: value })
      .then((res) => res.data.data)
      .catch((error) => {
        console.error(error);
        return [];
      });
  };

  const handleCheckboxChange = (event, names, typename) => {
    const { value, checked, name } = event.target;
    if (name === "Classroom" || name === "Online") {
      if (checked) {
        // setStatus([...status, value]);
        setMode([...mode, { id: value, name: name, type: "mode" }]);
      } else {
        setMode(mode.filter((item) => item.id !== value));
      }
    } else if (
      names === "male" ||
      names === "female" ||
      names === "transgender"
    ) {
      if (checked) {
        // setStatus([...status, value]);
        setGender([...gender, { id: value, name: name, type: "gender" }]);
      } else {
        setGender(gender.filter((item) => item.id !== value));
      }
    } else if (names === "exam_district") {
      if (checked) {
        // setStatus([...status, value]);
        setExam_district([
          ...exam_district,
          { id: value, name: name, type: "exam_district" },
        ]);
      } else {
        setExam_district(exam_district.filter((item) => item.id !== value));
      }
    }
  };

  async function getOldBatchView(fTime, Ttime) {
    const dats = {
      start_date: fTime,
      end_date: Ttime,
    };

    try {
      const response = await AxiosInstance.post("api/batch/oldStud/list", dats);
      if (response.data.data.length > 0) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.type === "search") {
      setSearchTerm("");
      filterSearch = [];
    } else if (chipToDelete.type === "mode") {
      setMode(mode.filter((item) => item.id !== chipToDelete.id));
      filterMode = mode.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "gender") {
      setGender(gender.filter((item) => item.id !== chipToDelete.id));
      filterGender = gender.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.type === "exam_district") {
      setExam_district(
        exam_district.filter((item) => item.id !== chipToDelete.id)
      );
      filterexamD = exam_district.filter((item) => item.id !== chipToDelete.id);
    }
    loadChipdata();
  };

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "mode") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        mode: false,
      });
    } else if (name === "gender") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        gender: false,
      });
    } else if (name === "exam_district") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        exam_district: false,
      });
    }
  };

  // handle search function
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      updateStateData();
      setSearchTerm(val);
      if (val.length > 0) {
        filterSearch = [{ name: "" + val, type: "search" }];
      } else {
        filterSearch = [];
      }
      loadChipdata();
    }
  };

  // --------New code (Search Icon)----
  const handleSearchFocus = () => {
    if (txtBSearch.current) {
      txtBSearch.current.focus();

      // Create a new KeyboardEvent
      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        keyCode: 13,
        which: 13,
        code: "Enter",
        bubbles: true,
      });

      // Dispatch the event
      txtBSearch.current.dispatchEvent(event);
    }
  };

  function updateStateData() {
    // filterStatus = status;

    if (searchTerm.length > 0) {
      filterSearch = [{ name: "" + searchTerm, type: "search" }];
    } else {
      filterSearch = [];
    }
  }

  function loadListdata() {
    let tempMode = [];
    let tempGender = [];

    let tempEXDNumber = [];

    let tempExD = [];

    mode.map((obj) => {
      tempMode.push(obj.name);
    });
    gender.map((obj) => {
      tempGender.push(obj.name);
    });

    exam_district.map((obj) => {
      tempExD.push(obj.id);
    });

    tempEXDNumber = tempExD.map(Number);

    let statusFilter = {
      id: id,
      class_mode: mode.length > 0 ? tempMode : "",
      gender: gender.length > 0 ? tempGender : "",
      district: exam_district.length > 0 ? tempEXDNumber : "",
      search: searchTerm.length > 0 ? searchTerm : "",
    };
    // console.log(statusFilter, "statusFilter");

    AxiosInstance.post("api/batch/studentlist/byFilter", statusFilter)
      .then((res) => {
        // console.log("success ", res);
        if (res.data.status === true) {
          const fiata = res.data.data;
          const serialNumberAddedData = fiata.map((data, index) => ({
            ...data,
            serialNumber: index + 1,
          }));
          // setUpdateListData(fiata);
          setUpdateListData(serialNumberAddedData);
          const examDistricts = res.data.data
            .map((item) =>
              item.exam_district !== null && item.district_id !== null
                ? {
                    value: item.district_id,
                    label: item.exam_district,
                  }
                : []
            )
            .flat();
          // Remove duplicates by converting the array to a Set and back to an array
          const uniqueexamD = [...new Set(examDistricts)];
          setDexamD(uniqueexamD);
          setDexamDS(uniqueexamD);
        } else if (res.data.status === false) {
          setUpdateListData([]);
        }
      })
      .catch((error) => {
        // console.log("Error res: ", error.response.data);
        setUpdateListData([]);
        return error;
      });
  }

  function loadChipdata() {
    //setUpdateChipData([]);
    let tempArr = [];
    // setUpdateChipData(status);
    filterSearch.map((obj) => {
      tempArr.push(obj);
    });

    filterMode.map((obj) => {
      tempArr.push(obj);
    });
    filterGender.map((obj) => {
      tempArr.push(obj);
    });
    filterexamD.map((obj) => {
      tempArr.push(obj);
    });

    setUpdateChipData(tempArr);
  }

  const handleFilter = (e, dame) => {
    // alert(dame,"dame");
    if (dame === "mode") {
      setIsOpen((prevState) => ({
        ...prevState,
        mode: !prevState.mode,
        gender: false,
        exam_district: false,
      }));
    } else if (dame === "gender") {
      setIsOpen((prevState) => ({
        ...prevState,
        gender: !prevState.gender,
        mode: false,
        exam_district: false,
      }));
    } else if (dame === "exam_district") {
      setIsOpen((prevState) => ({
        ...prevState,
        exam_district: !prevState.exam_district,
        gender: false,
        mode: false,
      }));
    }
  };

  // generate pdf when user click download button

  // total table data  with pages
  const totalPages = Math.ceil(updateListData.length / rowsPerPage);

  // prev button pagination
  const handlePrev = (val) => {
    setCurrentPage(val);
  };
  // next button pagination
  const handleNext = (val) => {
    setCurrentPage(val);
  };
  // page count handle
  const handlePageCount = (e) => {
    const { name, value, checked } = e.target;
    if (name === "pageCount") {
      setRowsPerPage(parseInt(value));
      setCurrentPage(1);
      setUpdateListData([]);
    }
    loadListdata();
  };

  // pagination start end -   item per page changing
  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  // console.log(updateListData, "updateListData");
  const currentData = updateListData.slice(start, end);

  const startedDt = moment.utc(formFields.StartDate);

  const twelveAMNextDay = startedDt.clone().add(1, "day").startOf("day");

  // console.log(selectedMem , selectedStaffMem ,"ssjfsf");
  const handleCoreExam = (id, ask_core_exam) => {
    const ask_core_exam_chn = ask_core_exam === 0 ? 1 : 0;
    const coreExamdat = {
      type: type ? parseInt(type) : 4, //  model exam -> default type as 4
      batch_id: parseInt(id),
      ask_core_exam: ask_core_exam_chn,
    };
    AxiosInstance.post("api/batch/ask/coreExamDet", coreExamdat)
      .then((res) => {
        if (res.data.status === true) {
          FindById();
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const handleOpenHallPopup = () => {
    if (id) {
      AxiosInstance.post("api/batch/generate/hallticketME/view", {
        batch_id: parseInt(id),
      })
        .then((res) => {
          if (res.data.status === true) {
            if (res.data.data && res.data.data.info) {
              const hallTicketInfo =
                res.data.data.info.length > 0 && res.data.data.info;
              let SortHall = "";
              if (hallTicketInfo[0].paper_type === 0) {
                SortHall = hallTicketInfo
                  .filter((item) => new Date(item.started_dt) > currentDate)
                  .sort(
                    (a, b) => new Date(a.started_dt) - new Date(b.started_dt)
                  );
              } else {
                // Extract all start dates with their parent object
                const examDatesWithDetails = hallTicketInfo.flatMap((exam) =>
                  exam.pap_det.map((pap) => ({
                    ...pap,
                    no_of_paper: exam.no_of_paper, // Append the `no_of_paper` property to each `pap_det`
                    hall_ticket_status: exam.hall_ticket_status,
                    exam_detail_cstm_pk: exam.exam_detail_cstm_pk,
                    exam_title: exam.exam_title,
                    last_dt_apply: exam.last_dt_apply,
                  }))
                );
                // Find the earliest start date
                const nearestExamDetail = examDatesWithDetails
                  .filter((item) => new Date(item.start_dt) > currentDate)
                  .reduce((prev, curr) =>
                    prev.start_dt < curr.start_dt ? prev : curr
                  );
                SortHall = [nearestExamDetail];
              }
              // console.log(SortHall, "SortHallSortHallSortHall");
              setHallTicketData(SortHall);
            } else {
              setHallTicketData([]);
              setIsPaymentLoading(false);
            }
          } else {
            setHallTicketData([]);
          }
        })
        .catch((error) => {
          return error;
        });
    } else {
      console.log("BatchID is missing");
    }
    setOpenHallModal(true);
  };

  const handleFailureCase = () => {
    Swal.fire({
      title: "Warning",
      text: "At least one student needs to be enrolled and an exam center needs to be allocated.",
      icon: "Warning",
      confirmButtonText: "Ok",
    });
  };

  const handleOpenAttePopup = () => {
    if (id) {
      AxiosInstance.post("api/batch/generate/hallticketME/view", {
        batch_id: parseInt(id),
      })
        .then((res) => {
          if (res.data.status === true) {
            if (res.data.data && res.data.data.info) {
              const hallTicketInfo =
                res.data.data.info.length > 0 ? res.data.data.info : [];
              const SortHall = hallTicketInfo.sort(
                (a, b) => new Date(a.started_dt) - new Date(b.started_dt)
              );
              setAttentData(SortHall);
            } else {
              setAttentData([]);
            }
          } else {
            setAttentData([]);
          }
        })
        .catch((error) => {
          return error;
        });
    } else {
      console.log("BatchID is missing");
    }
    setOpenAttenModal(true);
  };

  const handleHallClose = () => {
    setOpenHallModal(false);
  };

  const handleExamCentreNavigation = () => {
    const districtData = selectedDistrict.map((dist) => ({
      id: dist.district_id,
      name: dist.label,
      code: dist.code,
      centreDetails: dist.centreDetails,
    }));
    const currentDateOnly = moment().startOf("day");
    const examDetailID = examDetail
      .filter((item) =>
        moment(item.LastDateApply, "DD-MM-YYYY").isSameOrAfter(currentDateOnly)
      )
      .sort(
        (a, b) =>
          moment(a.LastDateApply, "DD-MM-YYYY") -
          moment(b.LastDateApply, "DD-MM-YYYY")
      )
      .map((item) => ({
        pk: item.pk,
        exam_title_id: item.exam_title_id,
        title: item.title,
      }));

    const vb = examDetailID.find((bh) => bh.exam_title_id === 1)
      ? examDetailID.filter((bh) => bh.exam_title_id !== 1)
      : [];

    // console.log(districtData, "districtData", districtData, examDetail);

    navigate("/exam_centre", {
      state: {
        districtData,
        batchID: API_BATCH_ID,
        examDetailLID: examDetailID[0].pk,
        examDetailTile: examDetailID[0].title,
        examDetailIDS: vb.length > 0 ? vb.map((er) => er.pk) : [],
      },
    });
  };

  const handleGenReport = () => {
    navigate(`/GenerateReport/${id}`);
  };
  const handleCenterSupervisor = () => {
    // navigate("/CentreSupervisors");
    navigate(`/CentreSupervisors/${id}`);
  };
  const handleAttenClose = () => {
    setOpenAttenModal(false);
  };

  const handleMainHallThicket = (exam_title_idd) => {
    if (exam_title_idd) {
      setIsPaymentLoading(true);
      AxiosInstance.post("api/batch/generate/hallticket/info", {
        batch_id: parseInt(id),
        exam_detail_id: exam_title_idd,
        type: 4,
        created_by: userid,
        updated_by: userid,
      })
        .then((res) => {
          if (res.data.status === true) {
            setIsPaymentLoading(false);
            Swal.fire({
              title: "Success",
              text: res.data.message,
              icon: "Success",
              confirmButtonText: "Ok",
            });
            FindById();
            handleHallClose();
          } else {
            setIsPaymentLoading(false);
            Swal.fire({
              title: "Warning",
              text: res.data.message,
              icon: "Warning",
              confirmButtonText: "Ok",
            });
            handleHallClose();
          }
        })
        .catch((error) => {
          setIsPaymentLoading(false);
          return error;
        });
    } else {
      console.log("exam_title_idd is missing");
    }
  };

  // Function to convert an array of objects into CSV format
  const convertToCSV = (data) => {
    // Extract the headers from the keys of the first object
    const headers = Object.keys(data[0]).join(",");

    // Create CSV rows by joining values with commas
    const rows = data.map((obj) => {
      return Object.values(obj)
        .map((val) => `"${val}"`)
        .join(",");
    });

    // Combine the headers and rows into a single CSV string
    const csvContent = [headers, ...rows].join("\n");

    return csvContent;
  };

  // Function to trigger a CSV download
  const downloadCSV = (csvContent, filename = "AttendenceSheet.csv") => {
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a temporary link element
    const link = document.createElement("a");

    // Set the link's href to a URL created from the Blob
    const url = URL.createObjectURL(blob);
    link.href = url;

    // Set the link's download attribute to the desired filename
    link.download = filename;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger a click event on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

    // Revoke the Blob URL to free up memory
    URL.revokeObjectURL(url);
  };

  const handleAttenSheet = (exam_title_idd) => {
    if (exam_title_idd) {
      AxiosInstance.post("api/batch/attendance/dwld", {
        batch_id: parseInt(id),
        exam_detail_pk: exam_title_idd,
      })
        .then((res) => {
          if (res.data.status === true) {
            const csvData =
              res.data.data &&
              res.data.data.stud_list.length > 0 &&
              res.data.data.stud_list.map((el, ind) => {
                return {
                  "S.No": ind + 1,
                  "Name of the candidate": el.full_name,
                  "Regsister No": `${el.aiasa_reg_no}`,
                  "District Name": el.district_name,
                };
              });
            // console.log(csvData, "csvData");
            const csvContent = convertToCSV(csvData);
            // Trigger the CSV download
            downloadCSV(csvContent, "AttendanceSheet.csv");
            handleAttenClose();
          } else {
            Swal.fire({
              title: "Warning",
              text: res.data.message,
              icon: "Warning",
              confirmButtonText: "Ok",
            });
            handleAttenClose();
          }
        })
        .catch((error) => {
          return error;
        });
    } else {
      console.log("exam_title_idd is missing");
    }
  };

  const handleHallTicket = (batchId) => {
    if (batchId) {
      AxiosInstance.post("api/batch/send/hallticket", {
        batch_id: batchId,
        type: 4, // always for model exam
      })
        .then((res) => {
          if (res.data.status === true) {
            setSwalMsg({
              show: true,
              status: "success",
              message: "Hall Ticket send successfully for all the student list",
              title: "success",
              BottomOkClose: "Ok",
            });
          }
        })
        .catch((error) => {
          return error;
        });
    }
  };

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  const handleCloseModels_Succ = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };

  const handleCloseModel = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };
  const handleViewStudent = (batch) => {
    const userID = batch.user_id;
    const batchID = batch.batch_id;
    const addID = batch?.pk;
    const type = typeFromParam;
    navigate(
      `/TransactionHistory/ViewAdmission/${userID}/${batchID}/${addID}/${type}/0`
    );
  };
  const handleModelExamAnalyticsNavigate = (Batchid, type) => {
    return navigate(`/ModelExamAnalytics/${type}`, {
      state: {
        batchID: Batchid,
        category: formFields.Category,
        course: formFields.Course,
        paperType: formFields.Papers,
        examName: formFields.Name,
        examMedium: formFields.MediumInstruction,
        batchImg: formFields.batchImg,
        examMode: formFields.ClassMode,
        numberofExams: formFields.NModelExam,
        examDistricts: selectedDistricts.length > 0 ? selectedDistricts : [],
        batchStatus: `${
          type === "4"
            ? currentDate.isBetween(
                formFields.StartDate,
                formFields.endDate,
                "day",
                "[]" // Include the end date
              ) && currentDate.isAfter(tenAMModelExam)
              ? "Ongoing"
              : currentDate.isBefore(formFields.StartDate, "day") ||
                (currentDate.isSame(formFields.StartDate, "day") &&
                  currentDate.isBefore(tenAMModelExam))
              ? "Upcoming"
              : "Completed"
            : "-"
        }`,
        numberOfEnrolledStudents: formFields.EnrollStu,
        hallticketStatus: `${
          examDetail.length ===
          examDetail.filter((eop) => eop.hall_ticket_status === 2).length
            ? "Generated"
            : "Not Yet Generated"
        }  (${
          examDetail.length > 0 &&
          examDetail.filter((eop) => eop.hall_ticket_status === 2).length
        }/${formFields.NModelExam ? formFields.NModelExam : 0})`,
      },
    });
  };

  const handleRedirct = (arg) => {
    navigate(arg, {
      state: {
        filStatus: FSF,
        filCateCode: FCAF,
        fillClassMode: FCM,
        fillMedium: FME,
        fillLoca: FLO,
        fillShd: FSP,
        fillAon: FSTD,
        fillCOn: FCON,
        ftype: ftype,
        fillSearch: FSEAR,
      },
    });
  };

  const convertSecondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const remainingSeconds = totalSeconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);

    // if (minutes > 0) {
    //   return `${hours}:${minutes.toString().padStart(2, "0")}`;
    // } else {
    //   return `${hours}`;
    // }
    const decimalMinutes = minutes / 60;
    const decimalTime = hours + decimalMinutes;

    return decimalTime.toFixed(2);
  };
  const nearestLastDateApply = examDetail.reduce((nearest, exam) => {
    const examDate = moment(exam.LastDateApply, "DD-MM-YYYY");
    return !nearest || examDate.isBefore(nearest) ? examDate : nearest;
  }, null);

  const isButtonDisabled =
    nearestLastDateApply && nearestLastDateApply.isBefore(currentDate);

  const handleDRegsister = (batchIDD, EXDID) => {
    AxiosInstance.post("api/modelexam/registerno_download", {
      batch_id: batchIDD,
      exam_detail_id: EXDID,
    })
      .then((res) => {
        if (res.data.status === true) {
          setCsvListData(res.data.data);
        } else {
          Swal.fire({
            title: "Warning",
            text: res.data.message,
            icon: "Warning",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <div className=" BathDet">
      <div className="row">
        <div className="col-md-12">
          {openHallModal && (
            <GenarateHallTicket
              hallTicketData={hallTicketData}
              handleMainHallThicket={handleMainHallThicket}
              show={openHallModal}
              handleHallClose={handleHallClose}
            />
          )}
          {openAttenModal && (
            <GenarateAttentenceSheet
              hallTicketData={attentData}
              handleMainHallThicket={handleAttenSheet}
              show={openAttenModal}
              handleHallClose={handleAttenClose}
            />
          )}
          {swalMsg.show === true && (
            <ModelComForP
              show={swalMsg.show}
              message={swalMsg.message}
              status={swalMsg.status}
              title={swalMsg.title}
              BottomOkBtn={swalMsg.BottomOkBtn}
              BottomCancelBtn={swalMsg.BottomCancelBtn}
              handleCloseModels={handleCloseModel}
              // handleClose_yes={handleSPayfee}
              BottomOkClose={swalMsg.BottomOkClose}
              handleCloseModels_Succ={handleCloseModels_Succ}
            />
          )}
          <section className="StepOne">
            <div className="row">
              <div className="col-md-3">
                <h6 className="Title ">
                  {type === "0" && "View Course Batch"}
                  {type === "1" && "View Test Batch"}
                  {type === "2" && "View Counselling Batch"}
                  {type === "3" && "View Special class Batch"}
                  {type === "4" && "View Model Exam Batch"}
                  {type === "5" && "View Mock Interview Batch"}
                </h6>
                {type === "0" && (
                  <Link
                    to={"javascript:void(0)"}
                    onClick={() => handleRedirct(`/ViewCourseBatch/${1}`)}
                    className="CancelButtons  MobileShow "
                  >
                    Back to Batches
                  </Link>
                )}

                {type === "1" && (
                  <Link
                    to={"javascript:void(0)"}
                    onClick={() => handleRedirct(`/ViewCourseBatch/${2}`)}
                    className="CancelButtons MobileShow "
                  >
                    Back to Batches
                  </Link>
                )}

                {type === "2" && (
                  <Link
                    to={"javascript:void(0)"}
                    onClick={() => handleRedirct(`/ViewCourseBatch/${4}`)}
                    className="CancelButtons MobileShow "
                  >
                    Back to Batches
                  </Link>
                )}

                {type === "3" && (
                  <Link
                    to={"javascript:void(0)"}
                    onClick={() => handleRedirct(`/ViewCourseBatch/${3}`)}
                    className="CancelButtons MobileShow "
                  >
                    Back to Batches
                  </Link>
                )}

                {type === "4" && (
                  <Link
                    to={"javascript:void(0)"}
                    onClick={() => handleRedirct(`/ViewCourseBatch/${5}`)}
                    className="CancelButtons MobileShow "
                  >
                    Back to Batches
                  </Link>
                )}

                {type === "5" && (
                  <Link
                    to={"javascript:void(0)"}
                    onClick={() => handleRedirct(`/ViewCourseBatch/${6}`)}
                    className="CancelButtons MobileShow "
                  >
                    Back to Batches
                  </Link>
                )}
              </div>
              <div className="col-md-9">
                <div className="NavButtons">
                  {type === "0" && (
                    <Link
                      to={"javascript:void(0)"}
                      onClick={() => handleRedirct(`/ViewCourseBatch/${1}`)}
                      className="CancelButtons MobileHide "
                    >
                      Back to Batches
                    </Link>
                  )}
                  {type === "1" && (
                    <Link
                      to={"javascript:void(0)"}
                      onClick={() => handleRedirct(`/ViewCourseBatch/${2}`)}
                      className="CancelButtons MobileHide mt-3"
                    >
                      Back to Batches
                    </Link>
                  )}
                  {type === "2" && (
                    <Link
                      to={"javascript:void(0)"}
                      onClick={() => handleRedirct(`/ViewCourseBatch/${4}`)}
                      className="CancelButtons MobileHide mt-3"
                    >
                      Back to Batches
                    </Link>
                  )}
                  {type === "3" && (
                    <Link
                      to={"javascript:void(0)"}
                      onClick={() => handleRedirct(`/ViewCourseBatch/${3}`)}
                      className="CancelButtons MobileHide mt-3"
                    >
                      Back to Batches
                    </Link>
                  )}
                  {type === "4" && (
                    <Link
                      to={"javascript:void(0)"}
                      onClick={() => handleRedirct(`/ViewCourseBatch/${5}`)}
                      className="CancelButtons MobileHide mt-3"
                    >
                      Back to Batches
                    </Link>
                  )}
                  {type === "5" && (
                    <Link
                      to={"javascript:void(0)"}
                      onClick={() => handleRedirct(`/ViewCourseBatch/${6}`)}
                      className="CancelButtons MobileHide mt-3"
                    >
                      Back to Batches
                    </Link>
                  )}
                  {type === "2" || type === "5" || type === "4" ? (
                    currentDate.isBetween(
                      formFields.StartDate,
                      formFields.endDate,
                      "day",
                      "[]" // Include the end date
                    ) && currentDate.isAfter(tenAMModelExam) ? (
                      <button type="button" className="ListViewBtn me-3 ">
                        <Link to={`javascript:void(0)`}>
                          <span className="material-icons me-1 fs-5 align-middle text-white">
                            border_color
                          </span>
                        </Link>

                        <Link to={`/CreateCourseBatch/${type}/${id}`}>
                          Edit {type === "0" && "Course Batch"}
                          {type === "1" && "Test Batch"}
                          {type === "2" && "Counselling Batch"}
                          {type === "3" && "Special class Batch"}
                          {type === "4" && "Model Exam Batch"}
                          {type === "5" && "Mock Interview Batch"}
                        </Link>
                      </button>
                    ) : currentDate.isBefore(formFields.StartDate, "day") ||
                      (currentDate.isSame(formFields.StartDate, "day") &&
                        currentDate.isBefore(tenAMModelExam)) ? (
                      <button type="button" className="ListViewBtn me-3 ">
                        <Link to={`javascript:void(0)`}>
                          <span className="material-icons me-1 fs-5 align-middle text-white">
                            border_color
                          </span>
                        </Link>

                        <Link to={`/CreateCourseBatch/${type}/${id}`}>
                          Edit {type === "0" && "Course Batch"}
                          {type === "1" && "Test Batch"}
                          {type === "2" && "Counselling Batch"}
                          {type === "3" && "Special class Batch"}
                          {type === "4" && "Model Exam Batch"}
                          {type === "5" && "Mock Interview Batch"}
                        </Link>
                      </button>
                    ) : (
                      <button
                        disabled
                        type="button"
                        className="ListViewBtn DisabledListvi  me-3 "
                      >
                        <Link to={`javascript:void(0)`}>
                          <span className="material-icons me-1 fs-5 align-middle text-white">
                            border_color
                          </span>
                        </Link>

                        <Link to={`javascript:void(0)`}>
                          Edit {type === "0" && "Course Batch"}
                          {type === "1" && "Test Batch"}
                          {type === "2" && "Counselling Batch"}
                          {type === "3" && "Special class Batch"}
                          {type === "4" && "Model Exam Batch"}
                          {type === "5" && "Mock Interview Batch"}
                        </Link>
                      </button>
                    )
                  ) : (
                    <button type="button" className="ListViewBtn me-3 ">
                      <Link to={`javascript:void(0)`}>
                        <span className="material-icons me-1 fs-5 align-middle text-white">
                          border_color
                        </span>
                      </Link>

                      <Link to={`/CreateCourseBatch/${type}/${id}`}>
                        Edit {type === "0" && "Course Batch"}
                        {type === "1" && "Test Batch"}
                        {type === "2" && "Counselling Batch"}
                        {type === "3" && "Special class Batch"}
                        {type === "4" && "Model Exam Batch"}
                        {type === "5" && "Mock Interview Batch"}
                      </Link>
                    </button>
                  )}

                  {type === "4" && (
                    <>
                      <button
                        type="button"
                        // className={`ListViewBtn me-3 `}
                        className={`ListViewBtn me-3 ${
                          examDetail.length > 0 &&
                          examDetail.every((eo) => eo.hall_ticket_status === 2)
                            ? "DisabledListvi"
                            : ""
                        } `}
                        disabled={
                          examDetail.length > 0 &&
                          examDetail.every((eo) => eo.hall_ticket_status === 2)
                            ? true
                            : false
                        }
                        onClick={handleExamCentreNavigation}
                      >
                        <Link>
                          <p className="p-0 m-0 d-flex align-items-center">
                            {" "}
                            <span class="material-icons-outlined">
                              location_on
                            </span>
                            <span> Exam Centres</span>
                          </p>
                        </Link>
                      </button>
                      <button
                        type="button"
                        onClick={
                          formFields.EnrollStu > 0 &&
                          districtWise.length > 0 &&
                          districtWise.every(
                            (el) =>
                              el.examCenter.length > 0 &&
                              el.examCenter.every(
                                (ell) => ell.exam_center !== ""
                              )
                          )
                            ? () => handleOpenHallPopup()
                            : () => handleFailureCase()
                        }
                        className={`ListViewBtn  me-3`}
                      >
                        <Link to={`javascript:void(0)`}>
                          <span className="material-icons me-1 fs-5 align-middle text-white">
                            email
                          </span>
                        </Link>
                        <Link to={`javascript:void(0)`}>
                          Generate AIASA's Hall Ticket
                        </Link>
                      </button>
                      <button
                        className={`ListViewBtn me-3 ${
                          examDetail.length > 0 &&
                          examDetail[0].hall_ticket_status === 0
                            ? "DisabledListvi"
                            : ""
                        } `}
                        disabled={
                          examDetail.length > 0 &&
                          examDetail[0].hall_ticket_status === 0
                        }
                        onClick={() =>
                          handleModelExamAnalyticsNavigate(id, type)
                        }
                      >
                        <IconAnalytics color="#fff" className="fs-5" />
                        Model Exam Analytics
                      </button>

                      <button
                        type="button"
                        className={`ListViewBtn me-3 ${
                          examDetail.length > 0 &&
                          moment(
                            examDetail[0].LastDateApply,
                            "DD-MM-YYYY"
                          ).isAfter(currentDate)
                            ? "DisabledListvi"
                            : ""
                        } `}
                        disabled={
                          examDetail.length > 0 &&
                          moment(
                            examDetail[0].LastDateApply,
                            "DD-MM-YYYY"
                          ).isAfter(currentDate)
                        }
                        onClick={() => handleGenReport()}
                      >
                        <Link>
                          <p className="p-0 m-0 d-flex align-items-center">
                            <IconAnalytics color="#fff" className="fs-5" />
                            <span>Generate Report</span>
                          </p>
                        </Link>
                      </button>
                    </>
                  )}
                  {type === "4" && (
                    <button type="button" className="ListViewBtn  me-3">
                      <Link to={`javascript:void(0)`}>
                        <span className="material-icons me-1 fs-5 align-middle text-white">
                          {formFields.ask_core_exam === 0
                            ? "assignment"
                            : "block"}
                        </span>
                      </Link>

                      <Link
                        onClick={() =>
                          handleCoreExam(id, formFields.ask_core_exam)
                        }
                      >
                        {formFields.ask_core_exam === 0
                          ? "Request Student's Hall Ticket"
                          : "Cancel Hall Ticket Request"}
                      </Link>
                    </button>
                  )}
                  {type === "4" && (
                    <button type="button" className="ListViewBtn  me-3">
                      <Link
                        onClick={handleCenterSupervisor}
                        to="javascript:void(0)"
                      >
                        Centre Supervisors
                      </Link>
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Basic Details</p>
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Category
                          </label>
                          <span className="ReviewLabel">
                            {formFields.Category || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Course
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {formFields.Course || ""}
                          </span>
                        </div>
                      </div>
                      {type === "4" && (
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              Single Papers / Combined Papers?
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {formFields.Papers === "0"
                                ? "Single Papers"
                                : "Combined Papers" || ""}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="col-md-4">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Name
                          </label>
                          <span className="ReviewLabel">
                            {formFields.Name || ""}
                          </span>
                        </div>
                      </div>
                      {type !== "4" && (
                        <div className="col-md-4">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              Section
                            </label>
                            <span className="ReviewLabel">
                              {formFields.Section || ""}
                            </span>
                          </div>
                        </div>
                      )}

                      {type !== "4" && (
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              Location
                            </label>
                            <span className="ReviewLabel">
                              {formFields.location || ""}
                              {/* {formFields.location === "1" ?  "Tambaram": "" }
                            {formFields.location === "3" ?  "Vellore": "" }
                            {formFields.location === "4" ?  "Ch - Anna Nagar": "" } */}
                            </span>
                          </div>
                        </div>
                      )}

                      {type != "5" && type != "2" && (
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              {type === "1"
                                ? "Test Mode"
                                : type === "4"
                                ? "Mode of Exam"
                                : "Class Mode"}
                            </label>
                            <span className="ReviewLabel">
                              {formFields.ClassMode === "ClassRoom"
                                ? "Classroom"
                                : "Online" || ""}
                            </span>
                          </div>
                        </div>
                      )}

                      {type === "4" && (
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              Exam Districts
                            </label>
                            <span className="ReviewLabel">
                              {selectedDistricts.length > 0
                                ? selectedDistricts
                                    .map((el) => el.label)
                                    .join(", ")
                                : "_"}
                            </span>
                          </div>
                        </div>
                      )}

                      {type === "4" && (
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              Number of Model Exams
                            </label>
                            <span className="ReviewLabel">
                              {/* {formFields.NModelExam || ""} */}
                              {formFields.NModelExam
                                ? formFields.NModelExam
                                : 0}
                            </span>
                          </div>
                        </div>
                      )}

                      {type != "5" && type != "2" && (
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              {type === "4"
                                ? "Medium of Exam"
                                : "Medium of Instruction"}
                            </label>
                            <span className="ReviewLabel">
                              {formFields.MediumInstruction || ""}
                            </span>
                          </div>
                        </div>
                      )}

                      {type !== "4" && (
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              Total Number of Students
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {formFields.NoStudents || ""}
                            </span>
                          </div>
                        </div>
                      )}

                      {type === "1" && (
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              Total Number of Test
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {formFields.NoTest || ""}
                            </span>
                          </div>
                        </div>
                      )}

                      {type !== "4" && type !== "2" && type !== "5" && (
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              Subject
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {formFields.subject || "None"}
                            </span>
                          </div>
                        </div>
                      )}

                      {type === "1" && (
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              Test Details
                            </label>
                            <a
                              className="ReviewLabel text-danger"
                              target="_blank"
                              href={`${ImageUrlPrefix}/${formFields.TestPdf}`}
                            >
                              {formFields.TestPdf != null
                                ? formFields.original_test_img_url
                                : ""}
                            </a>
                          </div>
                        </div>
                      )}

                      {type === "3" && (
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              Subject Syllabus{" "}
                            </label>
                            <a
                              className="ReviewLabel text-danger"
                              target="_blank"
                              href={`${ImageUrlPrefix}/${formFields.subject_file_path}`}
                            >
                              {formFields.subject_file_path != null
                                ? formFields.original_subject_file_path
                                : ""}
                            </a>
                          </div>
                        </div>
                      )}

                      {(type === "2" || type === "5") && (
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              Name of exam passed
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {formFields.exampassed || ""}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Number of Enrolled Students
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {formFields.EnrollStu || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 mb-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Batch Status
                          </label>
                          {/* AIASA-000185 - v1 issues solved by lalitha */}

                          {/* // based on start date and duration i show the status */}

                          {type === "2" || type === "5" || type === "4" ? (
                            currentDate.isBetween(
                              formFields.StartDate,
                              formFields.endDate,
                              "day",
                              "[]" // Include the end date
                            ) && currentDate.isAfter(tenAMModelExam) ? (
                              <td id="td">
                                <span className="ReviewLabel GreenTexts">
                                  Ongoing
                                </span>
                              </td>
                            ) : currentDate.isBefore(
                                formFields.StartDate,
                                "day"
                              ) ||
                              (currentDate.isSame(
                                formFields.StartDate,
                                "day"
                              ) &&
                                currentDate.isBefore(tenAMModelExam)) ? (
                              <td id="td">
                                <span className=" ReviewLabel PinkText">
                                  Upcoming
                                </span>
                              </td>
                            ) : (
                              <td id="td">
                                <span className=" ReviewLabel GreyText">
                                  Completed
                                </span>
                              </td>
                            )
                          ) : (
                            <ReviewstatusCheck
                              startDate={formFields.StartDate}
                              duration={formFields.Duration}
                            />
                          )}
                        </div>
                      </div>

                      {type === "4" && (
                        <div className="col-lg-4 mb-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              Hall Ticket Status
                            </label>
                            <span className="ReviewLabel">
                              {`${
                                examDetail.length ===
                                examDetail.filter(
                                  (eop) => eop.hall_ticket_status === 2
                                ).length
                                  ? "Generated"
                                  : examDetail.length > 0 &&
                                    examDetail.filter(
                                      (eop) => eop.hall_ticket_status === 2
                                    ).length >= 1
                                  ? "Generated"
                                  : "Not Yet Generated"
                              }  (${
                                examDetail.length > 0 &&
                                examDetail.filter(
                                  (eop) => eop.hall_ticket_status === 2
                                ).length
                              }/${
                                formFields.NModelExam
                                  ? formFields.NModelExam
                                  : 0
                              })`}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-5">
                    <div className="forms mt-4 mb-2  ">
                      <div id="batchImage">
                        {formFields.batchImg && (
                          <img
                            // src={`${ImageUrlPrefix}/${formFields.batchImg}`}
                            src={`${ImageUrlPrefix}/${formFields.batchImg}`}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {type !== "4" && type !== "5" && type !== "2" && (
            <section className="StepOne">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Schedule</p>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="row">
                        {type != "4" && type != "5" && type != "2" && (
                          <div className="col-lg-3 col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted mt-2 "
                              >
                                Schedule Preference
                              </label>
                              <span className="ReviewLabel">
                                {formFields.SchedulePreference || ""}
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="col-lg-3 col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              Start Date
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {formFields.StartDate
                                ? moment(formFields.StartDate).format(
                                    "DD/MM/YYYY"
                                  )
                                : "" || ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              Time
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {moment(formFields.Time, "h:mm:ss A").format(
                                "h:mm A"
                              )}
                              -{" "}
                              {moment(formFields.endTime, "h:mm:ss A").format(
                                "h:mm A"
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              {type !== "4"
                                ? "Duration (in Months)"
                                : "Duration (in Hours)"}
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {formFields.Duration || ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {type !== "4" && type !== "5" && type !== "2" && (
            <section className="StepOne">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Partial Payment</p>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              Eligible for Partial Payment?
                            </label>
                            <span className="ReviewLabel">
                              {formFields.pPayment || ""}
                            </span>
                          </div>
                        </div>
                        {formFields.pPayment === "Yes" && (
                          <div className="col-lg-6 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted mt-2 "
                              >
                                1st Payment Percentage
                              </label>
                              <span className="ReviewLabel">
                                {" "}
                                {formFields.perOne || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {formFields.pPayment === "Yes" && (
                          <div className="col-lg-6 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted mt-2 "
                              >
                                1st Payment Date
                              </label>
                              <span className="ReviewLabel">
                                Student's Admission Date
                              </span>
                            </div>
                          </div>
                        )}

                        {formFields.pPayment === "Yes" && (
                          <div className="col-lg-6 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted mt-2 "
                              >
                                2nd Payment Percentage
                              </label>
                              <span className="ReviewLabel">
                                {formFields.perTwo || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {formFields.pPayment === "Yes" && (
                          <div className="col-lg-6 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted mt-2 "
                              >
                                2nd Payment Date
                              </label>
                              <span className="ReviewLabel">
                                {formFields.perTwoDate || ""}
                              </span>
                            </div>
                          </div>
                        )}
                        {formFields.pPayment === "Yes" && (
                          <div className="col-lg-6 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted mt-2 "
                              >
                                3rd Payment Percentage
                              </label>
                              <span className="ReviewLabel">
                                {formFields.perThree || ""}
                              </span>
                            </div>
                          </div>
                        )}

                        {formFields.pPayment === "Yes" && (
                          <div className="col-lg-6 col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted mt-2 "
                              >
                                3rd Payment Date
                              </label>
                              <span className="ReviewLabel">
                                {formFields.perThreeDate || ""}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {type !== "4" && type !== "5" && type !== "2" && (
            <section className=" StepOne">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Fees and Discounts</p>

                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-2 col-md-4 mb-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Fees
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            ₹ {formFields.Fees || ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-4 mb-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted mt-2 "
                          >
                            Special Offer (if any)
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            ₹ {formFields.Discount || "0"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {type === "5" && (
            <div className="row">
              {mockPanel.map((el, index) => (
                <div className="col-md-12" key={index}>
                  <ReviewMockPanelMember record={el} />
                </div>
              ))}
            </div>
          )}

          {type === "5" && (
            <div className="row">
              {mockPanel.map((el, index) => (
                <div className="col-md-12" key={index}>
                  <ReviewFeesDetails record={el} type={5} />
                </div>
              ))}
            </div>
          )}

          {type === "2" && (
            <div className="row">
              {cPanel.map((el, index) => (
                <div className="col-md-12" key={index}>
                  <ReviewCounselingPanel record={el} />
                </div>
              ))}
            </div>
          )}

          {type === "2" && (
            <div className="row">
              {cPanel.map((el, index) => (
                <div className="col-md-12" key={index}>
                  <ReviewFeesDetails record={el} type={2} />
                </div>
              ))}
            </div>
          )}
          {/* {console.log(examDetail, "examDetail")} */}
          {type === "4" && (
            <div className="row">
              {examDetail.map((el, index) => (
                <div
                  className={
                    examDetail.length % 2 !== 0
                      ? examDetail.length - 1 === index
                        ? "col-md-12"
                        : "col-md-12 "
                      : "col-md-12 "
                  }
                  key={index}
                >
                  <section className="StepOne">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          Model Exam{" "}
                          {examDetail.length > 0 ? `${index + 1}` : ""} Details
                        </p>
                        <div className="row mb-3">
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted mt-2 "
                              >
                                Exam Title{" "}
                              </label>
                              <span className="ReviewLabel">
                                {el.title || ""}
                              </span>
                            </div>
                          </div>
                          {formFields.Papers === "0" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  Date
                                </label>
                                <span className="ReviewLabel">
                                  {/* {console.log(el.date, "el.date")} */}
                                  {el.date ? el.date : ""}
                                </span>
                              </div>
                            </div>
                          )}
                          {formFields.Papers === "0" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  Duration (in Hours)
                                </label>
                                <span className="ReviewLabel">
                                  {el.duration || ""}
                                </span>
                              </div>
                            </div>
                          )}
                          {formFields.Papers === "0" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  Time
                                </label>
                                <span className="ReviewLabel">
                                  {el.startTime
                                    ? moment(el.startTime, "h:mm:ss A").format(
                                        "h:mm A"
                                      )
                                    : "_"}{" "}
                                  to{" "}
                                  {el.endTime
                                    ? moment(el.endTime, "h:mm:ss A").format(
                                        "h:mm A"
                                      )
                                    : "_"}
                                </span>
                              </div>
                            </div>
                          )}
                          {formFields.Papers === "0" && (
                            <div className="col-md-3 ">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  Uploaded Exam Syllabus
                                  {el.syllabus !== "" &&
                                    el.syllabus !== null && (
                                      <a
                                        target="_blank"
                                        href={`${ImageUrlPrefix}/${el.syllabus}`}
                                      >
                                        {" "}
                                        <Download className="download_icon" />
                                      </a>
                                    )}
                                </label>
                                <span className="ReviewLabel">
                                  {el.original_syllabus_url !== null
                                    ? el.original_syllabus_url
                                    : ""}
                                </span>
                              </div>
                            </div>
                          )}

                          {formFields.Papers === "1" && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  No of Papers
                                </label>
                                <span className="ReviewLabel">
                                  {" "}
                                  {el.NPapers || ""}
                                </span>
                              </div>
                            </div>
                          )}

                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted mt-2 "
                              >
                                Last Date to Apply
                              </label>
                              <span className="ReviewLabel">
                                {" "}
                                {moment(el.LastDateApply, "DD/MM/YYYY").format(
                                  "DD/MM/YYYY"
                                ) || ""}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted mt-2 "
                              >
                                Hall Ticket status
                              </label>
                              <span className="ReviewLabel">
                                {el.hall_ticket_status === 0
                                  ? "Not Yet Generated"
                                  : el.hall_ticket_status === 1
                                  ? "Hall tickets are being generated now"
                                  : `Generated ${
                                      el.student_count > 0 &&
                                      `(${el.student_count})`
                                    }`}
                              </span>
                            </div>
                          </div>
                          {el.hall_ticket_status === 2 && (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  Register Number List
                                  <Download
                                    onClick={() => handleDRegsister(id, el.pk)}
                                    className="download_icon"
                                  />
                                  <CSVLink
                                    data={csvListData}
                                    filename={"RegisterNumberList.csv"}
                                    ref={csvLinkRef}
                                    className="hidden" // Hide the actual CSVLink element
                                  />
                                </label>
                                <span className="ReviewLabel">
                                  RegisterNumberList.csv
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              ))}
            </div>
          )}

          {type === "4" && (
            <div className="row">
              {districtWise.length > 0 &&
                districtWise.map((el, index) => (
                  <div className="col-md-12" key={index}>
                    <section className="StepOne">
                      <div className="row box">
                        <div className="col-md-12 ">
                          <p className="StepTitle">
                            Exam District{" "}
                            {districtWise.length > 0 ? `- ${el.id}` : ""}
                          </p>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  District
                                </label>
                                <span className="ReviewLabel">
                                  {el.district && el.district.label
                                    ? el.district.label
                                    : ""}
                                  {el.districtCode &&
                                    `(${
                                      el.districtCode ? el.districtCode : "_"
                                    })`}
                                </span>
                              </div>
                            </div>
                            {/* {
                              el.enroll_count.length > 0  ?
                              el.enroll_count.map((eop , index) => (
                                <>
                                  <div className="col-md-3">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted mt-2 "
                                      >
                                        Total Number of Students Enrolled
                                      </label>
                                      <span className="ReviewLabel">
                                        {eop.gender_cnt ? eop.gender_cnt : "_"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted mt-2 "
                                      >
                                        Male
                                      </label>
                                      <span className="ReviewLabel">
                                        {
                                          el.enroll_count.filter(
                                            (elop) => elop.gender === "Male"
                                          ).length
                                        }
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted mt-2 "
                                      >
                                        Female
                                      </label>
                                      <span className="ReviewLabel">
                                        {
                                          el.enroll_count.filter(
                                            (elop) => elop.gender === "Female"
                                          ).length
                                        }
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="forms ">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted mt-2 "
                                      >
                                        Transgender
                                      </label>
                                      <span className="ReviewLabel">
                                        {
                                          el.enroll_count.filter(
                                            (elop) =>
                                              elop.gender === "Transgender"
                                          ).length
                                        }
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )) : (
                                <>
                                <div className="col-md-3">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted mt-2 "
                                    >
                                      Total Number of Students Enrolled
                                    </label>
                                    <span className="ReviewLabel">
                                    {"_"}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted mt-2 "
                                    >
                                      Male
                                    </label>
                                    <span className="ReviewLabel">
                                    {"_"}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted mt-2 "
                                    >
                                      Female
                                    </label>
                                    <span className="ReviewLabel">
                                    {"_"}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted mt-2 "
                                    >
                                      Transgender
                                    </label>
                                    <span className="ReviewLabel">
                                      {"_"}
                                    </span>
                                  </div>
                                </div>
                              </>

                              )} */}

                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  Total Number of Students Enrolled
                                </label>
                                <span className="ReviewLabel">
                                  {/* { parseInt(el.enroll_count.filter( (elop) => elop.gender === "Male").map((el) =>el.gender_cnt))
                                     + parseInt(el.enroll_count.filter( (elop) => elop.gender === "Female").map((el) =>el.gender_cnt))
                                     + parseInt(el.enroll_count.filter( (elop) => elop.gender === "Transgender").map((el) =>el.gender_cnt)) > 0 ? parseInt(el.enroll_count.filter( (elop) => elop.gender === "Transgender").map((el) =>el.gender_cnt)) : 0} */}
                                  {el.enroll_count.reduce(
                                    (total, item) => total + item.gender_cnt,
                                    0
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  Male
                                </label>
                                <span className="ReviewLabel">
                                  {el.enroll_count
                                    .filter((elop) => elop.gender === "Male")
                                    .map((el) => el.gender_cnt) > 0
                                    ? el.enroll_count
                                        .filter(
                                          (elop) => elop.gender === "Male"
                                        )
                                        .map((el) => el.gender_cnt)
                                    : "_"}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  Female
                                </label>
                                <span className="ReviewLabel">
                                  {el.enroll_count
                                    .filter((elop) => elop.gender === "Female")
                                    .map((el) => el.gender_cnt) > 0
                                    ? el.enroll_count
                                        .filter(
                                          (elop) => elop.gender === "Female"
                                        )
                                        .map((el) => el.gender_cnt)
                                    : "_"}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  Transgender
                                </label>
                                <span className="ReviewLabel">
                                  {el.enroll_count
                                    .filter(
                                      (elop) => elop.gender === "Transgender"
                                    )
                                    .map((el) => el.gender_cnt) > 0
                                    ? el.enroll_count
                                        .filter(
                                          (elop) =>
                                            elop.gender === "Transgender"
                                        )
                                        .map((el) => el.gender_cnt)
                                    : "_"}
                                </span>
                              </div>
                            </div>
                          </div>

                          {el.examCenter &&
                            el.examCenter.length > 0 &&
                            el.examCenter.map((ell) => (
                              <div className="row mb-2">
                                <div className="col-md-3">
                                  <div className="forms ">
                                    <div className="d-flex align-items-center">
                                      <label
                                        htmlFor="inputName"
                                        className="form-label text-muted mt-2 "
                                      >
                                        Exam Center
                                      </label>
                                      <Switch
                                        className="radioSwitchBtn"
                                        checked={
                                          ell.redwnld_req_approved
                                            ? ell.redwnld_req_approved === 0
                                              ? false
                                              : true
                                            : false
                                        }
                                        onClick={() =>
                                          handleChangeRadio(ell.pk)
                                        }
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                      />
                                    </div>

                                    <span className="ReviewLabel">
                                      {ell.exam_center ? ell.exam_center : "_"}
                                    </span>
                                    {ell.locationUrl !== null &&
                                      ell.locationUrl !== "" &&
                                      ell.locationUrl !== "null" && (
                                        <a
                                          target="_balank"
                                          href={`${ell.locationUrl}`}
                                          className="ReviewLabelText"
                                        >
                                          {`(${ell.locationUrl})`}
                                        </a>
                                      )}
                                    <span className="ReviewLabel">
                                      {ell.full_name
                                        ? `(${ell.full_name} - ${ell.mobile_no})`
                                        : ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-md-3">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted mt-2 "
                                    >
                                      Max Count
                                    </label>
                                    <span className="ReviewLabel">
                                      {ell.MaxCount ? ell.MaxCount : 0}
                                      {/* {ell.enroll_count.length > 0 ? ell.enroll_count.length : "_"} */}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted mt-2 "
                                    >
                                      Male
                                    </label>
                                    <span className="ReviewLabel">
                                      {ell.enroll_count.length > 0 &&
                                      ell.enroll_count
                                        .filter(
                                          (elop) => elop.gender === "Male"
                                        )
                                        .map((el) => el.gender_cnt) > 0
                                        ? ell.enroll_count
                                            .filter(
                                              (elop) => elop.gender === "Male"
                                            )
                                            .map((el) => el.gender_cnt)
                                        : "_"}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted mt-2 "
                                    >
                                      Female
                                    </label>
                                    <span className="ReviewLabel">
                                      {ell.enroll_count
                                        .filter(
                                          (elop) => elop.gender === "Female"
                                        )
                                        .map((el) => el.gender_cnt) > 0
                                        ? ell.enroll_count
                                            .filter(
                                              (elop) => elop.gender === "Female"
                                            )
                                            .map((el) => el.gender_cnt)
                                        : "_"}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted mt-2 "
                                    >
                                      Transgender
                                    </label>
                                    <span className="ReviewLabel">
                                      {ell.enroll_count
                                        .filter(
                                          (elop) =>
                                            elop.gender === "Transgender"
                                        )
                                        .map((el) => el.gender_cnt) > 0
                                        ? ell.enroll_count
                                            .filter(
                                              (elop) =>
                                                elop.gender === "Transgender"
                                            )
                                            .map((el) => el.gender_cnt)
                                        : "_"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </section>
                  </div>
                ))}
            </div>
          )}

          {type === "4" && examWiseEnroll.length > 0 && (
            <div className="row">
              <div className="col-md-12">
                <section className="StepOne">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Exam-wise Student Enrollment</p>
                      <div className="row mt-2">
                        {examWiseEnroll.length > 0 &&
                          examWiseEnroll.map((el) => (
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  {el.name}
                                </label>
                                <span className="ReviewLabel"> {el.value}</span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          )}
          {type === "4" && (
            <div className="row">
              <div className="col-md-12">
                <section className="StepOne">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Exam Fees </p>
                      <div className="row mb-3">
                        {examDetail.map((el, index) => (
                          <div className="col-md-3" key={index}>
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted mt-2 "
                              >
                                Fees for{" "}
                                {examDetail.length === 1 ? " " : `${index + 1}`}{" "}
                                {index + 1 > 1 ? "Exams" : "Exam"}
                              </label>
                              <span className="ReviewLabel">
                                {el.fees || ""}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          )}

          {type === "4" && formFields.Papers === "1" && (
            <div className="row">
              {examDetail.map((mkl, index) => (
                <div className="col-md-12">
                  <section className="StepOne">
                    {mkl.PaperDetailsSection.map((ell, ink) => (
                      <div className="row box">
                        <div className="col-md-12">
                          <p className="StepTitle">
                            Model Exam {index + 1} Paper {ink + 1} Details
                          </p>
                          <div className="row mt-2 mb-2">
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  Paper Title
                                </label>
                                <span className="ReviewLabel">{ell.title}</span>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  Date
                                </label>
                                <span className="ReviewLabel">
                                  {ell.date ? ell.date : "_"}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  Duration (in Hours)
                                </label>
                                <span className="ReviewLabel">
                                  {ell.duration}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  Time
                                </label>
                                <span className="ReviewLabel">
                                  {" "}
                                  {moment(ell.startTime, "h:mm:ss A").format(
                                    "h:mm A"
                                  )}{" "}
                                  to{" "}
                                  {moment(ell.endTime, "h:mm:ss A").format(
                                    "h:mm A"
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  Uploaded Exam Syllabus
                                  {ell.syllabus !== "" &&
                                    ell.syllabus !== null && (
                                      <a
                                        target="_blank"
                                        href={`${ImageUrlPrefix}/${ell.syllabus}`}
                                      >
                                        {" "}
                                        <Download className="download_icon" />
                                      </a>
                                    )}
                                </label>
                                <span className="ReviewLabel">
                                  {ell.original_syllabus_url !== null
                                    ? ell.original_syllabus_url
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </section>
                </div>
              ))}
            </div>
          )}

          <div className="row">
            {/* {console.log(oldBatch, "oldBatch")} */}
            {oldBatch.map((el, index) => (
              <div className="col-md-12" key={index}>
                <section className="StepOne">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">
                        Old Student Discount
                        {oldBatch.length === 1 ? " " : `- ${el.id}`}
                      </p>
                      <div className="row mb-3">
                        <div className="col-md-3">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted mt-2 "
                            >
                              Old Student Discount
                            </label>
                            <span className="ReviewLabel">
                              ₹ {el.OldDPercentage || "0"}
                            </span>
                          </div>
                        </div>
                        {el.OldDPercentage !== "" && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted mt-2 "
                              >
                                Batch Range From
                              </label>
                              <span className="ReviewLabel">
                                {el.BatchRFrom || "_"}
                              </span>
                            </div>
                          </div>
                        )}
                        {el.OldDPercentage !== "" && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted mt-2 "
                              >
                                Batch Range To
                              </label>
                              <span className="ReviewLabel">
                                {el.BatchRTo || "_"}
                              </span>
                            </div>
                          </div>
                        )}
                        {el.OldDPercentage !== "" && (
                          <div className="col-md-3">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted mt-2 "
                              >
                                Old Student Discount Batches
                              </label>
                              <p className="ReviewLabel mb-1 mt-1">
                                {el.selectedOptions.length > 0
                                  ? el.selectedOptions
                                      .map((item) => `${item.label}`)
                                      .join(", ")
                                  : "_"}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            ))}
          </div>

          {type === "4" && (
            <div className="row">
              <div className="col-md-12">
                <section className="StepOne">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Special Offer Discount</p>
                      {mExamSF.length > 0 &&
                        mExamSF.map((lop) => (
                          <div className="row mt-2 mb-2">
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted mt-2 "
                                >
                                  Type of Discount
                                </label>

                                <span className="ReviewLabel">
                                  {lop.NoStudentBatch === "1" &&
                                    "For all the students enrolled in this batch"}
                                  {lop.NoStudentBatch === "2" &&
                                    "For the ongoing batches"}
                                  {lop.NoStudentBatch === "3" &&
                                    "For the first 'n' number of students"}
                                  {lop.NoStudentBatch === "4" && "None"}
                                </span>
                              </div>
                            </div>
                            {(lop.NoStudentBatch === "1" ||
                              lop.NoStudentBatch === "3" ||
                              lop.NoStudentBatch === "2") && (
                              <div className="col-md-3">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted mt-2 "
                                  >
                                    Offer Amount
                                  </label>
                                  <span className="ReviewLabel">
                                    {lop.SOfferDis}
                                  </span>
                                </div>
                              </div>
                            )}

                            {lop.NoStudentBatch === "2" && (
                              <div className="col-md-3">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted mt-2 "
                                  >
                                    Applicable Batches
                                  </label>
                                  {lop.selectedOptions.length > 0
                                    ? lop.selectedOptions.map((el) => (
                                        <span className="ReviewLabel">
                                          {el.label}
                                        </span>
                                      ))
                                    : "_"}
                                </div>
                              </div>
                            )}

                            {lop.NoStudentBatch === "3" && (
                              <div className="col-md-3">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted mt-2 "
                                  >
                                    Student Count
                                  </label>
                                  <span className="ReviewLabel">
                                    {lop.StudentBatchCount}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          )}
          <section className="StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Welcome Message</p>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted mt-2 "
                      >
                        Welcome Message
                      </label>
                      <span className="ReviewLabel">
                        {" "}
                        {formFields.message || ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {type !== "2" && type !== "5" && (
            <section className="StepOne">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle" id="stec">
                    Schedule
                  </p>
                  <div className="row mt-4 mb-5">
                    <div className="col-md-12">
                      <MyCalendar
                        duration={formFields.Duration}
                        startDate={formFields.StartDate}
                        type={type}
                        batch_id={id}
                        ClassMode={formFields.ClassMode}
                        studentEmail={emails}
                        category={formFields.Category}
                        category_course_id={formFields.category_course_id} //New change
                        eventFields={eventFields}
                        startTime={formFields.Time}
                        subcategorys={formFields.subCate}
                        Courses={formFields.Course}
                        endTime={formFields.endTime}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          <section className="StepOne">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Batch Students</p>
                <div className="row">
                  <div className="col-lg-3 col-md-6 mb-2 ms-3 mt-3">
                    <div class="input-group">
                      <input
                        ref={txtBSearch} //new
                        type="text"
                        class="Inputs mobileInput"
                        id="SearchInput"
                        name="SearchInput"
                        placeholder="Search by Name"
                        onKeyDown={handleSearch}
                      />
                      <span
                        class="input-group-text"
                        id="SearchInput"
                        onClick={handleSearchFocus}
                      >
                        <span
                          className="material-icons"
                          onClick={handleSearchFocus}
                        >
                          search
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                {updateChipData.length > 0 ? (
                  <div className="row mt-3">
                    <div
                      className="col-md-12 col-sm-12 col-xs-12 "
                      id="paperil"
                    >
                      <Paper
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          listStyle: "none",
                          p: 0.5,
                          m: 0,
                        }}
                        component="ul"
                      >
                        {updateChipData.map((data, index) => {
                          // console.log(data, " - data - ", index);
                          let icon;
                          let key = "" + data.name;
                          return (
                            <ListItem key={data}>
                              <Chip
                                icon={icon}
                                label={key}
                                onDelete={handleDelete(data, index)}
                              />
                            </ListItem>
                          );
                        })}

                        <ListItem>
                          <a
                            className="allclear"
                            href="javascript:void(0)"
                            onClick={handleAllClear}
                          >
                            Clear All Filters
                          </a>
                        </ListItem>
                      </Paper>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="row mt-2 ms-1 me-1">
                  <div className="col-md-12 table-responsive p-0">
                    <table className="table" id="example">
                      <thead>
                        <tr>
                          <th id="th" style={{ width: "5%" }}>
                            {" "}
                            <span className="text_th">S.No</span>
                            <span
                              class="material-icons align-middle"
                              onClick={(e) => handleSort(e, "serialNumber")}
                            >
                              {" "}
                              import_export{" "}
                            </span>
                          </th>
                          <th id="th">
                            <span
                              className="text_th"
                              onClick={(e) => handleSort(e, "fullname")}
                            >
                              Name
                            </span>

                            <span
                              class="material-icons align-middle"
                              onClick={(e) => handleSort(e, "fullname")}
                            >
                              {" "}
                              import_export{" "}
                            </span>
                          </th>
                          <th id="th">
                            <span
                              className="text_th"
                              onClick={(e) => handleSort(e, "admission_id")}
                            >
                              {" "}
                              Admission ID
                            </span>
                            <span
                              class="material-icons align-middle"
                              onClick={(e) => handleSort(e, "admission_id")}
                            >
                              {" "}
                              import_export{" "}
                            </span>{" "}
                            {/* <span class="material-icons align-middle">
                              filter_list
                            </span> */}
                          </th>

                          <th id="th">
                            <span
                              className="text_th"
                              onClick={(e) => handleSort(e, "phone_no")}
                            >
                              Phone Number
                            </span>

                            <span
                              class="material-icons align-middle"
                              onClick={(e) => handleSort(e, "phone_no")}
                            >
                              import_export
                            </span>
                            {/* <span class="material-icons align-middle">
                              filter_list
                            </span> */}
                          </th>
                          <th id="th">
                            <span
                              className="text_th"
                              onClick={(e) => handleSort(e, "email")}
                            >
                              Email ID
                            </span>
                            <span
                              class="material-icons align-middle"
                              onClick={(e) => handleSort(e, "email")}
                            >
                              import_export
                            </span>{" "}
                            {/* <span class="material-icons align-middle">
                              filter_list
                            </span> */}
                          </th>
                          {type === "4" && (
                            <th id="th">
                              <span
                                className="text_th"
                                onClick={(e) => handleSort(e, "gender")}
                              >
                                Gender
                              </span>
                              <span
                                class="material-icons align-middle"
                                onClick={(e) => handleSort(e, "gender")}
                              >
                                import_export
                              </span>{" "}
                              <span
                                class={` align-middle material-icons  ${
                                  gender.length > 0 ? "active_icons" : ""
                                }`}
                                onClick={(e) => handleFilter(e, "gender")}
                              >
                                filter_list
                              </span>
                              {isOpen.gender === true && (
                                <div className="filterBoxs p-1">
                                  <div className="row mt-4">
                                    <div className="col-md-12">
                                      <div class="form-check ms-3">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          name="male"
                                          value="1"
                                          id="male"
                                          checked={gender.some((element) => {
                                            if (element.name === "male") {
                                              return true;
                                            }
                                            return false;
                                          })}
                                          onChange={(e) =>
                                            handleCheckboxChange(e, "male")
                                          }
                                        />
                                        <label
                                          class="form-check-label "
                                          for="defaultCheck1"
                                        >
                                          Male
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mt-2">
                                    <div className="col-md-12">
                                      <div class="form-check ms-3">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          name="female"
                                          value="2"
                                          id="female"
                                          checked={gender.some((element) => {
                                            if (element.name === "female") {
                                              return true;
                                            }
                                            return false;
                                          })}
                                          onChange={(e) =>
                                            handleCheckboxChange(e, "female")
                                          }
                                        />
                                        <label
                                          class="form-check-label "
                                          for="defaultCheck1"
                                        >
                                          Female
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row mt-2">
                                    <div className="col-md-12">
                                      <div class="form-check ms-3">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          name="Transgender"
                                          value="3"
                                          id="Transgender"
                                          checked={gender.some((element) => {
                                            if (
                                              element.name === "Transgender"
                                            ) {
                                              return true;
                                            }
                                            return false;
                                          })}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              e,
                                              "transgender"
                                            )
                                          }
                                        />
                                        <label
                                          class="form-check-label "
                                          for="defaultCheck1"
                                        >
                                          Transgender
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <hr />
                                  <div className="row mt-3">
                                    <div className="col-md-4  col-4 col-sm-4">
                                      <span
                                        className="clearFilter ms-2 "
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleClear("gender")}
                                      >
                                        Clear
                                      </span>
                                    </div>
                                    <div className="col-md-8  col-8 col-sm-8">
                                      <button
                                        className="FilterButton"
                                        onClick={() =>
                                          handleApplyFilter("gender")
                                        }
                                      >
                                        Apply Filter
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </th>
                          )}

                          {type === "4" && (
                            <th id="th">
                              <span
                                className="text_th"
                                onClick={(e) => handleSort(e, "exam_district")}
                              >
                                Preferred Exam District
                              </span>
                              <span
                                class="material-icons align-middle"
                                onClick={(e) => handleSort(e, "exam_district")}
                              >
                                import_export
                              </span>{" "}
                              <span
                                class={` align-middle material-icons  ${
                                  exam_district.length > 0 ? "active_icons" : ""
                                }`}
                                onClick={(e) =>
                                  handleFilter(e, "exam_district")
                                }
                              >
                                filter_list
                              </span>
                              {isOpen.exam_district === true && (
                                <div className="filterBoxs p-1">
                                  <div className="filterBoxText">
                                    <div className="row ms-1">
                                      <div className="col-lg-12 col-md-12">
                                        <div class="input-group searchInputsss">
                                          <input
                                            type="text"
                                            class="Inputs "
                                            id="SearchFilterInputs"
                                            name="SearchFilterInputs"
                                            placeholder="Search Here"
                                            onKeyDown={(e) =>
                                              handleFilterCourseSearch(e)
                                            }
                                          />
                                          {/* <span
                                            class="input-group-text"
                                            id="SearchInput"
                                          >
                                            <span className="material-icons">
                                              search
                                            </span>
                                          </span> */}
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                    {dexamD.map((el) => (
                                      <div className="row mt-2">
                                        <div className="col-md-12">
                                          <div class="form-check ms-3">
                                            <input
                                              class="form-check-input"
                                              type="checkbox"
                                              name={el.label} // Use el.label to set the name and value
                                              value={el.value}
                                              id={el.label}
                                              checked={exam_district.some(
                                                (element) => {
                                                  if (
                                                    element.name === el.label
                                                  ) {
                                                    return true;
                                                  }
                                                  return false;
                                                }
                                              )}
                                              onChange={(e) =>
                                                handleCheckboxChange(
                                                  e,
                                                  "exam_district"
                                                )
                                              }
                                            />
                                            <label
                                              class="form-check-label PinkText"
                                              for="defaultCheck1"
                                            >
                                              {el.label}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>

                                  <hr />
                                  <div className="row mt-3">
                                    <div className="col-md-4  col-4 col-sm-4">
                                      <span
                                        className="clearFilter ms-2 "
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleClear("exam_district")
                                        }
                                      >
                                        Clear
                                      </span>
                                    </div>
                                    <div className="col-md-8  col-8 col-sm-8">
                                      <button
                                        className="FilterButton"
                                        onClick={() =>
                                          handleApplyFilter("exam_district")
                                        }
                                      >
                                        Apply Filter
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </th>
                          )}

                          {type === "4" && (
                            <th id="th">
                              <span
                                className="text_th"
                                onClick={(e) => handleSort(e, "no_of_exams")}
                              >
                                No. of Exams Enrolled
                              </span>
                              <span
                                class="material-icons align-middle"
                                onClick={(e) => handleSort(e, "no_of_exams")}
                              >
                                import_export
                              </span>{" "}
                            </th>
                          )}

                          <th id="th">
                            <span
                              className="text_th"
                              onClick={(e) => handleSort(e, "amount")}
                            >
                              {type === "4" ? "Paid Amount" : "Fee Paid"}
                            </span>

                            <span
                              class="material-icons align-middle"
                              onClick={(e) => handleSort(e, "amount")}
                            >
                              import_export
                            </span>
                            {/* <span class="material-icons align-middle">
                              filter_list
                            </span> */}
                          </th>
                          {type !== "4" && (
                            <th id="th">
                              <span
                                className="text_th"
                                onClick={(e) => handleSort(e, "class_mode")}
                              >
                                Application Mode
                              </span>

                              <span
                                class="material-icons align-middle"
                                onClick={(e) => handleSort(e, "class_mode")}
                              >
                                import_export
                              </span>
                              <span
                                class={` align-middle material-icons  ${
                                  mode.length > 0 ? "active_icons" : ""
                                }`}
                                onClick={(e) => handleFilter(e, "mode")}
                              >
                                filter_list
                              </span>
                              {isOpen.mode === true && (
                                <div className="filterBoxs p-1">
                                  <div className="row mt-4">
                                    <div className="col-md-12">
                                      <div class="form-check ms-3">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          name="Online"
                                          value="1"
                                          id="Online"
                                          checked={mode.some((element) => {
                                            if (element.name === "Online") {
                                              return true;
                                            }
                                            return false;
                                          })}
                                          onChange={(e) =>
                                            handleCheckboxChange(e, "Online")
                                          }
                                        />
                                        <label
                                          class="form-check-label "
                                          for="defaultCheck1"
                                        >
                                          Online
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mt-2">
                                    <div className="col-md-12">
                                      <div class="form-check ms-3">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          name="Classroom"
                                          value="0"
                                          id="Classroom"
                                          checked={mode.some((element) => {
                                            if (element.name === "Classroom") {
                                              return true;
                                            }
                                            return false;
                                          })}
                                          onChange={(e) =>
                                            handleCheckboxChange(e, "Classroom")
                                          }
                                        />
                                        <label
                                          class="form-check-label "
                                          for="defaultCheck1"
                                        >
                                          Classroom
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <hr />
                                  <div className="row mt-3">
                                    <div className="col-md-4  col-4 col-sm-4">
                                      <span
                                        className="clearFilter ms-2 "
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleClear("mode")}
                                      >
                                        Clear
                                      </span>
                                    </div>
                                    <div className="col-md-8  col-8 col-sm-8">
                                      <button
                                        className="FilterButton"
                                        onClick={() =>
                                          handleApplyFilter("mode")
                                        }
                                      >
                                        Apply Filter
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </th>
                          )}

                          <th id="th">
                            {" "}
                            <span className="text_th">Action</span>{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.length === 0 ? (
                          <tr>
                            <td colspan="10" style={{ textAlign: "center" }}>
                              No records to display
                            </td>
                          </tr>
                        ) : (
                          currentData.map((batch, index) => (
                            <tr key={index} title={batch.admission_id}>
                              <td id="td">{batch.serialNumber}</td>
                              <td id="td">{batch.fullname}</td>
                              <td id="td">{batch.admission_id}</td>
                              <td id="td"> {batch.phone_no}</td>
                              <td id="td">{batch.email}</td>
                              {type === "4" && <td id="td">{batch.gender}</td>}
                              {type === "4" && (
                                <td id="td">{batch.exam_district}</td>
                              )}
                              {type === "4" && (
                                <td id="td">{batch.no_of_exams}</td>
                              )}
                              <td id="td">{batch.amount}</td>
                              {type !== "4" && (
                                <td id="td">{batch.class_mode}</td>
                              )}

                              <td id="td">
                                <button
                                  className="button"
                                  onClick={() => handleViewStudent(batch)}
                                >
                                  <span class="material-icons">visibility</span>
                                </button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row mt-2 ms-1 me-1 mb-3">
                  <div className="col-md-12 p-0">
                    <div className="pag pagssss w-100 dflexcenter">
                      <span className="ipage">Items Per Page</span>
                      <select
                        name="pageCount"
                        id="pageCount"
                        defaultValue={rowsPerPage}
                        onChange={handlePageCount}
                        className="ms-2"
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      <span className="pagenumber ms-2">
                        {" "}
                        {updateListData.length > 0
                          ? currentPage * rowsPerPage + 1 - rowsPerPage
                          : 0}{" "}
                        -
                        {updateListData.length < currentPage * rowsPerPage
                          ? updateListData.length
                          : currentPage * rowsPerPage}{" "}
                        of {updateListData.length}
                      </span>
                      <button
                        className="prev_button ms-2 me-2"
                        onClick={(e) => handlePrev(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <span class="material-icons">chevron_left</span>
                      </button>
                      <button
                        className="prev_button"
                        onClick={() => handleNext(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <span class="material-icons">chevron_right</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* loader */}
          {isPaymentLoading && (
            <Dialog open={isPaymentLoading}>
              <DialogContent>
                <div style={{ margin: "15px" }}>
                  <p> We are generating the hall ticket. Please wait...</p>
                </div>
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReadViewBath;
