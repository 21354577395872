import React, { useState, useEffect } from "react";
import LatexParserComponent from "./LatexParserComponent";

import MathJax from "react-mathjax";
import parse from "html-react-parser";

const LatexProcessorComponent = ({ originalString }) => {
  // console.log(originalString, "originalString");
  // Example string containing LaTeX tags
  // const originalString = 'This is a <latex>\\frac{1}{2}</latex> equation and <latex>e^{i\\pi} + 1 = 0</latex>.';

  // Function to split and process LaTeX content
  const processLatexContent = (str) => {
    // Regular expression to match <latex>...</latex> tags
    // const latexRegex = /<latex>(.*?)<\/latex>/gs;
    const latexRegex = /\\\((.*?)\\\)/g;

    // Initialize arrays to store matched and unmatched parts
    let matchedParts = [];
    let unmatchedParts = [];
    let originalStringParts = [];
    // Use index to track position
    let index = 0;

    // Process string using regex
    let match;
    while ((match = latexRegex.exec(str)) !== null) {
      // Push the text before the match as unmatched
      if (match.index > index) {
        unmatchedParts.push({
          type: "unmatched",
          content: str.substring(index, match.index),
        });
        originalStringParts.push({
          type: "unmatched",
          content: str.substring(index, match.index),
        });
      }

      // Push the matched LaTeX content (you can process it here if needed)
      matchedParts.push({
        type: "matched",
        content: match[1],
        position: match.index,
      });

      originalStringParts.push({
        type: "matched",
        content: match[1],
        position: match.index,
      });

      // Update index to continue search
      index = latexRegex.lastIndex;
    }

    // Push remaining part of the string as unmatched
    if (index < str.length) {
      unmatchedParts.push({
        type: "unmatched",
        content: str.substring(index),
      });
      originalStringParts.push({
        type: "unmatched",
        content: str.substring(index),
      });
    }

    // Return processed parts
    return { matchedParts, unmatchedParts, originalStringParts };
  };

  // Process the original string
  const { matchedParts, unmatchedParts, originalStringParts } =
    processLatexContent(originalString);

  // Function to handle parsing and catching syntax errors
  const handleParseContent = (arg) => {
    try {
      // First, attempt to sanitize the HTML content (cleaning up malformed tags)
      const sanitizedContent = sanitizeHTML(arg);
      // Then parse the sanitized content
      return parse(sanitizedContent);
    } catch (error) {
      console.error("Parsing failed:", error);
      // Return a fallback content if parsing fails (e.g., a warning message)
      return "";
    }
  };

  // Function to sanitize any malformed HTML tags dynamically
  const sanitizeHTML = (htmlString) => {
    // Use a regex to remove invalid tags (like <sub<) and fix them to valid tags
    const cleanedString = htmlString.replace(/<([a-zA-Z0-9]+)[^>]*</g, "<$1");
    return cleanedString;
  };
  // Render the processed content
  return (
    <>
      {originalStringParts.map((part, index) =>
        part.type == "matched" ? (
          <MathJax.Provider>
            <MathJax.Node formula={part.content} />
          </MathJax.Provider>
        ) : (
          handleParseContent(part.content)
          // parse(part.content)
        )
      )}
    </>
  );
};

export default LatexProcessorComponent;
