import React, { useState, useEffect } from "react";
import ExamPad from "../../../../img/testmodule/ExamPad.png";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [remainingTimeSST, setRemainingTimeSST] = useState({});
  const { state } = location;
  if (state) {
    sessionStorage.setItem("TestSuccess", JSON.stringify(state));
    localStorage.setItem("TestSuccess", JSON.stringify(state));
  }
  let storedState = state;
  if (!storedState) {
    const storedStateString = sessionStorage.getItem("TestSuccess");
    storedState = storedStateString ? JSON.parse(storedStateString) : {};
  }

  const {
    batchID,
    eventID,
    userID,
    finishTime,
    eventName,
    user_name,
    startDate,
  } = storedState;

  const [showPage2, setShowPage2] = useState(false);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  // console.log(finishTime, "finishTime");
  const [finishingMoment, setFinishingMoment] = useState(() => {
    const now = moment();
    return now.clone().add(moment.duration(finishTime));
  });

  // Calculate the initial remaining time
  const calculateRemainingTime = () => {
    const now = moment();
    const diff = finishingMoment.diff(now);

    if (diff <= 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    const duration = moment.duration(diff);
    return {
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  };
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime);

  useEffect(() => {
    const handlePopState = (event) => {
      window.history.pushState(null, "", window.location.href);
    };
    // Function to handle page unload (refresh or navigation)
    const handleBeforeUnload = (event) => {
      event.preventDefault(); // Necessary for most browsers
    };
    window.history.pushState(null, null, window.location.href);

    window.addEventListener("beforeunload", handleBeforeUnload);
    // Add event listener to handle the back button
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();
      const diff = finishingMoment.diff(now);

      if (diff <= 0) {
        setShowPage2(true);
        setRemainingTime({ hours: 0, minutes: 0, seconds: 0 });
        clearInterval(interval);
      } else {
        const duration = moment.duration(diff);
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        console.log(seconds, "seconds");
        setRemainingTime({
          hours,
          minutes,
          seconds,
        });
      }
    }, 1000);

    sessionStorage.setItem(
      "TestSuccess",
      JSON.stringify({
        batchID,
        eventID,
        userID,
        finishTime: `${String(remainingTime.hours).padStart(2, "0")}:${String(
          remainingTime.minutes
        ).padStart(2, "0")}:${String(remainingTime.seconds).padStart(2, "0")}`,
        eventName,
        user_name,
        startDate,
      })
    );
    localStorage.setItem(
      "TestSuccess",
      JSON.stringify({
        batchID,
        eventID,
        userID,
        finishTime: `${String(remainingTime.hours).padStart(2, "0")}:${String(
          remainingTime.minutes
        ).padStart(2, "0")}:${String(remainingTime.seconds).padStart(2, "0")}`,
        eventName,
        user_name,
        startDate,
      })
    );
    return () => clearInterval(interval);
  }, [remainingTime]);

  const handleViewTestPerfomance = () => {
    navigate("/TestPerformanceListView");
  };

  return (
    <>
      {!showPage2 && (
        <div className="BathDet">
          <div className="row">
            <div className="col-md-12">
              <section className="StepOne">
                <div className="heading dflexbetww">
                  <div className="mt-2 dbetw">
                    <h6 className="Title">
                      {eventName} -{" "}
                      {startDate ? moment(startDate).format("DD/MM/YYYY") : "_"}
                    </h6>
                  </div>
                </div>
                <section className="StepOne mt-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row mt-5">
                        <div className="col-lg-4 col-md-4 payment-col-img">
                          <div>
                            <img
                              className="payment-brand mb-4"
                              src={ExamPad}
                              alt="LogoImage"
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-10 ">
                          <p className="payment-name">Hi {user_name}</p>
                          <p className="payment-paragraph">
                            Your answer sheet has been submitted successfully.
                          </p>
                          <p className="payment-paragraph">
                            Your evaluated results will be accessible, and the
                            View Your Test Performance button will be enabled in{" "}
                            <span className="payment-span">
                              {remainingTime.hours}h : {remainingTime.minutes}m
                              :{remainingTime.seconds}s
                            </span>
                            , allowing you to view your results.
                          </p>
                          <p className="payment-paragraph">
                            Additionally, you can view your test performance
                            from the{" "}
                            <span className="payment-color">
                              Test Performance
                            </span>{" "}
                            page.
                          </p>
                          <button
                            type="submit"
                            className="payment-button"
                            disabled
                            style={{ cursor: "none" }}
                            onClick={handleViewTestPerfomance}
                          >
                            View Your Test Performance
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </div>
          </div>
        </div>
      )}

      {showPage2 && (
        <div className="BathDet">
          <div className="row">
            <div className="col-md-12">
              <section className="StepOne">
                <div className="heading dflexbetww">
                  <div className="mt-2 dbetw">
                    <h6 className="Title">
                      {eventName} -{" "}
                      {startDate ? moment(startDate).format("DD/MM/YYYY") : "_"}
                    </h6>
                  </div>
                </div>
                <section className="StepOne mt-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row mt-5">
                        <div className="col-lg-4 col-md-4 payment-col-img">
                          <div>
                            <img
                              className="payment-brand mb-4"
                              src={ExamPad}
                              alt="LogoImage"
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-10 ">
                          <p className="payment-name">Hi {user_name}</p>
                          <p className="payment-paragraph">
                            Your answer sheet has been submitted successfully.
                          </p>
                          <p className="payment-paragraph">
                            You can view your test results and analytics by
                            clicking the button below
                          </p>
                          <button type="submit" className="DashboardButton">
                            <Link
                              className="text-white"
                              to={"/TestPerformanceListView"}
                            >
                              View Your Test Performance{" "}
                            </Link>
                          </button>
                          <p className="payment-paragraph mt-3">
                            Additionally, you can view your test performance
                            from the{" "}
                            <span className="payment-color">
                              Test Performance
                            </span>{" "}
                            page.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentSuccess;
