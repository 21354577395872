import React from "react";
import "./Question.css";
import parse from "html-react-parser";
import LatexProcessorComponent from "../../../../utils/LatexProcessorComponent";

function QtypeTen(props) {
  const { index } = props;

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot be found in local storage");
  }

  const splitLines = props.list.tm_question.split("\n");
  const EsplitLines = props.list.en_question.split("\n");

  const optionType = props.list.options_type;

  const generateOptionsEnglish = (optionType, index, props) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];

    for (let i = 0; i < optionType; i++) {
      const option = options[i];
      const valueKey = `options_en_${option}`;
      const checked = props.userInput.some(
        (item) => item.pk === props.list.pk && item.UserOptionSec === option
      );
      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input d-flex">
            <input
              type="radio"
              className="radioInput"
              name={`q_answer_${props.index}_en`}
              checked={checked}
              value={props.list[valueKey]}
              onChange={(e) =>
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  1,
                  option,
                  props.list.subject_type
                )
              }
            />{" "}
            <span
              className="answer_label"
              onClick={(e) => {
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                );
              }}
            >
              ({option})
            </span>
          </div>
          <div>
            <span
              className="answer_label"
              onClick={(e) => {
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                );
              }}
            >
              {/* {parse(props.list[valueKey])}{" "} */}
              <LatexProcessorComponent originalString={props.list[valueKey]} />
            </span>
          </div>
        </div>
      );
    }

    return elements;
  };

  const generateOptionsTamil = (optionType, index, props) => {
    const options = ["A", "B", "C", "D", "E"];
    const elements = [];

    for (let i = 0; i < optionType; i++) {
      const option = options[i];
      const valueKey = `options_tm_${option}`;
      const checked = props.userInput.some(
        (item) => item.pk === props.list.pk && item.UserOptionSec === option
      );

      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input d-flex">
            <input
              type="radio"
              className="radioInput"
              name={`q_answer_${props.index}_tm`}
              checked={checked}
              value={props.list[valueKey]}
              onChange={(e) =>
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                )
              }
            />{" "}
            <span
              className="answer_label"
              onClick={(e) => {
                console.log("Span clicked for option:", options[i]);
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                );
              }}
            >
              ({option})
            </span>
          </div>
          <div>
            <span
              className="answer_label"
              onClick={(e) => {
                console.log("Span clicked for option:", options[i]);
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                );
              }}
            >
              {/* {parse(props.list[valueKey])}{" "} */}
              <LatexProcessorComponent originalString={props.list[valueKey]} />
            </span>
          </div>
        </div>
      );
    }

    return elements;
  };

  const parseLine = (line) => {
    const parts = line.split(" - ").map((part) => part.trim());
    let colSize;
    switch (parts.length) {
      case 2:
        colSize = 6;
        break;
      case 3:
        colSize = 4;
        break;
      default:
        colSize = 12 / parts.length;
    }
    return parts.map((part, index) => (
      <div
        className={`container col-md-${index === 0 ? 2 : 10} col-${
          index === 0 ? 3 : 9
        } text-start mb-3`}
        key={index}
      >
        <span key={index} className="question_labels_tamil">
          {/* {parse(part)} */}
          <LatexProcessorComponent originalString={part} />
        </span>
      </div>
    ));
  };

  const parseLineSOption = (line, lang) => {
    const parts = line.split(" - ").map((part) => part.trim());
    let colSize;
    switch (parts.length) {
      case 2:
        colSize = 6;
        break;
      case 3:
        colSize = 4;
        break;
      default:
        colSize = 12 / parts.length;
    }
    // return parts.map((part, index) => (
    //   <div
    //     className={`col-md-${index === 0 ? 2 : 10} col-${
    //       index === 0 ? 3 : 9
    //     } text-start mb-3`}
    //     key={index}
    //   >
    //     <span key={index} className="question_labels_tamil">
    //       {/* {parse(part)} */}
    //       <LatexProcessorComponent originalString={part} />
    //     </span>
    //   </div>
    // ));

    return (
      <div className="col-md-12 d-flex">
        <span
          style={{ textWrap: "nowrap", marginRight: "10px" }}
          className={`${
            lang === 0 ? "question_labels_tamil " : "question_labels_english"
          }`}
        >
          <LatexProcessorComponent originalString={parts[0]} />
        </span>
        {parts.length > 1 && (
          <div>
            <span
              className={`${
                lang === 0 ? "question_labels_tamil" : "question_labels_english"
              }`}
            >
              <LatexProcessorComponent originalString={parts[1]} />
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="Question_temp_VQ question_align">
      {props.list.en_question !== null &&
        props.list.en_question !== "" &&
        (props.list.lang_type === 1 ||
          (props.list.lang_type === 2 &&
            (props.Lang === 1 || props.Lang === 2))) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_tamil">
                {EsplitLines.length > 0 ? (
                  <LatexProcessorComponent originalString={EsplitLines[0]} />
                ) : (
                  ""
                )}
              </span>
            </div>
            {EsplitLines.length > 1 && (
              <div className="col-md-12 mt-3">
                {/* <div className="row">
                  {EsplitLines.map((line, index) => {
                    if (index === 1) {
                      return <>{parseLine(line)}</>;
                    }
                    return null;
                  })}
                </div> */}
                <div className="row">
                  {EsplitLines.map((line, index) => {
                    if (index !== 0) {
                      return <>{parseLineSOption(line, 1)}</>;
                    }
                    return null;
                  })}
                </div>
              </div>
            )}
            <div className="col-md-12  mt-3">
              <div className="row">
                {generateOptionsEnglish(optionType, index, props)}
              </div>
            </div>
          </div>
        )}
      {props.list.tm_question !== null &&
        props.list.tm_question !== "" &&
        (props.list.lang_type === 0 ||
          (props.list.lang_type === 2 &&
            (props.Lang === 0 || props.Lang === 2))) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_tamil">
                {splitLines.length > 0 ? (
                  <LatexProcessorComponent originalString={splitLines[0]} />
                ) : (
                  ""
                )}
              </span>
            </div>
            {splitLines.length > 1 && (
              <div className="col-md-12 mt-3">
                {/* <div className="row">
                  {splitLines.map((line, index) => {
                    if (index === 1) {
                      return <>{parseLine(line)}</>;
                    }
                    return null;
                  })}
                </div> */}
                <div className="row">
                  {splitLines.map((line, index) => {
                    if (index !== 0) {
                      return <>{parseLineSOption(line, 0)}</>;
                    }
                    return null;
                  })}
                </div>
              </div>
            )}
            <div className="col-md-12  mt-3">
              <div className="row">
                {generateOptionsTamil(optionType, index, props)}
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default QtypeTen;
