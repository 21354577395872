import React, { useState } from "react";
import "../../../../pages/dashboard/Modelcss.css";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import ImageDisplay from "./ImageDisplay";
import LatexProcessorComponent from "../../../../utils/LatexProcessorComponent";

export default function ViewExplaination(props) {
  console.log(props, "props");
  const { showModal, onSetShowModal } = props;
  const TamilSplit = props.element.tm_explanation.split("\n");
  const EnglishSplit = props.element.en_explanation.split("\n");
  const imageRegex = /<img>(https?:\/\/[^\s]+)<\/img>/i;
  const handleClose = () => {
    onSetShowModal(!showModal);
  };
  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 class="view-title" id="exampleModalLabel">
            View Explanation
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-12  mb-3">
              {EnglishSplit.length > 0 &&
                EnglishSplit.map((line, index) => {
                  // Split the line into parts using the regex
                  const parts = line.split(imageRegex); // Split by the <img> tag

                  return (
                    <div className="col-md-12" key={index}>
                      <p className="view-paragraph">
                        {parts.map((part, partIndex) => {
                          // Check if the part is an image URL
                          if (part.startsWith("http")) {
                            return (
                              <ImageDisplay
                                key={partIndex} // Use partIndex as key for unique identification
                                className="imgtnss"
                                ImgUrl={part} // Use the extracted URL for the image
                              />
                            );
                          } else {
                            return (
                              <span className="question_labels_english" key={partIndex}>
                                {/* Use span to keep text inline */}
                                <LatexProcessorComponent
                                  originalString={part}
                                />
                              </span>
                            );
                          }
                        })}
                      </p>
                    </div>
                  );
                })}
              {TamilSplit.length > 0 &&
                TamilSplit.map((line, lineIndex) => {
                  const parts = line.split(imageRegex); // Split the line using the regex
                  return (
                    <div className="col-md-12" key={lineIndex}>
                      <p className="view-paragraph">
                        {parts.map((part, partIndex) => {
                          if (part.startsWith("http")) {
                            // Check if the part is a URL
                            return (
                              <ImageDisplay
                                key={partIndex} // Use partIndex as key for unique identification
                                className="imgtnss"
                                ImgUrl={part} // Use the extracted URL here
                              />
                            );
                          } else {
                            return (
                              <span key={partIndex}>
                                {" "}
                                {/* Use span to keep text inline */}
                                <LatexProcessorComponent
                                  originalString={part}
                                />
                              </span>
                            );
                          }
                        })}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="DashboardButton" onClick={handleClose}>
          <a href="javascript:void(0)">Okay</a>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
