import { validateFEvent } from "./validateEFocus";
export const validateEvent = (
  data,
  sDate,
  type,
  useref_validation,
  subTopic
) => {
  const errors = {};
  console.log(data.endTime, "datauuuuuuuuu", data.qpath);
  if (data.name == "") {
    errors.name = "Please enter the event name";
  }
  if (!data.Method_type && type === "1") {
    errors.Method_type = "Please select the method of test";
  }
  if (!data.testType && type === "1") {
    errors.testType = "Please select the test type";
  }
  if (data.eventDate == "" && sDate == "") {
    errors.eventDate = "Please select the event date";
  }
  if (data.startTime == "") {
    errors.startTime = "Please select the start time";
  }
  if (data.endTime == "") {
    errors.endTime = "Please select the end time";
  }
  if (data.subject == "" && type != "1") {
    errors.subject = "Please select the subject";
  }
  if (data.staff == "" && type != "1") {
    errors.staff = "Please select the faculty name";
  }
  if (!data.no_question && type === "1") {
    errors.no_question = "Please enter the total number of questions";
  }
  if (!data.total_marks && type === "1") {
    errors.total_marks = "Please enter the total marks";
  }
  if (data.desc == "") {
    errors.desc = "Please enter the instruction to student";
  }
  if (data.desc_staff == "" && type === "0") {
    errors.desc_staff = "Please enter the instruction to faculty";
  }
  if (!data.qsyllpath) {
    errors.qsyllpath = "Please upload the syllabus";
  }
  if (!data.DanswerSheet && type === "1") {
    errors.DanswerSheet = "Please select the option to download answer sheet";
  }
  // if (!data.qpath && type === "1") {
  //   errors.qpath = "Please upload the question paper";
  // }
  if (data.qpath && data.cut_off === null && type === "1") {
    errors.cut_off = "Please enter the cut-off marks";
  }
  if (
    data.total_marks &&
    data.cut_off &&
    Number(data.cut_off) > Number(data.total_marks) &&
    type === "1"
  ) {
    errors.cut_off = "Cut-off marks cannot be greater than the total marks";
  }
  validateFEvent(data, sDate, type, useref_validation);
  return errors;
};