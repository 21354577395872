import React, { useState, useRef, useEffect } from "react";
import logo from "../../../img/AIASA.png";
import IconLogo from "../../../img/IconLogo.png";
import { Link, useNavigate } from "react-router-dom";
import Image from "../../../img/img1.png";
import Video from "../../../img/india.mp4";
import "./NewPassword.css";
import { Logostyle } from "../../../Themes/themes";
import { Error } from "../../../Themes/themes";
import { AxiosInstance } from "../../../../src/axios/index";
import { useParams } from "react-router-dom";
import { height } from "@mui/system";
import { Dialog, DialogContent } from "@mui/material";
function NewPassword() {
  const navigate = useNavigate();
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const txtNewPass = useRef(null);
  const txtNewCPass = useRef(null);
  const { id } = useParams();

  useEffect(() => {
    sessionStorage.removeItem("userDetails");
  }, []);

  useEffect(() => {
    const videoElement = document.getElementById(
      "newPassword-background-image-poster"
    );

    const isIPhone = () => {
      return /iPhone/.test(navigator.userAgent) && !window.MSStream;
    };

    if (isIPhone()) {
      videoElement.setAttribute("disablePictureInPicture", true);
      videoElement.setAttribute("disableRemotePlayback", true);
      videoElement.setAttribute("playsInline", true);
    }

    // In case the mini player still appears, we pause the video
    videoElement.addEventListener("enterpictureinpicture", (event) => {
      if (isIPhone()) {
        event.preventDefault();
        videoElement.pause();
      }
    });
  }, []);

  // setting initial value for all input fields
  const [initialfields, setInitialfields] = useState({
    cpassword: "",
    password: "",
  });
  const [eyes, setEyes] = useState(false);

  const [eyess, setEyess] = useState(false);
  // setting error value for all input fields
  const [error, setError] = useState({});

  // validate form fields and setting error
  const validate = (data) => {
    const errors = {};

    if (!data.password) {
      errors.password = "Please Enter Password.";
    }
    if (!data.cpassword) {
      errors.cpassword = "Please Enter confirm password.";
    }
    if (data.password.length < 8) {
      errors.password = "Password must be 8 character.";
    }
    if (data.password != data.cpassword) {
      errors.password = "Password does not match.";
    }
    validateFVV(data);
    return errors;
  };

  const validateFVV = (data) => {
    const errors = {};

    if (!data.password) {
      txtNewPass.current.focus();
    } else if (!data.cpassword) {
      txtNewCPass.current.focus();
    } else if (data.password.length < 8) {
      txtNewPass.current.focus();
    } else if (data.password != data.cpassword) {
      txtNewCPass.current.focus();
    }
    return errors;
  };

  /**
   * Handle change event for password fields.
   * Updates the corresponding field value in state based on the input field's name.
   * @param {object} event - The change event object.
   * @param {string} event.target.name - The name of the input field.
   * @param {string} event.target.value - The value of the input field.
   */

  const handleChange = (event) => {
    // console.log(event.target.value,"...handlechange...");

    // set input values into state
    setInitialfields({
      ...initialfields,
      [event.target.name]: event.target.value,
    });

    // set error values into state
    setError({
      ...error,
      [event.target.name]: "",
    });
  };

  const handleEyes = () => {
    setEyes(!eyes);
  };

  const handleEyess = () => {
    setEyess(!eyess);
  };

  /**
   * Handles submission of the form when form submits.
   * Saves the updated form values to local storage.
   * @param {Object} e - The form submission event.
   */

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(initialfields);

    // check if no error in input fields
    if (Object.keys(errors).length === 0) {
      setIsPaymentLoading(true);
      try {
        // api request to password reset
        const response = await AxiosInstance.post("api/user/resetpassword", {
          user_id: id,
          password: initialfields.password,
        });
        if (response.data.status === true) {
          setIsPaymentLoading(false);
          navigate("/resetpwd");
        } else {
          setIsPaymentLoading(false);
        }
      } catch (error) {
        setIsPaymentLoading(false);
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };

  return (
    <>
      <section
        className="container-fluid PasswordForm videoWrapper"
        id="wrapper_Forget_Password"
      >
        <video
          id="newPassword-background-image-poster"
          poster={Image}
          autoPlay
          muted
          loop
          data-wf-ignore="true"
          data-object-fit="cover"
          disableRemotePlayback
          disablePictureInPicture
          playsInline
          controls={false}
        >
          <source src={Video} type="video/mp4" />
        </video>
        <div className="row">
          <div className="col-md-12 box" id="boxs">
            <form className="" onSubmit={handleSubmit}>
              <img src={IconLogo} id="img" alt="Logo" />
              <h1 className="">Set New Password</h1>
              <p> A new beginning.Set your new password and start fresh.</p>
              <input
                ref={txtNewPass}
                type={eyes === true ? "text" : "password"}
                name="password"
                onChange={handleChange}
                value={initialfields.password}
                className="NewpInput"
                placeholder="New Password"
              />
              {initialfields.password.length > 0 && (
                <span
                  className="material-icons eyes_login"
                  onClick={handleEyes}
                >
                  {eyes === true ? "visibility" : "visibility_off"}
                </span>
              )}
              {error.password && (
                <small className="error">{error.password}</small>
              )}

              <input
                ref={txtNewCPass}
                type={eyess === true ? "text" : "password"}
                name="cpassword"
                onChange={handleChange}
                value={initialfields.cpassword}
                className="NewpInput"
                placeholder="Confirm New Password"
              />
              {initialfields.cpassword.length > 0 && (
                <span
                  className="material-icons eyes_logins"
                  onClick={handleEyess}
                >
                  {eyess === true ? "visibility" : "visibility_off"}
                </span>
              )}
              {error.cpassword && (
                <small className="error">{error.cpassword}</small>
              )}

              <button
                type="submit"
                className="LoginBtn"
                name="ResetPassword"
                value=""
              >
                Reset Password
              </button>
            </form>
            <div className="BackLogin">
              <Link to="/login">
                <i class="fa fa-long-arrow-left" id="MIcons"></i>Back to Log in
              </Link>
            </div>
            <div className="copyRights">
              © {new Date().getFullYear()} All Rights Reserved
            </div>
          </div>
        </div>
        {isPaymentLoading && (
          <Dialog open={isPaymentLoading}>
            <DialogContent>
              <div style={{ margin: "15px" }}>
                <p>Please wait...</p>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </section>
    </>
  );
}

export default NewPassword;
