import React, { useEffect, useRef } from "react";
import "./CreateEvent.css";
import styles from "./CreateEvent.module.css";
import {
  Link,
  Navigate,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { useState } from "react";
import { validateEvent } from "./validationField";
import { AxiosInstance } from "../../../../axios";
import Swal from "sweetalert2";
import moment from "moment";
import { PublicClientApplication } from "@azure/msal-browser";
import { Client } from "@microsoft/microsoft-graph-client";
import { ClientID, ImageUrlPrefix, RedirectURL } from "../../../../labels";
import { Download } from "@mui/icons-material";
import { customStyles } from "../../../../pages/customStyles";
import Select, { components, useStateManager } from "react-select";
import DatePickerSample from "../../DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs from "dayjs";
import ModelComForP from "../../../../pages/ModelComForP";
import SubjectTopics from "./SubjectTopics";
import { multiCusutomStyle } from "../../../../pages/Multicustomstyles";
import { CommonOption, CustomCOption } from "../../../../labels/Lableoption";
import toast from "react-hot-toast";
import { TimeIcon } from "@mui/x-date-pickers";
import { Toast } from "bootstrap";
import Loader from "../../Loader";
function CreateEvent() {
  const [isLoading, setIsLoading] = useState(false);
  const txtEventName = useRef(null);
  const txtEventDate = useRef(null);
  const txtEventSTime = useRef(null);
  const txtEventETime = useRef(null);
  const txtEventSubject = useRef(null);
  const txtEventFaculty = useRef(null);
  const txtEventDesc = useRef(null);
  const txtEventTNo = useRef(null);
  const txtEventTtopic = useRef(null);
  const txtEventTNQ = useRef(null);
  const txtEventTmarks = useRef(null);
  const txtEventPaper = useRef(null);
  const txtEventCutOff = useRef(null);
  const txtEventDownloadAnswerSheet = useRef(null);
  const txtEventstaffDesc = useRef(null);
  const txtEventsyllPaper = useRef(null);
  const txtEventMethod_type = useRef(null);
  const txtEventTestType = useRef(null);
  const txtEventOtherABatch = useRef(null);
  const txtEventSTSub = useRef(null);
  const txtEventSTtopics = useRef(null);
  const fileInputRef = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const msalConfig = {
    auth: {
      clientId: ClientID,
      redirectUri: RedirectURL,
    },
  };
  //for teams integration
  const msalInstance = new PublicClientApplication(msalConfig);
  const location = useLocation();
  // console.log("swastic", location);
  //for get value from location state npm
  const {
    sEmail,
    id,
    sDate,
    batch_id,
    category,
    startTime,
    category_course_id,
    endTime,
    coures_name,
    subcate,
    type,
    ClassMode,
  } = location.state || {};
  console.log(location.state);
  const navigate = useNavigate();
  const [error, setError] = useState({});

  const [dropDown, setDropDown] = useState({
    staff: null,
    test_type: null,
  });
  const [formData, setFormData] = useState({
    name: "",
    eventDate: "",
    startTime: "",
    endTime: "",
    endTime_In: "",
    startTime_In: "",
    subject: "",
    subject_pk: "",
    staff: "",
    staff_user_id: "",
    desc: "",
    desc_staff: "",
    event_type: type === "1" || type === 1 ? "1" : "0",
    test_number: "",
    testType: "",
    staffEmail: "",
    total_marks: "",
    qpath: null,
    original_que_paper_filepath: null,
    qsyllpath: null,
    original_syllabus_filepath: null,
    no_question: "",
    teams_url: null,
    Method_type: "0",
    AppBatch: "",
    category_course_id: "",
    navigateScreen: false,
    cut_off: null,
    DanswerSheet: "",
    questionPath: null,
    AnswerBookletPath: "",
    answer_booklet_original_path: null,
    questionFrameSetPath: null,
    question_frame_path: null,
    question_frame_original_path: null,
    AnswerKeyPath: null,
    doubleEvaluation: "",
    answer_key_original_path: "",
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      event_type: formData.event_type,
    }));
  }, [formData.event_type]);

  const [subject, setSubject] = useState([]);
  const [subject_test, setSubject_test] = useState([]);
  const [testTopic, setTestTopic] = useState([]);
  const [staff, setStaff] = useState([]);
  const [testType, setTestType] = useState([]);
  const [appBatch, setAppBatch] = useState([]);
  const [appBatchse, setAppBatchse] = useState([]);
  // const [getSubject,setGetSubject]=useState([])

  const [subTopic, setSubTopic] = useState([
    {
      id: 1,
      STSubject: "",
      STSubject_id: "",
      STTopicOption: [],
      STtopic: [],
      STSubject_error: "",
      STtopic_error: "",
    },
  ]);

  const [userId, setUserId] = useState(null);

  const [userData, setUserData] = useState([]);
  const [accessTokens, setAccessToken] = useState();

  const [flagReload, setFlagReload] = useState(false);

  const [swalMsg, setSwalMsg] = useState({
    show: false,
    status: "",
    message: "",
    title: "",
    BottomOkBtn: "",
    BottomCancelBtn: "",
    BottomOkClose: "",
  });

  const txtBatchSdate = useRef(null);
  const navigateToPreviousScreen = () => {
    if (formData.navigateScreen === false) {
      navigate(-1); // Navigate back by one step in the history
    }
    else {
      navigate(-2); // Navigate back by one step in the history
    }
  };
  const navigateToPreviousScreenClass = () => {
    navigate(-1); // Navigate back by one step in the history
  };
  const navigateToWPreviousScreenClass = () => {
    navigate("/AdminDetailCalendar", {
      state: {
        id,
        types: 1, // Corrected key
        coures_name,
        category_course_id,
        subcate,
      },
    });
    // navigate(-1);
  };

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  useEffect(() => {
    if (id) {
      FindbyId(id);
    }
  }, [id]);

  const FindbyId = (idid) => {
    AxiosInstance.post("/api/event/findById", {
      id: idid,
      staff_id: "",
      event_type:
        formData.event_type === "1" || formData.event_type === 1 ? 1 : 0,
    })
      .then((res) => {
        if (res.data.status === true) {
          setFormData({
            name: res.data.data.name,
            eventDate: res.data.data.start_date
              ? res.data.data.start_date.slice(0, 10)
              : "",
            startTime:
              res.data.data.start_time !== null
                ? moment(res.data.data.start_time, "hh:mm:ss").format(
                  "hh:mm:ss a"
                )
                : "",
            startTime_In: `${moment(res.data.data.started_dt).format(
              "YYYY-MM-DD"
            )}T${res.data.data.start_time}`,
            endTime:
              res.data.data.end_time !== null
                ? moment(res.data.data.end_time, "HH:mm:ss").format(
                  "hh:mm:ss a"
                )
                : "",
            endTime_In: `${moment(res.data.data.started_dt).format(
              "YYYY-MM-DD"
            )}T${res.data.data.end_time}`,
            staff_user_id: res.data.data.staff_user_id
              ? res.data.data.staff_user_id
              : null,
            Method_type: res.data.data.method_of_test === 1 ? "1" : "0",
            testType: res.data.data.test_type_pk,
            staff: res.data.data.staff_id ? res.data.data.staff_id : null,
            subject: res.data.data.subject ? res.data.data.subject : null,
            event_type: res.data.data.event_type === 1 ? "1" : "0",
            test_number:
              res.data.data.test_no !== null ? res.data.data.test_no : "",
            total_marks:
              res.data.data.tot_marks !== null ? res.data.data.tot_marks : "",
            qpath:
              res.data.data.que_paper_filepath !== null
                ? res.data.data.que_paper_filepath
                : null,
            original_que_paper_filepath:
              res.data.data.original_que_paper_filepath !== null
                ? res.data.data.original_que_paper_filepath
                : null,
            qsyllpath:
              res.data.data.syllabus_filepath !== null
                ? res.data.data.syllabus_filepath
                : null,
            original_syllabus_filepath:
              res.data.data.original_syllabus_filepath !== null
                ? res.data.data.original_syllabus_filepath
                : null,
            no_question:
              res.data.data.no_of_questions !== null
                ? res.data.data.no_of_questions
                : "",
            desc: res.data.data.description,
            desc_staff: res.data.data.staff_description,
            teams_url: res.data.data.teams_url,
            cut_off: res.data.data.cut_off,
            DanswerSheet:
              res.data.data.ans_sheet_download_req === 1 ? "1" : "0",
            doubleEvaluation: res.data.data.double_evaluation,
            AnswerBookletPath:
              res.data.data.answer_booklet_path !== null
                ? res.data.data.answer_booklet_path
                : null,
            answer_booklet_original_path:
              res.data.data.answer_booklet_original_path !== null
                ? res.data.data.answer_booklet_original_path
                : null,
            AnswerKeyPath:
              res.data.data.answer_key_path !== null
                ? res.data.data.answer_key_path
                : null,
            answer_key_original_path:
              res.data.data.answer_key_original_path !== null
                ? res.data.data.answer_key_original_path
                : null,
            questionPath:
              res.data.data.que_paper_filepath !== null
                ? res.data.data.que_paper_filepath
                : null,
            // questionFrameSetPath:
            //   res.data.data.questionFrameSetPath !== null
            //     ? res.data.data.questionFrameSetPath
            //     : null,
            questionFrameSetPath:
              res.data.data.question_frame_path !== null &&
                res.data.data.question_frame_path !== ""
                ? res.data.data.question_frame_path
                : null,
            question_frame_original_path:
              res.data.data.question_frame_original_path !== null &&
                res.data.data.question_frame_original_path !== ""
                ? res.data.data.question_frame_original_path
                : null,
          });
          setDropDown(() => ({
            ["test_type"]: {
              value: res.data.data.test_type_pk,
              label: res.data.data.test_type_name,
            },
            ["staff"]: {
              value: res.data.data.staff_name,
              label: res.data.data.staff_name,
            },
          }));

          const subTopic =
            res.data.data.subject_test_topics.length > 0
              ? res.data.data.subject_test_topics.map((onk, index) => ({
                id: index + 1,
                subject_test_topic_pk: onk.id,
                STSubject: onk.subject_name,
                STSubject_id: onk.subject_pk,
                STtopic: onk.test_topics.map((io) => ({
                  value: io.id,
                  label: io.test_topic,
                  test_topic: io.test_topic,
                })),
                STSubject_error: "",
                STtopic_error: "",
              }))
              : [
                {
                  id: 1,
                  STSubject: "",
                  STSubject_id: "",
                  STTopicOption: [],
                  STtopic: [],
                  STSubject_error: "",
                  STtopic_error: "",
                },
              ];
          setSubTopic(subTopic);

          const otherApplicable =
            res.data.data.oth_app_batch.length > 0
              ? res.data.data.oth_app_batch.map((onks, index) => ({
                batch_pk: onks.other_batch_pk,
                batch_name: onks.name,
                value: onks.other_batch_pk,
                label: `${onks.name} - ${onks.medium} - ${onks.class_mode}`,
                section: onks.section,
                class_mode: onks.class_mode,
                medium: onks.medium,
                category: category,
                subcategory: subcate,
                category_class: coures_name,
                category_course_id: category_course_id,
              }))
              : [];
          setAppBatchse(otherApplicable);
        }
      })
      .catch((error) => {
        return error;
      });
  };

  useEffect(() => {
    if (category && subcate && coures_name) {
      AxiosInstance.post("/api/all/getSubjects", {
        category: category,
        sub_category: subcate,
        course_name: coures_name,
      })
        .then((res) => {
          if (res.data.status === true) {
            if (res.data.data.length > 0) {
              const subject = res.data.data.map((item, index) => ({
                value: item.id,
                label: item.subject,
                type: item.type,
                category: item.category,
                subcategory: item.subcategory,
                course_name: item.course_name,
                subject: item.subject,
              }));
              setSubject_test(subject);
              // const subjectclass = res.data.data.map((item, index) => ({
              //   value: item.subject,
              //   label: item.subject,
              //   pk: item.id,
              // }));
              // setSubject(subjectclass);
            } else {
              setSubject_test([]);
              // setSubject([]);
            }
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, []);

  useEffect(() => {
    if (batch_id && category) {
      AxiosInstance.post(`/api/event/batches/applicable`, {
        category: category,
        category_course_id: category_course_id,
        batch_pk: batch_id,
      })
        .then((res) => {
          if (res.data.status === true) {
            // console.log("data.data", res.data.data);
            if (res.data.data.length > 0) {
              const Apbatch = res.data.data.map((item, index) => ({
                medium: item.medium,
                value: item.batch_pk,
                label: `${item.batch_name} - (${item.medium} - ${item.class_mode})`,
                // label: `${item.batch_name} - (${item.medium})`,
                batch_pk: item.batch_pk,
                batch_name: item.batch_name,
                class_mode: item.class_mode,
                section: item.section,
                category: item.category,
                category_class: item.category_class,
                category_course_id: item.category_course_id,
              }));
              setAppBatch(Apbatch);
            }
          } else {
            setAppBatch([]);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, []);

  useEffect(() => {
    const userJSON = sessionStorage.getItem("userDetails");
    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        const userid = user.user_id;
        setUserId(userid);
      }
    }
  }, []);

  useEffect(() => {
    AxiosInstance.post(`/api/staff/bySubject`, { category: category })
      .then((res) => {
        if (res.data.status === true) {
          if (res.data.data.length > 0) {
            const staff = res.data.data.map((item, index) => ({
              value: item.id,
              user_id: item.user_id,
              email: item.email,
              label: item.fullname,
            }));
            setStaff(staff);
          }
        } else {
          setStaff([]);
        }
      })
      .catch((error) => {
        toast.error(
          "Something went wrong when loading subject list. Please try again."
        );
        return error;
      });
    // ------Test Type --------
  }, []);

  useEffect(() => {
    AxiosInstance.post(`/api/all/getTestTypes`, {
      method_of_test: formData.Method_type
        ? parseInt(formData.Method_type)
        : "",
    })
      .then((res) => {
        if (res.data.status === true) {
          if (res.data.data.length > 0) {
            const TestType = res.data.data.map((item, index) => ({
              value: item.id,
              label: item.name,
            }));
            setTestType(TestType);
          }
        } else {
          setTestType([]);
        }
      })
      .catch((error) => {
        toast.error(
          "Something went wrong when loading test-types. Please try again."
        );
        return error;
      });
  }, [formData.Method_type]);

  useEffect(() => {
    if (formData.staff_user_id) {
      AxiosInstance.post("/api/all/getSubject/byFaculty", {
        category: category,
        user_id: formData.staff_user_id,
      })
        .then((res) => {
          if (res.data.status === true) {
            if (res.data.data.length > 0) {
              const subject = res.data.data.map((item, index) => ({
                value: item.subject,
                label: item.subject,
                pk: item.subject_pk,
              }));
              setSubject(subject);
            }
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, [formData.staff]);

  const handleSelectCH = (option, dname) => {
    if (dname === "staff") {
      const selectedOptionId = option.email;
      setFormData((prevState) => ({
        ...prevState,
        [dname]: option.value,
        ["staffEmail"]: selectedOptionId,
        ["staff_user_id"]: option.user_id,
      }));
      setDropDown((prevState) => ({
        ...prevState,
        [dname]: option,
      }));
      setError((prevError) => ({
        ...prevError,
        [dname]: "",
      }));
    } else if (dname === "subject") {
      // console.log(option,"option");
      setFormData((prevState) => ({
        ...prevState,
        [dname]: option.value,
      }));
      setFormData((prevState) => ({
        ...prevState,
        ["subject_pk"]: option.pk,
      }));
      setDropDown((prevState) => ({
        ...prevState,
        [dname]: option,
      }));
      setError((prevError) => ({
        ...prevError,
        [dname]: "",
      }));
    } else if (dname === "AppBatch") {
      const selectedAppBatch = option.map((el) => ({
        value: el.value,
        label: el.label,
        batch_pk: el.value,
        batch_name: el.label,
        section: el.section,
        category: el.category,
        category_class: el.category_class,
        category_course_id: el.category_course_id,
      }));
      setAppBatchse(selectedAppBatch);
      setError((prevError) => ({
        ...prevError,
        [appBatch]: "",
      }));
    } else if (dname === "test_type") {
      setFormData((prevState) => ({
        ...prevState,
        ["testType"]: option.value,
      }));
      setDropDown((prevState) => ({
        ...prevState,
        [dname]: option,
      }));
      setError((prevError) => ({
        ...prevError,
        ["testType"]: "",
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [dname]: option.value,
      }));
      setDropDown((prevState) => ({
        ...prevState,
        [dname]: option,
      }));
      setError((prevError) => ({
        ...prevError,
        [dname]: "",
      }));
    }
  };

  const handleChangeCuDate = (dates, name) => {
    const dateSam = moment(dates).format("YYYY-MM-DD");
    // console.log(name, "name", dateSam);
    setFormData((prevState) => ({
      ...prevState,
      [name]: dateSam,
    }));
    setError({
      ...error,
      [name]: "",
    });
  };
  // useEffect(() => {
  //   if (formData.Method_type === "1") {
  //     handleChangeTime(formData.startTime, "startTime");
  //   } else {
  //     setError((prevError) => ({
  //       ...prevError,
  //       startTime: "",
  //     }));
  //   }
  // }, [formData.Method_type]);
  const [isEndTimeDisabled, setIsEndTimeDisabled] = useState(true);
  const handleChangeTime = (TimeDate, Dname) => {
    console.log(startTime, endTime, "---- edit ");
    const currentTimePlus30 = dayjs().add(30, "minute"); // Current time + 30 mins
    const currentTime = dayjs();
    const selectedTime = dayjs(TimeDate);
    
    let cur_date = new Date();
    let current_date = moment(cur_date).format("YYYY-MM-DD");
    console.log("selectedTime...",current_date,"-------",sDate);
    
    if (Dname === "startTime" && formData.event_type !== "1" && formData.Method_type === "1") {
      if (selectedTime.isBefore(currentTime) && current_date === sDate) {
        setError((prevError) => ({
          ...prevError,
          ["startTime"]: "The Start Time should not be lesser than the current time",
        }));
        setFormData((prevFields) => ({
          ...prevFields,
          ["startTime_In"]: "",
          ["startTime"]: "",
        }));
        setIsEndTimeDisabled(true);
        return;
      }
      const targetTimeStart = moment(startTime, "HH:mm:ss").format("h:mm:ss a");
      const targetTimeEnd = moment(endTime, "HH:mm:ss").format("h:mm:ss a");
      const formattedTime = dayjs(TimeDate).format("hh:mm:ss a");
      const result =
        moment(TimeDate).format("YYYY-MM-DD") + "T" + formattedTime;
      if (
        (dayjs(formattedTime, "hh:mm:ss a").isAfter(
          dayjs(targetTimeStart, "hh:mm:ss a")
        ) ||
          dayjs(formattedTime, "hh:mm:ss a").isSame(
            dayjs(targetTimeStart, "hh:mm:ss a")
          )) && // formattedTime can be same as targetTimeStart (9:00 AM)
        (dayjs(formattedTime, "hh:mm:ss a").isBefore(
          dayjs(targetTimeEnd, "hh:mm:ss a")
        ) ||
          dayjs(formattedTime, "hh:mm:ss a").isSame(
            dayjs(targetTimeEnd, "hh:mm:ss a")
          )) // formattedTime can be same as targetTimeEnd (6:00 PM)
      ) {
        if (dayjs(TimeDate).isAfter(currentTimePlus30) && formData.Method_type === "1") {
          setFormData((prevFields) => ({
            ...prevFields,
            ["startTime_In"]: result,
            ["startTime"]: formattedTime,
          }));
          setError({
            ...error,
            ["startTime"]: "",
          });
          setIsEndTimeDisabled(false);
        } else {
          setFormData((prevFields) => ({
            ...prevFields,
            ["startTime_In"]: "",
            ["startTime"]: "",
          }));
          setError({
            ...error,
            ["startTime"]:
              "Please choose a start time that's at least 30 minutes ahead.",
          });
          setIsEndTimeDisabled(true);
        }
      } else {
        setFormData((prevFields) => ({
          ...prevFields,
          ["startTime_In"]: "",
          ["startTime"]: "",
        }));
        setError({
          ...error,
          ["startTime"]: `The selected time must be after the batch start time (i.e.${moment(
            startTime,
            "HH:mm:ss"
          ).format(
            "h:mm A"
          )}) and on or before the batch end time (i.e. ${moment(
            endTime,
            "HH:mm:ss"
          ).format("h:mm A")})`,
        });
      }
    } else if (Dname === "startTime" && formData.event_type === "1") {
      if (selectedTime.isBefore(currentTime) && current_date === sDate) {
        setError((prevError) => ({
          ...prevError,
          ["startTime"]: "The Start Time should not be lesser than the current time",
        }));
        setFormData((prevFields) => ({
          ...prevFields,
          ["startTime_In"]: "",
          ["startTime"]: "",
        }));
        setIsEndTimeDisabled(true);
        return;
      }
      const formattedTime = dayjs(TimeDate).format("hh:mm:ss a");
      const result =
        moment(TimeDate).format("YYYY-MM-DD") + "T" + formattedTime;
      // // Check if the selected date is today's date
      const isSameDay = dayjs(sDate).isSame(dayjs(), "day"); // Check if the selected date is today
      if (isSameDay && formData.Method_type === "1") {
        // If it's the current day, check if TimeDate is at least 30 minutes ahead
        if (dayjs(TimeDate).isAfter(currentTimePlus30)) {
          // Valid: TimeDate is after current time + 30 mins
          setFormData((prevFields) => ({
            ...prevFields,
            ["startTime_In"]: result,
            ["startTime"]: formattedTime,
          }));
          setError({
            ...error,
            ["startTime"]: "", // Clear any error
          });
        } else {
          // Invalid: TimeDate is within 30 mins from now
          setError({
            ...error,
            ["startTime"]:
              "Please choose a start time that's at least 30 minutes ahead",
          });
        }
      } else {
        // If the selected date is not today (i.e., it's a future day), no need for the 30-minute constraint
        setFormData((prevFields) => ({
          ...prevFields,
          ["startTime_In"]: result,
          ["startTime"]: formattedTime,
        }));

        setError({
          ...error,
          ["startTime"]: "", // Clear any error for future date
        });
        setIsEndTimeDisabled(false);
      }
    }
    else if (Dname === "endTime" && formData.event_type !== "1") {
      if (isEndTimeDisabled) return;
      const targetTimeStart = moment(startTime, "HH:mm:ss").format("h:mm:ss a");
      const targetTimeEnd = moment(endTime, "HH:mm:ss").format("h:mm:ss a");
      const formattedTime = dayjs(TimeDate).format("HH:mm:ss a");
      const result =
        moment(TimeDate).format("YYYY-MM-DD") + "T" + formattedTime;
      const start = dayjs(
        `${sDate} ` + formData.startTime,
        "YYYY-MM-DD hh:mm:ss a"
      );
      const end = dayjs(`${sDate} ` + formattedTime, "YYYY-MM-DD hh:mm:ss a");
      // console.log(result,"result",formattedTime,end,start);

      if (
        end.isAfter(start) &&
        (dayjs(formattedTime, "hh:mm:ss a").isBefore(
          dayjs(targetTimeEnd, "h:mm:ss a")
        ) ||
          dayjs(formattedTime, "hh:mm:ss a").isSame(
            dayjs(targetTimeEnd, "h:mm:ss a")
          )) // Allow formattedTime to be same as targetTimeEnd (6:00 PM)
      ) {
        setFormData((prevFields) => ({
          ...prevFields,
          ["endTime_In"]: result,
          ["endTime"]: formattedTime,
        }));
        setError({
          ...error,
          endTime: "",
        });
      } else {
        setFormData((prevFields) => ({
          ...prevFields,
          ["endTime_In"]: "",
          ["endTime"]: "",
        }));
        setError({
          ...error,
          ["endTime"]: `The selected time must be after the start time and on or before the batch end time (i.e. ${moment(
            endTime,
            "HH:mm:ss"
          ).format("h:mm A")})`,
        });
      }
    } else if (Dname === "endTime" && formData.event_type === "1") {
      let validatedSDate = dayjs(sDate, "YYYY-MM-DD", true).isValid()
        ? sDate
        : dayjs(sDate).format("YYYY-MM-DD");
      const formattedTime = dayjs(TimeDate).format("HH:mm:ss a");
      const result =
        moment(TimeDate).format("YYYY-MM-DD") + "T" + formattedTime;
      const start = dayjs(
        `${validatedSDate} ` + formData.startTime,
        "YYYY-MM-DD hh:mm:ss a"
      );
      const end = dayjs(
        `${validatedSDate} ` + formattedTime,
        "YYYY-MM-DD hh:mm:ss a"
      );

      if (end.isAfter(start)) {
        setFormData((prevFields) => ({
          ...prevFields,
          ["endTime_In"]: result,
          ["endTime"]: formattedTime,
          // ["startTime_In"]: "",
          // ["startTime"]: "",
        }));
        setError({
          ...error,
          endTime: "",
        });
      } else {
        setFormData((prevFields) => ({
          ...prevFields,
          ["endTime_In"]: "",
          ["endTime"]: "",
        }));
        setError({
          ...error,
          ["endTime"]: "The End Time should be greater than the Start Time",
        });
      }
    }
  };

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    // console.log(name, value);
    if (name === "startTime" && formData.event_type != "1") {
      const selectedStartTime = value;
      const selectedStartTimeParts = selectedStartTime.split(":");
      const selectedStartHour = parseInt(selectedStartTimeParts[0], 10);
      const selectedStartMinute = parseInt(selectedStartTimeParts[1], 10);

      const targetTimeStart = startTime;
      const targetTimeStartParts = targetTimeStart.split(":");
      const targetStartHour = parseInt(targetTimeStartParts[0], 10);
      const targetStartMinute = parseInt(targetTimeStartParts[1], 10);

      const targetTimeEnd = endTime;
      const targetTimeEndParts = targetTimeEnd.split(":");
      const targetEndHour = parseInt(targetTimeEndParts[0], 10);
      const targetEndMinute = parseInt(targetTimeEndParts[1], 10);

      if (
        (selectedStartHour > targetStartHour ||
          (selectedStartHour === targetStartHour &&
            selectedStartMinute >= targetStartMinute)) &&
        (selectedStartHour < targetEndHour ||
          (selectedStartHour === targetEndHour &&
            selectedStartMinute <= targetEndMinute))
      ) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        setError((prevError) => ({
          ...prevError,
          startTime: "",
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          startTime: `Start time should be greater than or equal to ${moment(
            startTime,
            "HH:mm:ss"
          ).format("h:mm A")} and less than or equal to ${moment(
            endTime,
            "HH:mm:ss"
          ).format("h:mm A")}.`,
        }));
      }
    } else if (name === "endTime" && formData.event_type != "1") {
      const selectedEndTime = value;
      const selectedEndTimeParts = selectedEndTime.split(":");
      const selectedEndHour = parseInt(selectedEndTimeParts[0], 10);
      const selectedEndMinute = parseInt(selectedEndTimeParts[1], 10);

      const selectedStartTime = startTime;
      const selectedStartTimeParts = selectedStartTime.split(":");
      const selectedStartHour = parseInt(selectedStartTimeParts[0], 10);
      const selectedStartMinute = parseInt(selectedStartTimeParts[1], 10);

      const targetTime = endTime;
      const targetTimeParts = targetTime.split(":");
      const targetHour = parseInt(targetTimeParts[0], 10);
      const targetMinute = parseInt(targetTimeParts[1], 10);

      if (
        selectedEndHour > targetHour ||
        (selectedEndHour === targetHour && selectedEndMinute > targetMinute)
      ) {
        setError((prevError) => ({
          ...prevError,
          endTime: `End time should be earlier than or equal to ${moment(
            endTime,
            "HH:mm:ss"
          ).format("h:mm A")}.`,
        }));
      } else if (
        selectedEndHour < selectedStartHour ||
        (selectedEndHour === selectedStartHour &&
          selectedEndMinute < selectedStartMinute)
      ) {
        setError((prevError) => ({
          ...prevError,
          endTime: "End time should be later than the start time.",
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));

        setError({
          ...error,
          [name]: "",
        });
      }
    } else if (name === "staff" && formData.event_type != "1") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      const selectedOption = event.target.options[event.target.selectedIndex];
      const selectedOptionId = selectedOption.id;

      setFormData((prevState) => ({
        ...prevState,
        staffEmail: selectedOptionId,
      }));

      setError({
        ...error,
        [name]: "",
      });
    } else if (name === "qsyllpath" && files && files.length > 0) {
      const syllabus = files[0];
      if (
        files[0].type == "application/pdf" ||
        files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg"
      ) {
        if (files[0].size < 2 * 1024 * 1024) {
          // console.log("xcxv");
          const formData = new FormData();
          formData.append("syllabus", syllabus);
          AxiosInstance.post("/api/upload/", formData)
            .then((res) => {
              setFormData((prevState) => ({
                ...prevState,
                qsyllpath: res.data.path,
                original_syllabus_filepath: res.data.originalname,
              }));
              setError({
                ...error,
                qsyllpath: "",
              });
            })
            .catch((error) => {
              // console.log(error);
              return error;
            });
        } else {
          // console.log("error");
          let err = "File size must be less than 2MB.";
          setError({
            ...error,
            qsyllpath: err,
          });
        }
      } else {
        let err = "pdf or jpg  files only allowed";
        setError({
          ...error,
          qsyllpath: err,
        });
      }
    } else if (
      name === "qpath" &&
      files &&
      files.length > 0 &&
      formData.event_type === "1"
    ) {
      const question_bank = files[0];
      if (
        files[0].type == "text/csv" ||
        files[0].type == "application/vnd.ms-excel"
      ) {
        if (files[0].size < 10 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("question_bank", question_bank);
          AxiosInstance.post("/api/upload/", formData)
            .then((res) => {
              setFormData((prevState) => ({
                ...prevState,
                qpath: res.data.path,
                original_que_paper_filepath: res.data.originalname,
              }));
              setError({
                ...error,
                qpath: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 10MB.";
          setError({
            ...error,
            qpath: err,
          });
        }
      } else {
        let err = "csv files only allowed";
        setError({
          ...error,
          qpath: err,
        });
      }
    } else if (name === "AnswerBookletPath" && files && files.length > 0) {
      const answer_booklet = files[0];
      if (files[0].type == "text/pdf" || files[0].type == "application/pdf") {
        if (files[0].size < 10 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("answer_booklet", answer_booklet);
          AxiosInstance.post("/api/upload/", formData)
            .then((res) => {
              setFdata((prevState) => ({
                ...prevState,
                AnswerBookletPath: res.data.path,
                answer_booklet_original_path: res.data.originalname,
              }));
              setError({
                ...error,
                AnswerBookletPath: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 10MB.";
          setError({
            ...error,
            AnswerBookletPath: err,
          });
        }
      } else {
        let err = "pdf files only allowed";
        setError({
          ...error,
          qpath: err,
        });
      }
    } else if (name === "AnswerKeyPath" && files && files.length > 0) {
      const answer_key = files[0];
      if (files[0].type == "text/pdf" || files[0].type == "application/pdf") {
        if (files[0].size < 10 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("answer_key", answer_key);
          AxiosInstance.post("/api/upload/", formData)
            .then((res) => {
              setFdata((prevState) => ({
                ...prevState,
                AnswerKeyPath: res.data.path,
                answer_key_original_path: res.data.originalname,
              }));
              setError({
                ...error,
                AnswerKeyPath: "",
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 10MB.";
          setError({
            ...error,
            AnswerKeyPath: err,
          });
        }
      } else {
        let err = "pdf files only allowed";
        setError({
          ...error,
          qpath: err,
        });
      }
    } else if (name === "questionFrameSetPath" && files && files.length > 0) {
      const question_frame_set = files[0];

      // Check for valid CSV MIME types
      const allowedMimeTypes = ["text/csv", "application/vnd.ms-excel"];

      // Validate file type
      if (allowedMimeTypes.includes(files[0].type)) {
        // Validate file size (less than 10MB)
        if (files[0].size < 10 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("question_frame_set", question_frame_set);

          // First API call
          AxiosInstance.post("/api/upload/", formData)
            .then((res) => {
              setFdata((prevState) => ({
                ...prevState,
                questionFrameSetPath: res.data.path,
                question_frame_original_path: res.data.originalname,
              }));

              setError({
                ...error,
                questionFrameSetPath: "",
              });

              if (res.data.status) {
                // Second API call

                AxiosInstance.post("/api/test/descriptive/question_upload", {
                  batch_id: batch_id,
                  event_id: fdata.event_id,
                  question_frame_path: res.data.path,
                  // created_by: res.data.data.updated_by,
                })
                  .then((response) => {
                    console.log("Second API response:", response.data);
                  })
                  .catch((error) => {
                    console.error("Error uploading question:", error);
                  });
              }
            })
            .catch((error) => {
              console.error("Error in first API call:", error);
            });
        } else {
          let err = "File size must be less than 10MB.";
          setError({
            ...error,
            questionFrameSetPath: err,
          });
        }
      } else {
        // Handle invalid file type (e.g., PDF or other)
        let err = " CSV files only allowed.";
        setError({
          ...error,
          questionFrameSetPath: err,
        });
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
    }
  };

  const createOnlineMeeting = async (accessToken, formData) => {
    try {
      const [organizer, attendees] = await Promise.all([
        getUserMatchList(accessToken, formData.staffEmail),
        getAttendeeUserList(accessToken, sEmail),
      ]);
      // console.log("xcxc");
      const onlineMeeting = {
        // startDateTime: "2023-06-16T04:50:00.000Z",
        // endDateTime: "2023-06-16T05:00:00.000Z",
        startDateTime: moment(
          `${sDate} ${formData.startTime}`,
          "YYYY-MM-DD HH:mm:ss"
        ).toISOString(),
        endDateTime: moment(
          `${sDate} ${formData.endTime}`,
          "YYYY-MM-DD HH:mm:ss"
        ).toISOString(),
        participants: {
          organizer: organizer,
          attendees: attendees,
          coOrganizer: organizer,
        },
        accessLevel: "Restricted",
        subject: formData.subject,
        joinMeetingIdSettings: {
          isPasscodeRequired: true,
        },
        lobbyBypassSettings: {
          scope: "invited",
          isDialInBypassEnabled: false,
        },
        allowedAttendees: "OrganizerAndInvitees",
      };
      console.log(onlineMeeting, "onlineMeeting");
      return onlineMeeting;
    } catch (error) {
      console.log(error);
    }
  };

  //for attendee  list
  const getAttendeeUserList = async (accessToken, staffEmails) => {
    // console.log("staffEmails", staffEmails);
    const client = Client.init({
      authProvider: (done) => {
        done(null, accessToken);
      },
    });

    const matchedUsers = [];
    for (const userEmail of staffEmails) {
      try {
        if (userEmail.includes("@gmail.com")) {
          // console.log("gmail");
          // console.log(userEmail);
          const userUPN = userEmail;
          const response = await client
            .api(`/users?$filter=mail eq '${userUPN}'`)
            .header("ConsistencyLevel", "eventual")
            .get();
          if (response) {
            // Iterate through the user objects in the response
            for (const user of response.value) {
              const { id, displayName, mail } = user;
              const matchedUserObject = {
                upn: mail,
                role: "attendee",
                identity: {
                  user: {
                    id: id,
                    displayName: displayName,
                    identityProvider: "AAD",
                  },
                },
              };
              matchedUsers.push(matchedUserObject);
            }
          } else {
            console.log(`No user found for email: ${userEmail}`);
          }
        } else if (!userEmail.includes("@gmail.com")) {
          // console.log(" not gmail");
          const response = await client
            .api(`/users/${userEmail}`)
            .header("ConsistencyLevel", "eventual")
            .get();
          if (response && response.id) {
            const { id, displayName, mail } = response;
            const matchedUserObject = {
              upn: mail,
              role: "attendee",
              identity: {
                user: {
                  id: id,
                  displayName: displayName,
                  identityProvider: "AAD",
                },
              },
            };
            matchedUsers.push(matchedUserObject);
          } else {
            console.log(`No user found for email: ${userEmail}`);
          }
        }
      } catch (error) {
        console.log(error, "lalitha");
        // throw error;
      }
    }
    console.log("matchedUsers", matchedUsers);
    return matchedUsers;
  };
  //for user  match email
  const getUserMatchList = (accessToken, gmail) => {
    console.log("gmail", gmail);
    const client = Client.init({
      authProvider: (done) => {
        done(null, accessToken);
      },
    });

    return client
      .api(`/users?$filter=mail eq '${gmail}'`)
      .header("ConsistencyLevel", "eventual")
      .get()
      .then((response) => {
        if (response && response.id) {
          const { id, displayName, mail } = response;
          const organizer = {
            identity: {
              user: {
                id: id,
                displayName: displayName,
              },
            },
            role: "presenter",
            upn: mail,
          };
          // role :coorganizer checking if any need for coorganizer
          return organizer;
        } else {
          console.log(`No user found for email: ${gmail}`);
        }
      })
      .catch((error) => {
        console.log(error, "geting error ");
        console.log(`No user found for email: ${gmail}`);
      });
  };

  const validateSubTopic = (subTopicss) => {
    if (subTopicss.length > 0) {
      const UpdatesubTopic = subTopicss.map((OB) => {
        let updatedOB = { ...OB };
        if (OB.STSubject === "") {
          updatedOB.STSubject_error = "Please select the subject";
          // txtEventsyllPaper.current.focus();
        }
        if (OB.STtopic && OB.STtopic.length === 0) {
          updatedOB.STtopic_error = "Please select the test topic";
          // txtEventsyllPaper.current.focus();
        }

        return updatedOB;
      });
      setSubTopic(UpdatesubTopic);
    }
  };

  const validateSubTopic_Focus = (subTopicss) => {
    if (subTopicss.length > 0) {
      const UpdatesubTopic = subTopicss.map((OB) => {
        let updatedOB = { ...OB };
        if (OB.STSubject === "") {
          // updatedOB.STSubject_error = "Please Select Subject";
          txtEventsyllPaper.current.focus();
        }
        if (OB.STtopic && OB.STtopic.length === 0) {
          // updatedOB.STtopic_error = "Please Select Test Topic";
          txtEventsyllPaper.current.focus();
        }

        return updatedOB;
      });
    }
  };

  const handleWheel = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    const inputElement = txtEventTmarks.current;
    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);
  useEffect(() => {
    const inputElement = txtEventTNQ.current;
    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const useref_validation = {
      name: txtEventName.current,
      date: txtEventDate.current,
      startTime: txtEventSTime.current,
      endTime: txtEventETime.current,
      subject: txtEventSubject.current,
      faculty: txtEventFaculty.current,
      description: txtEventDesc.current,
      staff_dec: txtEventstaffDesc.current,
      testNo: txtEventTNo.current,
      testTopic: txtEventTtopic.current,
      testNQ: txtEventTNQ.current,
      testMarks: txtEventTmarks.current,
      paper: txtEventPaper.current,
      cut_off: txtEventCutOff.current,
      syllabuspaper: txtEventsyllPaper.current,
      Method_type: txtEventMethod_type.current,
      testType: txtEventTestType.current,
      appBatch: txtEventOtherABatch.current,
      DanswerSheet: txtEventDownloadAnswerSheet.current,
    };
    const errors = validateEvent(
      formData,
      sDate,
      formData.event_type,
      useref_validation,
      subTopic
    );
    validateSubTopic(subTopic);

    if (Object.keys(errors).length === 0 && (type === "1" || type === 1)) {
      validateSubTopic_Focus(subTopic);
    }
    const start = dayjs(`${sDate} ${formData.startTime}`, "YYYY-MM-DD hh:mm:ss a");
    const end = dayjs(`${sDate} ${formData.endTime}`, "YYYY-MM-DD hh:mm:ss a");
    const currentTime = dayjs();
    const selectedStartTime = dayjs(formData.startTime, "hh:mm:ss a");
    let cur_date = new Date();
    let current_date = moment(cur_date).format("YYYY-MM-DD");
    if (selectedStartTime.isBefore(currentTime) && current_date === sDate) {
      errors.startTime = "The Start Time should not be lesser than the current time";
    } else if (!end.isAfter(start)) {
      setError({
        ...error,
        endTime: "The End Time should be greater than the Start Time",
      });
    }
    if (
      (Object.keys(errors).length === 0 && (type === "0" || type === 0)) || // No errors
      (Object.keys(errors).length === 0 &&
        subTopic.length > 0 && // At least one subtopic
        subTopic.every(
          (el) =>
            el.STtopic_error === "" &&
            el.STSubject_error === "" &&
            el.STSubject_id !== "" &&
            el.STtopic.length > 0
        ))
    ) {
      setIsSubmitting(true);
      try {
        if (id) {
          //for not batch batch or test batch checking
          if (formData.event_type != "1") {
            const updateEvent = {
              id: id,
              event_type: formData.event_type === "1" ? 1 : 0,
              name: formData.name,
              batch_id: batch_id,
              start_date: formData.eventDate ? formData.eventDate : sDate,
              start_time:
                formData.startTime !== null
                  ? moment(formData.startTime, "hh:mm:ss a").format("HH:mm:ss")
                  : "",
              end_time:
                formData.endTime !== null
                  ? moment(formData.endTime, "hh:mm:ss a").format("HH:mm:ss")
                  : "",
              syllabus_filepath: formData.qsyllpath,
              original_syllabus_filepath: formData.original_syllabus_filepath,
              subject_pk: formData.subject_pk,
              staff_id: formData.staff,
              description: formData.desc,
              staff_description: formData.desc_staff ? formData.desc_staff : "",
              updated_by: userId,
              teams_url: formData.teams_url,
              ans_sheet_download_req: 0,
            };
            setIsLoading(true);
            AxiosInstance.post("/api/event/edit", updateEvent)
              .then((res) => {
                if (res.data.status === true) {
                  setIsLoading(false);
                  toast.success(res.data.message);
                  navigateToPreviousScreen();
                }
              })
              .catch((error) => {
                return error;
              });
          } else {
            const subject_topic =
              subTopic.length > 0
                ? subTopic.map((el) => ({
                  subject_pk: el.STSubject_id,
                  subject_name: el.STSubject,
                  test_topics:
                    el.STtopic.length > 0
                      ? el.STtopic.map((topic) => topic.test_topic)
                      : [],
                  test_topics_pk:
                    el.STtopic.length > 0
                      ? el.STtopic.map((topic) => topic.value)
                      : [],
                }))
                : [];

            const otherAppli =
              appBatchse.length > 0
                ? appBatchse.map((el) => ({
                  batch_pk: el.batch_pk,
                  // batch_name: el.batch_name,
                }))
                : [];

            let updateEvent = {};

            if (formData.Method_type === "0") {
              updateEvent = {
                id: id,
                test_no: 1,
                event_type: formData.event_type === "1" ? 1 : 0,
                name: formData.name,
                batch_id: batch_id,
                start_date: formData.eventDate ? formData.eventDate : sDate,
                start_time:
                  formData.startTime !== null
                    ? moment(formData.startTime, "hh:mm:ss a").format(
                      "HH:mm:ss"
                    )
                    : "",
                end_time:
                  formData.endTime !== null
                    ? moment(formData.endTime, "hh:mm:ss a").format("HH:mm:ss")
                    : "",
                subject_pk: null,
                staff_id: null,
                tot_marks: formData.total_marks,
                que_paper_filepath: formData.qpath,
                original_que_paper_filepath:
                  formData.original_que_paper_filepath,
                syllabus_filepath: formData.qsyllpath,
                original_syllabus_filepath: formData.original_syllabus_filepath,
                que_status:
                  formData.qpath !== null && formData.qpath !== "" ? 1 : 0,

                no_of_questions: formData.no_question,
                description: formData.desc,
                staff_description: formData.desc_staff
                  ? formData.desc_staff
                  : "",
                updated_by: userId,
                teams_url: formData.teams_url,
                test_type_pk: formData.testType,
                method_of_test: formData.Method_type === "1" ? 1 : 0,
                ans_sheet_download_req: formData.DanswerSheet === "1" ? 1 : 0,
                subject: subject_topic,
                oth_app_batch: otherAppli,
                cut_off: formData.cut_off ? formData.cut_off : null,
              };
            } else {
              updateEvent = {
                id: id,
                test_no: 1, // need to remove so put static 1
                name: formData.name,
                event_type: formData.event_type === "1" ? 1 : 0,
                batch_id: batch_id ? parseInt(batch_id) : 0,
                start_date: formData.eventDate ? formData.eventDate : sDate,
                start_time:
                  formData.startTime !== null
                    ? moment(formData.startTime, "hh:mm:ss a").format(
                      "HH:mm:ss"
                    )
                    : "",
                end_time:
                  formData.endTime !== null
                    ? moment(formData.endTime, "hh:mm:ss a").format("HH:mm:ss")
                    : "",
                subject_pk: null,
                tot_marks: formData.total_marks,
                staff_id: null,
                que_status:
                  formData.qpath !== null && formData.qpath !== "" ? 1 : 0, // send if eventtype is 1
                no_of_questions: formData.no_question,
                description: formData.desc,
                staff_description: formData.desc_staff
                  ? formData.desc_staff
                  : "",
                syllabus_filepath: formData.qsyllpath,
                original_syllabus_filepath: formData.original_syllabus_filepath,
                created_by: userId,
                teams_url: "/ViewSchedule/joinTest",
                test_type_pk: formData.testType,
                method_of_test: formData.Method_type === "1" ? 1 : 0,
                ans_sheet_download_req: formData.DanswerSheet === "1" ? 1 : 0,
                subject: subject_topic,
                oth_app_batch: otherAppli,
                // Descriptive Upload

                que_paper_filepath:
                  formData.questionPath === null ? "" : formData.questionPath,
                original_que_paper_filepath:
                  formData.original_que_paper_filepath === null
                    ? ""
                    : formData.original_que_paper_filepath,
                answer_booklet_path: formData.AnswerBookletPath,
                answer_booklet_original_path:
                  formData.answer_booklet_original_path === null
                    ? ""
                    : formData.answer_booklet_original_path,

                question_frame_path: formData.questionFrameSetPath,
                question_frame_original_path:
                  formData.question_frame_original_path,
                answer_key_path: formData.AnswerKeyPath,
                answer_key_original_path: formData.answer_key_original_path,
                double_evaluation: formData.doubleEvaluation,
                cut_off: formData.cut_off ? formData.cut_off : null,
              };
            }
            setIsLoading(true);
            AxiosInstance.post("/api/event/edit", updateEvent)
              .then((res) => {
                if (res.data.status === true) {
                  setIsLoading(false);
                  if (
                    formData.qpath &&
                    res.data.data.method_of_test === 0
                    // &&
                    // formData.original_que_paper_filepath
                  ) {
                    AxiosInstance.post("/api/test/questionUpload", {
                      batch_id: res.data.data.batch_id,
                      que_paper_filepath: res.data.data.que_paper_filepath,
                      event_id: res.data.data.id,
                      no_of_questions: formData.no_question,
                      created_by: res.data.data.created_by,
                      updated_by: res.data.data.updated_by,
                      updated_dt: moment().format("YYYY-MM-DD HH:mm:ss"),
                    })
                      .then((response) => {
                        if (response.data.status === true) {
                          toast.success(res.data.message);

                          navigateToWPreviousScreenClass();
                        } else {
                          toast.error(response.data.message);
                        }
                      })
                      .catch((error) => {
                        return error;
                      });
                  } else if (
                    // formData.question_frame_path &&
                    res.data.data.method_of_test === 1
                    // &&
                    // formData.question_frame_original_path
                  ) {
                    AxiosInstance.post(
                      "/api/test/descriptive/question_upload",
                      {
                        batch_id: res.data.data.batch_id,
                        event_id: res.data.data.id,
                        question_frame_path: res.data.data.question_frame_path,
                        created_by: res.data.data.created_by,
                      }
                    )
                      .then((response) => {
                        if (response.data.status === true) {
                          toast.success(res.data.message);
                          navigateToWPreviousScreenClass();
                        } else {
                          toast.error(response.data.message);
                        }
                      })
                      .catch((error) => {
                        return error;
                      });
                  } else {
                    toast.success(res.data.message);

                    navigateToWPreviousScreenClass();
                  }
                } else {
                  toast.error(res.data.message);
                }
              })
              .catch((error) => {
                return error;
              });
          }
        } else {
          //for not batch batch or test batch checking
          if (formData.event_type !== "1" && ClassMode == "Online") {
            msalInstance
              .loginPopup()
              .then((response) => {
                console.log("llaitha meetin login ");
                const accessToken = response.accessToken;
                setAccessToken(accessToken);
                // Use the access token to call the Microsoft Graph API
                const client = Client.init({
                  authProvider: (done) => {
                    done(null, accessToken);
                  },
                });
                setIsLoading(true);
                createOnlineMeeting(accessToken, formData).then(
                  (onlineMeeting) => {
                    client
                      .api("/me/onlineMeetings")
                      .post(onlineMeeting)
                      .then((response) => {
                        setIsLoading(true);
                        const CreateEvent = {
                          name: formData.name,
                          event_type: formData.event_type === "1" ? 1 : 0,

                          batch_id: batch_id,
                          start_date: formData.eventDate
                            ? formData.eventDate
                            : sDate,
                          start_time:
                            formData.startTime !== null
                              ? moment(formData.startTime, "hh:mm:ss a").format(
                                "HH:mm:ss"
                              ) // Parse and format correctly using 12-hour format with AM/PM
                              : "",

                          end_time:
                            formData.endTime !== null
                              ? moment(formData.endTime, "hh:mm:ss a").format(
                                "HH:mm:ss"
                              ) // Same for end time
                              : "",
                          subject_pk: formData.subject_pk,
                          staff_id: formData.staff,
                          description: formData.desc,
                          staff_description: formData.desc_staff
                            ? formData.desc_staff
                            : "",
                          syllabus_filepath: formData.qsyllpath,
                          original_syllabus_filepath:
                            formData.original_syllabus_filepath,
                          created_by: userId,
                          teams_url: response.joinWebUrl,
                          ans_sheet_download_req: 0,
                        };
                        // console.log(CreateEvent, "CreateEvent");
                        setIsLoading(true);
                        AxiosInstance.post(`/api/event/add`, CreateEvent)
                          .then((res) => {
                            if (res.data.status === true) {
                              setIsLoading(false);
                              toast.success(res.data.message);
                              navigateToPreviousScreen();
                            } else {
                              setIsLoading(false);
                              toast.error(res.data.message);
                            }
                          })
                          .catch((error) => {
                            setIsLoading(false);
                            console.log(error);
                            return error;
                          });
                        setIsLoading(false);
                      })
                      .catch((error) => {
                        setIsLoading(false);
                        console.log(error);
                      });
                  }
                );
              })
              .catch((error) => {
                setIsLoading(false);
                toast.error(error.message || "An error occurred");
              });
          } else if (
            formData.event_type !== "1" &&
            formData.classMode !== "Online"
          ) {
            const CreateEvent = {
              name: formData.name,
              event_type: formData.event_type === "1" ? 1 : 0,

              batch_id: batch_id,
              start_date: formData.eventDate ? formData.eventDate : sDate,
              start_time:
                formData.startTime !== null
                  ? moment(formData.startTime, "hh:mm:ss a").format("HH:mm:ss") // Parse and format correctly using 12-hour format with AM/PM
                  : "",

              end_time:
                formData.endTime !== null
                  ? moment(formData.endTime, "hh:mm:ss a").format("HH:mm:ss") // Same for end time
                  : "",
              subject_pk: formData.subject_pk,
              staff_id: formData.staff,
              description: formData.desc,
              staff_description: formData.desc_staff ? formData.desc_staff : "",
              syllabus_filepath: formData.qsyllpath,
              original_syllabus_filepath: formData.original_syllabus_filepath,
              created_by: userId,
              teams_url: null,
              ans_sheet_download_req: 0,
            };
            // console.log(CreateEvent, "CreateEvent");
            setIsLoading(true);
            AxiosInstance.post(`/api/event/add`, CreateEvent)
              .then((res) => {
                if (res.data.status === true) {
                  setIsLoading(false);
                  toast.success(res.data.message);
                  navigateToPreviousScreen();
                } else {
                  toast.error(res.data.message);
                }
                setIsLoading(false);
              })
              .catch((error) => {
                return error;
              });
          } else {
            const subject_topic =
              subTopic.length > 0
                ? subTopic.map((el) => ({
                  subject_pk: el.STSubject_id,
                  subject_name: el.STSubject,
                  test_topics:
                    el.STtopic.length > 0
                      ? el.STtopic.map((topic) => topic.test_topic)
                      : [],
                  test_topics_pk:
                    el.STtopic.length > 0
                      ? el.STtopic.map((topic) => topic.value)
                      : [],
                }))
                : [];
            const otherAppli =
              appBatchse.length > 0
                ? appBatchse.map((el) => ({
                  batch_pk: el.batch_pk,
                  // batch_name: el.batch_name,
                }))
                : [];

            let CreateEvent = {};

            if (formData.Method_type === "0") {
              CreateEvent = {
                test_no: 1, // need to remove so put static 1
                name: formData.name,
                event_type: formData.event_type === "1" ? 1 : 0,
                batch_id: batch_id ? parseInt(batch_id) : 0,
                start_date: formData.eventDate ? formData.eventDate : sDate,
                start_time:
                  formData.startTime !== null
                    ? moment(formData.startTime, "hh:mm:ss a").format(
                      "HH:mm:ss"
                    )
                    : "",
                end_time:
                  formData.endTime !== null
                    ? moment(formData.endTime, "hh:mm:ss a").format("HH:mm:ss")
                    : "",
                subject_pk: null,
                tot_marks: formData.total_marks,
                staff_id: null,
                que_paper_filepath: formData.qpath,
                original_que_paper_filepath:
                  formData.original_que_paper_filepath,
                que_status:
                  formData.qpath !== null && formData.qpath !== "" ? 1 : 0, // send if eventtype is 1
                no_of_questions: formData.no_question,
                description: formData.desc,
                staff_description: formData.desc_staff
                  ? formData.desc_staff
                  : "",
                syllabus_filepath: formData.qsyllpath,
                original_syllabus_filepath: formData.original_syllabus_filepath,
                created_by: userId,
                teams_url: "/ViewSchedule/joinTest",
                test_type_pk: formData.testType,
                method_of_test: formData.Method_type === "1" ? 1 : 0,
                ans_sheet_download_req: formData.DanswerSheet === "1" ? 1 : 0,
                subject: subject_topic,
                oth_app_batch: otherAppli,
                cut_off: formData.cut_off ? formData.cut_off : null,
              };
            } else {
              CreateEvent = {
                test_no: 1, // need to remove so put static 1
                name: formData.name,
                event_type: formData.event_type === "1" ? 1 : 0,
                batch_id: batch_id ? parseInt(batch_id) : 0,
                start_date: formData.eventDate ? formData.eventDate : sDate,
                start_time:
                  formData.startTime !== null
                    ? moment(formData.startTime, "hh:mm:ss a").format(
                      "HH:mm:ss"
                    )
                    : "",
                end_time:
                  formData.endTime !== null
                    ? moment(formData.endTime, "hh:mm:ss a").format("HH:mm:ss")
                    : "",
                subject_pk: null,
                tot_marks: formData.total_marks,
                staff_id: null,
                que_status:
                  formData.Method_type === "0"
                    ? formData.qpath !== null && formData.qpath !== ""
                      ? 1
                      : 0
                    : formData.questionPath !== null &&
                      formData.questionPath !== ""
                      ? 1
                      : 0,
                no_of_questions: formData.no_question,
                description: formData.desc,
                staff_description: formData.desc_staff
                  ? formData.desc_staff
                  : "",
                syllabus_filepath: formData.qsyllpath,
                original_syllabus_filepath: formData.original_syllabus_filepath,
                created_by: userId,
                teams_url: "/ViewSchedule/joinTest",
                test_type_pk: formData.testType,
                method_of_test: formData.Method_type === "1" ? 1 : 0,
                ans_sheet_download_req: formData.DanswerSheet === "1" ? 1 : 0,
                subject: subject_topic,
                oth_app_batch: otherAppli,
                // Descriptive Upload
                que_paper_filepath:
                  formData.questionPath === null ? "" : formData.questionPath,
                original_que_paper_filepath:
                  formData.original_que_paper_filepath === null
                    ? ""
                    : formData.original_que_paper_filepath,
                answer_booklet_path: formData.AnswerBookletPath,
                answer_booklet_original_path:
                  formData.answer_booklet_original_path === null
                    ? ""
                    : formData.answer_booklet_original_path,

                question_frame_path: formData.questionFrameSetPath,
                question_frame_original_path:
                  formData.question_frame_original_path,
                answer_key_path: formData.AnswerKeyPath,
                answer_key_original_path: formData.answer_key_original_path,
                // double_evaluation: formData.doubleEvaluation,
                double_evaluation: formData.doubleEvaluation
                  ? formData.doubleEvaluation
                  : 0,

                cut_off: formData.cut_off ? formData.cut_off : null,
              };
            }
            setIsLoading(true);
            AxiosInstance.post(`/api/event/add`, CreateEvent)
              .then((res) => {
                setIsLoading(false);
                if (res.data.status === true) {
                  if (
                    formData.qpath &&
                    res.data.data.method_of_test === 0 &&
                    formData.original_que_paper_filepath
                  ) {
                    AxiosInstance.post("/api/test/questionUpload", {
                      batch_id: res.data.data.batch_id,
                      que_paper_filepath: res.data.data.que_paper_filepath,
                      event_id: res.data.data.id,
                      no_of_questions: formData.no_question,
                      created_by: res.data.data.created_by,
                      updated_by: res.data.data.updated_by,
                      updated_dt: moment().format("YYYY-MM-DD HH:mm:ss"),
                    })
                      .then((response) => {
                        if (response.data.status === true) {
                          toast.success(res.data.message);

                          navigateToPreviousScreen();
                        } else {
                          const eventID = res.data.data.id;
                          setFormData((prevState) => ({
                            ...prevState,
                            navigateScreen: true,
                          }));
                          navigate(`/CreateEvent`, {
                            state: {
                              id: eventID,
                              sDate,
                              batch_id,
                              category,
                              startTime,
                              endTime,
                              type,
                              coures_name,
                              category_course_id,
                              subcate,
                            },
                          });
                          toast.error(response.data.message);
                        }
                      })
                      .catch((error) => {
                        return error;
                      });
                  } else if (
                    formData.questionFrameSetPath &&
                    res.data.data.method_of_test === 1 &&
                    formData.question_frame_original_path
                  ) {
                    AxiosInstance.post(
                      "/api/test/descriptive/question_upload",
                      {
                        batch_id: res.data.data.batch_id,
                        question_frame_path: res.data.data.question_frame_path,
                        event_id: res.data.data.id,
                        created_by: res.data.data.created_by,
                      }
                    )
                      .then((response) => {
                        if (response.data.status === true) {
                          toast.success(res.data.message);

                          navigateToPreviousScreen();
                        } else {
                          const eventID = res.data.data.id;
                          clearFileInput();
                          navigate(`/CreateEvent`, {
                            state: {
                              id: eventID,
                              sDate,
                              batch_id,
                              category,
                              startTime,
                              endTime,
                              type,
                              coures_name,
                              category_course_id,
                              subcate,
                            },
                          });
                          setFormData((prevState) => ({
                            ...prevState,
                            navigateScreen: true,
                            question_frame_original_path: null,
                            question_frame_path: null,
                          }));
                          toast.error(response.data.message);
                        }
                        setIsLoading(false);
                      })
                      .catch((error) => {
                        setIsLoading(false);
                        return error;
                      });
                  } else {
                    setIsLoading(false);
                    toast.success(res.data.message);
                    navigateToPreviousScreen();
                  }
                } else {
                  toast.error(res.data.message);
                }
              })
              .catch((error) => {
                setIsLoading(false);
                return error;
              });
          }
        }
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
        console.log(error);
      }
    } else {
      setError(errors);
      setIsSubmitting(false);
      return;
    }
  };
  // add sub and topic
  const AddSubTop = () => {
    setSubTopic((prevState) => [
      ...prevState,
      {
        id: prevState.length > 0 ? prevState.length + 1 : 1,
        STSubject: "",
        STSubject_id: "",
        STTopicOption: [],
        STtopic: [],
        STSubject_error: "",
        STtopic_error: "",
      },
    ]);
  };
  //delete sub and topics

  const deleteSubTop = (id) => {
    const val = subTopic
      .filter((item) => item.id !== id)
      .map((item, index) => ({ ...item, id: index + 1 }));
    setSubTopic(val);
  };

  const handleSubjectOptionChange = async (option, id, name) => {
    if (name === "STSubject") {
      if (option) {
        const OptionSelected = {
          value: option.value,
          label: option.label,
          type: option.type,
          category: option.category,
          subcategory: option.subcategory,
          course_name: option.course_name,
          subject: option.label,
        };
        setSubTopic((prevState) => {
          const isAlreadyExists = prevState.some(
            (q) => q.STSubject_id === OptionSelected.value
          );

          return prevState.map((q) => {
            if (q.id === id) {
              if (isAlreadyExists) {
                return {
                  ...q,
                  [name]: "",
                  STSubject_id: "",
                  STSubject_error: "This subject is already selected.",
                  STTopicOption: [],
                };
              } else {
                return {
                  ...q,
                  [name]: OptionSelected.label,
                  STSubject_id: OptionSelected.value,
                  STSubject_error: "",
                  STtopic: [],
                  STTopicOption: [],
                };
              }
            }
            return q;
          });
        });
        // handleTestTopics(
        //   OptionSelected.label,
        //   OptionSelected.category,
        //   option.subcategory,
        //   option.course_name
        // );
      } else {
        setSubTopic((prevState) => {
          return prevState.map((q) => {
            if (q.id === id) {
              return {
                ...q,
                [name]: "",
                ["STSubject_id"]: "",
                STSubject_error: "",
                STtopic: [],
                STTopicOption: [],
              };
            }
            return q;
          });
        });
      }
    } else if (name === "STtopic") {
      if (option.length > 0) {
        const selectTestTopics = option.map((el) => ({
          value: el.value,
          label: el.label,
          type: el.type,
          category: el.category,
          subcategory: el.subcategory,
          course_name: el.course_name,
          subject: el.subject,
          test_topic: el.test_topic,
        }));
        setSubTopic((prevState) => {
          return prevState.map((q) => {
            if (q.id === id) {
              return {
                ...q,
                ["STtopic"]: selectTestTopics,
                STtopic_error: "",
              };
            }
            return q;
          });
        });
      } else {
        setSubTopic((prevState) => {
          return prevState.map((q) => {
            if (q.id === id) {
              return {
                ...q,
                ["STtopic"]: [],
                STtopic_error: "",
              };
            }
            return q;
          });
        });
      }

      //  already selected value check
      // setSubTopic((prevState) => {
      //   const allExistingTopics = prevState.flatMap((q) => q.STtopic || []);
      //   const newTopics = selectTestTopics.filter(
      //     (newTopic) =>
      //       !allExistingTopics.some(
      //         (existingTopic) => newTopic.value === existingTopic.value
      //       )
      //   );

      //   const isAnyNewTopic = newTopics.length > 0;

      //   console.log(allExistingTopics, "allExistingTopics");
      //   console.log(newTopics, "newTopics");

      //   return prevState.map((q) => {
      //     if (q.id === id) {
      //       if (!isAnyNewTopic) {
      //         return {
      //           ...q,
      //           STtopic: [],
      //           STtopic_error: "Selected topics already exist.",
      //         };
      //       } else {
      //         return {
      //           ...q,
      //           STtopic: [...(q.STtopic || []), ...newTopics],
      //           STtopic_error: "",
      //         };
      //       }
      //     }
      //     return q;
      //   });
      // });
    }
  };

  const handleTestTopics = (subjectLabel, cate, subcate, course_name) => {
    AxiosInstance.post("/api/all/getTestTopics/bySubject", {
      category: cate,
      sub_category: subcate,
      course_name: course_name,
      subject: subjectLabel,
    })
      .then((res) => {
        if (res.data.status === true) {
          if (res.data.data.length > 0) {
            const test_topic = res.data.data.map((item, index) => ({
              value: item.id,
              label: item.test_topic,
              type: item.type,
              category: item.category,
              subcategory: item.subcategory,
              course_name: item.course_name,
              subject: item.subject,
              test_topic: item.test_topic,
            }));
            return test_topic;
            // setTestTopic(test_topic);
          }
          // else {
          //   setTestTopic([]);
          // }
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const handleCloseModels_Succ = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };

  const handleTimingFocus = (add) => {
    if (add.current) {
      add.current.focus();
      add.current.click(); // Simulate a click to open the picker
    }
  };
  const handleCloseModel = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };

  // Descriptive Upload Question Paper
  const [fdata, setFdata] = useState({
    title: "",
    sDate: "",
    startTime: "",
    endTime: "",
    subject: "",
    staff_name: "",
    description: "",
    batchName: "",
    category: "",
    course: "",
    method_of_test: "",
    que_status: "",
    classMode: "",
    medium: "",
    location: "",
    teams_url: "",
    batch_id: "",
    event_id: "",
    test_num: "",
    test_topic: "",
    tol_mark: "",
    no_ques: "",
    qpath: null,
    assign_to: "",
    assign_to_name: "",
    event_type: "",
    cutOff: "",
    publish_results: null,
    // descriptivr question paper
    questionPath: null,
    original_que_paper_filepath: null,
    AnswerBookletPath: null,
    answer_booklet_original_path: null,
    questionFrameSetPath: null,
    question_frame_original_path: null,
    AnswerKeyPath: null,
    answer_key_original_path: null,
    cut_off: "",
    doubleEvaluation: "",
    original_que_paper_filepath: null,
  });

  const handleFileChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "questionPath") {
      if (files && files.length > 0) {
        setFormData((prevState) => ({
          ...prevState,
          questionPath: null,
          original_que_paper_filepath: null,
        }));
        const question_paper = files[0];
        if (files[0].type == "text/pdf" || files[0].type == "application/pdf") {
          if (files[0].size < 10 * 1024 * 1024) {
            const formData = new FormData();
            formData.append("question_paper", question_paper);
            AxiosInstance.post("/api/upload/", formData)
              .then((res) => {
                setFormData((prevState) => ({
                  ...prevState,
                  questionPath: res.data.path,
                  original_que_paper_filepath: res.data.originalname,
                }));
                setError({
                  ...error,
                  questionPath: "",
                });
              })
              .catch((error) => {
                return error;
              });
          } else {
            let err = "File size must be less than 10MB.";
            setError({
              ...error,
              questionPath: err,
            });
            setFormData((prevState) => ({
              ...prevState,
              questionPath: null,
              original_que_paper_filepath: null,
            }));
          }
        } else {
          let err = "pdf files only allowed";
          setError({
            ...error,
            questionPath: err,
          });
          setFormData((prevState) => ({
            ...prevState,
            questionPath: null,
            original_que_paper_filepath: null,
          }));
        }
      } else {
        setFormData((prevState) => ({
          ...prevState,
          questionPath: null,
          original_que_paper_filepath: null,
        }));
      }
    } else if (name === "AnswerBookletPath") {
      if (files && files.length > 0) {
        setFormData((prevState) => ({
          ...prevState,
          AnswerBookletPath: null,
          answer_booklet_original_path: null,
        }));
        const answer_booklet = files[0];
        if (files[0].type == "text/pdf" || files[0].type == "application/pdf") {
          if (files[0].size < 10 * 1024 * 1024) {
            const formData = new FormData();
            formData.append("answer_booklet", answer_booklet);
            AxiosInstance.post("/api/upload/", formData)
              .then((res) => {
                setFormData((prevState) => ({
                  ...prevState,
                  AnswerBookletPath: res.data.path,
                  answer_booklet_original_path: res.data.originalname,
                }));
                setError({
                  ...error,
                  AnswerBookletPath: "",
                });
              })
              .catch((error) => {
                return error;
              });
          } else {
            let err = "File size must be less than 10MB.";
            setError({
              ...error,
              AnswerBookletPath: err,
            });
            setFormData((prevState) => ({
              ...prevState,
              AnswerBookletPath: null,
              answer_booklet_original_path: null,
            }));
          }
        } else {
          let err = "pdf files only allowed";
          setError({
            ...error,
            qpath: err,
          });
          setFormData((prevState) => ({
            ...prevState,
            AnswerBookletPath: null,
            answer_booklet_original_path: null,
          }));
        }
      } else {
        setFormData((prevState) => ({
          ...prevState,
          AnswerBookletPath: null,
          answer_booklet_original_path: null,
        }));
      }
    } else if (name === "AnswerKeyPath") {
      if (files && files.length > 0) {
        const answer_key = files[0];
        setFormData((prevState) => ({
          ...prevState,
          AnswerKeyPath: null,
          answer_key_original_path: null,
        }));
        if (files[0].type == "text/pdf" || files[0].type == "application/pdf") {
          if (files[0].size < 10 * 1024 * 1024) {
            const formData = new FormData();
            formData.append("answer_key", answer_key);
            AxiosInstance.post("/api/upload/", formData)
              .then((res) => {
                setFormData((prevState) => ({
                  ...prevState,
                  AnswerKeyPath: res.data.path,
                  answer_key_original_path: res.data.originalname,
                }));
                setError({
                  ...error,
                  AnswerKeyPath: "",
                });
              })
              .catch((error) => {
                return error;
              });
          } else {
            let err = "File size must be less than 10MB.";
            setError({
              ...error,
              AnswerKeyPath: err,
            });
            setFormData((prevState) => ({
              ...prevState,
              AnswerKeyPath: null,
              answer_key_original_path: null,
            }));
          }
        } else {
          let err = "pdf files only allowed";
          setError({
            ...error,
            AnswerKeyPath: err,
          });
          setFormData((prevState) => ({
            ...prevState,
            AnswerKeyPath: null,
            answer_key_original_path: null,
          }));
        }
      } else {
        setFormData((prevState) => ({
          ...prevState,
          AnswerKeyPath: null,
          answer_key_original_path: null,
        }));
      }
    } else if (name === "questionFrameSetPath") {
      if (files && files.length > 0) {
        const question_frame_set = files[0];
        setFormData((prevState) => ({
          ...prevState,
          questionFrameSetPath: null,
          question_frame_original_path: null,
        }));
        const allowedMimeTypes = ["text/csv", "application/vnd.ms-excel"];

        // Validate file type
        if (allowedMimeTypes.includes(files[0].type)) {
          // Validate file size (less than 10MB)
          if (files[0].size < 10 * 1024 * 1024) {
            const formData = new FormData();
            formData.append("question_frame_set", question_frame_set);

            // First API call
            AxiosInstance.post("/api/upload/", formData)
              .then((res) => {
                setFormData((prevState) => ({
                  ...prevState,
                  questionFrameSetPath: res.data.path,
                  question_frame_original_path: res.data.originalname,
                }));

                setError({
                  ...error,
                  questionFrameSetPath: "",
                });
              })
              .catch((error) => {
                console.error("Error in first API call:", error);
              });
          } else {
            let err = "File size must be less than 10MB.";
            setError({
              ...error,
              questionFrameSetPath: err,
            });
            setFormData((prevState) => ({
              ...prevState,
              questionFrameSetPath: null,
              question_frame_original_path: null,
            }));
          }
        } else {
          // Handle invalid file type (e.g., PDF or other)
          let err = " CSV files only allowed.";
          setError({
            ...error,
            questionFrameSetPath: err,
          });
          setFormData((prevState) => ({
            ...prevState,
            questionFrameSetPath: null,
            question_frame_original_path: null,
          }));
        }
      } else {
        setFormData((prevState) => ({
          ...prevState,
          questionFrameSetPath: null,
          question_frame_original_path: null,
        }));
      }
    } else {
      setFormData((prevFields) => ({
        ...prevFields,
        [name]: parseInt(value),
      }));
      setError({
        ...error,
        [name]: "",
      });
    }
  };
  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <div className="Event">
      <div className="row">
        <div className="col-md-12">
          {swalMsg.show === true && (
            <ModelComForP
              show={swalMsg.show}
              message={swalMsg.message}
              status={swalMsg.status}
              title={swalMsg.title}
              BottomOkBtn={swalMsg.BottomOkBtn}
              BottomCancelBtn={swalMsg.BottomCancelBtn}
              handleCloseModels={handleCloseModel}
              // handleClose_yes={handleSPayfee}
              BottomOkClose={swalMsg.BottomOkClose}
              handleCloseModels_Succ={handleCloseModels_Succ}
            />
          )}
          <section className=" StepOne ">
            <div className="heading  mt-3 d-flex justify-content-between ">
              {id ? (
                <h4 className="Test_Titles">Edit Event</h4>
              ) : (
                <h4 className="Test_Titles">Create New Event</h4>
              )}
              <Link
                to="javascript:void(0)"
                onClick={navigateToPreviousScreen}
                className="CancelButton"
              >
                {/* Back to View  Batch */}
                Back to View Batch
              </Link>
              <button
                type="button"
                className={(isSubmitting)? "TestSaveButton me-1 DisabledListviS":"TestSaveButton"}
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                <a href="javascript:void(0)"> Save Event</a>
              </button>
            </div>
            <div className="row box ">
              <div className="col-md-12">
                <p className="StepTitle">Event Details</p>
                <div className="row ">
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="name_event">Event Name</label>
                      <input
                        ref={txtEventName}
                        type="text"
                        class="Inputs"
                        id="name_event"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter Event Name"
                        required
                      />
                      {error.name && (
                        <small className="error">{error.name}</small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="event_type">Event Type</label>
                      <div
                        className="d-flex flex-lg-row flex-md-row flex-column"
                        id="radiobuttons"
                      >
                        <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="event_type"
                            id="event_type2"
                            // disabled={type === "4" ? true : false}
                            disabled
                            value="0"
                            checked={formData.event_type === "0"}
                            onChange={handleChange}
                          />
                          <label
                            className="checkbox-label mt-4"
                            htmlFor="event_type2"
                            style={{ opacity: "0.3", cursor: "not-allowed" }} // temporary styles
                          >
                            Class
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="checkbox_radio"
                            type="radio"
                            name="event_type"
                            disabled={type === "4" ? true : false}
                            id="event_type1"
                            value="1"
                            checked={formData.event_type === "1"}
                            onChange={handleChange}
                          />
                          <label
                            className="checkbox-label mt-4"
                            htmlFor="event_type1"
                          >
                            Test
                          </label>
                        </div>
                      </div>
                      {error.event_type && (
                        <small className="error">{error.event_type}</small>
                      )}
                    </div>
                  </div>
                  {formData.event_type === "1" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="Method_type">Method of Test</label>
                        <div
                          className="d-flex flex-lg-row flex-md-row flex-column"
                          id="radiobuttons"
                        >
                          <div className="form-check form-check-inline">
                            <input
                              ref={txtEventMethod_type}
                              className="checkbox_radio"
                              type="radio"
                              name="Method_type"
                              id="Method_type2"
                              disabled={type === "4" ? true : false}
                              value="0"
                              checked={formData.Method_type === "0"}
                              onChange={handleChange}
                            />
                            <label
                              className="checkbox-label mt-4"
                              htmlFor="Method_type2"
                            >
                              Objective
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="checkbox_radio"
                              type="radio"
                              name="Method_type"
                              // disabled={type === "4" ? true : false}
                              disabled
                              id="Method_type1"
                              value="1"
                              checked={formData.Method_type === "1"}
                              onChange={handleChange}
                            />
                            <label
                              className="checkbox-label mt-4"
                              htmlFor="Method_type1"
                              style={{ opacity: "0.3", cursor: "not-allowed" }} // temporary styles
                            >
                              Descriptive
                            </label>
                          </div>
                        </div>
                        {error.Method_type && (
                          <small className="error">{error.Method_type}</small>
                        )}
                      </div>
                    </div>
                  )}
                  {formData.event_type === "1" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="testType">Test Type</label>
                        <Select
                          ref={txtEventTestType}
                          id="testType"
                          name="testType"
                          value={dropDown.test_type}
                          onChange={(option) =>
                            handleSelectCH(option, "test_type")
                          }
                          options={
                            testType
                              .filter((item) => item && item.label) // Filter out null and undefined elements
                              .sort((a, b) => a.label.localeCompare(b.label)) // Sort options alphabetically by label
                          }
                          isSearchable={true} // Enables search functionality
                          placeholder="Select an option"
                          maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                          filterOption={(option, searchText) =>
                            option.label
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                          } // Filter options based on the search text
                          styles={customStyles} // Apply custom styles her
                        />

                        {error.testType && (
                          <small className="error">{error.testType}</small>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="eventDate">Date</label>
                      <DatePickerSample
                        txtAdMDob={txtBatchSdate}
                        class={"Inputs width_for_datepicker"}
                        // class={"width_for_datepicker"}
                        readOnly={"readOnly"}
                        IconInput={"EventreactDatepickerIocn"}
                        name={"eventDate"}
                        handleChange={handleChangeCuDate}
                        fieldInput={
                          formData.eventDate !== null &&
                            formData.eventDate !== ""
                            ? formData.eventDate
                            : sDate
                        }
                      />
                      {error.eventDate && (
                        <small className="error">{error.eventDate}</small>
                      )}
                    </div>
                  </div>

                  {/* {formData.event_type === "1" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="subject">Subject:</label>
                        <Select
                          ref={txtEventSubject}
                          id="subject"
                          name="subject"
                          value={(formData.subject !== null && formData.subject !== "") ? { value: formData.subject, label: formData.subject } : null}
                          onChange={option => handleSelectCH(option, 'subject')}
                          options={subject
                            .filter((item) => item && item.label) // Filter out null and undefined elements
                            .sort((a, b) => a.label.localeCompare(b.label)) // Sort options alphabetically by label
                          }
                          isSearchable={true} // Enables search functionality
                          placeholder="Select an option"
                          maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5 
                          filterOption={(option, searchText) =>
                            option.label.toLowerCase().includes(searchText.toLowerCase())
                          } // Filter options based on the search text
                          styles={customStyles} // Apply custom styles her
                        />
                        {error.subject && (
                          <small className="error">{error.subject}</small>
                        )}
                      </div>
                    </div>
                  )} */}
                  <div className="col-md-6">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div className="forms ">
                        <label for="startTime">Start Time</label>
                        <MobileTimePicker
                          ref={txtEventSTime}
                          id="startTime"
                          step="1"
                          name="startTime"
                          placeholder="Enter startTime"
                          title={formData.startTime}
                          value={dayjs(formData.startTime_In)}
                          //  views={['hours','minutes', 'seconds']}
                          format="hh:mm:ss a"
                          // minTime={dayjs()}
                          onChange={(data) =>
                            handleChangeTime(data, "startTime")
                          }
                        />
                        <TimeIcon
                          className="TimerIconEvent"
                          color="primary"
                          onClick={() => handleTimingFocus(txtEventSTime)}
                        />
                        {error.startTime && (
                          <small className="error" style={{ marginTop: "0px" }}>
                            {error.startTime}
                          </small>
                        )}
                      </div>
                    </LocalizationProvider>
                  </div>
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="endTime">End Time</label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileTimePicker
                          ref={txtEventETime}
                          id="endTime"
                          step={1}
                          name="endTime"
                          placeholder="Enter endTime"
                          title={formData.endTime}
                          value={dayjs(formData.endTime_In)}
                          format="hh:mm:ss a" // Use 'HH' for 24-hour format
                          onChange={(data) => handleChangeTime(data, "endTime")}
                          disabled={isEndTimeDisabled}
                        />
                        <TimeIcon
                          className="TimerIconEvent"
                          color="primary"
                          onClick={() => handleTimingFocus(txtEventETime)}
                        />
                      </LocalizationProvider>
                      {error.endTime && (
                        <small className="error" style={{ marginTop: "0px" }}>
                          {error.endTime}
                        </small>
                      )}
                    </div>
                  </div>
                  {formData.event_type === "1" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="no_question">
                          Total Number of Questions
                        </label>
                        <input
                          ref={txtEventTNQ}
                          type="number"
                          class="Inputs"
                          value={
                            formData.no_question < 0 ? 0 : formData.no_question
                          }
                          onChange={handleChange}
                          id="no_question"
                          name="no_question"
                          placeholder="Total Number of Questions"
                        />
                        {error.no_question && (
                          <small className="error">{error.no_question}</small>
                        )}
                      </div>
                    </div>
                  )}

                  {formData.event_type === "1" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="total_marks">Total Marks</label>
                        <input
                          ref={txtEventTmarks}
                          type="number"
                          class="Inputs"
                          value={
                            formData.total_marks < 0 ? 0 : formData.total_marks
                          }
                          onChange={handleChange}
                          id="total_marks"
                          name="total_marks"
                          placeholder="Total Marks"
                        />
                        {error.total_marks && (
                          <small className="error">{error.total_marks}</small>
                        )}
                      </div>
                    </div>
                  )}

                  {formData.event_type != "1" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="staff">Faculty Name</label>
                        <Select
                          ref={txtEventFaculty}
                          id="staff"
                          name="staff"
                          value={dropDown.staff}
                          onChange={(option) => handleSelectCH(option, "staff")}
                          options={
                            staff
                              .filter((item) => item && item.label) // Filter out null and undefined elements
                              .sort((a, b) => a.label.localeCompare(b.label)) // Sort options alphabetically by label
                          }
                          isSearchable={true} // Enables search functionality
                          placeholder="Select an option"
                          maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                          filterOption={(option, searchText) =>
                            option.label
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                          } // Filter options based on the search text
                          styles={customStyles} // Apply custom styles her
                        />
                        {error.staff && (
                          <small className="error">{error.staff}</small>
                        )}
                      </div>
                    </div>
                  )}
                  {formData.event_type != "1" && (
                    <div className="col-md-6">
                      <div className="forms  ">
                        <label for="subject">Subject</label>
                        <Select
                          ref={txtEventSubject}
                          id="subject"
                          name="subject"
                          value={
                            formData.subject !== null && formData.subject !== ""
                              ? {
                                value: formData.subject,
                                label: formData.subject,
                              }
                              : null
                          }
                          onChange={(option) =>
                            handleSelectCH(option, "subject")
                          }
                          options={
                            subject
                              .filter((item) => item && item.label) // Filter out null and undefined elements
                              .sort((a, b) => a.label.localeCompare(b.label)) // Sort options alphabetically by label
                          }
                          isSearchable={true} // Enables search functionality
                          placeholder="Select an option"
                          maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                          filterOption={(option, searchText) =>
                            option.label
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                          } // Filter options based on the search text
                          styles={customStyles} // Apply custom styles her
                        />
                        {error.subject && (
                          <small className="error">{error.subject}</small>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="desc">Instruction to Student</label>
                      <textarea
                        ref={txtEventDesc}
                        class="textareaInput"
                        name="desc"
                        id="desc"
                        rows="5"
                        col="10"
                        maxLength={500}
                        value={formData.desc}
                        onChange={handleChange}
                      ></textarea>
                      {error.desc && (
                        <small className="error">{error.desc}</small>
                      )}
                    </div>
                  </div>
                  {formData.event_type != "1" && (
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="desc_staff">Instruction to Faculty</label>
                        <textarea
                          ref={txtEventstaffDesc}
                          class="textareaInput"
                          name="desc_staff"
                          id="desc_staff"
                          rows="5"
                          col="10"
                          maxLength={500}
                          value={formData.desc_staff}
                          onChange={handleChange}
                        ></textarea>
                        {error.desc_staff && (
                          <small className="error">{error.desc_staff}</small>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="forms ">
                      <label for="qsyllpath">
                        Upload Syllabus (In PDF or JPG format)
                        {formData.qsyllpath !== "" &&
                          formData.qsyllpath !== null && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${formData.qsyllpath}`}
                            >
                              <Download className="download_icon" />
                            </a>
                          )}
                      </label>
                      <div className="row">
                        <div
                          className={
                            formData.qsyllpath !== "" &&
                              formData.qsyllpath !== null
                              ? "col-md-3 "
                              : "col-md-12"
                          }
                        >
                          <input
                            ref={txtEventsyllPaper}
                            type="file"
                            className={
                              formData.qsyllpath !== "" &&
                                formData.qsyllpath !== null
                                ? "InputsFile"
                                : "InputsFile"
                            }
                            id="qsyllpath"
                            name="qsyllpath"
                            onChange={handleChange}
                            placeholder="Enter syllabus path"
                            required
                          />
                        </div>
                        {formData.qsyllpath !== "" &&
                          formData.qsyllpath !== null && (
                            <div
                              className={
                                "col-md-9 d-flex align-content-center p-0"
                              }
                            >
                              <span className="nn_upload_file me-1">
                                {formData.qsyllpath !== "" &&
                                  formData.qsyllpath !== null
                                  ? formData.original_syllabus_filepath
                                  : ""}
                              </span>
                              <span className="material-icons align-middle text-danger verified_upload">
                                verified
                              </span>
                            </div>
                          )}
                      </div>
                      {error.qsyllpath && (
                        <small className="error">{error.qsyllpath}</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {formData.event_type === "1" && (
            <section className="StepOne ">
              {subTopic.map((item, index) => (
                <SubjectTopics
                  key={item.id}
                  length={subTopic.length}
                  handleSubjectOptionChange={handleSubjectOptionChange}
                  AddSubTop={AddSubTop}
                  deleteSubTop={deleteSubTop}
                  subject_test={subject_test}
                  testTopic={testTopic}
                  record={item}
                  txtEventSTSub={txtEventSTSub}
                  txtEventSTtopics={txtEventSTtopics}
                  cate={category}
                  subcate={subcate}
                  course_name={coures_name}
                />
              ))}
            </section>
          )}
          {formData.event_type === "1" && (
            <section className="StepOne ">
              <div className="row box ">
                <div className="col-md-12">
                  <p className="StepTitle">Other Applicable Batches</p>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="appBatch">Other Applicable Batches </label>

                        <Select
                          ref={txtEventOtherABatch}
                          id="appBatch"
                          name="appBatch"
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          isClearable={true}
                          isMulti
                          value={appBatchse.map((value) => ({
                            value: value.value,
                            label: value.label,
                            section: value.section,
                            category: value.category,
                            category_class: value.category_class,
                            category_course_id: value.category_course_id,
                          }))}
                          onChange={(option) =>
                            handleSelectCH(option, "AppBatch")
                          }
                          options={
                            appBatch
                              .filter((item) => item && item.label)
                              .sort((a, b) => a.label.localeCompare(b.label)) // Sort options alphabetically by label
                          }
                          isSearchable={true} // Enables search functionality
                          placeholder="Select an option"
                          maxMenuHeight={200} // Set a maximum height for the dropdown menu maxium 5
                          filterOption={(option, searchText) =>
                            option.label
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                          } // Filter options based on the search text
                          components={{
                            Option: CommonOption, // Correct the syntax here
                          }}
                          styles={multiCusutomStyle} // Apply custom styles her
                        />
                        {error.appBatch && (
                          <small className="error">{error.appBatch}</small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {formData.event_type === "1" && formData.Method_type === "0" && (
            <section className="StepOne ">
              <div className="row box ">
                <div className="col-md-12">
                  <p className="StepTitle">Upload Question Paper</p>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <div className="forms ">
                        <label for="qpath">
                          Upload Question Paper (In CSV format)
                          {formData.qpath !== "" && formData.qpath !== null && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${formData.qpath}`}
                            >
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <div className="row">
                          <div
                            className={
                              formData.qpath !== "" && formData.qpath !== null
                                ? "col-md-3 "
                                : "col-md-12"
                            }
                          >
                            <input
                              ref={txtEventPaper}
                              type="file"
                              className={
                                formData.qpath !== "" && formData.qpath !== null
                                  ? "InputsFile nn_upload_width"
                                  : "InputsFile  vv_upload_width"
                              }
                              id="qpath"
                              name="qpath"
                              onChange={handleChange}
                              placeholder="Enter question path"
                              required
                            />
                          </div>
                          {formData.qpath !== "" && formData.qpath !== null && (
                            <div
                              className={
                                "col-md-9 d-flex align-content-center p-0"
                              }
                            >
                              <span className="nn_upload_file">
                                {formData.qpath !== "" &&
                                  formData.qpath !== null
                                  ? formData.original_que_paper_filepath
                                  : ""}
                              </span>
                            </div>
                          )}
                        </div>

                        {error.qpath && (
                          <small className="error">{error.qpath}</small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="forms">
                        <label htmlFor="inputName" className="form-label  ">
                          Cut-off Marks
                        </label>
                        <input
                          ref={txtEventCutOff}
                          type="number"
                          class="Inputs"
                          id="cut_off"
                          value={formData.cut_off <= 0 ? "" : formData.cut_off}
                          name="cut_off"
                          placeholder="Enter Cut-off Marks"
                          onChange={handleChange}
                        />
                        {error.cut_off && (
                          <small className="error">{error.cut_off}</small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="forms ">
                        <label for="Method_type">
                          Allow students to download the answer sheet on the
                          View Test page?
                        </label>
                        <div
                          className="d-flex flex-lg-row flex-md-row flex-column"
                          id="radiobuttons"
                        >
                          <div className="form-check form-check-inline">
                            <input
                              ref={txtEventDownloadAnswerSheet}
                              className="checkbox_radio"
                              type="radio"
                              name="DanswerSheet"
                              id="YesDAnswersheet"
                              value="1"
                              checked={formData.DanswerSheet === "1"}
                              onChange={handleChange}
                            />
                            <label
                              className="checkbox-label mt-4"
                              htmlFor="YesDAnswersheet"
                            >
                              Yes
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="checkbox_radio"
                              type="radio"
                              name="DanswerSheet"
                              id="NoDAnswersheet"
                              value="0"
                              checked={formData.DanswerSheet === "0"}
                              onChange={handleChange}
                            />
                            <label
                              className="checkbox-label mt-4"
                              htmlFor="NoDAnswersheet"
                            >
                              No
                            </label>
                          </div>
                        </div>
                        {error.DanswerSheet && (
                          <small className="error">{error.DanswerSheet}</small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {formData.event_type === "1" && formData.Method_type === "1" && (
            <section className="global_container">
              <div className="row m-0">
                <fieldset>
                  <legend
                    className="w-auto"
                    style={{ fontSize: "16px", padding: "0px 10px" }}
                  >
                    Question Paper
                  </legend>
                  <div class="row">
                    <div class="col-12">
                      <form className="row g-3">
                        {/* <div class="col-md-6 ">
                          <label
                            for="formFile"
                            class="form-label confirm_value"
                          >
                            Question Paper
                          </label>
                          <input
                            type="file"
                            class={`${styles.desc_input} w-100 opacity-75 rounded-2 input-font-size-sm`}
                            id="questionPath"
                            name="questionPath"
                            onChange={handleFileChange}
                            placeholder="Enter question path"
                            required
                          />

                          {formData.questionPath !== "" &&
                            formData.questionPath !== null && (
                              <div
                                className={
                                  "col-md-g d-flex align-content-center p-0"
                                }
                              >
                                <span className="nn_upload_file">
                                  {formData.questionPath !== "" &&
                                    formData.questionPath !== null
                                    ? formData.original_que_paper_filepath
                                    : ""}
                                </span>

                                {formData.questionPath !== "" &&
                                  formData.questionPath !== null && (
                                    <a
                                      target="_blank"
                                      href={`${ImageUrlPrefix}/${formData.questionPath}`}
                                      style={{ marginTop: "15px" }}
                                    >
                                      <Download className="download_icon" />
                                    </a>
                                  )}
                              </div>
                            )}

                          {error.questionPath && (
                            <small className="error">
                              {error.questionPath}
                            </small>
                          )}
                        </div> */}
                        <div className="col-lg-6 col-md-12">
                          <div className="forms ">
                            <div className="d-flex">
                              <div className="row">
                                <label
                                  for="formFile"
                                  class={` form-label confirm_value`}
                                >
                                  Question Paper
                                </label>
                              </div>
                              {formData.questionPath !== "" &&
                                formData.questionPath !== null && (
                                  <div
                                    className={
                                      "d-flex align-content-center p-0"
                                    }
                                  >
                                    {formData.questionPath !== "" &&
                                      formData.questionPath !== null && (
                                        <>
                                          <a
                                            target="_blank"
                                            href={`${ImageUrlPrefix}/${formData.questionPath}`}
                                          // style={{ marginTop: "15px" }}
                                          >
                                            <Download className="download_icon" />
                                          </a>
                                        </>
                                      )}
                                  </div>
                                )}
                            </div>
                            <div className="row">
                              <div
                                className={
                                  formData.questionPath !== "" &&
                                    formData.questionPath !== null
                                    ? "col-md-3 col-5 "
                                    : "col-md-12"
                                }
                              >
                                <input
                                  type="file"
                                  className={
                                    formData.questionPath !== "" &&
                                      formData.questionPath !== null
                                      ? "InputsSelectFile nn_upload_width"
                                      : "InputsSelectFile  vv_upload_width"
                                  }
                                  id="questionPath"
                                  name="questionPath"
                                  onChange={handleFileChange}
                                  placeholder="Enter question path"
                                  required
                                />
                              </div>
                              {formData.questionPath !== "" &&
                                formData.questionPath !== null && (
                                  <div
                                    className={
                                      "col-md-9 col-7 d-flex  align-content-center p-0"
                                    }
                                  >
                                    <span className="nn_upload_fileevaluator">
                                      {formData.questionPath !== "" &&
                                        formData.questionPath !== null
                                        ? formData.original_que_paper_filepath
                                        : ""}
                                    </span>
                                    {formData.questionPath !== "" &&
                                      formData.questionPath !== null && (
                                        <>
                                          <span
                                            className={`${styles.verified_uploadeval} material-icons align-middle text-danger`}
                                          >
                                            verified
                                          </span>
                                        </>
                                      )}
                                  </div>
                                )}
                            </div>

                            {error.questionPath && (
                              <small className="error">
                                {error.questionPath}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* <div class="col-md-6 ">
                          <label
                            for="formFile"
                            class="form-label confirm_value"
                          >
                            Answer Booklet
                          </label>
                          <input
                            type="file"
                            class={`${styles.desc_input} w-100 opacity-75 rounded-2 input-font-size-sm`}
                            id="AnswerBookletPath"
                            name="AnswerBookletPath"
                            onChange={handleFileChange}
                            placeholder="Enter question path"
                            required
                          />
                          {formData.AnswerBookletPath !== "" &&
                            formData.AnswerBookletPath !== null && (
                              <div
                                className={
                                  "col-md-9 d-flex align-content-center p-0"
                                }
                              >
                                <span className="nn_upload_file">
                                  {formData.AnswerBookletPath !== "" &&
                                    formData.AnswerBookletPath !== null
                                    ? formData.answer_booklet_original_path
                                    : ""}
                                </span>
                                {formData.AnswerBookletPath !== "" &&
                                  formData.AnswerBookletPath !== null && (
                                    <a
                                      target="_blank"
                                      href={`${ImageUrlPrefix}/${formData.AnswerBookletPath}`}
                                      style={{ marginTop: "15px" }}
                                    >
                                      <Download className="download_icon" />
                                    </a>
                                  )}
                              </div>
                            )}

                          {error.AnswerBookletPath && (
                            <small className="error">
                              {error.AnswerBookletPath}
                            </small>
                          )}
                        </div> */}
                        <div className="col-lg-6 col-md-12">
                          <div className="forms ">
                            <div className="d-flex">
                              <div className="row">
                                <label
                                  for="formFile"
                                  class={` form-label confirm_value`}
                                >
                                  Answer Booklet
                                </label>
                              </div>
                              {formData.AnswerBookletPath !== "" &&
                                formData.AnswerBookletPath !== null && (
                                  <div
                                    className={
                                      "d-flex align-content-center p-0"
                                    }
                                  >
                                    {formData.AnswerBookletPath !== "" &&
                                      formData.AnswerBookletPath !== null && (
                                        <>
                                          <a
                                            target="_blank"
                                            href={`${ImageUrlPrefix}/${formData.AnswerBookletPath}`}
                                          // style={{ marginTop: "15px" }}
                                          >
                                            <Download className="download_icon" />
                                          </a>
                                        </>
                                      )}
                                  </div>
                                )}
                            </div>
                            <div className="row">
                              <div
                                className={
                                  formData.AnswerBookletPath !== "" &&
                                    formData.AnswerBookletPath !== null
                                    ? "col-md-3 col-5 "
                                    : "col-md-12"
                                }
                              >
                                <input
                                  type="file"
                                  className={
                                    formData.AnswerBookletPath !== "" &&
                                      formData.AnswerBookletPath !== null
                                      ? "InputsSelectFile nn_upload_width"
                                      : "InputsSelectFile  vv_upload_width"
                                  }
                                  id="AnswerBookletPath"
                                  name="AnswerBookletPath"
                                  onChange={handleFileChange}
                                  placeholder="Enter Answer BookletPath"
                                  required
                                />
                              </div>
                              {formData.AnswerBookletPath !== "" &&
                                formData.AnswerBookletPath !== null && (
                                  <div
                                    className={
                                      "col-md-9 col-7 d-flex iconBlue align-content-center p-0"
                                    }
                                  >
                                    <span className="nn_upload_fileevaluator">
                                      {formData.AnswerBookletPath !== "" &&
                                        formData.AnswerBookletPath !== null
                                        ? formData.answer_booklet_original_path
                                        : ""}
                                    </span>
                                    {formData.AnswerBookletPath !== "" &&
                                      formData.AnswerBookletPath !== null && (
                                        <>
                                          <span
                                            className={`${styles.verified_uploadeval} material-icons align-middle text-danger`}
                                          >
                                            verified
                                          </span>
                                        </>
                                      )}
                                  </div>
                                )}
                            </div>
                            {error.AnswerBookletPath && (
                              <small className="error">
                                {error.AnswerBookletPath}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* <div class="col-md-6 ">
                          <label
                            for="formFile"
                            class="form-label confirm_value"
                          >
                            Question Frame Set
                          </label>

                          <input
                            ref={fileInputRef}
                            type="file"
                            class={`${styles.desc_input} w-100 opacity-75 rounded-2 input-font-size-sm`}
                            id="questionFrameSetPath"
                            name="questionFrameSetPath"
                            onChange={handleFileChange}
                            placeholder="Enter question path"
                            required
                          />
                          {formData.questionFrameSetPath !== "" &&
                            formData.questionFrameSetPath !== null && (
                              <div
                                className={
                                  "col-md-9 d-flex align-content-center p-0"
                                }
                              >
                                <span className="nn_upload_file">
                                  {formData.questionFrameSetPath !== "" &&
                                    formData.questionFrameSetPath !== null
                                    ? formData.question_frame_original_path
                                    : ""}
                                </span>

                                {formData.questionFrameSetPath !== "" &&
                                  formData.questionFrameSetPath !== null && (
                                    <a
                                      target="_blank"
                                      href={`${ImageUrlPrefix}/${formData.questionFrameSetPath}`}
                                      style={{ marginTop: "15px" }}
                                    >
                                      <Download className="download_icon" />
                                    </a>
                                  )}
                              </div>
                            )}

                          {error.questionFrameSetPath && (
                            <small className="error">
                              {error.questionFrameSetPath}
                            </small>
                          )}
                        </div> */}
                        <div className="col-lg-6 col-md-12">
                          <div className="forms ">
                            <div className="d-flex">
                              <div className="row">
                                <label
                                  for="formFile"
                                  class={` form-label confirm_value`}
                                >
                                  Question Frame Set
                                </label>
                              </div>
                              {formData.questionFrameSetPath !== "" &&
                                formData.questionFrameSetPath !== null && (
                                  <div
                                    className={
                                      "d-flex align-content-center p-0"
                                    }
                                  >
                                    {formData.questionFrameSetPath !== "" &&
                                      formData.questionFrameSetPath !==
                                      null && (
                                        <>
                                          <a
                                            target="_blank"
                                            href={`${ImageUrlPrefix}/${formData.questionFrameSetPath}`}
                                          // style={{ marginTop: "15px" }}
                                          >
                                            <Download className="download_icon" />
                                          </a>
                                        </>
                                      )}
                                  </div>
                                )}
                            </div>
                            <div className="row">
                              <div
                                className={
                                  formData.questionFrameSetPath !== "" &&
                                    formData.questionFrameSetPath !== null
                                    ? "col-md-3 col-5 "
                                    : "col-md-12"
                                }
                              >
                                <input
                                  ref={fileInputRef}
                                  type="file"
                                  className={
                                    formData.questionFrameSetPath !== "" &&
                                      formData.questionFrameSetPath !== null
                                      ? ` InputsSelectFile nn_upload_width`
                                      : ` InputsSelectFile vv_upload_width`
                                  }
                                  id="questionFrameSetPath"
                                  name="questionFrameSetPath"
                                  onChange={handleFileChange}
                                  placeholder="Enter question path"
                                  required
                                />
                              </div>
                              {formData.questionFrameSetPath !== "" &&
                                formData.questionFrameSetPath !== null && (
                                  <div
                                    className={
                                      "col-md-9 col-7 d-flex iconBlue align-content-center p-0"
                                    }
                                  >
                                    <span className="nn_upload_fileevaluator">
                                      {formData.questionFrameSetPath !== "" &&
                                        formData.questionFrameSetPath !== null
                                        ? formData.question_frame_original_path
                                        : ""}
                                    </span>
                                    {formData.questionFrameSetPath !== "" &&
                                      formData.questionFrameSetPath !==
                                      null && (
                                        <>
                                          <span
                                            className={`${styles.verified_uploadeval} material-icons align-middle text-danger`}
                                          >
                                            verified
                                          </span>
                                        </>
                                      )}
                                  </div>
                                )}
                            </div>
                            {error.questionFrameSetPath && (
                              <small className="error">
                                {error.questionFrameSetPath}
                              </small>
                            )}
                          </div>
                        </div>
                        {/* <div class="col-md-6 ">
                          <label
                            for="formFile"
                            class="form-label confirm_value"
                          >
                            Answer Key
                          </label>

                          <input
                            type="file"
                            class={`${styles.desc_input} w-100 opacity-75 rounded-2 input-font-size-sm`}
                            id="AnswerKeyPath"
                            name="AnswerKeyPath"
                            onChange={handleFileChange}
                            placeholder="Enter question path"
                            required
                          />

                          {formData.AnswerKeyPath !== "" &&
                            formData.AnswerKeyPath !== null && (
                              <div
                                className={
                                  "col-md-9 d-flex align-content-center p-0"
                                }
                              >
                                <span className="nn_upload_file">
                                  {formData.AnswerKeyPath !== "" &&
                                    formData.AnswerKeyPath !== null
                                    ? formData.answer_key_original_path
                                    : ""}
                                </span>
                                {formData.AnswerKeyPath !== "" &&
                                  formData.AnswerKeyPath !== null && (
                                    <a
                                      target="_blank"
                                      href={`${ImageUrlPrefix}/${formData.AnswerKeyPath}`}
                                      style={{ marginTop: "15px" }}
                                    >
                                      <Download className="download_icon" />
                                    </a>
                                  )}
                              </div>
                            )}
                          {error.AnswerKeyPath && (
                            <small className="error">
                              {error.AnswerKeyPath}
                            </small>
                          )}
                        </div> */}
                        <div className="col-lg-6 col-md-12">
                          <div className="forms ">
                            <div className="d-flex">
                              <div className="row">
                                <label
                                  for="formFile"
                                  class={` form-label confirm_value`}
                                >
                                  Answer Key
                                </label>
                              </div>
                              {formData.AnswerKeyPath !== "" &&
                                formData.AnswerKeyPath !== null && (
                                  <div
                                    className={
                                      "d-flex align-content-center p-0"
                                    }
                                  >
                                    {formData.AnswerKeyPath !== "" &&
                                      formData.AnswerKeyPath !== null && (
                                        <>
                                          <a
                                            target="_blank"
                                            href={`${ImageUrlPrefix}/${formData.AnswerKeyPath}`}
                                          // style={{ marginTop: "15px" }}
                                          >
                                            <Download className="download_icon" />
                                          </a>
                                        </>
                                      )}
                                  </div>
                                )}
                            </div>
                            <div className="row">
                              <div
                                className={
                                  formData.AnswerKeyPath !== "" &&
                                    formData.AnswerKeyPath !== null
                                    ? "col-md-3 col-5 "
                                    : "col-md-12"
                                }
                              >
                                <input
                                  type="file"
                                  className={
                                    formData.AnswerKeyPath !== "" &&
                                      formData.AnswerKeyPath !== null
                                      ? "InputsSelectFile nn_upload_width"
                                      : "InputsSelectFile  vv_upload_width"
                                  }
                                  id="AnswerKeyPath"
                                  name="AnswerKeyPath"
                                  onChange={handleFileChange}
                                  placeholder="Enter question path"
                                  required
                                />
                              </div>
                              {formData.AnswerKeyPath !== "" &&
                                formData.AnswerKeyPath !== null && (
                                  <div
                                    className={
                                      "col-md-9 col-7 d-flex iconBlue align-content-center p-0"
                                    }
                                  >
                                    <span className="nn_upload_fileevaluator">
                                      {formData.AnswerKeyPath !== "" &&
                                        formData.AnswerKeyPath !== null
                                        ? formData.answer_key_original_path
                                        : ""}
                                    </span>
                                    {formData.AnswerKeyPath !== "" &&
                                      formData.AnswerKeyPath !== null && (
                                        <>
                                          <span
                                            className={`${styles.verified_uploadeval} material-icons align-middle text-danger`}
                                          >
                                            verified
                                          </span>
                                        </>
                                      )}
                                  </div>
                                )}
                            </div>
                            {error.AnswerKeyPath && (
                              <small className="error">
                                {error.AnswerKeyPath}
                              </small>
                            )}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label
                            for="Cut-off"
                            class="form-label confirm_value "
                          >
                            Cut-off
                          </label>
                          <input
                            ref={txtEventCutOff}
                            type="number"
                            id="cut_off"
                            class={`${styles.border_head_color} form-control form-control-sm shadow-none input-font-size`}
                            value={formData.cut_off < 0 ? 0 : formData.cut_off}
                            name="cut_off"
                            placeholder="Enter Cut-off Mark"
                            onChange={handleFileChange}
                          />
                          {error.cut_off && (
                            <small className="error">{error.cut_off}</small>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="doubleEvaluation"
                            className={`${styles.form_label} confirm_value`}
                          >
                            Double Evaluation
                          </label>
                          <div className="mt-2">
                            <input
                              // ref={txtEventDownloadAnswerSheet}
                              className={styles.checkbox_radio}
                              type="radio"
                              name="doubleEvaluation"
                              id="doubleEvaluations"
                              value="1"
                              checked={formData.doubleEvaluation === 1}
                              onChange={handleFileChange}
                            />
                            <label
                              className={`${styles.margin_right} form-label confirm_value`}
                              htmlFor="doubleEvaluations"
                            >
                              Yes
                            </label>

                            <input
                              className={styles.checkbox_radio}
                              type="radio"
                              name="doubleEvaluation"
                              id="doubleEvaluationv"
                              value="0"
                              checked={formData.doubleEvaluation === 0}
                              onChange={handleFileChange}
                            />
                            <label
                              className="form-label confirm_value"
                              htmlFor="doubleEvaluationv"
                            >
                              No
                            </label>
                          </div>
                          {error.doubleEvaluation && (
                            <small className="error">
                              {error.doubleEvaluation}
                            </small>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </fieldset>
              </div>
            </section>
          )}

          <div className="heading  mb-4 mt-3 d-flex justify-content-end">
            <Link to="/ViewCourseBatch" className="CancelButton">
              {/* Back to View Course Batch */}
              Back to View Batch
            </Link>
            <button
              type="button"
              className={(isSubmitting )? "TestSaveButton me-1 DisabledListviS":"TestSaveButton"}
              disabled={isSubmitting }
              onClick={handleSubmit}
            >
              Save Event
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateEvent;
