import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import createInfo from "./Announcement.module.css";
import Select from "react-select";
import Swal from "sweetalert2";
import { customStyles } from "../../../../pages/customStyles";
import { AxiosInstance } from "../../../../axios";
import toast from "react-hot-toast";
import Loader from "../../Loader";
import moment from "moment";
import DatePickerSample from "../../DatePicker";

export default function CreateAnnouncementInfo() {
  const { id } = useParams();
  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id not available in session storage");
  }

  const navigate = useNavigate();
  const [ccategory, setCcategory] = useState([]);
  const [initialfields, setInitialfields] = useState({
    Url: "",
    UrlLabel: "",
    Category: "",
    Category_ID: "",
    Description: "",
    start_date: "",
    end_date: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  // Fetch categories for the select dropdown
  useEffect(() => {
    AxiosInstance.get("api/course/category_list")
      .then((res) => {
        const categories = res.data?.data?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setCcategory(categories);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  useEffect(() => {
    if (id) {
      AxiosInstance.post(`api/all/announcement/view`, { id: id })
        .then((res) => {
          setIsLoading(true);
          if (res.data.status === true) {
            setIsLoading(false);
            setInitialfields({
              Url: res.data.data[0].url,
              Category: res.data.data[0].category_name,
              Category_ID: res.data.data[0].category_id,
              Description: res.data.data[0].description,
              start_date: moment(res.data.data[0].start_date).format(
                "YYYY-MM-DD"
              ),
              end_date: moment(res.data.data[0].end_date).format("YYYY-MM-DD"),
            });
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          return error;
        });
    }
  }, [id]);

  const handleChangeSelectDropdown = (option, dname) => {
    if (dname === "Category") {
      setInitialfields((prevState) => ({
        ...prevState,
        Category: option.label,
        Category_ID: option.value,
      }));
      setError({
        ...error,
        [dname]: "",
      });
    }
  };

  const handleDateChange = (date, fieldName) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    if (fieldName === "start_date" || fieldName === "end_date") {
      const from =
        fieldName === "start_date" ? formattedDate : initialfields.start_date;
      const to =
        fieldName === "end_date" ? formattedDate : initialfields.end_date;

      if (fieldName === "end_date" && moment(to).isBefore(moment(from))) {
        setInitialfields({
          ...initialfields,
          [fieldName]: formattedDate,
        });
        setError((prev) => ({
          ...prev,
          end_date: "Display To date cannot be earlier than Display From date",
        }));
        return;
      }
      if (
        fieldName === "start_date" &&
        moment(from).isAfter(moment(to)) &&
        to
      ) {
        setInitialfields({
          ...initialfields,
          [fieldName]: formattedDate,
        });
        setError((prev) => ({
          ...prev,
          start_date: "Display From date cannot be later than Display To date",
        }));
        return;
      }
    }
    setInitialfields({
      ...initialfields,
      [fieldName]: formattedDate,
    });
    setError({
      ...error,
      [fieldName]: "",
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInitialfields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
    setError({
      ...error,
      [name]: "",
    });
  };
  //  ref
  const description = useRef(null);
  const url = useRef(null);
  const category_tt = useRef(null);
  const display_start_date = useRef(null);
  const display_end_date = useRef(null);

  // Validation function
  const validateFields = (data, suTopics) => {
    const errors = {};
    // const urlRegex =
    //   /^(https?:\/\/((?!-)[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+|\d{1,3}(?:\.\d{1,3}){3}))(:\d{1,5})?(\/[^\s?#]*)?(\?[^\s#]*)?(#[^\s]*)?$/iu;
    const urlRegex =
      /^(https?:\/\/(localhost(:\d{1,5})?|((?!-)[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+|\d{1,3}(?:\.\d{1,3}){3}))(:\d{1,5})?(\/[^\s?#]*)?(\?[^\s#]*)?(#[^\s]*)?)$/iu;

    if (!data.Url) {
      errors.Url = "Please enter a URL";
    } else if (!urlRegex.test(data.Url)) {
      errors.Url = "Please enter a valid URL";
    }

    if (!data.Category) {
      errors.Category = "Please select a category";
    }

    if (!data.Description) {
      errors.Description = "Please enter a description";
    } else if (data.Description.length > 250) {
      errors.Description = "Description must not exceed 250 characters";
    }

    if (!data.start_date) {
      errors.start_date = "Please select Display From date";
    }

    if (!data.end_date) {
      errors.end_date = "Please select Display To date";
    }

    validateFocusCommentSection(data, suTopics);
    return errors;
  };

  // focus the error fields in comment section
  const validateFocusCommentSection = (COMSECFO) => {
    if (!COMSECFO.Description) {
      description.current.focus();
    } else if (!COMSECFO.Url) {
      url.current.focus();
    } else if (!COMSECFO.Category) {
      category_tt.current.focus();
    } else if (!COMSECFO.start_date) {
      display_start_date.current.focus();
    } else if (!COMSECFO.end_date) {
      display_end_date.current.focus();
    }
  };

  const handleSubmit = () => {
    const errors = validateFields(initialfields);
    if (initialfields.Description.length > 250) {
      errors.Description = "Description must not exceed 250 characters";
    }
    if (Object.keys(errors).length === 0) {
      try {
        if (id) {
          // If an id is provided, update the data
          const announcementData = {
            id: id,
            description: initialfields.Description,
            url: initialfields.Url,
            category_id: initialfields.Category_ID,
            category_name: initialfields.Category,
            start_date: initialfields.start_date,
            end_date: initialfields.end_date,
            created_dt: moment().format("YYYY-MM-DD HH:mm:ss"),
            created_by: userid,
          };

          AxiosInstance.post(`api/all/announcement/create`, announcementData)
            .then((res) => {
              if (res.status === 200) {
                toast.success(res.data.message);
                navigate("/Announcement");
              } else {
                toast.error(res.data.message);
              }
            })
            .catch((error) => {
              return error;
            });
        } else {
          // If no id is provided, add the new data
          const createAnnouncementData = {
            description: initialfields.Description,
            url: initialfields.Url,
            category_id: initialfields.Category_ID,
            category_name: initialfields.Category,
            start_date: initialfields.start_date,
            end_date: initialfields.end_date,
            created_dt: moment().format("YYYY-MM-DD HH:mm:ss"),
            created_by: userid,
            id: "",
          };

          AxiosInstance.post(
            `api/all/announcement/create`,
            createAnnouncementData
          )
            .then((res) => {
              // console.log(res);
              if (res.status === 200) {
                toast.success(res.data.message);
                navigate("/Announcement");
              } else {
                toast.error(res.data.message);
              }
            })
            .catch((error) => {
              return error;
            });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };

  const navigateToPreviousScreen = () => {
    navigate(-1);
  };
  // const futureDate = moment().add(0, "days");
  const futureDate = new Date();

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <div className={`${createInfo.CourseInfoSec} BathHeader global_container`}>
      <div className="row">
        <div className="col-md-6 col-12">
          <h2 className="TitleHeader">
            {id ? "Edit Announcement" : "Create New Announcement"}
          </h2>
        </div>
        <div className="col-md-6 col-12 ListBtn">
          <ul className="List-Button">
            <li className="ListBtnItems">
              <Link
                to={id ? "javascript:void(0)" : "/Announcement"}
                onClick={id ? navigateToPreviousScreen : () => {}}
                className="secondaryButton "
                variant="secondary"
              >
                {id ? "Back to View Announcement" : "Back to Announcements"}
              </Link>
            </li>
            <li className="ListBtnItems">
              <Link
                to="javascript:void(0)"
                onClick={handleSubmit}
                className="primaryButton text-center "
                variant="primary"
              >
                Save Announcement
              </Link>
            </li>
          </ul>
        </div>
        <div className="row m-0 mb-3">
          <fieldset className="mt-3">
            <legend className="w-auto">Announcement Details</legend>
            <form className="row g-3">
              {/* <div className="col-md-6">
                <label
                  htmlFor="Description"
                  className="form-label confirm_value"
                >
                  Description
                </label>
                <input
                  ref={description}
                  className={`desc_input_text w-100 rounded-2 input-font-size-sm`}
                  placeholder="Enter the description"
                  value={initialfields.Description}
                  name="Description"
                  maxLength={500}
                  onChange={handleChange}
                />
                {error.Description && (
                  <small className={createInfo.errors}>
                    {error.Description}
                  </small>
                )}
              </div> */}
              <div className="col-md-6">
                <label
                  htmlFor="Description"
                  className="form-label confirm_value"
                >
                  Description
                </label>
                <input
                  ref={description}
                  className="desc_input_text w-100 rounded-2 input-font-size-sm"
                  placeholder="Enter the description"
                  value={initialfields.Description}
                  name="Description"
                  onChange={(e) => handleChange(e)} // Allow free typing
                />
                {/* <small className="text-muted">
                  {initialfields.Description.length} / 250 characters
                </small> */}
                {error.Description && (
                  <small className={createInfo.errors}>
                    {error.Description}
                  </small>
                )}
              </div>

              <div className="col-md-6">
                <label htmlFor="Url" className="form-label confirm_value">
                  URL
                </label>
                <input
                  ref={url}
                  className={`desc_input_text w-100 rounded-2 input-font-size-sm`}
                  placeholder="Enter the URL"
                  value={initialfields.Url}
                  name="Url"
                  onChange={handleChange}
                />
                {error.Url && (
                  <small className={createInfo.errors}>{error.Url}</small>
                )}
              </div>

              <div className="col-md-6">
                <label htmlFor="Category" className="form-label confirm_value">
                  Category
                </label>
                <Select
                  ref={category_tt}
                  styles={customStyles}
                  value={
                    initialfields.Category && initialfields.Category_ID
                      ? {
                          label: initialfields.Category,
                          value: initialfields.Category_ID,
                        }
                      : null
                  }
                  options={ccategory}
                  onChange={(e) => handleChangeSelectDropdown(e, "Category")}
                  isSearchable={true}
                  placeholder="Please select the category"
                />
                {error.Category && (
                  <small className={createInfo.errors}>{error.Category}</small>
                )}
              </div>

              <div className="col-md-6">
                <label
                  htmlFor="Start Date"
                  className="form-label confirm_value"
                >
                  Display From
                </label>
                <DatePickerSample
                  txtAdMDob={display_start_date}
                  handleChange={(date) => handleDateChange(date, "start_date")}
                  // fieldInput={initialfields.start_date}
                  fieldInput={
                    initialfields.start_date
                      ? moment(initialfields.start_date).format("DD/MM/YYYY")
                      : ""
                  }
                  minDate={futureDate}
                  // selectedDate={initialfields.start_date}
                />

                {error.start_date && (
                  <small className={createInfo.errors}>
                    {error.start_date}
                  </small>
                )}
              </div>

              <div className="col-md-6">
                <label htmlFor="End Date" className="form-label confirm_value">
                  Display To
                </label>
                <DatePickerSample
                  txtAdMDob={display_end_date}
                  handleChange={(date) => handleDateChange(date, "end_date")}
                  // fieldInput={initialfields.end_date}
                  fieldInput={
                    initialfields.end_date
                      ? moment(initialfields.end_date).format("DD/MM/YYYY")
                      : ""
                  }
                  minDate={futureDate}
                  // selectedDate={initialfields.end_date}
                />
                {error.end_date && (
                  <small className={createInfo.errors}>{error.end_date}</small>
                )}
              </div>
            </form>
          </fieldset>
        </div>
        {/*  */}
        <div className="col-md-12 col-12 ListBtn">
          <ul className="List-Button">
            <li className="ListBtnItems">
              <Link
                to={id ? "javascript:void(0)" : "/Announcement"}
                onClick={id ? navigateToPreviousScreen : () => {}}
                className="secondaryButton "
                variant="secondary"
              >
                {id ? "Back to View Announcement" : "Back to Announcements"}
              </Link>
            </li>
            <li className="ListBtnItems">
              <Link
                to="javascript:void(0)"
                onClick={handleSubmit}
                className="primaryButton text-center "
                variant="primary"
              >
                Save Announcement
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
