import React, { useState, useContext, useRef, useEffect } from "react";
import "./LoginForm.css";
import Logo from "../img/AIASA.png";
import IconLogo from "../img/IconLogo.png";
import { Link } from "react-router-dom";
import Video from "../img/india.mp4";
import Image from "../img/img1.png";
import VideoOne from "../img/india.webm";
import axios from "axios";
import { Dialog, DialogContent } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { AxiosInstance } from "../axios/index";
import { UserContext } from "../context/UserDetails";
import Swal from "sweetalert2";
import styled from "styled-components";
import Loader from "../shared/Component/Loader";

const StyledVideo = styled.video`
  &::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
  }
  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
  &::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
  }
`;

function SampleForm() {
  const txtLoginEmail = useRef(null);
  const txtLoginPass = useRef(null);

  const { login } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  let from = location.state?.form?.pathname || "/";

  const [fields, setFields] = useState({
    email: "",
    password: "",
    is_rem: false,
  });
  const [isIOSDevice, setIsIOSDevice] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  useEffect(() => {
    if (
      window.location.search.indexOf("?m=t") == -1 &&
      window.getComputedStyle
    ) {
      let mobile = false;

      if (
        window
          .getComputedStyle(document.getElementsByTagName("html")[0], null)
          .getPropertyValue("border-top-style") == "dashed"
      ) {
        mobile = true;
      }
      setIsIOSDevice(mobile);
      // console.log("laksh-ios", mobile, isIOSDevice);
    }
  }, []);

  useEffect(() => {
    const videoElement = document.getElementById(
      "login-background-image-poster"
    );

    const isIPhone = () => {
      return /iPhone/.test(navigator.userAgent) && !window.MSStream;
    };

    if (isIPhone()) {
      videoElement.setAttribute("disablePictureInPicture", true);
      videoElement.setAttribute("disableRemotePlayback", true);
      videoElement.setAttribute("playsInline", true);
    }

    // In case the mini player still appears, we pause the video
    videoElement.addEventListener("enterpictureinpicture", (event) => {
      if (isIPhone()) {
        event.preventDefault();
        videoElement.pause();
      }
    });
  }, []);

  useEffect(() => {
    const getRem = sessionStorage.getItem("Is_remember");
    if (getRem) {
      const vvv = JSON.parse(getRem);
      if (vvv.is_rem) {
        setFields({
          ["email"]: vvv.email,
          ["password"]: vvv.password,
          ["is_rem"]: vvv.is_rem,
        });
      }
    }
  }, []);

  const errors = {};

  function isValidEmail(email) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  }
  const [fieldsError, setFieldsError] = useState({});

  const [eyes, setEyes] = useState(false);

  const validateFieldss = (data) => {
    if (!data.email) {
      errors.email = "Please Enter Email address.";
    }
    if (!isValidEmail(data.email)) {
      errors.email = "Please Enter a Valid Email";
    }
    if (!data.password) {
      errors.password = "Please Enter Password.";
    }
    if (data.password && data.password.length < 6) {
      errors.password = "Password must be 6 character.";
    }
    validateFieldssFocus(data);
    return errors;
  };

  const validateFieldssFocus = (data) => {
    if (!data.email) {
      txtLoginEmail.current.focus();
    }
    if (!isValidEmail(data.email)) {
      txtLoginEmail.current.focus();
    }
    if (!data.password) {
      txtLoginPass.current.focus();
    }
    if (data.password && data.password.length < 6) {
      txtLoginPass.current.focus();
    }

    return errors;
  };

  const handleChange = (event) => {
    // console.log(event,"event");
    var eventValue = event.target.value;
    var eventName = event.target.name;
    var eventChecked = event.target.checked;
    if (eventName === "is_rem") {
      // console.log(eventChecked,"eventChecked");
      if (eventChecked) {
        setFields({
          ...fields,
          ["is_rem"]: eventChecked,
        });
      } else {
        setFields({
          ...fields,
          ["is_rem"]: false,
        });
      }
    } else {
      setFields({
        ...fields,
        [event.target.name]: eventValue,
      });
      setFieldsError({
        ...fieldsError,
        [event.target.name]: "",
      });
    }
  };
  const handleEyes = () => {
    setEyes(!eyes);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateFieldss(fields);

    if (Object.keys(errors).length === 0) {
      setIsPaymentLoading(true);
      try {
        const response = await AxiosInstance.post("api/user/login", {
          email: fields.email,
          password: fields.password,
        });

        if (response.data.status == true) {
          setIsPaymentLoading(false);
          sessionStorage.setItem(
            "active_user_count",
            JSON.stringify({
              logout_flag: 0,
              user_id_active_user: response.data.user_id,
            })
          );
          const userJSON = JSON.parse(localStorage.getItem("userDetails"));

          if (userJSON && userJSON.user_id !== response.data.user_id) {
            Swal.fire({
              title: "Warning",
              text: "An account is already logged in. Please log out and try again.",
              icon: "success",
              confirmButtonText: "Ok",
              focusConfirm: true,
              didOpen: () => {
                const confirmButton = Swal.getConfirmButton();
                if (confirmButton) confirmButton.focus();
              },
            }).then((result) => {
              if (result.isConfirmed) {
                // localStorage.removeItem("userDetails");
                // sessionStorage.removeItem("userDetails");
                login(userJSON, () => {
                  navigate(from, { replace: true });
                });
              }
            });
          } else if (userJSON && userJSON.user_id === response.data.user_id) {
            login(response.data, () => {
              navigate(from, { replace: true });
            });
          } else {
            sessionStorage.setItem(
              "userDetails",
              JSON.stringify(response.data)
            );
            sessionStorage.setItem(
              "user_id",
              JSON.stringify(response.data.user_id)
            );
            sessionStorage.setItem(
              "role_id",
              JSON.stringify(response.data.role_id)
            );
            //local storage
            localStorage.setItem(
              "active_user_count",
              JSON.stringify({
                logout_flag: 0,
                user_id_active_user: response.data.user_id,
              })
            );
            localStorage.setItem("userDetails", JSON.stringify(response.data));
            localStorage.setItem(
              "user_id",
              JSON.stringify(response.data.user_id)
            );
            localStorage.setItem(
              "role_id",
              JSON.stringify(response.data.role_id)
            );

            // warning
            Swal.fire({
              title: "Success",
              text: response.data.message,
              icon: "success",
              confirmButtonText: "Ok",
              focusConfirm: true,
              didOpen: () => {
                const confirmButton = Swal.getConfirmButton();
                if (confirmButton) confirmButton.focus();
              },
            }).then((result) => {
              if (result.isConfirmed) {
                login(response.data, () => {
                  navigate(from, { replace: true });
                });
                sessionStorage.setItem(
                  "Is_remember",
                  JSON.stringify({
                    is_rem: fields.is_rem,
                    email: fields.email,
                    password: fields.password,
                  })
                );
              }
            });
          }
        } else if (response.data.status == false) {
          setIsPaymentLoading(false);
          if (response.data.data === 0) {
            Swal.fire({
              title: "Status",
              text: response.data.message,
              confirmButtonText: "Register Now",
              focusConfirm: true,
              didOpen: () => {
                const confirmButton = Swal.getConfirmButton();
                if (confirmButton) confirmButton.focus();
              },
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/register");
              }
            });
          } else {
            Swal.fire({
              title: "Status",
              text: response.data.message,
              confirmButtonText: "Ok",
              focusConfirm: true,
              didOpen: () => {
                const confirmButton = Swal.getConfirmButton();
                if (confirmButton) confirmButton.focus();
              },
            });
          }
        }
      } catch (error) {
        setIsPaymentLoading(false);
        console.log(error);
      }
    } else {
      setFieldsError(errors);
    }
  };
  const progress = 70;
  return (
    <div className=" LoginForm videoWrapper" id="wrapper_Login">
      <video
        id="login-background-image-poster"
        autoPlay
        loop
        muted
        data-wf-ignore="true"
        data-object-fit="cover"
        disableRemotePlayback
        disablePictureInPicture
        playsInline
        controls={false}
      >
        <source src={Video} type="video/mp4" />
      </video>
      <div className="row">
        <div className="col-md-12 box">
          <form className="" onSubmit={handleSubmit}>
            <img src={IconLogo} id="img" alt="Logo" />
            <h1 className="">Log in to Anna IAS Academy</h1>
            <p>Your Journey to Success Begin Here</p>
            <input
              className="LoginInputs"
              ref={txtLoginEmail}
              type="email"
              placeholder="Email"
              onChange={handleChange}
              value={fields.email}
              name="email"
            />
            {fieldsError.email && (
              <small className="error">{fieldsError.email}</small>
            )}
            <input
              className="LoginInputs"
              type={eyes === true ? "text" : "password"}
              ref={txtLoginPass}
              placeholder="Password"
              onChange={handleChange}
              value={fields.password}
              name="password"
            />
            {/* AIASA-0000031 issues updated by lalitha  */}
            {fields.password.length > 0 && (
              <span className="material-icons eyes_login" onClick={handleEyes}>
                {eyes === true ? "visibility" : "visibility_off"}
              </span>
            )}
            {fieldsError.password && (
              <small className="error">{fieldsError.password}</small>
            )}
            <div className="d-flex">
              <input
                type="checkbox"
                name="is_rem"
                onChange={handleChange}
                checked={fields.is_rem}
                value={fields.is_rem}
              />
              <span className="checkbox-inline">Remember me</span>
              <Link to="/forgetPassword" className="forgot-link">
                Forgot Password?
              </Link>
            </div>
            <button type="submit" className="LoginBtn" name="Login">
              Login
            </button>
          </form>
          <div className="row SignupText">
            <div className="col-md-12">
              <div className="reg">
                <span>Not yet registered ?</span>
                <Link to="/register" className="rega">
                  Register Now
                </Link>
              </div>
            </div>
          </div>
          <div className="copyRights">
            © {new Date().getFullYear()} All Rights Reserved
          </div>
        </div>
      </div>
      {isPaymentLoading && (
        <Dialog open={isPaymentLoading}>
          <DialogContent>
            <div style={{ margin: "15px" }}>
              <p>Please wait...</p>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default SampleForm;
