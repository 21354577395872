import React from "react";
import styles from "./CourseCategory.module.css";

const UpcomingBatchTemplate = ({ batch }) => {
  const {
    name = "",
    category_class = "",
    subject = [],
    medium = "",
    type = "",
    batch_type = "",
    class_mode = "",
    location_name = "",
    started_dt = "",
    start_time = "",
    end_time = "",
    duration = "",
    discount = 0,
    old_stud_disc_sum = "",
    fees = "",
    exam_fees = "",
  } = batch;
  // console.log("batch", batch);

  // Function to format date
  const formatDate = (dateString) => {
    // Create a Date object from the date string
    const date = new Date(dateString);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits for day
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    // Return the formatted date in dd/mm/yyyy
    return `${day}/${month}/${year}`;
  };

  // Function to format time
  const formatTime = (timeString) => {
    const [hour, minute] = timeString.split(":");
    const hours = parseInt(hour, 10);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHour = hours % 12 || 12; // Convert 0 or 12 to 12
    const formattedMinute = minute.padStart(2, "0"); // Ensure two digits for minutes
    return `${formattedHour}:${formattedMinute} ${period}`;
  };

  // Utility function to convert class mode
  const convertClassMode = (classMode, type) => {
    // Convert specific values to lowercase or format as needed
    if (type === 4 && classMode.toLowerCase() === "classroom") {
      return "Offline";
    }
    if (classMode.toLowerCase() === "classroom") {
      return "Classroom";
    }
    // Additional conversion logic can be added here if needed
    return classMode;
  };
  const convertedClassMode = convertClassMode(class_mode, type);

  // Indian currency formatting function
  const formatIndianCurrency = (value) => {
    let amount = typeof value === "number" ? value : parseFloat(value);

    if (isNaN(amount)) {
      return "";
    }

    // Convert number to string for manipulation
    let [integerPart] = amount.toFixed(2).split(".");

    // Add commas for Indian numbering system
    let lastThreeDigits = integerPart.slice(-3);
    let otherDigits = integerPart.slice(0, -3);

    if (otherDigits !== "") {
      lastThreeDigits = "," + lastThreeDigits;
    }

    otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");

    // return otherDigits + lastThreeDigits + (decimalPart ? '.' + decimalPart : '');
    return otherDigits + lastThreeDigits;
  };
  const feeToDisplay = type === 4 ? exam_fees : fees;
  const formattedFee = formatIndianCurrency(feeToDisplay);
  const specialOffer = discount > 0 ? formatIndianCurrency(discount) : "";
  const oldStudentDiscount = !!old_stud_disc_sum
    ? formatIndianCurrency(old_stud_disc_sum)
    : "";

  // Create rows and hr dynamically
  const rows = [];
  let details = [
    {
      label: "Batch Name",
      value: name,
      icon: "format_shapes",
      condition: true,
    },
    {
      label: "Course",
      value: category_class,
      icon: "assignment",
      condition: true,
    },
    {
      label: "Subject",
      value:
        subject.length > 0
          ? subject.map((sub) => sub.subject).join(", ")
          : "N/A",
      icon: "assignment",
      condition: subject.length > 0,
    },
    { label: "Medium", value: medium, icon: "g_translate", condition: true },
    {
      label: "Schedule Preference",
      value: batch_type,
      icon: "laptop_chromebook",
      condition: batch_type !== null,
    },
    {
      label: type === 4 ? "Exam Mode" : type === 1 ? "Test Mode" : "Class Mode",
      value: convertedClassMode,
      icon: "laptop_chromebook",
      condition: true,
    },
    {
      label: "Location",
      value: location_name,
      icon: "location_on",
      condition: class_mode === "ClassRoom" && type !== 4 ? true : false,
    },
    {
      label: "Starts On",
      value: formatDate(started_dt),
      icon: "today",
      condition: true,
    },
    {
      label: "Time",
      value: `${start_time ? formatTime(start_time) : ""} to ${
        end_time ? formatTime(end_time) : ""
      }`,
      icon: "timer",
      condition: batch_type !== null,
    },
    {
      label: "Duration (in Months)",
      value: duration ? `${duration}` : "",
      icon: "timer",
      condition: !!duration,
    },
    {
      label: "Actual Fee",
      value: formattedFee,
      icon: "currency_rupee",
      condition: feeToDisplay > 0,
    },
    {
      label: "Special Offer",
      value: specialOffer,
      icon: "currency_rupee",
      condition: discount > 0,
    },
    {
      label: "Old Student Discount",
      value: oldStudentDiscount,
      icon: "currency_rupee",
      condition: !!old_stud_disc_sum,
    },
  ];
  for (let i = 0; i < details.length; i += 4) {
    // Slice the details array to get a chunk of 4 items
    details = details.filter((item) => item.condition !== false);
    const rowDetails = details.slice(i, i + 4);
    // Create the columns for the current row
    const columns = rowDetails.map((item, index) => (
      <div key={i + index} className="col-lg-3 col-12">
        <div className={`${styles.IconImg} mb-2 m-lg-0`}>
          <span className={`${styles.micons} material-icons`}>{item.icon}</span>
          <div className="d-flex flex-column">
            <p className={`${styles.confirm_label} m-0 p-0`}>{item.label}</p>
            <p className={`${styles.confirm_value} m-0 p-0`}>{item.value}</p>
          </div>
        </div>
      </div>
    ));
    rows.push(
      <React.Fragment key={i}>
        <div className="row">{columns}</div>
        {i + 4 < details.length && (
          <hr className={`${styles.upcoming_batch_hr} w-100`} />
        )}
      </React.Fragment>
    );
  }
  return rows;
};
export default UpcomingBatchTemplate;
