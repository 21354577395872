import React from "react";
import "./Question.css";
import parse from "html-react-parser";
import LatexProcessorComponent from "../../../../utils/LatexProcessorComponent";

export default function QparaType(props) {
  const { index } = props;

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  const splitLines = props.list.split("\n");

  return (
    <div className="Question_temp question_align mt-1 mb-1">
      {props.list.tm_question !== null && props.list.tm_question !== "" && (
        <div className="row tamilQues mt-1">
          <div className="col-md-12 p-0">
            <span className={props.classET}>
              {/* parse()  */}
              {splitLines.length > 0 ? (
                <LatexProcessorComponent originalString={splitLines[0]} />
              ) : (
                ""
              )}
            </span>
          </div>
          {/* {console.log(splitLines, "splitLines")} */}
          {splitLines.map((line, index) => {
            if (line.startsWith("p1-")) {
              return (
                <div className="col-md-12 mt-1" key={index}>
                  <p className={props.classET}>
                    <LatexProcessorComponent
                      originalString={line.substring(4)}
                    />
                    {/* {parse(line.substring(4))} */}
                  </p>
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
}
