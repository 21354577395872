import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import ViewInfo from "./Announcement.module.css";
import { AxiosInstance } from "../../../../axios";
import Loader from "../../Loader";
export default function ViewAnnouncementInfo() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { FCAT } = location.state || {};
  const { FSTATUS } = location.state || {};
  const { FSEARCH } = location.state || {};

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }

  const [formFields, setFormFields] = useState({
    Category: "",
    Description: "",
    StartDate: "",
    Url: "",
    EndDate: "",
    status: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    AxiosInstance.post("api/all/announcement/view", {
      id: parseInt(id),
      // optional_type: parseInt(opType),
    })
      .then((res) => {
        if (res.data.status === true && res.data.data?.length > 0) {
          const courseData = res.data.data[0];
          // console.log(courseData, "data");

          setFormFields({
            Category: courseData.category_name,
            Description: courseData.description,
            Url: courseData.url,
            StartDate: moment(courseData.start_date).format("DD/MM/YYYY"),
            EndDate: moment(courseData.end_date).format("DD/MM/YYYY"),
            status: courseData.status,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return error;
      });
  }, [id]);

  const navigateToPreviousScreen = () => {
    navigate(`/Announcement`, {
      state: {
        filCateCode: FCAT,
        filCateStatus: FSTATUS,
        filCateSearch: FSEARCH,
      },
    });
  };

  const handleNavigate = (id) => {
    navigate(`/CreateAnnouncement/${id}`);
  };

  const handleBackFilter = () => {
    navigate(`/Announcement`);
  };

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <div className={`${ViewInfo.CourseInfoSec} BathHeader global_container`}>
      <div className="row">
        <div className="col-md-6 col-12">
          <h2 className="TitleHeader">View Announcement</h2>
        </div>
        <div className="col-md-6 col-12 ListBtn">
          <ul class="List-Button">
            <li class="ListBtnItems">
              <Link
                // onClick={handleBackFilter}
                onClick={navigateToPreviousScreen}
                to="javascript:void(0)"
                className="secondaryButton "
                variant="secondary"
              >
                Back to Announcements
              </Link>
            </li>

            <li class="ListBtnItems">
              <Link
                to="javascript:void(0)"
                // onClick={() => handleNavigate(id)}
                onClick={() => handleNavigate(id)}
                className="primaryButton"
                variant="primary"
              >
                {" "}
                <span className="material-icons me-1 fs-5 align-middle text-white">
                  border_color
                </span>{" "}
                Edit Announcement
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="mb-4">
        <fieldset className="mt-3">
          <legend className="w-auto">Announcement Details</legend>
          <div className="row mb-3">
            <div className="my-1 col-lg-3 col-md-3 col-12">
              <div className="d-flex flex-column">
                <p className="confirm_label m-0 p-0"> Description</p>
                <p className="confirm_value my-1 m-0 p-0">
                  {" "}
                  {formFields.Description || "_"}
                </p>
              </div>
            </div>
            <div className="my-1 col-lg-3 col-md-3 col-12">
              <div className="d-flex flex-column">
                <p className="confirm_label m-0 p-0"> URL</p>
                <p className="confirm_value my-1 m-0 p-0">
                  {" "}
                  {formFields.Url || "_"}
                </p>
              </div>
            </div>
            <div className="my-1 col-lg-3 col-md-3 col-12">
              <div className="d-flex flex-column">
                <p className="confirm_label m-0 p-0"> Category</p>
                <p className="confirm_value my-1 m-0 p-0">
                  {" "}
                  {formFields.Category || "_"}
                </p>
              </div>
            </div>
            <div className="my-1 col-lg-3 col-md-3 col-12">
              <div className="d-flex flex-column">
                <p className="confirm_label m-0 p-0">Display From</p>
                <p className="confirm_value my-1 m-0 p-0">
                  {formFields.StartDate ? formFields.StartDate : "_"}
                </p>
              </div>
            </div>

            <div className="my-1 col-lg-3 col-md-3 col-12">
              <div className="d-flex flex-column">
                <p className="confirm_label m-0 p-0">Display To</p>
                <p className="confirm_value my-1 m-0 p-0">
                  {formFields.EndDate ? formFields.EndDate : "_"}
                </p>
              </div>
            </div>

            <div className="my-1 col-lg-3 col-md-3 col-12">
              <div className="d-flex flex-column">
                <p className="confirm_label m-0 p-0">Status</p>
                <p className="confirm_value my-1 m-0 p-0">
                  {formFields.status || "_"}
                </p>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
}
