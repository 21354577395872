import React, { useState, useEffect } from "react";
import styles from "./CourseCategory.module.css";
import { ImageUrlPrefix } from "../../labels/index";
import UpcomingBatchTemplate from "./UpcomingBatchTemplate";
import separator_pattern_left from "../../img/dashboard/Bottom Separator Pattern - Left (1).png";
import separator_pattern_right from "../../img/dashboard/Bottom Separator Pattern - Right (1).png";
import red_undeline from "../../img/dashboard/Red Underline (1).png";
import { AxiosInstance } from "../../axios";
import { useNavigate, useLocation } from "react-router-dom";

const CourseCategory = () => {
  const [status, setStatus] = useState([1, 2]); // Default status value
  const [activeTabName, setActiveTabName] = useState("TNPSC");
  const [admissionType, setAdmissionType] = useState("0"); // Default to Course
  const [branch, setBranch] = useState("2"); // Default to Kanchipuram
  const [activeTab, setActiveTab] = useState(1);
  const [upcomingBatch, setUpcomingBatch] = useState([]);
  const [batchMode, setBatchMode] = useState("2"); // Default to offline

  let user_type = null;
  let userid = null;
  const userJSON = sessionStorage.getItem("userDetails");

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
      user_type = user.user_type;
    }
  }

  // Mapping for admission types
  const admissionTypeMap = {
    0: "Courses",
    1: "Test Batches",
    2: "Counselling",
    3: "Special Classes",
    4: "Model Exams",
    5: "Mock Interview",
  };

  const getAdmissionTypeName = (type) => admissionTypeMap[type] || "Unknown";
  const getLegendName = (type) => admissionTypeMap[type] || "Admission Type";

  const fetchBatchData = async () => {
    try {
      const loc = branch === "20" ? "2" : branch;

      const req = {
        category: [activeTabName],
        status: status,
        batch_type: "",
        medium: "",
        class_mode: "",
        start_date: "",
        end_date: "",
        c_start_date: "",
        c_end_date: "",
        start_time: "",
        end_time: "",
        search: "",
        discount: 0,
        old_stud_disc_sum: "",
        fees: "",
        exam_fees: "",
        type: [admissionType],
        location: [loc],
        is_allowed_admission: 1,
      };

      const res = await AxiosInstance.post("api/batch/list", req);
      const data = res.data.data || [];

      let filteredBatches = [];

      if (admissionType === "4") {
        // Handle Model Exam type
        filteredBatches = data.filter(
          (item) =>
            item.location === loc &&
            (batchMode === "20"
              ? item.class_mode === "Online"
              : item.class_mode !== "Online")
        );
      } else {
        // Handle other admission types
        filteredBatches = data.filter((item) => item.location === loc);
        if (branch === "20") {
          filteredBatches = filteredBatches.filter(
            (item) => item.class_mode === "Online"
          );
        } else if (branch === "2") {
          filteredBatches = filteredBatches.filter(
            (item) => item.class_mode !== "Online"
          );
        }
      }

      setUpcomingBatch(filteredBatches);
    } catch (error) {
      console.error("Error fetching batch data:", error);
      setUpcomingBatch([]); // Handle error by clearing data
    }
  };

  useEffect(() => {
    fetchBatchData();
  }, [admissionType, branch, activeTab, batchMode]);

  const handleAdmissionTypeChange = (event) => {
    const newAdmissionType = event.target.value;
    setAdmissionType(newAdmissionType);

    // Set default branch and batchMode based on admission type
    if (newAdmissionType === "4") {
      setBranch("20"); // Default to offline batches
      setBatchMode("2"); // Default to offline
    }
  };
  // Handle learning mode change
  const handleBatchModeChange = (e) => {
    const selectedMode = e.target.value;
    setBatchMode(selectedMode);
    // If Online is selected, disable location selection
    if (selectedMode === "20") {
      setBranch("20"); // Reset location
    } else {
      setBranch("2"); // Default to Kanchipuram for Offline
    }
  };
  const handleBranchChange = (event) => {
    const newValue = event.target.value;
    setBranch(newValue);

    // Set batch mode based on branch selection
    if (admissionType === "4") {
      setBatchMode(newValue); // Set to the selected value
    }
  };
  const tabs = [
    { label: "TNPSC" },
    { label: "UPSC" },
    { label: "TRB" },
    { label: "TET" },
    { label: "TNUSRB" },
    { label: "RRB" },
    { label: "SSC" },
    { label: "BANKING" },
  ];
  const handleTabClick = (tabIndex, name) => {
    setActiveTab(tabIndex);
    setActiveTabName(name);
  };
  const navigate = useNavigate(); // Create a navigate function
  const location = useLocation();

  const handleJoinNow = (batch) => {
    // Store batch data in sessionStorage
    sessionStorage.setItem("selectedBatch", JSON.stringify(batch));

    const userJSON = sessionStorage.getItem("userDetails");
    let userid = null;

    if (userJSON) {
      const user = JSON.parse(userJSON);
      if (user && user.user_id) {
        userid = user.user_id;
      }
    }

    if (!userid) {
      // Store the desired path in sessionStorage
      sessionStorage.setItem(
        "redirectPath",
        JSON.stringify({
          type: batch.type,
          id: batch.id, // You might want to store more information if needed
        })
      );

      // Navigate to login screen
      navigate("/login");
    } else {
      // If user is logged in, navigate based on admission type
      navigateToAdmission(batch.type);
    }

    // console.log("Batch data stored:", batch);
  };
  const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
  const isAdmin = userDetails?.role === "admin"; // Adjust key if needed

  const navigateToAdmission = (admissionType) => {
    if (admissionType === 0) {
      navigate("/Admission/Course", {});
    } else if (admissionType === 1) {
      navigate("/Admission/TestBatch");
    } else if (admissionType === 3) {
      navigate("/Admission/SpecialClasses");
    } else if (admissionType === 4) {
      navigate("/Admission/Modelexam");
    } else {
      console.log("Unknown admission type:", admissionType);
    }
  };

  return (
    <>
      <div
        className={`${styles.courseCategory} ${styles.global_container} ${styles.separator_pattern_left} mt-lg-5 mt-3`}
      >
        <img
          src={separator_pattern_left}
          alt="separator_pattern_left"
          className={styles.separator_pattern_left}
          style={{ width: "50%", height: "auto", objectFit: "cover" }}
        />
      </div>
      <div className="m-lg-4 m-3">
        <h3 className={`${styles.courseTitle} text-center position-relative`}>
          Discover the co
          <span>
            <img
              className={`${styles.course_underline_img}`}
              src={red_undeline}
              alt="word_underline"
            />
          </span>
          urse you deserve
        </h3>
        <p className={`${styles.subtitle}`}>
          These courses are designed to help you gain insights and knowledge
          from the vast experience of former IAS and IPS officers and retired
          bureaucrats.
        </p>
        <div className={`${styles.coursebatch_container} p-3`}>
          <div className="course-content-nav mb-3">
            <ul className="nav" id="myTab" role="tablist">
              {tabs.map((tab, index) => (
                <li
                  className="nav-item"
                  key={index}
                  role={tab.label.toLowerCase()}
                >
                  <button
                    className={
                      activeTab === index + 1 ? "nav-link active" : "nav-link"
                    }
                    id={`${tab.label.toLowerCase()}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target="#about-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="about-tab-pane"
                    aria-selected={activeTab === index + 1}
                    onClick={() => handleTabClick(index + 1, tab.label)}
                  >
                    {tab.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="d-flex flex-lg-row justify-content-lg-end flex-column">
            <div className={`${styles.categoryknow} d-flex align-items-center`}>
              <a href="#" className={styles.categoryknow}>
                Know More About {activeTabName}
              </a>
            </div>

            {/* Choose Your Course */}
            <div className="d-flex flex-column mx-3">
              <label htmlFor="admissionType" className={styles.confirm_value1}>
                Choose Your Course
              </label>
              <select
                id="admissionType"
                className={`${styles.custom_width} ${styles.dropdownarrow_img} ${styles.select} ${styles.cursorPtn} form-select my-2`}
                aria-label="Select admission type"
                onChange={handleAdmissionTypeChange}
                value={admissionType}
              >
                <option value="0">Courses</option>
                <option value="1">Test Batches</option>
                <option value="3">Special Classes</option>
                <option value="4">Model Exams</option>
                <option value="5">Mock Interview</option>
                <option value="2">Counselling</option>
              </select>
            </div>

            {/* Choose Your Learning Mode */}
            <div className="d-flex flex-column mx-3">
              <label htmlFor="batchMode" className={styles.confirm_value1}>
                {admissionType === "4"
                  ? "Choose Your Exam Mode"
                  : "Choose Your Learning Mode"}
              </label>
              <select
                id="batchMode"
                className={`${styles.dropdownarrow_img} ${styles.custom_width} ${styles.select} ${styles.cursorPtn} form-select my-2`}
                aria-label="Select batch mode"
                onChange={handleBatchModeChange} // Updated function
                value={batchMode}
              >
                <option value="2">Offline</option>
                <option value="20">Online</option>
              </select>
            </div>

            {/* Choose Your Location (Enabled/Disabled based on Learning Mode & Admission Type) */}
            <div className="d-flex flex-column mx-3">
              <label
                htmlFor="branch"
                className={`${styles.confirm_value1} `}
                // style={{ color: batchMode === "20" ? "#ccc" : "inherit" }} // Grayed out label when disabled
                style={{
                  color:
                    batchMode === "20" || admissionType === "4"
                      ? "#ccc"
                      : "inherit",
                  opacity:
                    batchMode === "20" || admissionType === "4" ? 0.8 : 1, // Reduce opacity when disabled
                  // cursor: batchMode === "20" ? "not-allowed" : "default", // Disable cursor
                }}
              >
                Choose Your Location
              </label>
              <select
                id="branch"
                className={`${styles.dropdownarrow_img} ${styles.custom_width} ${styles.select} ${styles.cursorPtn} form-select my-2`}
                aria-label="Select branch"
                onChange={handleBranchChange}
                value={branch}
                style={{
                  color:
                    batchMode === "20" || admissionType === "4"
                      ? "#ccc"
                      : "inherit",
                  opacity:
                    batchMode === "20" || admissionType === "4" ? 0.8 : 1, // Reduce opacity when disabled
                  cursor:
                    batchMode === "20" || admissionType === "4"
                      ? "not-allowed"
                      : "default", // Disable cursor
                }}
                disabled={batchMode === "20" || admissionType === "4"} // Disable select when Online is selected
              >
                <option value="" disabled>
                  Choose Your Location
                </option>

                {admissionType === "4" || batchMode !== "2" ? (
                  <option value="20" disabled className={styles.disabledLabel}>
                    Choose Your Location
                  </option>
                ) : (
                  <>
                    <option value="1">Tambaram</option>
                    <option value="2">Kanchipuram</option>
                    <option value="3">Vellore</option>
                    <option value="4">Chennai</option>
                  </>
                )}
              </select>
            </div>
          </div>

          <div
            className={`${styles.contentHeight} tab-content`}
            id="TNPSCgroup1"
          >
            <div
              className="tab-pane fade show active"
              id="about-tab-pane"
              role="tabpanel"
              aria-labelledby="about-tab"
              tabIndex="0"
            >
              <fieldset className="rounded-3">
                <legend>
                  {upcomingBatch.length > 0
                    ? getLegendName(upcomingBatch[0].type)
                    : getAdmissionTypeName(admissionType)}
                </legend>
                {upcomingBatch.length > 0 ? (
                  upcomingBatch.map((batch) => (
                    <div
                      className="card p-3 mb-3"
                      style={{ border: "solid 2px #c1393d" }}
                      key={batch.id}
                    >
                      <div className="row">
                        <div className="col-lg-10 col-12">
                          <UpcomingBatchTemplate batch={batch} />
                        </div>
                        <div className="col-lg-2 col-12">
                          <img
                            src={`${ImageUrlPrefix}/${batch.img_url}`}
                            className={`${styles.course_img} mb-2`}
                            alt="course_img"
                          />
                          {(user_type === 2 || user_type === null) && (
                            <button
                              type="button"
                              className={`${styles.SaveButton} w-100`}
                              onClick={() => handleJoinNow(batch)}
                            >
                              <span className={styles.buttonText}>
                                Join Now
                              </span>
                              <span className={styles.arrowIcon}>&rarr;</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>
                    We currently have no batches scheduled for the selected
                    Admission Type and Location. Please check back soon for
                    updates on upcoming batches.
                  </p>
                )}
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.separator_pattern_right} d-flex justify-content-end my-lg-5 my-4`}
      >
        <img
          src={separator_pattern_right}
          alt="separator_pattern_right"
          className={`${styles.separator_pattern_right}`}
          style={{ width: "50%", height: "auto", objectFit: "cover" }}
        />
      </div>
    </>
  );
};

export default CourseCategory;
