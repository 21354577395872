import React, { useState, useEffect, useRef } from "react";
// import MyCalendar from "../CourseBatch/CalendarView";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import ICON_WARNING from "@mui/icons-material/Warning";
import styled from "styled-components";
import Swal from "sweetalert2";
import IconDownload from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Select, { components } from "react-select";
import ViewCalendar from "../Purchase/Calendar";
import { AxiosInstance } from "../../../../axios";
import { ImageUrlPrefix } from "../../../../labels";
import { ALLOWED_MINUTES, MEDIA_SIZE_LG } from "../../../../utils/helper";
import ModelComForP from "../../../../pages/ModelComForP";
import StartTest from "./components/StartTest";
import ViewTestAlert from "./components/ViewTestAlert";
import styles from "./ViewTestDetail.module.css";
import toast from "react-hot-toast";
import RtypeOne from "./RtypeOne";
import RtypeTwo from "./RtypeTwo";
import RtypeThree from "./RtypeThree";
import RtypeFour from "./RtypeFour";
import RtypeFive from "./RtypeFive";
import RtypeTen from "./RtypeTen";
import RtypeNine from "./RtypeNine";
import RtypeEight from "./RtypeEight";
import RtypeSeven from "./RtypeSeven";
import RtypeSix from "./RtypeSix";
import Rtypepara from "./Rtypepara";
import RtypeParaOption from "./RtypeParaOption";
import ExplanationQuery from "../Faculty/StudentsQuery/ExplanationQuery";
import QuerySection from "../Faculty/StudentsQuery/QuerySection";
import { customStyles } from "../../../../pages/customStyles";

import { Dialog, DialogContentText, DialogTitle } from "@mui/material";
import axios from "axios";
import { Modal } from "react-bootstrap";
import ModalDialogContent from "../../../../ui/ModalDialogContent";
import ModalDialogActions from "../../../../ui/ModalDialogActions";
import NavigationButton from "../../../../ui/NavigationButton";
import { TailSpin } from "react-loader-spinner";
import Loader from "../../Loader";
import { Download } from "@mui/icons-material";

// warning-section
const WarningSectionContainer = styled.section`
  background-color: var(--color-brand-200);
  color: var(--color-brand-800);
  padding: 15px;
  margin-top: 10px;
  border: 1px solid var(--color-brand-800);
  border-radius: var(--border-radius-md);
  margin-bottom: 20px;
  @media (min-width: ${MEDIA_SIZE_LG}) {
    padding: 30px;
    margin-bottom: 30px;
  }
`;

const WarningSectionPara = styled.p`
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
`;

export default function ViewTestDetail() {
  const { event_id, batch_id, status, event_type, type_batch, labelStatus } =
    useParams();

  const currentDate = moment();
  const navigate = useNavigate();
  const txtUplANS = useRef(null);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
    sessionStorage.setItem("buttonClickCount", 0);
    localStorage.setItem("buttonClickCount", 0);
  }, [event_id]);

  const [updateStatus, setUpdateStatus] = useState(status);
  const [subTopic, setSubTopic] = useState([]);

  const newCount = 0;

  sessionStorage.setItem("buttonClickCount", newCount);
  localStorage.setItem("buttonClickCount", newCount);
  const [swalMsg, setSwalMsg] = useState({
    show: false,
    status: "",
    message: "",
    title: "",
    BottomOkBtn: "",
    BottomCancelBtn: "",
    BottomOkClose: "",
  });

  const [flagset, setFlagSet] = useState(false);

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;
  // console.log(userJSON, "useriduseriduseriduserid");

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot available in session storage");
  }

  useEffect(() => {
    getIFD(event_id, batch_id, userid);
  }, []);
  useEffect(() => {
    getIFD(event_id, batch_id, userid);
  }, [flagset]);

  const getIFD = (event_ids, batch_ids, userids) => {
    setIsLoading(true);
    AxiosInstance.post("api/test/view/byUser", {
      event_id: parseInt(event_ids),
      batch_id: parseInt(batch_ids),
      user_id: userids,
    })
      .then((res) => {
        if (res.data.status === true) {
          setIsLoading(false);
          let elss = res.data.data;
          let comment = res.data.data.comments;
          // console.log("commant studemt sk", comment[0].staff_name);
          setEvaluatorComment(comment);
          setFdata((prevFdata) => ({
            ...prevFdata,
            title: elss.event_name,
            sDate:
              elss.start_date !== null
                ? // ? moment(elss.start_date).format("DD/MM/YYYY")
                moment(elss.start_date).format("YYYY/MM/DD")
                : "",
            startTime: elss.start_time,
            endTime: elss.end_time,
            methodOfTest: elss.method_of_test,
            descriptionForStudent: elss.description,
            syllabusFile: elss.syllabus_filepath,
            testType: elss.test_type_name,
            subject: elss.subject,
            ans_sheet_download_req: elss.ans_sheet_download_req,
            obtained_marks: elss.marks_obtained,
            location: elss.location,
            location_name: elss.location_name,
            course: elss.category_class,
            medium: elss.medium,
            batchName: elss.batch_name,
            classMode: elss.class_mode,
            category: elss.category,
            subject: elss.subject,
            rank: elss.rank ? elss.rank : "",
            total_answer: elss.no_of_answered,
            teams_url: elss.teams_url ? elss.teams_url : null,
            marks_obtained: elss.marks_obtained,
            batch_id: elss.batch_id,
            event_id: elss.event_pk,
            test_num: elss.test_no,
            test_topic: elss.test_topic,
            tol_mark: elss.tot_marks,
            no_ques: elss.no_of_questions,
            descriptive_answer_original_sheet: elss.answer_sheet_path,
            descriptive_answer_sheet: elss.descriptive_answer_sheet,
            uploadAnswerSheet: elss.answer_sheet_path,
            answer_sheet_original_path: elss.original_answet_sheet_path,
            resultStatus:
              elss.marks_obtained !== null && elss.publish_results === 1
                ? true
                : false,
            subjectList: elss.sub_tot_marks,
            classRank: elss.classrank,
            overallRank: elss.overallrank,
            attemptScore2: elss.attempt_2_score,
            attempScore3: elss.attempt_3_score,
            attendenceStatus: elss.attendance_status,
            attempt_no_status: elss.attempt_no_status,
            que_paper_filepath: elss.que_paper_filepath,
            answer_booklet_path: elss.answer_booklet_path,
            answer_key_path: elss.answer_key_path,
            cut_off: elss.cut_off,
            question_upload_status: elss.que_status,
          }));
          const subTopics =
            elss.subjects && elss.subjects.length > 0 ? elss.subjects : [];
          setSubTopic(subTopics);
          const query_io = elss.query.length > 0 ? elss.query : [];
          setQueries(query_io);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return error;
      });
  };

  const [evaluatorComment, setEvaluatorComment] = useState({});
  const [uasstatus, setUasstatus] = useState(false);
  const [fdata, setFdata] = useState({
    title: "",
    sDate: "",
    methodOfTest: "",
    testType: "",
    descriptionForStudent: "",
    syllabusFile: "",
    startTime: "",
    endTime: "",
    subject: "",
    obtained_marks: "",
    medium: "",
    course: "",
    batchName: "",
    category: "",
    classMode: "",
    location: "",
    location_name: "",
    rank: "",
    total_answer: "",
    teams_url: "",
    batch_id: "",
    event_id: "",
    test_num: "",
    test_topic: "",
    marks_obtained: "",
    tol_mark: "",
    no_ques: "",
    resultStatus: false,
    subjectList: [],
    classRank: "",
    overallRank: "",
    attemptScore2: "",
    attempScore3: "",
    attendenceStatus: "",
    ans_sheet_download_req: "",
    uploadAnswerSheet: null,
    que_paper_filepath: "",
    descriptive_answer_original_sheet: "",
    descriptive_answer_sheet: "",
    answer_sheet_original_path: "",
    answer_booklet_path: "",
    answer_sheet_path: "",
    answer_key_path: "",
    cut_off: "",
    staff_name: "",
    handWriting: "",
    keyPoints: "",
    Presentation: "",
    Quantity_of_facts: "",
    Quality_of_content: "",
    Pictographics: "",
    headings: "",
    Subheadings: "",
    hightlight_facts: "",
    evaluated_answer_sheet: "",
    question_upload_status: 0,
  });
  console.log(fdata.resultStatus, "resultStatus");

  const [queries, setQueries] = useState([]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const currentTime = moment();
  const startTime = moment(fdata.startTime, "HH:mm:ss");
  const STARTTIME = new Date(fdata.startTime);
  const calculatedEndTime = new Date(
    STARTTIME.getFullYear(),
    STARTTIME.getMonth(),
    STARTTIME.getDate(),
    STARTTIME.getMinutes() + ALLOWED_MINUTES,
    0
  );
  const endTime = moment(fdata.endTime, "HH:mm:ss");

  const [canJoinTest, setCanJoinTest] = useState(false);
  const userTime = useRef(new Date());

  useEffect(() => {
    const startTime = moment(fdata.startTime, "HH:mm:ss");
    const endTime = moment(fdata.endTime, "HH:mm:ss");
    if (
      moment(fdata.sDate).isAfter(currentDate, "day") ||
      (moment(currentDate).isSame(fdata.sDate, "day") &&
        moment(startTime, "HH:mm:ss").isAfter(currentDate, "minute") &&
        moment(endTime, "HH:mm:ss").isAfter(currentDate, "minute"))
    ) {
      // Event is in the future
      setUpdateStatus("2");
      // navigate(`/ViewTestDetail/${props.batch_id}/${id}/2/${event_type}`);
    } else if (
      (moment(currentDate).isSame(fdata.sDate, "day") &&
        moment(startTime, "HH:mm:ss").isSame(currentDate, "minute")) ||
      (moment(currentDate).isSame(fdata.sDate, "day") &&
        moment(startTime, "HH:mm:ss").isBefore(currentDate, "minute") &&
        moment(endTime, "HH:mm:ss").isAfter(currentDate, "minute"))
    ) {
      // console.log(endTime,"sds")
      // Event is ongoing
      setUpdateStatus("1");
      // navigate(`/ViewTestDetail/${props.batch_id}/${id}/1/${event_type}`);
    } else if (
      (moment(currentDate).isSame(fdata.sDate, "day") &&
        moment(endTime, "HH:mm:ss").isSame(currentDate, "minute")) ||
      (moment(currentDate).isSame(fdata.sDate, "day") &&
        moment(startTime, "HH:mm:ss").isBefore(currentDate, "minute") &&
        moment(endTime, "HH:mm:ss").isAfter(currentDate, "minute"))
    ) {
      // console.log(endTime,"sdsdf")
      // Event is in the past
      setUpdateStatus("0");
    } else if (
      moment(fdata.sDate).isBefore(currentDate, "day") ||
      (moment(currentDate).isSame(fdata.sDate, "day") &&
        moment(endTime, "HH:mm:ss").isBefore(currentDate, "minute"))
    ) {
      // console.log(endTime,"nmnm")
      // Event is in the past
      setUpdateStatus("0");
    } else {
      setUpdateStatus("0");
    }
  }, [startTime, endTime]);

  const handleReviewSubmission = () => {
    navigate(`/ReviewSubmission/${batch_id}/${event_id}`);
  };

  const handleDownloadAnswerSheet = () => {
    const formData = {
      user_id: userid,

      batch_id,

      event_id,
    };
    AxiosInstance.post("api/test/answer/download", formData)
      .then((res) => {
        if (res.data.status) {
          const { path } = res.data.data;
          if (path) window.location(`${path}`);
        } else {
          throw new Error("download answer sheet pdf error");
        }
      })
      .catch((err) => console.error(err));
  };

  const handleQuiz = () => {
    let dt = {
      batch_id: parseInt(batch_id),
      event_id: parseInt(event_id),
      user_id: userid,
    };
    // console.log(dt,"dt");
    AxiosInstance.post("api/test/start", dt)
      .then((res) => {
        if (res.data.status === true) {
          let startTime =
            res.data.data[0].start_time != null
              ? res.data.data[0].start_time
              : "";
          let endTime =
            res.data.data[0].end_time != null ? res.data.data[0].end_time : "";
          let batch_id = res.data.data[0].batch_id;
          let event_id = res.data.data[0].event_id;
          let no_ques = res.data.data[0].no_of_questions;
          let tol_mark = res.data.data[0].tot_marks;
          // let attempt_no =  (fdata.obtained_marks !==null && fdata.obtained_marks !=="") ? res.data.data[0].attempt : 2 ;
          let attempt_no = res.data.data[0].attempt;
          let method_of_test = res.data.data[0].method_of_test;
          let event_name = res.data.data[0].event_name;
          let type_type_name = res.data.data[0].name;
          let start_date_test = res.data.data[0].start_date;
          let description = res.data.data[0].description;
          let attendance_status = res.data.data[0].attendance_status;
          const navState = res.data.data[0];
          navigate(res.data.data[0].teams_url, {
            state: {
              eventName: event_name,
              methodOfTest: method_of_test,
              testType: type_type_name,
              date: start_date_test,
              startTime: startTime,
              endTime: endTime,
              // medium: medium,
              // course: course,
              // batchName: batchName,
              // category: category,
              // classMode: classMode,
              date: start_date_test,
              // location: location,
              totalNoQues: no_ques,
              totalMark: tol_mark,
              descForStudent: description,
              batchID: batch_id,
              eventID: event_id,
              attempt_no: attempt_no,
              attendance_status: attendance_status,
            },
          });
        } else {
          navigate("/");
          toast.error("You've reached the maximum limit of 2 re-takes, so another attempt isn't possible.");
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };
  const txtQueryStatus = useRef(null);

  const handleCloseModels_Succ = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };

  const handleCloseModel = () => {
    setSwalMsg({
      show: false,
      status: "",
      message: "",
      title: "",
      BottomOkClose: "",
    });

    // navigateToPreviousScreen();
  };
  const handleDownloadSyllabus = (path) => {
    window.open(`${ImageUrlPrefix}/${path}`);
  };
  const handleDownloadSyllabusss = (path) => {
    const link = document.createElement("a");
    link.href = path; // The URL or file path to the syllabus
    link.download = path.split("/").pop(); // Extracts the filename from the path
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the DOM
  };
  const handleViewTestPerfomance = () => {
    navigate(`/viewTestPerformance`, {
      state: {
        userID: userid,
        batchID: batch_id,
        eventID: event_id,
        type_batch: type_batch,
        METHODODTEST: fdata.methodOfTest,
        ANSWESHEET: fdata.uploadAnswerSheet,
        ANSWERKEY: fdata.answer_key_path,
        QUESTIONPAPER: fdata.que_paper_filepath,
        ATTENDSTATUS: fdata.attendenceStatus,
      },
    });
  };

  const [staffMem, setStaffMem] = useState([]);
  const [viewQueries, setViewQueries] = useState([]);

  const handleAssign = (option, dname, questionPk) => {
    // console.log(option, "option");
    if (option) {
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === questionPk) {
            return {
              ...q,
              IsFaculty_id: option.value,
              isFaculty_name: option.label,
              isFaculty_error: "",
            };
          }
          return q;
        });
      });
    } else {
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === questionPk) {
            return {
              ...q,
              IsFaculty_id: "",
              isFaculty_name: "",
              isFaculty_error: "",
            };
          }
          return q;
        });
      });
    }
  };
  const [error, setError] = useState({});
  // console.log(error.uploadAnswerSheet, "skkkkk");

  const handleChange = (event, elements) => {
    const { name, value, files, checked } = event.target;
    if (name === "query_path" && files && files.length > 0) {
      // console.log(value);
      const query_file = files[0];
      if (files[0].type == "application/pdf") {
        if (files[0].size < 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("query_file", query_file);
          AxiosInstance.post("api/upload/", formData)
            .then((res) => {
              setViewQueries((prevState) => {
                return prevState.map((q) => {
                  if (q.question_pk === elements.question_pk) {
                    return {
                      ...q,
                      query_path: res.data.path,
                      original_query_path: res.data.originalname,
                      query_path_error: "",
                    };
                  }
                  return q;
                });
              });
            })
            .catch((error) => {
              return error;
            });
        } else {
          let err = "File size must be less than 2MB.";
          setViewQueries((prevState) => {
            return prevState.map((q) => {
              if (q.question_pk === elements.question_pk) {
                return { ...q, query_path_error: err };
              }
              return q;
            });
          });
        }
      } else {
        let err = "pdf files only allowed";
        setViewQueries((prevState) => {
          return prevState.map((q) => {
            if (q.question_pk === elements.question_pk) {
              return { ...q, query_path_error: err };
            }
            return q;
          });
        });
      }
    } else if (name === "query_status") {
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === elements.question_pk) {
            return { ...q, query_status: value, query_Status_error: "" };
          }
          return q;
        });
      });
      setError({
        ...error,
        [name]: "",
      });
    } else if (name === "reply_query") {
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === elements.question_pk) {
            return { ...q, reply_query: value, reply_status_error: "" };
          }
          return q;
        });
      });
      setError({
        ...error,
        [name]: "",
      });
    }
  };
  const [flagReload, setFlagReload] = useState(false);

  const RaiseAdminQuery = (dff) => {
    AxiosInstance.post("api/test/raise/query/byAdmin", dff)
      .then((response) => {
        if (response.data.status) {
          setFlagReload(true);
          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          });
        } else {
          setFlagReload(false);
          Swal.fire({
            title: "Warning",
            text: response.data.message,
            icon: "Warning",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };
  const validateQueryReply = (queries) => {
    const UpdateReplyss = queries.map((OB) => {
      let updatedOB = { ...OB };
      if (!OB.query_status) {
        updatedOB.query_Status_error = "Please select Query Status";
      }
      if (OB.query_path === null) {
        updatedOB.query_path_error = "Please select Query Path";
      }
      if (!OB.reply_query) {
        updatedOB.reply_status_error = "Please enter reply ";
      }

      if (!OB.isFaculty_error) {
        updatedOB.isFaculty_error = "Please select faculty ";
      }

      return updatedOB;
    });

    setViewQueries(UpdateReplyss);
  };
  const handleUpdateApprove = (elements) => {
    validateQueryReply(viewQueries);
    if (
      viewQueries.length > 0 &&
      viewQueries.every(
        (el) =>
          el.query_Status_error === "" &&
          (el.query_path_error === "" || el.query_path_error === null) &&
          el.reply_status_error === ""
      )
    ) {
      const df = {
        user_type: 0, // admin assign to deafult user_type  0→admin, 1→faculty
        event_type: fdata.event_type ? fdata.event_type : 1, // default as test so event type 1
        event_pk: fdata.event_id ? fdata.event_id : "",
        batch_pk: fdata.batch_id ? fdata.batch_id : "",
        question_pk: elements.question_pk,
        remaind_again: 0,
        assigned_to: elements.IsFaculty_id ? elements.IsFaculty_id : "",
        assigned_by: userid, // admin_user id
        // rquery_pk:
        //   elements.stud_query && elements.stud_query.length > 0
        //     ? elements.stud_query.map((el) => el.pk)
        //     : null,
        query_status: elements.query_status, // 0 valid 1 invalid
        query_proof_filepath: elements.query_path,
        original_query_filepath: elements.original_query_path,
        reply_by_staff: elements.reply_query,
        query_stage: 1, // 0 others 1 approved
        query_approved_dt: moment().format("YYYY/MM/DD HH:mm:ss"),
        created_by: userid,
        updated_by: userid,
      };
      if (elements.reply_query !== "" && elements.query_status !== "") {
        RaiseAdminQuery(df);
      }
      setViewQueries((prevState) => {
        return prevState.map((q) => {
          if (q.question_pk === elements.question_pk) {
            return {
              ...q,
              isUpdate: false,
              isEdit: false,
              IsFacultyEdit: false,
              IsfacultyUpdate: true,
            };
          }
          return q;
        });
      });
    }
  };
  const StyledPara = styled.p`
    color: var(--color-text-primary);
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
  `;
  const StyledAlert = styled.div`
    background-color: var(--color-brand-800);
  `;
  const StyledAlertPara = styled.p`
    margin: 0;
    padding: 5px 0;
    text-align: center;
    color: var(--color-white);
    font-size: 14px;
    font-weight: var(--font-weight-bold);
  `;
  const HighLight = styled.span`
    color: var(--color-brand-900);
  `;

  const [startOpen, setStartOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const timeToDate = (timeStr) => {
    const [hours, minutes, seconds] = timeStr.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes, seconds, 0);
    return date;
  };
  const formattedDurations = "02:00:00";

  const convertToSeconds = (timeStr) => {
    const [hours, minutes, seconds] = timeStr.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  // Helper function to format seconds back into "HH:MM:SS"
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };
  const [timeLeft, setTimeLeft] = useState(
    convertToSeconds(formattedDurations)
  );
  const [isRunning, setIsRunning] = useState(true);
  useEffect(() => {
    if (timeLeft > 0) {
      setIsRunning(false);
      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => Math.max(prevTime - 1, 0));
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);
  const finalTime = formatTime(timeLeft);

  const [hour, minute, second] = finalTime.split(":");
  // Upload Answer Sheet
  const handleFileChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "uploadAnswerSheet") {
      if (files && files.length > 0) {
        const answer_sheet = files[0];
        setFdata((prevState) => ({
          ...prevState,
          uploadAnswerSheet: null,
          answer_sheet_original_path: null,
        }));
        if (files[0].type == "text/pdf" || files[0].type == "application/pdf") {
          if (files[0].size < 10 * 1024 * 1024) {
            const formData = new FormData();
            formData.append("answer_sheet", answer_sheet);
            AxiosInstance.post("api/upload/", formData)
              .then((res) => {
                setFdata((prevState) => ({
                  ...prevState,
                  uploadAnswerSheet: res.data.path,
                  answer_sheet_original_path: res.data.originalname,
                }));
                setError({
                  ...error,
                  uploadAnswerSheet: "",
                });
              })
              .catch((error) => {
                return error;
              });
          } else {
            let err = "File size must be less than 10MB.";
            setError({
              ...error,
              uploadAnswerSheet: err,
            });
            setFdata((prevState) => ({
              ...prevState,
              uploadAnswerSheet: null,
              answer_sheet_original_path: null,
            }));
          }
        } else {
          let err = "pdf files only allowed";
          setError({
            ...error,
            uploadAnswerSheet: err,
          });
          setFdata((prevState) => ({
            ...prevState,
            uploadAnswerSheet: null,
            answer_sheet_original_path: null,
          }));
        }
      } else {
        setFdata((prevState) => ({
          ...prevState,
          uploadAnswerSheet: null,
          answer_sheet_original_path: null,
        }));
      }
    } else {
      setFdata((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
    }
  };
  const validateUploadFile = (data) => {
    const errors = {};
    if (data.uploadAnswerSheet === null) {
      errors.uploadAnswerSheet = "Please Upload pdf file";
      txtUplANS.current.focus();
    }
    return errors;
  };

  const handleUpload = (e) => {
    const errors_des = validateUploadFile(fdata);
    if (Object.keys(errors_des).length === 0) {
      const updateEvent = {
        event_id: fdata.event_id,
        // batch_id: fdata.batch_id,
        batch_id: parseInt(batch_id),

        user_id: userid,
        answer_sheet_path: fdata.uploadAnswerSheet,
        answer_sheet_original_path: fdata.answer_sheet_original_path,
      };
      AxiosInstance.post(
        "api/test/descriptive/answer_sheet_upload",
        updateEvent
      )
        .then((res) => {
          if (res.data.status === true) {
            toast.success(res.data.message);
            setFlagSet(true);
            setUasstatus(false);
            // navigateToPreviousScreen();
          } else {
            toast.error(res.data.message);
            setFlagSet(true);
            setUasstatus(false);
            // navigateToPreviousScreen();
          }
        })
        .catch((error) => {
          setFlagSet(true);
          return error;
        });
    } else {
      setError(errors_des);
    }
  };

  // timing cal

  const TODAY = new Date(fdata.sDate);
  const [stHour, stMinute, stSecond] = fdata.startTime.split(":");
  const [endHour, endMinute, endSecond] = fdata.endTime.split(":");
  const startDateTime = new Date(
    TODAY.getFullYear(),
    TODAY.getMonth(),
    TODAY.getDate(),
    stHour,
    stMinute,
    stSecond
  );
  const endDateTime = new Date(
    TODAY.getFullYear(),
    TODAY.getMonth(),
    TODAY.getDate(),
    endHour,
    endMinute,
    endSecond
  );
  const [isAfterOneHour, setIsAfterOneHour] = useState(false);
  const [isOneHourAfterEnd, setIsOneHourAfterEnd] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date(); // Get the current time each second

      // Set isAfterOneHour to true one hour after the end time
      setIsAfterOneHour(
        now >= endDateTime &&
        now < new Date(endDateTime.getTime() + 60 * 60 * 1000)
      );

      // Set isOneHourAfterEnd to true one hour after the end time
      setIsOneHourAfterEnd(
        now > new Date(endDateTime.getTime() + 60 * 60 * 1000) // true only after one hour
      );
    }, 1000);

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [endDateTime]); // Depend on endDateTime to ensure the interval is updated if it changes

  const AnswerBookletUploadPath = () => {
    // Replace with the path to your PDF file
    const pdfUrl = `${ImageUrlPrefix}/${fdata.answer_booklet_path}`;
    window.open(pdfUrl, "_blank");
  };
  const QuestionPaperUploadPath = () => {
    // Replace with the path to your PDF file
    const pdfUrl = `${ImageUrlPrefix}/${fdata.que_paper_filepath}`;
    window.open(pdfUrl, "_blank");
  };
  const AnswerKeyUploadPath = () => {
    // Replace with the path to your PDF file
    const pdfUrl = `${ImageUrlPrefix}/${fdata.answer_key_path}`;
    window.open(pdfUrl, "_blank");
  };

  const onclickUploadAnswerSheet = () => {
    setUasstatus(true);
    window.scrollBy(0, 650);
  };

  const AnswerSheetUploadPath = () => {
    // Replace with the path to your PDF file

    const pdfUrl = `${ImageUrlPrefix}/${fdata.descriptive_answer_sheet}`;
    window.open(pdfUrl, "_blank");
  };

  const getLabelForHandWriting = (handWriting) => {
    const rating = ratingOptions.find(
      (option) => option.value === String(handWriting)
    );
    return rating ? rating.label : "_";
  };

  const ratingOptions = [
    { value: "4", label: "Excellent" },
    { value: "3", label: "Good" },
    { value: "2", label: "Average" },
    { value: "1", label: "Below Average" },
    { value: "0", label: "Poor" },
  ];

  const [isRunningHour, setIsRunningHour] = useState("0 h 0 m 0 s");
  const [isRunningHourStatus, setIsRunningHourStatus] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isAfterCalculatedEnd, setIsAfterCalculatedEnd] = useState(false);
  const [isAfterEnd, setIsAfterEnd] = useState(false);
  const [isAfterCalculatedEndFM, setIsAfterCalculatedEndFM] = useState(false);
  const [isAfterCalculatedEndFuture, setIsAfterCalculatedEndFuture] =
    useState(false);
  const [isBeforeHalfHour, setIsBeforeHalfHour] = useState(false);
  const [showTiming, setShowTiming] = useState(false);
  const [isAfterFTEEnMIn, setIsAfterFTEEnMIn] = useState(false);
  const [isAllowedstatus, setIsAllowedstatus] = useState(false);
  function getDateOnly(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }
  const endDate = getDateOnly(calculatedEndTime);

  const calculatedStartTime = new Date(
    startDateTime.getFullYear(),
    startDateTime.getMonth(),
    startDateTime.getDate(),
    startDateTime.getHours(),
    startDateTime.getMinutes() - ALLOWED_MINUTES,
    startDateTime.getSeconds()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setIsAllowed(
        new Date() >= calculatedStartTime && new Date() <= calculatedEndTime
      );

      // console.log(new Date(), calculatedEndTime, "-----", endDateTime);

      setIsAfterCalculatedEnd(new Date() > calculatedEndTime); //tempToday > tempCendTime
      setIsAfterEnd(new Date() > endDateTime); //tempToday > tempEndTime
      // Calculate the end time minus 15 minutes
      const fifteenMinutes = 15 * 60 * 1000; // 15 minutes in milliseconds
      const endTimeMinusFifteenMinutes = new Date(
        calculatedEndTime.getTime() - fifteenMinutes
      );
      // Check if the current time is within 15 minutes before the calculated end time
      const isWithinFifteenMinutesBeforeEnd =
        new Date() >= endTimeMinusFifteenMinutes &&
        new Date() <= calculatedEndTime;
      // Set the state based on whether the current time is within the 15-minute window
      setIsAfterCalculatedEndFM(isWithinFifteenMinutesBeforeEnd);
      // console.log(currentDate, endDate, "hhhhhh", currentDate < endDate);
      setIsAfterCalculatedEndFuture(currentDate < endDate);
      // Check if the current time is within the 30 minutes before startDateTime
      setIsBeforeHalfHour(
        now >= new Date(startDateTime.getTime() - 30 * 60 * 1000) &&
        now < startDateTime
      );
      // Set showTiming to true after startDateTime has passed
      setShowTiming(now >= startDateTime);

      // Set isAfterOneHour to true one hour after the start time
      setIsAfterOneHour(
        now >= endDateTime &&
        now < new Date(endDateTime.getTime() + 60 * 60 * 1000)
      );

      // Update isAfterFTEEnMIn for 15 minutes after startDateTime
      setIsAfterFTEEnMIn(
        now >= startDateTime &&
        now < new Date(startDateTime.getTime() + 15 * 60 * 1000)
      );

      // Set isOneHourAfterEnd to true one hour after the end time
      setIsOneHourAfterEnd(
        now > new Date(endDateTime.getTime() + 60 * 60 * 1000) // Evaluates to true only after one hour
      );

      // Calculate remaining time until endDateTime
      const remainingTime = endDateTime - now;
      if (remainingTime > 0) {
        setIsRunningHourStatus(now >= startDateTime); // Starts running status at exact startDateTime
        // Calculate and set remaining hours, minutes, and seconds
        const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
        const seconds = Math.floor((remainingTime / 1000) % 60);

        setIsRunningHour(`${hours} h ${minutes} m ${seconds} s`);
      } else {
        // Timer ends here
        setIsRunningHour("0 h 0 m 0 s");
        setIsRunningHourStatus(false);
        clearInterval(interval);
      }
      setIsAllowedstatus(true);
    }, 100);

    return () => clearInterval(interval);
  }, [startDateTime]);

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <>
      <div className={`${styles.BathDetDS} BathDet container-fluid`}>
        <div className="row">
          <div className="col-md-12">
            <section className={styles.StepOne}>
              <ViewTestAlert
                type_batch={type_batch}
                startTime={fdata.startTime}
                sDate={fdata.sDate}
                endTime={fdata.endTime}
                answer_booklet_path={fdata.answer_booklet_path}
                que_paper_filepath={fdata.que_paper_filepath}
                attempt_no_status={fdata.attempt_no_status}
                attendenceStatus={fdata.attendenceStatus}
                onClickJoinTest={handleQuiz}
                onClickViewtestPerfomance={handleViewTestPerfomance}
                onClickDownloadAnswerSheet={handleDownloadAnswerSheet}
                ans_sheet_download_req={fdata.ans_sheet_download_req}
                onClickReviewSubmission={handleReviewSubmission}
                isResultsPublished={fdata.resultStatus}
                // onDownloadClick="iujg"
                hoursTime={hour}
                minutesTime={minute}
                secondsTime={second}
                isRunning={isRunning}
                // AnswerBookletUploadPath={`${ImageUrlPrefix}/${fdata.answer_booklet_path}`}
                AnswerBookletUploadPath={AnswerBookletUploadPath}
                QuestionPaperUploadPath={QuestionPaperUploadPath}
                AnswerKeyUploadPath={AnswerKeyUploadPath}
                AnswerSheetUploadPath={AnswerSheetUploadPath}
                methodOfTestObjective={fdata.methodOfTest === 0}
                methodOfTestDesctiptive={fdata.methodOfTest === 1}
                onclickUploadAnswerSheet={onclickUploadAnswerSheet}
                UPLOADSTTAUS={fdata.question_upload_status === 0 ? 0 : 1}
                UPLOAD_STTAUS={fdata.question_upload_status === 0 ? 0 : 1}
                UASTATUS={uasstatus}
                handleUpload={handleUpload}
                labelStatus={labelStatus}
              />
              {/* Countdown: {formatTime(timeLeft)} */}
              {fdata.methodOfTest === 1 && (
                <div className="col-md-12">
                  <p className={styles.fullscreen_paragraph}>
                    <div className="row mb-3 ms-1">
                      <div className="col-lg-12 col-md-12 ps-0">
                        <div
                          className={`${styles.Note_Popup} h-0 viewTestInstruction`}
                        >
                          <ul className="mb-0">
                            <li>
                              The test is scheduled to commence at{" "}
                              {fdata.startTime !== null &&
                                fdata.startTime !== ""
                                ? moment(fdata.startTime, "HH:mm:ss").format(
                                  "h:mm A"
                                )
                                : "-"}{" "}
                              and conclude at{" "}
                              {fdata.endTime !== null && fdata.endTime !== ""
                                ? moment(fdata.endTime, "HH:mm:ss").format(
                                  "h:mm A"
                                )
                                : "-"}
                              .
                            </li>
                            <li>
                              You can download the answer booklet starting from
                              half an hour before the start time, i.e., at{" "}
                              {fdata.startTime !== null &&
                                fdata.startTime !== ""
                                ? moment(fdata.startTime, "HH:mm:ss")
                                  .subtract(30, "minutes")
                                  .format("h:mm A")
                                : "-"}
                              .
                            </li>
                            <li>
                              You can download the question paper within the
                              first 15 minutes of the test, from{" "}
                              {fdata.startTime !== null &&
                                fdata.startTime !== ""
                                ? moment(fdata.startTime, "HH:mm:ss").format(
                                  "h:mm A"
                                )
                                : "-"}{" "}
                              to{" "}
                              {fdata.startTime !== null &&
                                fdata.startTime !== ""
                                ? moment(fdata.startTime, "HH:mm:ss")
                                  .add(15, "minutes")
                                  .format("h:mm A")
                                : "-"}
                              .
                            </li>
                            <li>
                              Please remember to upload your answer sheet back
                              to the application within one hour after the test
                              ends, by{" "}
                              {fdata.endTime !== null && fdata.endTime !== ""
                                ? moment(fdata.endTime, "HH:mm:ss")
                                  .add(1, "hour")
                                  .format("h:mm A")
                                : "-"}
                              .
                            </li>
                            <li>
                              Ensure that you do not attempt to upload the
                              answer sheet after{" "}
                              {fdata.endTime !== null && fdata.endTime !== ""
                                ? moment(fdata.endTime, "HH:mm:ss")
                                  .add(1, "hour")
                                  .format("h:mm A")
                                : "-"}{" "}
                              as it will not be accepted.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </p>
                </div>
              )}

              <div className={`${styles.box} row`}>
                <div className="col-md-12">
                  <p className={styles.StepTitle}>Test Details</p>
                  <div className="row mb-3">
                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Test Name
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.title !== null && fdata.title !== ""
                            ? fdata.title
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Total Number of Questions
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.no_ques !== null && fdata.no_ques !== ""
                            ? fdata.no_ques
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Method of Test
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.methodOfTest === 0
                            ? "Objective"
                            : "Descriptive"}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Batch Name
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.batchName !== null ? fdata.batchName : "⎯"}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Category
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.category !== null ? fdata.category : "⎯"}
                        </span>
                      </div>
                    </div>
                    {/* Course */}
                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Course
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.course !== null ? fdata.course : "⎯"}
                        </span>
                      </div>
                    </div>
                    {/* class mode */}
                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Class Mode
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.classMode !== null ? fdata.classMode : "⎯"}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Medium
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.medium !== null ? fdata.medium : "⎯"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted"
                        >
                          Location
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.location_name !== null &&
                            fdata.location_name !== ""
                            ? fdata.location_name
                            : "⎯"}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Test Type
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.testType || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Date
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.sDate !== null && fdata.sDate !== ""
                            ? moment(fdata.sDate).format("DD/MM/YYYY")
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Start Time
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.startTime !== null && fdata.startTime !== ""
                            ? moment(fdata.startTime, "HH:mm:ss").format(
                              "h:mm A"
                            )
                            : "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          End Time
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.endTime !== null && fdata.endTime !== ""
                            ? moment(fdata.endTime, "HH:mm:ss").format("h:mm A")
                            : "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Total Marks
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.tol_mark !== null && fdata.tol_mark !== ""
                            ? fdata.tol_mark
                            : "-"}
                        </span>
                      </div>
                    </div>
                    {fdata.resultStatus === true && (
                      <>

                        {fdata.methodOfTest === 0 && (
                          <div className="col-md-3">
                            <div className={styles.forms}>
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Number of Questions Answered
                              </label>
                              <span className={styles.ReviewLabel}>
                                {fdata?.total_answer || "-"}
                              </span>
                            </div>
                          </div>
                        )}

                        <div className="col-md-3">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Obtained Marks
                            </label>
                            <span className={styles.ReviewLabel}>
                              {fdata?.marks_obtained || fdata?.marks_obtained === 0
                                ? fdata?.marks_obtained
                                : "_"}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Class Rank
                            </label>
                            <span className={styles.ReviewLabel}>
                              {fdata?.classRank || "-"}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Overall Rank
                            </label>
                            <span className={styles.ReviewLabel}>
                              {fdata?.overallRank || "-"}
                            </span>
                          </div>
                        </div>
                      </>
                    )}


                    {fdata.methodOfTest == 0 && fdata.resultStatus && (
                      <div className="col-md-3">
                        <div className={styles.forms}>
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            2nd Attempt Score
                          </label>
                          <span className={styles.ReviewLabel}>
                            {fdata?.attemptScore2 || "-"}
                          </span>
                        </div>
                      </div>
                    )}
                    {fdata.methodOfTest == 0 && fdata.resultStatus && (
                      <div className="col-md-3">
                        <div className={styles.forms}>
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            3rd Attempt Score
                          </label>
                          <span className={styles.ReviewLabel}>
                            {fdata?.attempScore3 || "-"}
                          </span>
                        </div>
                      </div>
                    )}
                    {fdata.subjectList.length > 0 && fdata.resultStatus && (
                      <>
                        {fdata.methodOfTest === 0 &&
                          fdata.subjectList.map((subject, i) => (
                            <div className="col-md-3" key={i}>
                              <div className={styles.forms}>
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  {`Marks in ${subject.subject_name}`}
                                </label>
                                <span className={styles.ReviewLabel}>
                                  {subject.marks_obtained
                                    ? subject.marks_obtained === 0
                                      ? "A"
                                      : subject.marks_obtained
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          ))}
                      </>
                    )}
                    <div className="col-md-12">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Instruction to Student
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.descriptionForStudent || "-"}
                        </span>
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                    <div className={styles.forms}>
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Test Number
                      </label>
                      <span className={`${styles.ReviewLabel}`}>
                        {fdata.test_num !== null && fdata.test_num !== ""
                          ? fdata.test_num
                          : "⎯"}
                      </span>
                    </div>
                  </div> */}
                    {/* <div className="col-md-3">
                    <div className={styles.forms}>
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Subject
                      </label>
                      <span className={styles.ReviewLabel}>
                        {console.log("laksh-fdata", fdata)}
                        {fdata.subject !== null && fdata.subject !== ""
                          ? fdata.subject
                          : "⎯"}
                      </span>
                    </div>
                  </div> */}

                    {/* <div className="col-md-3">
                    <div className={styles.forms}>
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Test Topic
                      </label>
                      <span className={styles.ReviewLabel}>
                        {fdata.test_topic !== null && fdata.test_topic !== ""
                          ? fdata.test_topic
                          : "⎯"}
                      </span>
                    </div>
                  </div> */}
                    {/* {updateStatus === "0" && (
                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Number of Questions Answered
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.total_answer !== null &&
                          fdata.total_answer !== ""
                            ? fdata.total_answer
                            : "⎯"}
                        </span>
                      </div>
                    </div>
                  )} */}

                    {/* {updateStatus === "0" && (
                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Obtained Marks
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.obtained_marks !== null
                            ? fdata.obtained_marks
                            : "⎯"}
                        </span>
                      </div>
                    </div>
                  )} */}

                    {/* {updateStatus === "0" && (
                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Rank
                        </label>
                        <span className={styles.ReviewLabel}>
                          {fdata.rank !== null && fdata.rank !== ""
                            ? fdata.rank
                            : "⎯"}
                        </span>
                      </div>
                    </div>
                  )} */}
                  </div>
                </div>
              </div>
            </section>
            {/* ----Evaluation---- */}

            <section className={styles.StepOne}>
              {Array.isArray(evaluatorComment) &&
                evaluatorComment.map((item, index) => (
                  <div className={`${styles.box} row`}>
                    <div key={index} className="col-md-12">
                      <p className={styles.StepTitle}>
                        Evaluation by {item.staff_name ? item.staff_name : "_"}
                      </p>
                      <div className="row mb-3">
                        <div className="col-md-3">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Obtained Marks
                            </label>
                            <span className={styles.ReviewLabel}>
                              {item?.marks_obtained ||
                                item?.marks_obtained === 0
                                ? item?.marks_obtained
                                : "_"}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Hand Writing
                            </label>
                            <span className={styles.ReviewLabel}>
                              {getLabelForHandWriting(item.hand_writing)}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Key Points
                            </label>
                            <span className={styles.ReviewLabel}>
                              {getLabelForHandWriting(item.key_points)}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Presentation
                            </label>
                            <span className={styles.ReviewLabel}>
                              {getLabelForHandWriting(item.presentation)}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Quantity of Facts
                            </label>
                            <span className={styles.ReviewLabel}>
                              {getLabelForHandWriting(item.quantity_facts)}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Quality of Content
                            </label>
                            <span className={styles.ReviewLabel}>
                              {getLabelForHandWriting(item.quality_content)}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Pictographics
                            </label>
                            <span className={styles.ReviewLabel}>
                              {getLabelForHandWriting(item.pictographics)}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Headings
                            </label>
                            <span className={styles.ReviewLabel}>
                              {getLabelForHandWriting(item.headings)}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Sub Headings
                            </label>
                            <span className={styles.ReviewLabel}>
                              {getLabelForHandWriting(item.sub_heading)}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Final Comments
                            </label>
                            <span className={styles.ReviewLabel}>
                              {item.highlight_facts || "-"}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className={styles.forms}>
                            <button
                              style={{
                                border: "none",
                                background: "none",
                                padding: "0px",
                                margin: "0px",
                                textAlign: "start",
                              }}
                              onClick={() =>
                                handleDownloadSyllabus(
                                  item.descriptive_answer_sheet
                                )
                              }
                            >
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                                style={{ cursor: "pointer" }}
                              >
                                Evaluated Answer Sheet{" "}
                                <IconDownload
                                  style={{
                                    color: "#C0272D",
                                    marginLeft: "5px",
                                  }}
                                />
                                <VisibilityIcon
                                  style={{
                                    color: "#C0272D",
                                    marginLeft: "5px",
                                  }}
                                />
                              </label>
                            </button>
                            <span className={styles.ReviewLabel}>
                              {item.descriptive_answer_original_sheet
                                ? item.descriptive_answer_original_sheet
                                : "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </section>
            {/* Subjects and Topics */}
            <section className={styles.StepOne}>
              <div className={`${styles.box} row`}>
                <div className="col-md-12 mb-3">
                  <p className={styles.StepTitle}>Subjects and Topics</p>
                  <div className="row ">
                    <div className="col-md-3">
                      <div className={styles.forms}>
                        <button
                          style={{
                            border: "none",
                            background: "none",
                            padding: "0px",
                            margin: "0px",
                            textAlign: "start",
                          }}
                        >
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                            style={{ cursor: "pointer" }}
                          >
                            Uploaded Syllabus{" "}
                            <IconDownload
                              style={{
                                color: "#C0272D",
                                marginLeft: "5px",
                              }}
                              onClick={() =>
                                handleDownloadSyllabusss(fdata.syllabusFile)
                              }
                            />
                            <VisibilityIcon
                              style={{
                                color: "#C0272D",
                                marginLeft: "5px",
                              }}
                              onClick={() =>
                                handleDownloadSyllabus(fdata.syllabusFile)
                              }
                            />
                          </label>
                        </button>
                        <span className={styles.ReviewLabel}>
                          {fdata.syllabusFile
                            ? fdata.syllabusFile.split("/").pop()
                            : "_"}
                        </span>
                      </div>
                    </div>
                  </div>
                  {subTopic.length > 0 ? (
                    subTopic.map((onk) => {
                      return (
                        <>
                          <div className="row">
                            <div className="col-md-4">
                              <div className={styles.forms}>
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Subject
                                </label>
                                <span className={styles.ReviewLabel}>
                                  {onk.subject_name}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-8">
                              <div className={styles.forms}>
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Test Topics
                                </label>
                                <span className={styles.ReviewLabel}>
                                  {/* {onk.test_topic_name} */}
                                  {onk.test_topic_name?.replace(/,/g, ", ")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <div className="row ">
                      <div className="col-md-3">
                        <div className={styles.forms}>
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Subject
                          </label>
                          <span className={styles.ReviewLabel}>No Records</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
            {isAllowedstatus === true &&
              isAfterEnd &&
              fdata.resultStatus &&
              fdata.methodOfTest === 1 && (
                <section className={styles.StepOne}>
                  <div className={`${styles.box} row`}>
                    <div className="col-md-12">
                      <p className={styles.StepTitle}>Question Paper</p>
                      <div className="row pb-3">
                        <div className="col-md-3 col-lg-4">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Question Paper
                              {fdata.que_paper_filepath !== "" &&
                                fdata.que_paper_filepath !== null && (
                                  <>
                                    <a
                                      href={`${ImageUrlPrefix}/${fdata.que_paper_filepath}`}
                                      target="_blank"
                                      download
                                    >
                                      <span
                                        className={`${styles.download_icon} material-icons align-middle`}
                                      >
                                        file_download
                                      </span>
                                    </a>
                                    <a
                                      href={`${ImageUrlPrefix}/${fdata.que_paper_filepath}`}
                                      target="_blank"
                                    >
                                      <span
                                        className={`${styles.download_icon} material-icons align-middle download_icon `}
                                      >
                                        visibility
                                      </span>
                                    </a>
                                  </>
                                )}
                            </label>
                            <span className={`${styles.ReviewLabel} mt-2`}>
                              {fdata.que_paper_filepath
                                ? fdata.que_paper_filepath.split("/").pop()
                                : "_"}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 col-lg-7">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Cut-Off
                            </label>
                            <span className={`${styles.ReviewLabel} mt-2`}>
                              {fdata.cut_off ? fdata.cut_off : "_"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

            {isAllowedstatus === true &&
              showTiming &&
              isAfterFTEEnMIn &&
              fdata.methodOfTest === 1 && (
                <section className={styles.StepOne}>
                  <div className={`${styles.box} row`}>
                    <div className="col-md-12">
                      <p className={styles.StepTitle}>Question Paper</p>
                      <div className="row pb-3">
                        <div className="col-md-3 col-lg-4">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Question Paper
                              {fdata.que_paper_filepath !== "" &&
                                fdata.que_paper_filepath !== null && (
                                  <>
                                    <a
                                      href={`${ImageUrlPrefix}/${fdata.que_paper_filepath}`}
                                      target="_blank"
                                      download
                                    >
                                      <span
                                        className={`${styles.download_icon} material-icons align-middle`}
                                      >
                                        file_download
                                      </span>
                                    </a>
                                    <a
                                      href={`${ImageUrlPrefix}/${fdata.que_paper_filepath}`}
                                      target="_blank"
                                    >
                                      <span
                                        className={`${styles.download_icon} material-icons align-middle download_icon `}
                                      >
                                        visibility
                                      </span>
                                    </a>
                                  </>
                                )}
                            </label>
                            <span className={`${styles.ReviewLabel} mt-2`}>
                              {fdata.que_paper_filepath
                                ? fdata.que_paper_filepath.split("/").pop()
                                : "_"}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 col-lg-7">
                          <div className={styles.forms}>
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Cut-Off
                            </label>
                            <span className={`${styles.ReviewLabel} mt-2`}>
                              {fdata.cut_off ? fdata.cut_off : "_"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

            {/* ---Descriptive Type (Question Paper Upload)---- */}
            {isAfterOneHour && fdata.methodOfTest === 1 && (
              <section className="global_container">
                <div className=" m-0">
                  {uasstatus === true ? (
                    <fieldset>
                      <legend
                        className="w-auto"
                        style={{ fontSize: "16px", padding: "0px 10px" }}
                      >
                        Upload Answer Sheet
                      </legend>
                      {/* <div class="col-md-6 ">
                        <label for="formFile" class="form-label confirm_value">
                          Upload Answer Sheet
                        </label>

                        <div className="row">
                          <div
                            className={
                              fdata.uploadAnswerSheet !== "" &&
                                fdata.uploadAnswerSheet !== null
                                ? "col-md-3 "
                                : "col-md-12"
                            }
                          >
                            <input
                              ref={txtUplANS}
                              type="file"
                              id="uploadAnswerSheet"
                              name="uploadAnswerSheet"
                              placeholder="Upload Question Paper"
                              className={
                                fdata.uploadAnswerSheet !== "" &&
                                  fdata.uploadAnswerSheet !== null
                                  ? `${styles.desc_input} InputsFile  vv_upload_width rounded-1`
                                  : `${styles.desc_input} InputsFile  vv_upload_width rounded-1`
                              }
                              onChange={handleFileChange}
                              autoComplete="AnswerKeyPath"
                              required
                            />

                            {error.uploadAnswerSheet && (
                              <small className="error_message">
                                {error.uploadAnswerSheet}
                              </small>
                            )}
                          </div>
                          {fdata.uploadAnswerSheet !== "" &&
                            fdata.uploadAnswerSheet !== null && (
                              <div
                                className={
                                  "col-md-9 d-flex align-content-center p-0"
                                }
                              >
                                <span className="nn_upload_widthFile">
                                  {fdata.uploadAnswerSheet !== "" &&
                                    fdata.uploadAnswerSheet !== null
                                    ? fdata.answer_sheet_original_path
                                    : ""}
                                </span>
                                <span className={`${styles.verified_upload} material-icons`}>
                                  verified
                                </span>
                              </div>
                            )}
                        </div>

                        {error.answer_sheet_original_path && (
                          <small className="error_message">
                            {error.answer_sheet_original_path}
                          </small>
                        )}
                      </div> */}

                      <div className="col-lg-6 col-md-12">
                        <div className="forms ">
                          <div className="d-flex">
                            <div className="row">
                              <label
                                for="formFile"
                                class={` form-label confirm_value`}
                              >
                                Upload Answer Sheet
                              </label>
                            </div>
                            {fdata.uploadAnswerSheet !== "" &&
                              fdata.uploadAnswerSheet !== null && (
                                <div
                                  className={"d-flex align-content-center p-0"}
                                >
                                  {fdata.uploadAnswerSheet !== "" &&
                                    fdata.uploadAnswerSheet !== null && (
                                      <>
                                        <a
                                          target="_blank"
                                          href={`${ImageUrlPrefix}/${fdata.uploadAnswerSheet}`}
                                        // style={{ marginTop: "15px" }}
                                        >
                                          <Download className="download_icon" />
                                        </a>
                                      </>
                                    )}
                                </div>
                              )}
                          </div>
                          <div className="row">
                            <div
                              className={
                                fdata.uploadAnswerSheet !== "" &&
                                  fdata.uploadAnswerSheet !== null
                                  ? "col-md-3 col-5 "
                                  : "col-md-12"
                              }
                            >
                              <input
                                ref={txtUplANS}
                                type="file"
                                id="uploadAnswerSheet"
                                name="uploadAnswerSheet"
                                placeholder="Upload Ansewr Sheet"
                                className={
                                  fdata.uploadAnswerSheet !== "" &&
                                    fdata.uploadAnswerSheet !== null
                                    ? "InputsSelectFile nn_upload_width"
                                    : "InputsSelectFile  vv_upload_width"
                                }
                                onChange={handleFileChange}
                                autoComplete="AnswerKeyPath"
                                required
                              />
                            </div>
                            {/* { fdata.uploadAnswerSheet !== "" &&
                                 fdata.uploadAnswerSheet !== null && (
                                  <div
                                    className={
                                      "col-md-9 col-7 d-flex  align-content-center p-0"
                                    }
                                  >
                                    <span className="nn_upload_fileevaluator">
                                      { fdata.uploadAnswerSheet !== "" &&
                                       fdata.uploadAnswerSheet !== null
                                        ? fdata.answer_sheet_original_path
                                        : ""}
                                    </span>
                                    { fdata.uploadAnswerSheet !== "" &&
                                       fdata.uploadAnswerSheet !== null && (
                                        <>
                                          <span
                                            className={`${styles.verified_uploadeval} material-icons align-middle text-danger`}
                                          >
                                            verified
                                          </span>
                                        </>
                                      )}
                                  </div>
                                )} */}
                            {fdata.uploadAnswerSheet !== "" &&
                              fdata.uploadAnswerSheet !== null && (
                                <div
                                  className={
                                    "col-md-9 d-flex align-content-center p-0"
                                  }
                                >
                                  <span className="nn_upload_fileevaluator">
                                    {fdata.uploadAnswerSheet !== "" &&
                                      fdata.uploadAnswerSheet !== null
                                      ? fdata.answer_sheet_original_path
                                      : ""}
                                  </span>
                                  <span
                                    className={`${styles.verified_uploadeval} material-icons`}
                                  >
                                    verified
                                  </span>
                                </div>
                              )}
                          </div>

                          {error.uploadAnswerSheet && (
                            <small className="error_message">
                              {error.uploadAnswerSheet}
                            </small>
                          )}
                        </div>
                      </div>
                    </fieldset>
                  ) : (
                    <section className={styles.StepOne}>
                      <div className={`${styles.box} row`}>
                        <div className="col-md-12">
                          <p className={styles.StepTitle}>Answer Sheet </p>
                          <div className="row">
                            <div className="col-md-12 col-lg-12">
                              <div className={styles.forms}>
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Answer Sheet
                                  {fdata.uploadAnswerSheet !== null && (
                                    <>
                                      <a
                                        href={`${ImageUrlPrefix}/${fdata.uploadAnswerSheet}`}
                                        target="_blank"
                                        download
                                      >
                                        <span
                                          className={`${styles.download_icon} material-icons align-middle`}
                                        >
                                          file_download
                                        </span>
                                      </a>
                                      <a
                                        href={`${ImageUrlPrefix}/${fdata.uploadAnswerSheet}`}
                                        target="_blank"
                                      >
                                        <span
                                          className={`${styles.download_icon} material-icons align-middle download_icon `}
                                        >
                                          visibility
                                        </span>
                                      </a>
                                    </>
                                  )}
                                </label>
                                <span className={`${styles.ReviewLabel} mt-2`}>
                                  {fdata.answer_sheet_original_path !== null
                                    ? fdata.answer_sheet_original_path
                                    : "_"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                </div>
              </section>
            )}

            {isOneHourAfterEnd && fdata.methodOfTest === 1 && (
              <section className={styles.StepOne}>
                <div className={`${styles.box} row`}>
                  <div className="col-md-12">
                    <p className={styles.StepTitle}> Answer Sheet</p>
                    <div className="row mb-3">
                      <div className="col-md-3">
                        <div className={styles.forms}>
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Answer Sheet{" "}
                            {fdata.uploadAnswerSheet !== "" &&
                              fdata.uploadAnswerSheet !== null && (
                                <>
                                  <a
                                    href={`${ImageUrlPrefix}/${fdata.uploadAnswerSheet}`}
                                    target="_blank"
                                    download
                                  >
                                    <span
                                      className={`${styles.download_icon} material-icons align-middle`}
                                    >
                                      file_download
                                    </span>
                                  </a>
                                  <a
                                    href={`${ImageUrlPrefix}/${fdata.uploadAnswerSheet}`}
                                    target="_blank"
                                  >
                                    <span
                                      className={`${styles.download_icon} material-icons align-middle download_icon `}
                                    >
                                      visibility
                                    </span>
                                  </a>
                                </>
                              )}
                          </label>
                          <span className={styles.ReviewLabel}>
                            {fdata.answer_sheet_original_path
                              ? fdata.answer_sheet_original_path
                              : "_"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {/* // overall Test Details */}
            {/* {isOneHourAfterEnd && fdata.methodOfTest === 1 && (
              <section className={styles.StepOne}>
                <div className={`${styles.box} row`}>
                  <div className="col-md-12">
                    <p className={styles.StepTitle}>Overall Test Details</p>
                    <div className="row mb-3">
                      <div className="col-md-3">
                        <div className={styles.forms}>
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Total Number of Questions
                          </label>
                          <span className={styles.ReviewLabel}>
                            {fdata.no_ques !== null && fdata.no_ques !== ""
                              ? fdata.no_ques
                              : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className={styles.forms}>
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Total Marks
                          </label>
                          <span className={styles.ReviewLabel}>
                            {fdata.tol_mark || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className={styles.forms}>
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Overall Obtained Marks
                          </label>
                          <span className={styles.ReviewLabel}>
                            {fdata.marks_obtained || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className={styles.forms}>
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Class Rank
                          </label>
                          <span className={styles.ReviewLabel}>
                            {fdata.classRank || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className={styles.forms}>
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Overall Rank
                          </label>
                          <span className={styles.ReviewLabel}>
                            {fdata?.overallRank || "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )} */}

            {isAfterOneHour &&
              fdata.methodOfTest === 1 &&
              uasstatus === true && (
                <div className="col-md-12 col-12 pt-3 ListBtn">
                  <ul class="List-Button">
                    <li class="ListBtnItems">
                      <Link
                        onClick={navigateToPreviousScreen}
                        to="javascript:void(0)"
                        className="secondaryButton "
                      >
                        {labelStatus == "1"
                          ? "Back to Test Performance"
                          : "Back to View Schedule"}
                      </Link>
                    </li>
                    <li class="ListBtnItems">
                      <Link
                        onClick={handleUpload}
                        to={"javascript:void(0)"}
                        className="primaryButton "
                        variant="primary"
                      >
                        Save Answer Sheet
                      </Link>
                    </li>
                  </ul>
                </div>
              )}

            {/* ------------ */}

            {queries.map((el, index) => (
              <section className={styles.StepOne}>
                <div className={`${styles.box} row`}>
                  <div className="col-md-12">
                    <p className={styles.StepTitle}>
                      Query - Question No: {el.ques_no}
                    </p>
                    <div className="row mt-3">
                      <div className="col-md-12 p-0">
                        {el.display_type === 0 && (
                          <RtypeOne type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 1 && (
                          <RtypeTwo type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 2 && (
                          <RtypeThree type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 3 && (
                          <RtypeFour type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 4 && (
                          <RtypeFive type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 5 && (
                          <RtypeSix type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 6 && (
                          <RtypeSeven type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 7 && (
                          <RtypeEight type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 8 && (
                          <RtypeNine type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 9 && (
                          <RtypeTen type={"2"} index={index} list={el} />
                        )}
                        {el.display_type === 10 && (
                          <>
                            {el.para_text_tm !== "" &&
                              (el.lang_type === 0 || el.lang_type === 2) && (
                                <Rtypepara
                                  type={"2"}
                                  classET={"question_labels_tamil"}
                                  index={index}
                                  list={el.para_text_tm}
                                />
                              )}

                            {el.para_text_en !== "" &&
                              (el.lang_type === 1 || el.lang_type === 2) && (
                                <Rtypepara
                                  type={"2"}
                                  classET={"question_labels_english"}
                                  index={index}
                                  list={el.para_text_en}
                                />
                              )}
                            <RtypeParaOption
                              type={"2"}
                              index={index}
                              list={el}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <ExplanationQuery
                      margin_yes={0}
                      lang_type={el.lang_type}
                      en_explan={el.en_explanation}
                      tm_expla={el.tm_explanation}
                    />
                    <div className={styles.BorderQuery}></div>
                    {el.stud_query &&
                      el.stud_query.map((ojk, index) => {
                        return <QuerySection query_rise={ojk} index={index} />;
                      })}
                    <div className={styles.BorderQuery}></div>
                    <div className="row mt-3 mb-4">
                      <div className="col-md-6 ">
                        {el.assignee_info !== null && (
                          <div className={styles.forms}>
                            <p
                              for="assign_to"
                              className={`${styles.titleQuery} mb-0`}
                              id={styles.assign_to_id}
                            >
                              Assign To
                            </p>
                            <span className={`${styles.ReviewLabel} ps-2 mt-2`}>
                              {el.assignee_info !== null &&
                                el.assignee_info.assigned_to_name}
                            </span>
                          </div>
                        )}
                      </div>
                      {el.assignee_info !== null &&
                        (el.assignee_info.reply_by_staff !== null ||
                          el.assignee_info.reply_by_staff !== "") && (
                          <>
                            <div className="col-md-6 ">
                              <div className={styles.forms}>
                                <p
                                  for="assign_to"
                                  className={`${styles.titleQuery} mb-0`}
                                  id={styles.assign_to_id}
                                >
                                  Query Status
                                </p>
                                <span
                                  className={`${styles.ReviewLabel} ps-2 mt-2`}
                                >
                                  {el.assignee_info.query_status === 1
                                    ? "Valid"
                                    : "Invalid"}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6 ">
                              <div className={styles.forms}>
                                <p
                                  for="assign_to"
                                  className={`${styles.titleQuery} mb-0`}
                                  id={styles.assign_to_id}
                                >
                                  Reply by {el.assignee_info.assigned_to_name}{" "}
                                  {el.assignee_info.reply_dt == null
                                    ? ""
                                    : `(${moment(el.assignee_info.reply_dt).format("DD/MM/YYYY hh:mm A")})`}

                                </p>
                                <span
                                  className={`${styles.ReviewLabel} ps-2 mt-2`}
                                >
                                  {el.assignee_info.reply_by_staff
                                    ? el.assignee_info.reply_by_staff
                                    : "_"}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className={styles.forms}>
                                <div class="d-flex">
                                  {" "}
                                  <p className={`${styles.titleQuery} mb-1`}>
                                    Attachments
                                  </p>
                                  <a
                                    download
                                    target="_blank"
                                    className={styles.attachmentQuery}
                                    href={`${ImageUrlPrefix}/${el.assignee_info &&
                                      el.assignee_info.query_proof_filepath
                                      ? el.assignee_info.query_proof_filepath
                                      : null
                                      }`}
                                  >
                                    <span
                                      class={`${styles.iconQuery} material-icons `}
                                    >
                                      download
                                    </span>
                                  </a>
                                  <a
                                    className={styles.attachmentQuery}
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${el.assignee_info &&
                                      el.assignee_info.query_proof_filepath
                                      ? el.assignee_info.query_proof_filepath
                                      : null
                                      }`}
                                  >
                                    <span
                                      class={`${styles.iconQuery} material-icons `}
                                    >
                                      visibility
                                    </span>
                                  </a>
                                </div>

                                <p className={`${styles.explain_ques} mt-0`}>
                                  {" "}
                                  {el.assignee_info !== null &&
                                    el.assignee_info.original_query_filepath}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </section>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
