import { Download, Visibility } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { GSTPRICE, ImageUrlPrefix } from "../../../../labels";
import moment from "moment";
import "./Purchase.css";
import EyeIcons from "../../../../img/eyeIcons.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AxiosInstance } from "../../../../axios";
import StudentConfirmType4 from "../../StudentConfirm/components/StudentConfirmType4";
import { USER_ADMIN, USER_STUDENT } from "../../../../utils/helper";
import Loader from "../../Loader";
export default function ReadViewAdmissionHistory() {
  const { user_id, batch_id, add_id, type, label } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = sessionStorage.getItem("userDetails")
    ? JSON.parse(sessionStorage.getItem("userDetails"))
    : "";
  const USER_TYPE = userInfo.user_type ? userInfo.user_type : 0;
  const [admissionID, setAdmissionID] = useState({
    adId: 0,
    is_paid: null,
    batch_id: null,
  });
  const [couName, setCouName] = useState({
    coueName: "",
  });
  //education useState
  const [educationQualification, setEducationQualification] = useState([
    "SSLC",
  ]);
  const [selectedSub, setSelectedSub] = useState([]);
  const [batchDetails, setBatchDetails] = useState([]);
  const navigate = useNavigate();
  //education useState
  const [sslcCerficates, setSslcCerficates] = useState([
    {
      name: "sslc",
      SSLC_CNo: "",
      SSLC_Year: "",
      SSLC_Marks: "",
      SSLC_Eboard: "",
      SSLC_Scl: "",
      SSLC_MI: "",
      SSLC_Path: null,
      SSLC_ORI_Path: null,
    },
    {
      name: "hsc",
      HSC_CNo: "",
      HSC_Year: "",
      HSC_Marks: "",
      HSC_Ebaord: "",
      HSC_Scl: "",
      HSC_MI: "",
      HSC_Path: null,
    },
    {
      name: "UG",
      UGCertificateNumber: "",
      UGYearpassing: "",
      UGDegree: "",
      UGMajor: "",
      UGMarksPercentage: "",
      UGClassName: "",
      UGNameInstitute: "",
      UG_Path: null,
    },
    {
      name: "PG",
      PGCertificateNumber: "",
      PGYearpassing: "",
      PGDegree: "",
      PGMajor: "",
      PGMarksPercentage: "",
      PGClassName: "",
      PGNameInstitute: "",
      PG_Path: null,
    },
    {
      name: "Integrated PG",
      IPCertificateNumber: "",
      IPYearpassing: "",
      IPDegree: "",
      IPMajor: "",
      IPMarksPercentage: "",
      IPClassName: "",
      IPNameInstitute: "",
      IP_Path: null,
    },
    {
      name: "Diploma",
      DCertificateNumber: "",
      DYearpassing: "",
      DDegree: "",
      DMajor: "",
      DMarksPercentage: "",
      DClassName: "",
      DNameInstitute: "",
      D_Path: null,
    },
    {
      name: "Professional Courses",
      PCCertificateNumber: "",
      PCYearpassing: "",
      PCDegree: "",
      PCMajor: "",
      PCMarksPercentage: "",
      PCClassName: "",
      PCNameInstitute: "",
      PC_Path: null,
    },
    {
      name: "Others",
      OtCertificateNumber: "",
      OtYearpassing: "",
      OtDegree: "",
      OtMajor: "",
      OtMarksPercentage: "",
      OtClassName: "",
      OtNameInstitute: "",
      Ot_Path: null,
    },
  ]);

  const [commentSec, setCommentSec] = useState([
    // {
    //   user_type:"",
    //   updated_dt: "",
    //   admin_comment: "",
    //   admission_id: "",
    // }
  ]);
  //specialPreference
  const [specialAdmissionPre, setSpecialAdmissionPre] = useState([
    // {
    //   id: 1,
    //   name: "",
    //   file: "",
    // },
  ]);
  //technical qualification
  const [techQualification, setTechQualification] = useState([
    // {
    //   id: 1,
    //   name: "",
    //   level: "",
    //   lang: "",
    //   Cnum: "",
    //   grade: "",
    //   year: "",
    //   file: "",
    // },
  ]);
  const [appCastLable, setAppCastLable] = useState([]);
  //list of inputs
  const [list, setList] = useState({
    FirstName: "",
    LastName: "",
    Fathername: "",
    MotherName: "",
    Profilephoto: null,
    DOB: "",
    age: 0,
    gender: "Male",
    MaritalStatus: "Single/Unmarried",
    aadharNo: "",
    aadharCardphoto: null,
    Mobile: "",
    original_sign_path: "",
    signaturePhoto: "",
    WhatsAppNumber: "",
    ParentMobileNo: "",
    ContactNumber: false,
    SamePresentAddress: false,
    emailaddress: "",
    BuildingNumberPresent: "",
    BuildingNumberPresent: "",
    StreetNamePresent: "",
    AreaPresent: "",
    LandmarkPresent: "",
    districtPresent: "",
    CityPresent: "",
    statePresent: "",
    PostPresent: "",
    TalukPresent: "",
    PincodePresent: 0,
    BuildingNumberPermanent: "",
    StreetNamePermanent: "",
    AreaPermanent: "",
    LandmarkPermanent: "",
    CityPermanent: "",
    districtPermanent: "",
    statePermanent: "",
    PincodePermanent: 0,
    PostPermanent: "",
    TalukPermanent: "",
    PresentAddress: false,
    Cast: "",
    CastFile: null,
    category: "",
    CompetitiveExamination: "Yes",
    CompetitiveExamName: "",
    Institute: "",
    ExamPreviously: "Yes",
    ExamDetailsRes: "",
    ExamWrite: "",
    workingRes: "Yes",
    Designation: "",
    Department: "",
    JoinGovt: "",
    AIASAStudent: "Yes",
    Year: "",
    CategoryAias: "",
    CourseAias: "",
    IDPhoto: null,
    original_aiasa_id_path: null,
    IDNumber: "",
    ClassType: "",
    CourseCate: "",
    CouresName: "",
    TeachMedium: "",
    Verify: false,
    admissionID: "",
    appliedOn: "",
    paidDate: "",
    BalanceFees: "",
    AdminStatus: "",
    section: "",
    comments: "",
    hallticket: "Yes",
    examcenter: "",
    ExamRegNo: "",
    hall_upload_path: "",
    hall_upload_original_path: "",
    examcenter_name: "",
    ExamName_passed: "",
    location_name: "",
    writtenexammark: "",
    Interviewmarks: "",
    overallrank: "",
    communalrank: "",
    typistrank: "",
    tamilrank: "",
    preferred_post: "",
    original_aadhar_path: null,
    original_profile_path: null,
    original_caste_path: null,
    paid_amount: 0,
    PPayment: "",
    old_stud_amount: "",
    old_stud_amount_applied: "",
    total_amount: "",
    paper_type: "",
    district: "",
    class_mode: "",
    district_name: "",
  });
  //date and time convert to normal data
  const dobCon = (userdate) => {
    if (!userdate) {
      return ""; // Handle empty input
    }
    const date = new Date(userdate);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ""; // Handle invalid date
    }
    // Extract the year, month, and day components
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const handleTransactionHistory = () => {
    if (USER_TYPE === USER_ADMIN) {
      navigate(-1);
    } else if (USER_TYPE === USER_STUDENT) {
      navigate("/TransactionHistory/2");
    } else {
      console.error("unhandled user-type");
    }
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  //use effect for load data

  //use effect for load data
  useEffect(() => {
    if (type !== "4") {
      setIsLoading(true);
      AxiosInstance.post("api/purchase/view", {
        user_id: user_id,
        admission_id: parseInt(add_id),
        type: parseInt(type),
        user_type: 1,
      })
        .then((res) => {
          const userinfo = res.data.data;
          const PersonDetails = {
            FirstName: userinfo.first_name,
            LastName: userinfo.last_name,
            Fathername: userinfo.father_name,
            MotherName: userinfo.mother_name,
            Profilephoto:
              userinfo.profile_path !== null ? userinfo.profile_path : null,
            DOB: dobCon(userinfo.dob),
            // //  DOB: "2000-07-29",
            age: userinfo.age,
            gender: userinfo.gender,
            MaritalStatus: userinfo.marital_status,
            aadharNo: userinfo.aadhar_no,
            aadharCardphoto:
              userinfo.aadhar_path !== null ? userinfo.aadhar_path : "",
            original_profile_path:
              userinfo.original_profile_path !== null
                ? userinfo.original_profile_path
                : null,
            original_aadhar_path:
              userinfo.original_aadhar_path !== null
                ? userinfo.original_aadhar_path
                : null,
            original_caste_path:
              userinfo.original_caste_path !== null
                ? userinfo.original_caste_path
                : null,
            original_sign_path:
              userinfo.original_specimen_signature_path !== null
                ? userinfo.original_specimen_signature_path
                : null,
            signaturePhoto:
              userinfo.specimen_signature_path !== null
                ? userinfo.specimen_signature_path
                : null,
            Cast: userinfo.community,
            CastFile:
              userinfo.community_path !== null
                ? userinfo.community_path.split("/").pop()
                : "",
            Mobile: userinfo.phone_no,
            WhatsAppNumber: userinfo.whatsapp_no,
            ContactNumber: userinfo.contact_type === 0 ? false : true,
            ParentMobileNo: userinfo.parent_mob_no,
            emailaddress: userinfo.email,
          };

          if (
            userinfo.Admission_details &&
            userinfo.Admission_details.length > 0
          )
            if (userinfo.scp.length > 0) {
              // New Added
              const Spc = userinfo.scp.map((el) => ({
                value: el,
                label: el,
              }));
              setAppCastLable(Spc);
            }
          {
            userinfo.Admission_details.map((imt) => {
              PersonDetails.admissionID = imt.admission_id;
              PersonDetails.appliedOn = imt.applied_on;
              PersonDetails.paidDate = imt.paid_amount;
              PersonDetails.BalanceFees = imt.balance_amt;
              PersonDetails.AdminStatus = imt.status;
              PersonDetails.section = imt.section;
              PersonDetails.comments = imt.feedback;
              PersonDetails.paid_amount = imt.paid_amount;
              PersonDetails.PPayment = imt.partial_payment;
              PersonDetails.old_stud_amount = imt.old_stud_disc;
              PersonDetails.old_stud_amount_applied = imt.old_stud_disc_applied;
            });

            const total__dis_amount_fees =
              userinfo.Admission_details[0].is_coupon !== 0
                ? userinfo.Admission_details[0].batch_fees -
                  (userinfo.Admission_details[0].batch_special_offer +
                    userinfo.Admission_details[0].coupon_amount)
                : userinfo.Admission_details[0].batch_fees -
                  userinfo.Admission_details[0].batch_special_offer;

            const gstamount_dis = (total__dis_amount_fees * GSTPRICE) / 100;

            const total_amount_to_pay = total__dis_amount_fees + gstamount_dis;

            PersonDetails.total_amount = total_amount_to_pay;
          }

          const SpecPrefrence = userinfo.scp;

          setSpecialAdmissionPre(SpecPrefrence);
          userinfo.address.map((obj) => {
            if (obj.address_type === 1) {
              PersonDetails.SamePresentAddress =
                obj.same_address === 0 ? true : false;
              PersonDetails.BuildingNumberPresent = obj.address1;
              PersonDetails.StreetNamePresent = obj.address2;
              PersonDetails.AreaPresent = obj.address3;
              PersonDetails.LandmarkPresent = obj.landmark;
              PersonDetails.districtPresent = obj.district_name;
              PersonDetails.CityPresent = obj.city;
              PersonDetails.statePresent = obj.state_name;
              PersonDetails.PostPresent = obj.post_name;
              PersonDetails.TalukPresent = obj.taluk;
              PersonDetails.PincodePresent = obj.postal_code;
            } else {
              PersonDetails.SamePresentAddress =
                obj.same_address === 0 ? true : false;
              PersonDetails.BuildingNumberPermanent = obj.address1;
              PersonDetails.StreetNamePermanent = obj.address2;
              PersonDetails.AreaPermanent = obj.address3;
              PersonDetails.LandmarkPermanent = obj.landmark;
              PersonDetails.districtPermanent = obj.district_name;
              PersonDetails.CityPermanent = obj.city;
              PersonDetails.statePermanent = obj.state_name;
              PersonDetails.PostPermanent = obj.post_name;
              PersonDetails.TalukPermanent = obj.taluk;
              PersonDetails.PincodePermanent = obj.postal_code;
            }
          });

          // firsttime load course when empty

          // console.log(userinfo,"koloo");

          let el = userinfo.Courses[0] ? userinfo.Courses[0] : userinfo.Courses;

          PersonDetails.CompetitiveExamination =
            el.competitive_exam === 1 ? "Yes" : "NO";
          PersonDetails.CompetitiveExamName = el.competitive_exam_name;
          PersonDetails.Institute = el.institute_name;
          PersonDetails.ExamPreviously =
            el.cleared_any_exam === 1 ? "Yes" : "NO";
          PersonDetails.ExamDetailsRes =
            el.exam_title !== null && el.exam_title !== ""
              ? el.exam_title.split(",")
              : "";
          PersonDetails.ExamWrite = el.exam_details;
          PersonDetails.workingRes = el.govt_emp === 1 ? "Yes" : "NO";
          PersonDetails.Designation = el.designation;
          PersonDetails.Department = el.department;
          PersonDetails.JoinGovt =
            el.govt_join_dt !== "" && el.govt_join_dt !== null
              ? dobCon(el.govt_join_dt)
              : "";
          PersonDetails.district = el.district;
          PersonDetails.AIASAStudent = el.aiasa_student === 1 ? "Yes" : "NO";
          PersonDetails.Year = el.aiasa_year;
          PersonDetails.CategoryAias = el.aiasa_category;
          PersonDetails.CourseAias = el.aiasa_course;
          PersonDetails.IDNumber = el.aiasa_id_number;
          PersonDetails.IDPhoto = el.aiasa_id_path;
          PersonDetails.original_aiasa_id_path = el.original_aiasa_id_path;
          PersonDetails.CourseCate = el.passed_exam_name;
          PersonDetails.hallticket = el.hall_ticket === 1 ? "Yes" : "No";
          PersonDetails.ExamRegNo = el.exam_reg_no;
          PersonDetails.examcenter = el.exam_center_id ? el.exam_center_id : "";
          PersonDetails.examcenter_name = el.exam_center_name
            ? el.exam_center_name
            : "";

          PersonDetails.location =
            el.location_id && el.location_id !== null && el.location_id !== ""
              ? el.location_id
              : "2";
          PersonDetails.location_name =
            el.location && el.location !== null && el.location !== ""
              ? el.location
              : "Kanchipuram";

          PersonDetails.ExamName_passed = el.passed_exam_name;
          PersonDetails.writtenexammark = el.written_exam_mark;
          PersonDetails.Interviewmarks = el.interview_mark;
          PersonDetails.overallrank = el.overall_rank;
          PersonDetails.communalrank = el.communal_rank;
          PersonDetails.typistrank = el.rank_in_typist;
          PersonDetails.tamilrank = el.marks_in_tamil;
          PersonDetails.markUpdatedMains = el.tot_marks_in_mains;
          PersonDetails.markUpdatedOral = el.tot_marks_in_oral_test;
          PersonDetails.MarksOne = el.marks_in_tier1;
          PersonDetails.MarksTwo = el.marks_in_tier2;
          PersonDetails.preferred_post = el.preferred_post;

          if (el.subject && el.subject.length > 0) {
            // Creating an array of objects in the desired format
            var result = el.subject.map((mmm) => ({
              label: mmm.name,
              value: mmm.name,
              pk: mmm.pk,
            }));
            // console.log(result,"reslt kxcxcvxcey");
            setSelectedSub(result);
          }

          if (userinfo.Courses.batch_details !== null) {
            PersonDetails.category = userinfo.Courses.batch_details.category;
            PersonDetails.CourseCate = userinfo.Courses.batch_details.course_id;
            PersonDetails.CouresName =
              userinfo.Courses.batch_details.course_name;
            PersonDetails.TeachMedium =
              userinfo.Courses.batch_details.batch_medium;
            PersonDetails.class_mode =
              userinfo.Courses.batch_details.class_mode;
          } else {
            PersonDetails.category = userinfo.Courses.category;
            PersonDetails.CourseCate = "";
            PersonDetails.CouresName = "";
            PersonDetails.TeachMedium = "";
            PersonDetails.class_mode = "";
          }

          const bath_id =
            userinfo.Courses.batch_details !== null
              ? {
                  batch_id: userinfo.Courses.batch_details.batch_id,
                }
              : null;

          const admiId = userinfo.Courses[0]
            ? {
                adId:
                  userinfo.Courses[0].batch_details !== null
                    ? userinfo.Courses[0].batch_details.ID
                    : null,
                is_paid: userinfo.Courses[0].is_paid,
                batch_id:
                  userinfo.Courses[0].batch_details !== null
                    ? userinfo.Courses[0].batch_details.batch_id
                    : null,
              }
            : {
                adId:
                  userinfo.Courses.batch_details !== null
                    ? userinfo.Courses.batch_details.ID
                    : null,
                is_paid: userinfo.Courses.is_paid,
                batch_id:
                  userinfo.Courses.batch_details !== null
                    ? userinfo.Courses.batch_details.batch_id
                    : null,
              };

          setAdmissionID(admiId);

          setList(PersonDetails);
          // setReadSpecialpre([]);
          // console.log(PersonDetails, "PersonDetails");

          // setReadSpecialpre(SpecPrefrence);

          let edq = userinfo.edu
            .filter((item) => item.qualification_type === "EDQ")
            .map((obj) => {
              let name = "";
              if (obj.name === "sslc") {
                name = "SSLC";
              } else if (obj.name === "hsc") {
                name = "HSC";
              } else {
                name = obj.name;
              }
              return name;
            });

          setEducationQualification(edq);
          let sslcCert = userinfo.edu
            .filter((item) => item.qualification_type === "EDQ")
            .map((el, index) => {
              if (el.name === "sslc") {
                const matchingCertificate = {};
                // if a matching certificate is found, update its properties
                matchingCertificate.name = el.name;
                matchingCertificate.SSLC_CNo = el.certificate_no;
                matchingCertificate.SSLC_Year = el.year_of_passing;
                matchingCertificate.SSLC_Marks = el.marks_percentage;
                matchingCertificate.SSLC_Eboard = el.edu_board;
                matchingCertificate.SSLC_Scl = el.institute;
                matchingCertificate.SSLC_MI = el.edu_medium;
                matchingCertificate.SSLC_Path = el.certificate_path;
                matchingCertificate.original_file_path = el.original_file_path;
                setSslcCerficates((prevState) => {
                  return prevState.map((q) => {
                    if (q.name === el.name) {
                      // return { ...q, matchingCertificate };
                      return matchingCertificate;
                    }
                    return q;
                  });
                });
              } else if (el.name === "hsc") {
                const matchingCertificate = {};
                // if a matching certificate is found, update its properties
                matchingCertificate.name = el.name;
                matchingCertificate.HSC_CNo = el.certificate_no;
                matchingCertificate.HSC_Year = el.year_of_passing;
                matchingCertificate.HSC_Marks = el.marks_percentage;
                matchingCertificate.HSC_Ebaord = el.edu_board;
                matchingCertificate.HSC_Scl = el.institute;
                matchingCertificate.HSC_MI = el.edu_medium;

                matchingCertificate.HSC_Path = el.certificate_path;
                matchingCertificate.original_file_path = el.original_file_path;
                setSslcCerficates((prevState) => {
                  return prevState.map((q) => {
                    if (q.name === el.name) {
                      // return { ...q, matchingCertificate };
                      return matchingCertificate;
                    }
                    return q;
                  });
                });
              } else if (el.name === "UG") {
                const matchingCertificate = {};

                // if a matching certificate is found, update its properties
                matchingCertificate.name = el.name;
                matchingCertificate.UGCertificateNumber = el.certificate_no;
                matchingCertificate.UGYearpassing = el.year_of_passing;
                matchingCertificate.UGDegree = el.degree_name;
                matchingCertificate.UGMajor = el.major_subject;
                matchingCertificate.UGMarksPercentage = el.marks_percentage;
                matchingCertificate.UGClassName = el.grade;
                matchingCertificate.UGNameInstitute = el.institute;

                matchingCertificate.UG_Path = el.certificate_path;
                matchingCertificate.original_file_path = el.original_file_path;
                setSslcCerficates((prevState) => {
                  return prevState.map((q) => {
                    if (q.name === el.name) {
                      // return { ...q, matchingCertificate };
                      return matchingCertificate;
                    }
                    return q;
                  });
                });
              } else if (el.name === "PG") {
                const matchingCertificate = {};
                // if a matching certificate is found, update its properties
                matchingCertificate.name = el.name;
                matchingCertificate.PGCertificateNumber = el.certificate_no;
                matchingCertificate.PGYearpassing = el.year_of_passing;
                matchingCertificate.PGMarksPercentage = el.marks_percentage;
                matchingCertificate.PGDegree = el.degree_name;
                matchingCertificate.PGMajor = el.major_subject;
                matchingCertificate.PGClassName = el.grade;
                matchingCertificate.PGNameInstitute = el.institute;

                matchingCertificate.PG_Path = el.certificate_path;
                matchingCertificate.original_file_path = el.original_file_path;
                setSslcCerficates((prevState) => {
                  return prevState.map((q) => {
                    if (q.name === el.name) {
                      return matchingCertificate;
                    }
                    return q;
                  });
                });
              } else if (el.name === "Integrated PG") {
                const matchingCertificate = {};

                // if a matching certificate is found, update its properties
                matchingCertificate.name = el.name;
                matchingCertificate.IPCertificateNumber = el.certificate_no;
                matchingCertificate.IPYearpassing = el.year_of_passing;
                matchingCertificate.IPDegree = el.degree_name;
                matchingCertificate.IPMajor = el.major_subject;
                matchingCertificate.IPMarksPercentage = el.marks_percentage;
                matchingCertificate.IPClassName = el.grade;
                matchingCertificate.IPNameInstitute = el.institute;

                matchingCertificate.IP_Path = el.certificate_path;
                matchingCertificate.original_file_path = el.original_file_path;
                setSslcCerficates((prevState) => {
                  return prevState.map((q) => {
                    if (q.name === el.name) {
                      // return { ...q, matchingCertificate };
                      return matchingCertificate;
                    }
                    return q;
                  });
                });
              } else if (el.name === "Diploma") {
                const matchingCertificate = {};

                // if a matching certificate is found, update its properties
                matchingCertificate.name = el.name;
                matchingCertificate.DCertificateNumber = el.certificate_no;
                matchingCertificate.DYearpassing = el.year_of_passing;
                matchingCertificate.DDegree = el.degree_name;
                matchingCertificate.DMajor = el.major_subject;
                matchingCertificate.DMarksPercentage = el.marks_percentage;
                matchingCertificate.DClassName = el.grade;
                matchingCertificate.DNameInstitute = el.institute;

                matchingCertificate.D_Path = el.certificate_path;
                matchingCertificate.original_file_path = el.original_file_path;
                setSslcCerficates((prevState) => {
                  return prevState.map((q) => {
                    if (q.name === el.name) {
                      // return { ...q, matchingCertificate };
                      return matchingCertificate;
                    }
                    return q;
                  });
                });
              } else if (el.name === "Professional Courses") {
                const matchingCertificate = {};

                // if a matching certificate is found, update its properties
                matchingCertificate.name = el.name;
                matchingCertificate.PCCertificateNumber = el.certificate_no;
                matchingCertificate.PCYearpassing = el.year_of_passing;
                matchingCertificate.PCDegree = el.degree_name;
                matchingCertificate.PCMajor = el.major_subject;
                matchingCertificate.PCMarksPercentage = el.marks_percentage;
                matchingCertificate.PCClassName = el.grade;
                matchingCertificate.PCNameInstitute = el.institute;

                matchingCertificate.PC_Path = el.certificate_path;
                matchingCertificate.original_file_path = el.original_file_path;
                setSslcCerficates((prevState) => {
                  return prevState.map((q) => {
                    if (q.name === el.name) {
                      // return { ...q, matchingCertificate };
                      return matchingCertificate;
                    }
                    return q;
                  });
                });
              } else if (el.name === "Others") {
                const matchingCertificate = {};

                // if a matching certificate is found, update its properties
                matchingCertificate.name = el.name;
                matchingCertificate.OtCertificateNumber = el.certificate_no;
                matchingCertificate.OtYearpassing = el.year_of_passing;
                matchingCertificate.OtDegree = el.degree_name;
                matchingCertificate.OtMajor = el.major_subject;
                matchingCertificate.OtMarksPercentage = el.marks_percentage;
                matchingCertificate.OtClassName = el.grade;
                matchingCertificate.OtNameInstitute = el.institute;
                matchingCertificate.Ot_Path = el.certificate_path;
                matchingCertificate.original_file_path = el.original_file_path;
                setSslcCerficates((prevState) => {
                  return prevState.map((q) => {
                    if (q.name === el.name) {
                      // return { ...q, matchingCertificate };
                      return matchingCertificate;
                    }
                    return q;
                  });
                });
              }
            });
          let teach = userinfo.edu
            .filter((item) => item.qualification_type === "THQ")
            .map((el, index) => {
              const Teachqu = {};

              // if a matching certificate is found, update its properties
              Teachqu.id = index === 0 ? 1 : index + 1;
              Teachqu.name = el.name;
              Teachqu.level = el.level;
              Teachqu.lang = el.edu_medium;
              Teachqu.Cnum = el.certificate_no;
              Teachqu.grade = el.grade;
              Teachqu.year =
                el.year_of_passing !== "" && el.year_of_passing !== null
                  ? el.year_of_passing
                  : "";
              Teachqu.file = el.certificate_path;
              Teachqu.original_file_path = el.original_file_path;
              return Teachqu;
            });
          setTechQualification(teach);
          // setReadbatch([]);

          // console.log(userinfo.Courses, "userinfo.Courses");

          const batchDe =
            userinfo.Courses.batch_details !== null
              ? {
                  batch_id: userinfo.Courses.batch_details.batch_id,
                  id: userinfo.Courses.batch_details.batch_id,
                  img_url: userinfo.Courses.batch_details.img_url,
                  course_name: userinfo.Courses.batch_details.course_name,
                  batch_name: userinfo.Courses.batch_details.batch_name,
                  course_id: userinfo.Courses.batch_details.course_id,
                  batch_details: userinfo.Courses.batch_details.batch_details,
                  class_mode: userinfo.Courses.batch_details.class_mode,
                  start_on: dobCon(userinfo.Courses.batch_details.start_on),
                  duration: userinfo.Courses.batch_details.duration,
                  act_c_fee: userinfo.Courses.batch_details.act_c_fee,
                  offer_spl: userinfo.Courses.batch_details.offer_spl,
                  dis_amt: userinfo.Courses.batch_details.dis_amt,
                  medium: userinfo.Courses.batch_details.medium,
                  is_selected: userinfo.Courses.batch_details.is_selected,
                  partial_payment:
                    userinfo.Courses.batch_details.partial_payment,
                  pay_1_perc: userinfo.Courses.batch_details.pay_1_perc,
                  pay_2_perc: userinfo.Courses.batch_details.pay_2_perc,
                  pay_3_perc: userinfo.Courses.batch_details.pay_3_perc,
                  pay_2_duedate: userinfo.Courses.batch_details.pay_2_duedate,
                  pay_3_duedate: userinfo.Courses.batch_details.pay_3_duedate,
                  old_stud_disc_applied:
                    userinfo.Courses.batch_details.old_stud_disc_applied,
                  tot_old_stud_amt:
                    userinfo.Courses.batch_details.tot_old_stud_amt,
                  exam_details:
                    userinfo.Courses.batch_details.exam_details &&
                    userinfo.Courses.batch_details.exam_details.exam_details &&
                    userinfo.Courses.batch_details.exam_details.exam_details
                      .length > 0
                      ? userinfo.Courses.batch_details.exam_details.exam_details
                      : [],
                  panel_details:
                    Object.keys(userinfo.Courses.batch_details.panel_details)
                      .length > 0
                      ? userinfo.Courses.batch_details.panel_details.panel_list
                      : [],
                  exam_fees:
                    Object.keys(userinfo.Courses.batch_details.exam_details)
                      .length > 0 &&
                    userinfo.Courses.batch_details.exam_details.exam_fees
                      .length > 0
                      ? userinfo.Courses.batch_details.exam_details.exam_fees
                      : Object.keys(
                          userinfo.Courses.batch_details.panel_details
                        ).length > 0
                      ? userinfo.Courses.batch_details.panel_details.exam_fees
                      : [],
                }
              : null;
          let ExamObj = [];
          batchDe.exam_details.map((el) => {
            ExamObj.push({
              pk: el.exam_detail_id,
              batch_id: el.batch_id,
              exam_title: el.exam_title,
              exam_title_id: el.exam_detail_id,
              started_dt: el.started_dt,
              batch_time: el.batch_time,
              duration_hrs: el.duration,
              no_of_papers: el.no_of_paper,
              syllabus_url: el.syllabus_url,
              is_selected_exam: el.is_selected_exam === 1 ? true : false,
            });
          });
          batchDe.exam_details = ExamObj;
          let PaneldetailsObj = [];
          batchDe.panel_details.map((el) => {
            PaneldetailsObj.push({
              pk: el.panel_pk,
              batch_id: el.batch_id,
              batch_type: el.batch_type,
              panel_name: el.panel_name,
              available_dates: el.available_dates,
              event_time: el.event_time,
              is_selected_panel: el.is_selected_panel == 1 ? true : false,
            });
          });
          batchDe.panel_details =
            PaneldetailsObj.length > 0 ? PaneldetailsObj : {};
          setBatchDetails([batchDe]);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          return error;
        });
    } else {
      setIsLoading(true);
      AxiosInstance.post("api/modelexam/view", {
        admission_id: add_id,
        type: parseInt(type),
        user_id: user_id,
      })
        .then((res) => {
          const userinfo = res.data.data;
          const PersonDetails = {
            FirstName: userinfo.first_name,
            LastName: userinfo.last_name,
            Fathername: userinfo.father_name,
            MotherName: userinfo.mother_name,
            Profilephoto:
              userinfo.profile_path !== null ? userinfo.profile_path : null,
            DOB: userinfo.dob !== null ? dobCon(userinfo.dob) : "",
            // //  DOB: "2000-07-29",
            age: userinfo.age,
            gender: userinfo.gender,
            MaritalStatus: userinfo.marital_status,
            aadharNo: userinfo.aadhar_no,
            aadharCardphoto:
              userinfo.aadhar_path !== null ? userinfo.aadhar_path : "",
            Cast: userinfo.community,
            CastFile:
              userinfo.community_path !== null
                ? userinfo.community_path.split("/").pop()
                : "",
            original_profile_path:
              userinfo.original_profile_path !== null
                ? userinfo.original_profile_path
                : null,
            original_aadhar_path:
              userinfo.original_aadhar_path !== null
                ? userinfo.original_aadhar_path
                : null,
            original_caste_path:
              userinfo.original_caste_path !== null
                ? userinfo.original_caste_path
                : null,
            original_sign_path:
              userinfo.original_specimen_signature_path !== null
                ? userinfo.original_specimen_signature_path
                : null,
            signaturePhoto:
              userinfo.specimen_signature_path !== null
                ? userinfo.specimen_signature_path
                : null,
            Mobile: userinfo.phone_no,
            WhatsAppNumber: userinfo.whatsapp_no,
            ContactNumber: userinfo.contact_type === 0 ? false : true,
            ParentMobileNo: userinfo.parent_mob_no,
            emailaddress: userinfo.email,
          };

          if (
            userinfo.Admission_details &&
            userinfo.Admission_details.length > 0
          )
            if (userinfo.scp.length > 0) {
              // New Added
              const Spc = userinfo.scp.map((el) => ({
                value: el,
                label: el,
              }));
              setAppCastLable(Spc);
            }

          if (userinfo.Admission_details.length > 0) {
            userinfo.Admission_details.map((imt) => {
              PersonDetails.admissionID = imt.admission_id;
              PersonDetails.appliedOn = imt.applied_on;
              PersonDetails.paidDate = imt.paid_amount;
              PersonDetails.BalanceFees = imt.balance_amt;
              PersonDetails.AdminStatus = imt.status;
              PersonDetails.section = imt.section;
              PersonDetails.comments = imt.feedback;
              PersonDetails.paid_amount = imt.paid_amount;
              PersonDetails.PPayment = imt.partial_payment;
              PersonDetails.old_stud_amount = imt.old_stud_disc;
              PersonDetails.old_stud_amount_applied = imt.old_stud_disc_applied;
            });

            const total__dis_amount_fees =
              userinfo.Admission_details[0].is_coupon !== 0
                ? userinfo.Admission_details[0].batch_fees -
                  (userinfo.Admission_details[0].batch_special_offer +
                    userinfo.Admission_details[0].coupon_amount)
                : userinfo.Admission_details[0].batch_fees -
                  userinfo.Admission_details[0].batch_special_offer;

            const gstamount_dis = (total__dis_amount_fees * GSTPRICE) / 100;

            const total_amount_to_pay = total__dis_amount_fees + gstamount_dis;

            PersonDetails.total_amount = total_amount_to_pay;
          }

          userinfo.address.map((obj) => {
            if (obj.address_type === 1) {
              PersonDetails.SamePresentAddress =
                obj.same_address === 0 ? true : false;
              PersonDetails.BuildingNumberPresent = obj.address1;
              PersonDetails.StreetNamePresent = obj.address2;
              PersonDetails.AreaPresent = obj.address3;
              PersonDetails.LandmarkPresent = obj.landmark;
              PersonDetails.districtPresent = obj.district_name;
              PersonDetails.CityPresent = obj.city;
              PersonDetails.statePresent = obj.state_name;
              PersonDetails.PostPresent = obj.post_name;
              PersonDetails.TalukPresent = obj.taluk;
              PersonDetails.PincodePresent = obj.postal_code;
            } else {
              PersonDetails.SamePresentAddress =
                obj.same_address === 0 ? true : false;
              PersonDetails.BuildingNumberPermanent = obj.address1;
              PersonDetails.StreetNamePermanent = obj.address2;
              PersonDetails.AreaPermanent = obj.address3;
              PersonDetails.LandmarkPermanent = obj.landmark;
              PersonDetails.districtPermanent = obj.district_name;
              PersonDetails.CityPermanent = obj.city;
              PersonDetails.statePermanent = obj.state_name;
              PersonDetails.PostPermanent = obj.post_name;
              PersonDetails.TalukPermanent = obj.taluk;
              PersonDetails.PincodePermanent = obj.postal_code;
            }
          });

          let teach = userinfo.tech_qualification
            .filter((item) => item.qualification_type === "THQ")
            .map((el, index) => {
              const Teachqu = {};
              Teachqu.id = index === 0 ? 1 : index + 1;
              Teachqu.TQ = el.name !== "" ? "0" : "1";
              Teachqu.name = el.name;
              Teachqu.level = el.level;
              Teachqu.lang = el.edu_medium;
              return Teachqu;
            });
          setTechQualification(teach);

          let el = userinfo.Courses[0] ? userinfo.Courses[0] : userinfo.Courses;

          PersonDetails.CompetitiveExamination =
            el.competitive_exam === 1 ? "Yes" : "NO";
          PersonDetails.CompetitiveExamName = el.competitive_exam_name;
          PersonDetails.Institute = el.institute_name;
          PersonDetails.paper_type = el.paper_type;
          PersonDetails.ExamPreviously =
            el.cleared_any_exam === 1 ? "Yes" : "NO";
          PersonDetails.ExamDetailsRes =
            el.exam_title !== null && el.exam_title !== ""
              ? el.exam_title.split(",")
              : "";
          PersonDetails.ExamWrite = el.exam_details;
          PersonDetails.workingRes = el.govt_emp === 1 ? "Yes" : "NO";
          PersonDetails.Designation = el.designation;
          PersonDetails.Department = el.department;
          PersonDetails.JoinGovt =
            el.govt_join_dt !== null && el.govt_join_dt !== ""
              ? dobCon(el.govt_join_dt)
              : "";
          PersonDetails.AIASAStudent = el.aiasa_student === 1 ? "Yes" : "NO";
          PersonDetails.Year = el.aiasa_year;
          PersonDetails.CategoryAias = el.aiasa_category;
          PersonDetails.CourseAias = el.aiasa_course;
          PersonDetails.IDNumber = el.aiasa_id_number;
          PersonDetails.IDPhoto = el.aiasa_id_path;
          PersonDetails.original_aiasa_id_path = el.original_aiasa_id_path;

          PersonDetails.CourseCate = el.passed_exam_name;
          PersonDetails.hallticket = el.hall_ticket === 1 ? "Yes" : "No";
          PersonDetails.ExamRegNo = el.exam_reg_no;
          PersonDetails.hall_upload_path =
            el.hall_ticket === 1 ? el.mainexam_hallticket_filepath : null;
          PersonDetails.hall_upload_original_path =
            el.hall_ticket === 1 ? el.original_mainexam_hallticket : null;
          PersonDetails.examcenter = el.exam_center_id;
          PersonDetails.examcenter_name = el.exam_center_name;
          // PersonDetails.district = el.district;
          PersonDetails.location =
            el.location_id && el.location_id !== null && el.location_id !== ""
              ? el.location_id
              : "2";
          PersonDetails.location_name =
            el.location && el.location !== null && el.location !== ""
              ? el.location
              : "Kanchipuram";
          PersonDetails.ExamName_passed = el.passed_exam_name;
          PersonDetails.writtenexammark = el.written_exam_mark;
          PersonDetails.Interviewmarks = el.interview_mark;
          PersonDetails.overallrank = el.overall_rank;
          PersonDetails.communalrank = el.communal_rank;
          PersonDetails.typistrank = el.rank_in_typist;
          PersonDetails.tamilrank = el.marks_in_tamil;
          PersonDetails.markUpdatedMains = el.tot_marks_in_mains;
          PersonDetails.markUpdatedOral = el.tot_marks_in_oral_test;
          PersonDetails.MarksOne = el.marks_in_tier1;
          PersonDetails.MarksTwo = el.marks_in_tier2;
          PersonDetails.preferred_post = el.preferred_post;

          if (userinfo.Courses.batch_details !== null) {
            PersonDetails.category = userinfo.Courses.batch_details.category;
            PersonDetails.CourseCate = userinfo.Courses.batch_details.course_id;
            PersonDetails.CouresName =
              userinfo.Courses.batch_details.course_name;
            PersonDetails.TeachMedium =
              userinfo.Courses.batch_details.batch_medium;
            PersonDetails.class_mode =
              userinfo.Courses.batch_details.class_mode;
            PersonDetails.district_name =
              userinfo.Courses.batch_details.district_name;
          } else {
            PersonDetails.category = "";
            PersonDetails.CourseCate = "";
            PersonDetails.CouresName = "";
            PersonDetails.TeachMedium = "";
            PersonDetails.district_name = "";
          }

          const bath_id =
            userinfo.Courses.batch_details !== null
              ? {
                  batch_id: userinfo.Courses.batch_details.batch_id,
                }
              : null;

          const admiId = userinfo.Courses[0]
            ? {
                adId:
                  userinfo.Courses[0].batch_details !== null
                    ? userinfo.Courses[0].batch_details.ID
                    : null,
                is_paid: userinfo.Courses[0].is_paid,
                batch_id:
                  userinfo.Courses[0].batch_details !== null
                    ? userinfo.Courses[0].batch_details.batch_id
                    : null,
              }
            : {
                adId:
                  userinfo.Courses.batch_details !== null
                    ? userinfo.Courses.batch_details.ID
                    : null,
                is_paid: userinfo.Courses.is_paid,
                batch_id:
                  userinfo.Courses.batch_details !== null
                    ? userinfo.Courses.batch_details.batch_id
                    : null,
              };

          setAdmissionID(admiId);

          setList(PersonDetails);

          const batchDe =
            userinfo.Courses.batch_details !== null
              ? {
                  batch_id: userinfo.Courses.batch_details.batch_id,
                  id: userinfo.Courses.batch_details.batch_id,
                  name: userinfo.Courses.batch_details.name,
                  paper_type: userinfo.Courses.paper_type
                    ? userinfo.Courses.paper_type
                    : 0,
                  is_selected_all:
                    userinfo.Courses.batch_details.is_selected_all,
                  img_url: userinfo.Courses.batch_details.img_url,
                  course_name: userinfo.Courses.batch_details.course_name,
                  course_id: userinfo.Courses.batch_details.course_id,
                  batch_details: userinfo.Courses.batch_details.batch_details,
                  class_mode: userinfo.Courses.batch_details.class_mode,
                  start_on: dobCon(userinfo.Courses.batch_details.start_on),
                  duration: userinfo.Courses.batch_details.duration,
                  act_c_fee: userinfo.Courses.batch_details.act_c_fee,
                  offer_spl: userinfo.Courses.batch_details.offer_spl,
                  dis_amt: userinfo.Courses.batch_details.dis_amt,
                  medium: userinfo.Courses.batch_details.medium,
                  is_selected: userinfo.Courses.batch_details.is_selected,
                  no_of_test: userinfo.Courses.batch_details.no_of_test,
                  batch_name: userinfo.Courses.batch_details.batch_name,
                  partial_payment:
                    userinfo.Courses.batch_details.partial_payment,
                  pay_1_perc: userinfo.Courses.batch_details.pay_1_perc,
                  pay_2_perc: userinfo.Courses.batch_details.pay_2_perc,
                  pay_3_perc: userinfo.Courses.batch_details.pay_3_perc,
                  pay_2_duedate: userinfo.Courses.batch_details.pay_2_duedate,
                  pay_3_duedate: userinfo.Courses.batch_details.pay_3_duedate,
                  is_selected: userinfo.Courses.batch_details.is_selected,
                  exam_details:
                    Object.keys(userinfo.Courses.batch_details.exam_details)
                      .length > 0
                      ? userinfo.Courses.batch_details.exam_details.exam_details
                      : [],
                  exam_fees:
                    Object.keys(userinfo.Courses.batch_details.exam_details)
                      .length > 0
                      ? userinfo.Courses.batch_details.exam_details.exam_fees
                      : [],
                }
              : null;

          let ExamObj = [];
          batchDe.exam_details.map((el) => {
            ExamObj.push({
              pk: el.exam_detail_id,
              batch_id: el.batch_id,
              exam_title: el.exam_title,
              exam_title_id: el.exam_detail_id,
              started_dt: el.started_dt,
              batch_time: el.batch_time,
              duration_hrs: el.duration,
              no_of_papers: el.no_of_paper,
              syllabus_url: el.syllabus_url,
              last_dt_apply: el.last_dt_apply,
              is_selected_exam: el.is_selected_exam === 1 ? true : false,
              papers: el.papers.length > 0 ? el.papers : [],
            });
          });

          batchDe.exam_details = ExamObj;
          // let PaneldetailsObj = [];
          // batchDe.panel_details.map((el) => {
          //   PaneldetailsObj.push({
          //     pk: el.panel_pk,
          //     batch_id: el.batch_id,
          //     batch_type: el.batch_type,
          //     panel_name: el.panel_name,
          //     available_dates: el.available_dates,
          //     event_time: el.event_time,
          //     is_selected_panel: el.is_selected_panel == 1 ? true : false,
          //   });
          // });
          // batchDe.panel_details =
          //   PaneldetailsObj.length > 0 ? PaneldetailsObj : {};

          //   console.log(batchDe,"cvfgt" , userinfo.Courses.batch_details !== null)

          setBatchDetails([batchDe]);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          return error;
        });
    }
  }, [user_id]);

  useEffect(() => {
    let extractedPart = null;
    // const bb_id = add_id.indexOf("P");
    // if (bb_id !== -1) {
    //   // If "P" is found in the string
    //   extractedPart = add_id.slice(bb_id + 2); // Extract part after "P"
    //   // console.log(extractedPart); // Outputs: "061"
    // }
    AxiosInstance.post("api/course/admission/chat/comm/list", {
      user_id: user_id,
      admission_id: parseInt(add_id),
      type: parseInt(type), // always
    })
      .then((res) => {
        // console.log(res);
        if (res.data.status === true) {
          const comSec = res.data.data.map((el) => ({
            user_type: el.user_type,
            admission_id: el.admission_id,
            comment: el.comment,
            updated_dt: el.updated_dt,
          }));
          setCommentSec(comSec);
        }
      })
      .catch((error) => {
        return error;
      });
  }, [user_id]);

  return isLoading ? (
    <div
      className="loaderApi"
      style={{
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        zIndex: 999,
      }}
    >
      {isLoading && <Loader />}
    </div>
  ) : (
    <div className="Purchase">
      <div className="row">
        <div className="col-md-12">
          <section className="sectionSix">
            <div className="heading dflexbetww flex_colum ">
              <div className=" mt-4 dbetw">
                <h4 className="Title">View Admission</h4>
                <Link
                  to="javascript:void(0)"
                  onClick={handleTransactionHistory}
                  className="CancelButton MobileShow mt-0"
                >
                  Back to View Transaction History
                </Link>
              </div>
              <div className="d-flex smButton align-items-center flex_colum  mobiledown mt-4">
                {USER_TYPE === USER_STUDENT && (
                  <Link
                    to="javascript:void(0)"
                    onClick={handleTransactionHistory}
                    className="CancelButton MobileHide"
                  >
                    Back to View Transaction History
                  </Link>
                )}

                {USER_TYPE === USER_ADMIN && type === "0" && (
                  <Link
                    to="javascript:void(0)"
                    onClick={handleTransactionHistory}
                    className="CancelButton MobileHide"
                  >
                    Back to View Course Batch
                  </Link>
                )}

                {USER_TYPE === USER_ADMIN && type === "1" && (
                  <Link
                    to="javascript:void(0)"
                    onClick={handleTransactionHistory}
                    className="CancelButton MobileHide"
                  >
                    Back to View Test Batch
                  </Link>
                )}

                {USER_TYPE === USER_ADMIN && type === "3" && (
                  <Link
                    to="javascript:void(0)"
                    onClick={handleTransactionHistory}
                    className="CancelButton MobileHide"
                  >
                    Back to View SpecialClasses Batch
                  </Link>
                )}

                {USER_TYPE === USER_ADMIN && type === "2" && (
                  <Link
                    to="javascript:void(0)"
                    onClick={handleTransactionHistory}
                    className="CancelButton MobileHide"
                  >
                    Back to View Counselling Batch
                  </Link>
                )}

                {USER_TYPE === USER_ADMIN && type === "5" && (
                  <Link
                    to="javascript:void(0)"
                    onClick={handleTransactionHistory}
                    className="CancelButton MobileHide"
                  >
                    Back to View Mockinterview Batch
                  </Link>
                )}

                {USER_TYPE === USER_ADMIN && type === "4" && (
                  <Link
                    to="javascript:void(0)"
                    onClick={handleTransactionHistory}
                    className="CancelButton MobileHide"
                  >
                    {label === "1"
                      ? "Back To Generate Report"
                      : label === "2"
                      ? "Back to View Model Exam Batch"
                      : "Back to View Model Exam Batch"}
                  </Link>
                )}

                {list.AdminStatus !== 1 &&
                  list.AdminStatus !== null &&
                  list.AdminStatus !== "" && (
                    <button
                      type="button"
                      className="SaveButton mt-lg-3 mt-md-3 me-lg-3"
                    >
                      <span class="material-icons">border_color</span>
                      {(type === "0" || type === 0) && (
                        <Link
                          className=""
                          to={`/Admission/Course/${list.AdminStatus}/${user_id}/${add_id}`}
                        >
                          Edit Admission Form
                        </Link>
                      )}
                      {(type === 1 || type === "1") && (
                        <Link
                          className=""
                          to={`/Admission/TestBatch/${list.AdminStatus}/${user_id}/${add_id}`}
                        >
                          Edit Admission Form
                        </Link>
                      )}
                      {(type === 3 || type === "3") && (
                        <Link
                          className=""
                          to={`/Admission/SpecialClasses/${list.AdminStatus}/${user_id}/${add_id}`}
                        >
                          Edit Admission Form
                        </Link>
                      )}
                      {(type === 5 || type === "5") && (
                        <Link
                          className=""
                          to={`/Admission/Mockinterview/${list.AdminStatus}/${user_id}/${add_id}`}
                        >
                          Edit Admission Form
                        </Link>
                      )}
                      {(type === 2 || type === "2") && (
                        <Link
                          className=""
                          to={`/Admission/Counselling/${list.AdminStatus}/${user_id}/${add_id}`}
                        >
                          Edit Admission Form
                        </Link>
                      )}
                      {(type === 4 || type === "4") && (
                        <Link
                          className=""
                          to={`/Admission/Modelexam/${list.AdminStatus}/${user_id}/${add_id}`}
                        >
                          Edit Admission Form
                        </Link>
                      )}
                    </button>
                  )}
                {USER_TYPE !== USER_ADMIN && (
                  <button type="button" className="SaveButton  mt-md-3 me-lg-3">
                    <span class="material-icons">visibility</span>
                    <Link
                      className=""
                      to={`/TransactionHistory/ViewAdmission/ViewPaymentHistory/${type}/${add_id}`}
                    >
                      View Payment History
                    </Link>
                  </button>
                )}
                {USER_TYPE !== USER_ADMIN && (
                  <button
                    disabled={list.AdminStatus !== 1 ? false : true}
                    type="button"
                    className="SaveButtons  mt-md-3"
                  >
                    <span class="material-icons">visibility</span>
                    <Link
                      className=""
                      to={
                        list.AdminStatus === 1
                          ? `/TransactionHistory/ViewAdmission/ViewSchedule/${batch_id}/${type}`
                          : "javascript:void(0)" // Non-clickable link for AdminStatus = 1
                      }
                    >
                      View Schedule
                    </Link>
                  </button>
                )}
              </div>
            </div>

            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Basic Details</p>

                <div className="row">
                  <div className="col-md-9 col-lg-9">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            First Name
                          </label>
                          <span className="ReviewLabel">
                            {list.FirstName || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Last Name
                          </label>
                          <span className="ReviewLabel">
                            {list.LastName || "-"}
                          </span>
                        </div>
                      </div>

                      {type === "4" && (
                        <div className="col-lg-4 col-md-12">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Father's Name/Guardian's Name
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {list.Fathername || "-"}
                            </span>
                          </div>
                        </div>
                      )}
                      {type === "4" && (
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Age
                            </label>
                            <span className="ReviewLabel">
                              {list.age || "-"}
                            </span>
                          </div>
                        </div>
                      )}
                      {type !== "4" && (
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Mother's Name
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {list.MotherName || "-"}
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Date of Birth
                          </label>
                          <span className="ReviewLabel">
                            {list.DOB
                              ? moment(list.DOB).format("DD/MM/YYYY")
                              : "_" || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Gender
                          </label>
                          <span className="ReviewLabel">
                            {list.gender || "-"}
                          </span>
                        </div>
                      </div>
                      {type !== "4" && (
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Age
                            </label>
                            <span className="ReviewLabel">
                              {list.age || "-"}
                            </span>
                          </div>
                        </div>
                      )}

                      {type !== "4" && (
                        <div className="col-md-4">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Phone Number
                            </label>
                            <span className="ReviewLabel">
                              {list.Mobile || "-"}
                            </span>
                          </div>
                        </div>
                      )}
                      {type !== "4" && (
                        <div className="col-md-4">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              WhatsApp Number
                            </label>
                            <span className="ReviewLabel">
                              {list.WhatsAppNumber || "-"}
                            </span>
                          </div>
                        </div>
                      )}

                      {type === "4" && (
                        <div className="col-lg-4 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Marital Status
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {list.MaritalStatus || "-"}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3">
                    <div className="forms mb-2  ">
                      <div id="profilephotos">
                        {list.Profilephoto && (
                          <img src={`${ImageUrlPrefix}/${list.Profilephoto}`} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {type === "4" && (
            <div className="row">
              <div className="col-md-6">
                <section className="sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">ID Proofs</p>

                      <div className="row mb-3">
                        <div className="col-lg-4 col-md-12">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              AADHAR Number
                            </label>
                            <span className="ReviewLabel">
                              {list.aadharNo || "-"}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-8 col-md-12">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Upload AADHAR Card
                              {list.aadharCardphoto && (
                                <a
                                  target="_balnk"
                                  download
                                  href={`${ImageUrlPrefix}/${list.aadharCardphoto}`}
                                >
                                  {" "}
                                  <Download className="download_icon" />
                                </a>
                              )}
                            </label>
                            <span className="ReviewLabel">
                              {list.original_aadhar_path || "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {/* ---------New Added-------(Signature)----- */}
              {type === "4" && (
                <div className="col-md-6">
                  <section className="sectionSix ">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">Specimen Signature</p>

                        <div className="row mb-3">
                          <div className="col-lg-12 col-md-12">
                            <div className="forms ">
                              <img
                                className="SignatureFile"
                                src={`${ImageUrlPrefix}/${list.signaturePhoto}`}
                                alt="Imagefor signature"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              )}

              <div className="col-md-6">
                <section className="sectionSix ">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">Communal Reservation Category</p>

                      <div className="row mb-3">
                        {type === "4" && (
                          <div className="col-lg-4 col-md-12">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Category
                              </label>
                              <span className="ReviewLabel">
                                {list.Cast || "-"}
                              </span>
                            </div>
                          </div>
                        )}

                        {type !== "4" && (
                          <div className="col-lg-8 col-md-12">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Uploaded Certificate
                                {list.CastFile && (
                                  <a
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${list.CastFile}`}
                                  >
                                    {" "}
                                    <Download className="download_icon" />
                                  </a>
                                )}
                              </label>
                              <span className="ReviewLabel">
                                {list.original_caste_path || "-"}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              {/* New Added -----------(Applicable Special Category Preferences) */}
              {type === "4" && (
                <div className="col-md-6">
                  <section className="sectionSix ">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">
                          Applicable Special Category Preferences
                        </p>

                        <div className="row mb-3">
                          <div className="col-lg-12 col-md-12">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Category
                              </label>
                              <span className="ReviewLabel">
                                {/* New Added----(Check this once your side lalitha) */}
                                {appCastLable.length > 0
                                  ? appCastLable.map((el) => el.label).join(",")
                                  : "_" || "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              )}
            </div>
          )}

          {type !== "4" && (
            <div className="row">
              {specialAdmissionPre.length > 0 &&
                specialAdmissionPre.map((el, index) => (
                  <div
                    className={
                      specialAdmissionPre.length % 2 !== 0
                        ? specialAdmissionPre.length - 1 === index
                          ? "col-md-12"
                          : "col-md-6 "
                        : "col-md-6 "
                    }
                    key={index}
                  >
                    <section className="sectionSix">
                      <div className="row box">
                        <div className="col-md-12">
                          <p className="StepTitle">
                            Special Category{" "}
                            {specialAdmissionPre.length === 1
                              ? " "
                              : `- ${el.id}`}
                          </p>
                          <div className="row mb-3">
                            <div className="col-md-4">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Category
                                </label>
                                <span className="ReviewLabel">
                                  {el.name || "-"}
                                </span>
                              </div>
                            </div>
                            {el.file !== "" && el.file !== null && (
                              <div className="col-lg-8 col-md-12 d-flex justify-content-lg-center">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Uploaded Certificate
                                    {el.file !== "" && el.file !== null && (
                                      <a
                                        target="_blank"
                                        href={`${ImageUrlPrefix}/${el.file}`}
                                      >
                                        {" "}
                                        <Download className="download_icon" />
                                      </a>
                                    )}
                                  </label>
                                  <span className="ReviewLabel">
                                    {el.file !== null
                                      ? el.original_pref_path
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            )}

                            {el.path_sslc !== "" && el.path_sslc !== null && (
                              <div className="col-lg-8 col-md-12 d-flex justify-content-lg-center">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Uploaded Certificate for sslc
                                    {el.path_sslc !== "" &&
                                      el.path_sslc !== null && (
                                        <a
                                          target="_blank"
                                          href={`${ImageUrlPrefix}/${el.path_sslc}`}
                                        >
                                          {" "}
                                          <Download className="download_icon" />
                                        </a>
                                      )}
                                  </label>
                                  <span className="ReviewLabel">
                                    {el.path_sslc !== null
                                      ? el.original_path_sslc
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            )}

                            {el.path_hsc !== "" && el.path_hsc !== null && (
                              <div className="col-lg-8 col-md-12 d-flex justify-content-lg-center">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Uploaded Certificate for HSC
                                    {el.path_hsc !== "" &&
                                      el.path_hsc !== null && (
                                        <a
                                          target="_blank"
                                          href={`${ImageUrlPrefix}/${el.path_hsc}`}
                                        >
                                          {" "}
                                          <Download className="download_icon" />
                                        </a>
                                      )}
                                  </label>
                                  <span className="ReviewLabel">
                                    {el.path_sslc !== null
                                      ? el.original_path_hsc
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            )}

                            {el.path_ug !== "" && el.path_ug !== null && (
                              <div className="col-lg-8 col-md-12 d-flex justify-content-lg-center">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Uploaded Certificate for UG
                                    {el.path_ug !== "" &&
                                      el.path_ug !== null && (
                                        <a
                                          target="_blank"
                                          href={`${ImageUrlPrefix}/${el.path_ug}`}
                                        >
                                          {" "}
                                          <Download className="download_icon" />
                                        </a>
                                      )}
                                  </label>
                                  <span className="ReviewLabel">
                                    {el.path_sslc !== null
                                      ? el.original_path_ug
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            )}

                            {el.path_pg !== "" && el.path_pg !== null && (
                              <div className="col-lg-8 col-md-12 d-flex justify-content-lg-center">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Uploaded Certificate for PG
                                    {el.path_pg !== "" &&
                                      el.path_pg !== null && (
                                        <a
                                          target="_blank"
                                          href={`${ImageUrlPrefix}/${el.path_pg}`}
                                        >
                                          {" "}
                                          <Download className="download_icon" />
                                        </a>
                                      )}
                                  </label>
                                  <span className="ReviewLabel">
                                    {el.path_sslc !== null
                                      ? el.original_path_pg
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                ))}
            </div>
          )}
          {/* -------New Added ------ (Technical Qualification )--------) */}

          {type === "4" && (
            <div className="row">
              {techQualification.length > 0 &&
                techQualification.map((el, index) => (
                  <div
                    className={
                      techQualification.length === 1
                        ? "col-md-12 "
                        : "col-md-12 "
                    }
                    key={index}
                  >
                    <section className=" sectionSix">
                      <div className="row box ">
                        <div className="col-md-12 ">
                          <p className="StepTitle">
                            Technical Qualification{" "}
                            {techQualification.length === 1
                              ? " "
                              : `- ${el.id}`}
                          </p>

                          <div className="row mb-3">
                            {type === "4" && (
                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted  oneline"
                                  >
                                    Do you possess any technical qualification?
                                  </label>
                                  <span className="ReviewLabel">
                                    {el.TQ === "0" ? "Yes" : "No" || "_"}
                                  </span>
                                </div>
                              </div>
                            )}
                            {type === "4" && el.TQ === "0" && (
                              <div className="col-md-3">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Technical Course
                                  </label>
                                  <span className="ReviewLabel">
                                    {el.TQ === "0" && el.name
                                      ? el.name === "Others"
                                        ? el.level
                                        : el.name || "_"
                                      : "_"}
                                  </span>
                                </div>
                              </div>
                            )}
                            {type === "4" &&
                              el.TQ === "0" &&
                              el.name !== "Others" && (
                                <div className="col-md-3">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      Typewriting Level
                                    </label>
                                    <span className="ReviewLabel">
                                      {el.TQ === "0" && el.level
                                        ? el.level
                                        : "_"}
                                    </span>
                                  </div>
                                </div>
                              )}
                            {type === "4" &&
                              el.TQ === "0" &&
                              el.name !== "Others" && (
                                <div className="col-md-3">
                                  <div className="forms ">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label text-muted "
                                    >
                                      Language
                                    </label>
                                    <span className="ReviewLabel">
                                      {el.TQ === "0" && el.lang ? el.lang : "_"}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                ))}
            </div>
          )}

          {type === "4" && (
            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Contact details</p>

                  <div className="row mb-3">
                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Phone Number
                        </label>
                        <span className="ReviewLabel">
                          {list.Mobile || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          WhatsApp Number
                        </label>
                        <span className="ReviewLabel">
                          {list.WhatsAppNumber || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Father/Guardian Phone Number
                        </label>
                        <span className="ReviewLabel">
                          {list.ParentMobileNo || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Email ID
                        </label>
                        <span className="ReviewLabel">
                          {list.emailaddress || "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          <div className="row">
            <div className="col-md-6">
              <section className="sectionSix">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Present Address</p>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Present Address
                          </label>
                        </div>
                      </div>

                      <span className="ReviewLabel mb-2">
                        {" "}
                        {list.BuildingNumberPresent ||
                          "-" + (list.BuildingNumberPresent ? "," : "") ||
                          ""}
                        {list.StreetNamePresent ||
                          "-" + (list.StreetNamePresent ? "," : "") ||
                          ""}
                      </span>
                      <span className="ReviewLabel  mb-2">
                        {" "}
                        {list.AreaPresent ||
                          "-" + (list.AreaPresent ? "," : "") ||
                          ""}
                        {list.LandmarkPresent ||
                          "-" + (list.LandmarkPresent ? "," : "") ||
                          ""}
                      </span>
                      <span className="ReviewLabel  mb-2">
                        {" "}
                        {list.TalukPresent ||
                          "-" + (list.TalukPresent ? "," : "") ||
                          ""}
                        {list.PostPresent ||
                          "-" + (list.PostPresent ? "," : "") ||
                          ""}
                      </span>

                      <span className="ReviewLabel  mb-2">
                        {list.districtPresent ||
                          "-" + (list.districtPresent ? "," : "") ||
                          ""}
                        {list.statePresent ||
                          "-" + (list.statePresent ? "," : "") ||
                          ""}
                      </span>
                      <span className="ReviewLabel  mb-2">
                        {list.CityPresent ||
                          "-" + (list.CityPresent ? "," : "") ||
                          ""}
                        {list.PincodePresent ||
                          "-" + (list.PincodePresent ? "" : "") ||
                          ""}
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="col-md-6">
              <section className="sectionSix ">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Permanent Address</p>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            {/* {list.SamePresentAddress === true
                              ? "Present Address"
                              : "Permanent Address"} */}
                            Permanent Address
                          </label>
                        </div>
                      </div>
                      <span className="ReviewLabel  mb-2">
                        {list.SamePresentAddress === true
                          ? list.BuildingNumberPresent ||
                            "-" + (list.BuildingNumberPresent ? "," : "")
                          : list.BuildingNumberPermanent ||
                            "-" + (list.BuildingNumberPermanent ? "," : "") ||
                            ""}
                        {list.SamePresentAddress === true
                          ? list.StreetNamePresent ||
                            "-" + (list.StreetNamePresent ? "," : "")
                          : list.StreetNamePermanent ||
                            "-" + (list.StreetNamePermanent ? "," : "") ||
                            ""}
                      </span>
                      <span className="ReviewLabel  mb-2">
                        {" "}
                        {list.SamePresentAddress === true
                          ? list.AreaPresent ||
                            "-" + (list.AreaPresent ? "," : "")
                          : list.AreaPermanent ||
                            "-" + (list.AreaPermanent ? "," : "") ||
                            ""}
                        {list.SamePresentAddress === true
                          ? list.LandmarkPresent ||
                            "-" + (list.LandmarkPresent ? "," : "")
                          : list.LandmarkPermanent ||
                            "-" + (list.LandmarkPermanent ? "," : "") ||
                            ""}
                      </span>
                      <span className="ReviewLabel  mb-2">
                        {" "}
                        {list.SamePresentAddress === true
                          ? list.TalukPresent ||
                            "-" + (list.TalukPresent ? "," : "")
                          : list.TalukPermanent ||
                            "-" + (list.TalukPermanent ? "," : "") ||
                            ""}
                        {list.SamePresentAddress === true
                          ? list.PostPresent ||
                            "-" + (list.PostPresent ? "," : "")
                          : list.PostPermanent ||
                            "-" + (list.PostPermanent ? "," : "") ||
                            ""}
                      </span>

                      <span className="ReviewLabel  mb-2">
                        {" "}
                        {list.SamePresentAddress === true
                          ? list.districtPresent ||
                            "-" + (list.districtPresent ? "," : "")
                          : list.districtPermanent ||
                            "-" + (list.districtPermanent ? "," : "") ||
                            ""}
                        {list.SamePresentAddress === true
                          ? list.statePresent ||
                            "-" + (list.statePresent ? "," : "")
                          : list.statePermanent ||
                            "-" + (list.statePermanent ? "," : "") ||
                            ""}
                      </span>
                      <span className="ReviewLabel  mb-2">
                        {" "}
                        {list.SamePresentAddress === true
                          ? list.CityPresent ||
                            "-" + (list.CityPresent ? "," : "")
                          : list.CityPermanent ||
                            "-" + (list.CityPermanent ? "," : "") ||
                            ""}
                        {list.SamePresentAddress === true
                          ? list.PincodePresent ||
                            "-" + (list.PincodePresent ? "," : "")
                          : list.PincodePermanent ||
                            "-" + (list.PincodePermanent ? "" : "") ||
                            ""}
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          {type === "4" && (
            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">
                    {/* -------------------Type was change as 4 to 1 in (Model Exam Enrollment-title)----------------- */}
                    {type === "4" && "Model Exam Enrollment"}
                    {type === "5" && "Mock Interview Enrollment"}{" "}
                    {type === "0" && "Program Enrollment"}
                    {type === "1" && "Test Batch Enrollment"}
                    {type === "3" && "Special class Enrollment"}
                  </p>

                  <div className="row mb-3">
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Category
                        </label>
                        <span className="ReviewLabel">
                          {" "}
                          {list.category || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Course
                        </label>
                        <span className="ReviewLabel">
                          {list.CouresName || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Medium of Instruction
                        </label>
                        <span className="ReviewLabel">
                          {list.TeachMedium || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Exam Center District
                        </label>
                        <span className="ReviewLabel">
                          {list.district_name || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Mode of Exam{" "}
                        </label>
                        <span className="ReviewLabel">
                          {list.class_mode === "ClassRoom"
                            ? "Offline"
                            : "Online" || "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* {type !== "4" && (
            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">
                  
                    {type === "4" && "Model Exam Enrollment"}
                    {type === "5" && "Mock Interview Enrollment"}{" "}
                    {type === "0" && "Program Enrollment"}
                    {type === "1" && "Test Batch Enrollment"}
                    {type === "3" && "Special class Enrollment"}
                  </p>

                  <div className="row mb-3">
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Category
                        </label>
                        <span className="ReviewLabel">
                          {" "}
                          {list.category || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Course
                        </label>
                        <span className="ReviewLabel">
                          {list.CouresName || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Medium of Instruction
                        </label>
                        <span className="ReviewLabel">
                          {list.TeachMedium || "-"}
                        </span>
                      </div>
                    </div>
                   
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Mode of Exam{" "}
                        </label>
                        <span className="ReviewLabel">
                          {list.classMode || "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )} */}

          {type !== "4" && (
            <div className="row">
              {educationQualification.includes("SSLC") && (
                <div
                  className={
                    educationQualification.includes("SSLC") &&
                    !educationQualification.includes("HSC")
                      ? " col-md-12"
                      : " col-md-6"
                  }
                >
                  <section className="sectionSix ">
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">SSLC</p>

                        <div className="row mb-3">
                          <div className="col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Certificate Number
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[0].SSLC_CNo || "-"}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                {" "}
                                Year of Passing
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[0].SSLC_Year || "-"}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                {" "}
                                Marks Percentage
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[0].SSLC_Marks || "-"}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                {" "}
                                Education Board
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[0].SSLC_Eboard || "-"}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                {" "}
                                Medium of Instruction
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[0].SSLC_MI || "-"}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                {" "}
                                School Name
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[0].SSLC_Scl || "-"}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                {" "}
                                Uploaded Certificate
                                {sslcCerficates[0].SSLC_Path && (
                                  <a
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${sslcCerficates[0].SSLC_Path}`}
                                  >
                                    {" "}
                                    <Download className="download_icon" />
                                  </a>
                                )}
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[0].SSLC_Path !== null
                                  ? sslcCerficates[0].original_file_path
                                  : "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>{" "}
                </div>
              )}
              {educationQualification.includes("HSC") && (
                <div
                  className={
                    !educationQualification.includes("SSLC") &&
                    educationQualification.includes("HSC")
                      ? " col-md-12"
                      : " col-md-6 "
                  }
                >
                  <section
                    className={`${
                      !educationQualification.includes("SSLC") &&
                      educationQualification.includes("HSC")
                        ? ""
                        : "widthForms"
                    } sectionSix`}
                  >
                    <div className="row box">
                      <div className="col-md-12">
                        <p className="StepTitle">HSC</p>

                        <div className="row mb-3">
                          <div className="col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                Certificate Number
                              </label>
                              <div className="ReviewLabel">
                                {sslcCerficates[1].HSC_CNo || "-"}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                {" "}
                                Year of Passing
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[1].HSC_Year || "-"}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                {" "}
                                Marks Percentage
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[1].HSC_Marks || "-"}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                {" "}
                                Education Board
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[1].HSC_Ebaord || "-"}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                {" "}
                                Medium of Instruction
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[1].HSC_MI || "-"}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                {" "}
                                School Name
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[1].HSC_Scl || "-"}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12">
                            <div className="forms ">
                              <label
                                htmlFor="inputName"
                                className="form-label text-muted "
                              >
                                {" "}
                                Uploaded Certificate
                                {sslcCerficates[1].HSC_Path && (
                                  <a
                                    target="_blank"
                                    href={`${ImageUrlPrefix}/${sslcCerficates[1].HSC_Path}`}
                                  >
                                    {" "}
                                    <Download className="download_icon" />
                                  </a>
                                )}
                              </label>
                              <span className="ReviewLabel">
                                {sslcCerficates[1].HSC_Path !== null
                                  ? sslcCerficates[1].original_file_path
                                  : "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              )}
            </div>
          )}

          {type !== "4" && educationQualification.includes("UG") && (
            <section className=" sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">UG</p>

                  <div className="row mb-3">
                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].UGCertificateNumber || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {" "}
                          {sslcCerficates[2].UGYearpassing || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].UGDegree || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].UGMajor || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks Percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].UGMarksPercentage || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].UGClassName || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].UGNameInstitute || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded UG Certificate
                          {sslcCerficates[2].UG_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[2].UG_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[2].UG_Path !== null
                            ? sslcCerficates[2].original_file_path
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {type !== "4" && educationQualification.includes("PG") && (
            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">PG</p>

                  <div className="row mb-3">
                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PGCertificateNumber || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {" "}
                          {sslcCerficates[3].PGYearpassing || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PGDegree || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PGMajor || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks Percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PGMarksPercentage || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PGClassName || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PGNameInstitute || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded Certificate
                          {sslcCerficates[3].PG_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[3].PG_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[3].PG_Path !== null
                            ? sslcCerficates[3].original_file_path
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {type !== "4" && educationQualification.includes("Integrated PG") && (
            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Integrated PG</p>

                  <div className="row mb-3">
                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].IPCertificateNumber || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].IPYearpassing || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].IPDegree || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].IPMajor || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks Percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].IPMarksPercentage || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].IPClassName || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].IPNameInstitute || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded Certificate
                          {sslcCerficates[4].IP_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[4].IP_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[4].IP_Path !== null
                            ? sslcCerficates[4].original_file_path
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {type !== "4" && educationQualification.includes("Diploma") && (
            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Diploma</p>

                  <div className="row mb-3">
                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].DCertificateNumber || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].DYearpassing || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].DDegree || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].DMajor || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks of percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].DMarksPercentage || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].DClassName || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].DNameInstitute || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded Certificate
                          {sslcCerficates[5].D_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[5].D_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[5].D_Path !== null
                            ? sslcCerficates[5].original_file_path
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {type !== "4" &&
            educationQualification.includes("Professional Courses") && (
              <section className="sectionSix">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Professional Courses</p>

                    <div className="row mb-3">
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Certificate Number
                          </label>
                          <span className="ReviewLabel">
                            {sslcCerficates[6].PCCertificateNumber || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Year of Passing
                          </label>
                          <span className="ReviewLabel">
                            {" "}
                            {sslcCerficates[6].PCYearpassing || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Degree
                          </label>
                          <span className="ReviewLabel">
                            {sslcCerficates[6].PCDegree || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Major
                          </label>
                          <span className="ReviewLabel">
                            {sslcCerficates[6].PCMajor || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Marks Percentage
                          </label>
                          <span className="ReviewLabel">
                            {sslcCerficates[6].PCMarksPercentage || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Class
                          </label>
                          <span className="ReviewLabel">
                            {sslcCerficates[6].PCClassName || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Name of the college / university
                          </label>
                          <span className="ReviewLabel">
                            {sslcCerficates[6].PCNameInstitute || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Uploaded Certificate
                            {sslcCerficates[6].PC_Path && (
                              <a
                                target="_blank"
                                href={`${ImageUrlPrefix}/${sslcCerficates[6].PC_Path}`}
                              >
                                {" "}
                                <Download className="download_icon" />
                              </a>
                            )}
                          </label>
                          <span className="ReviewLabel">
                            {sslcCerficates[6].PC_Path !== null
                              ? sslcCerficates[6].original_file_path
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

          {type !== "4" && educationQualification.includes("Others") && (
            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Others</p>

                  <div className="row mb-3">
                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Certificate Number
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[7].OtCertificateNumber || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Year of Passing
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[7].OtYearpassing || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Degree
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[7].OtDegree || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Major
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[7].OtMajor || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Marks Percentage
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[7].OtMarksPercentage || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Class
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[7].OtClassName || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Name of the college / university
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[7].OtNameInstitute || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Uploaded Certificate
                          {sslcCerficates[7].Ot_Path && (
                            <a
                              target="_blank"
                              href={`${ImageUrlPrefix}/${sslcCerficates[7].Ot_Path}`}
                            >
                              {" "}
                              <Download className="download_icon" />
                            </a>
                          )}
                        </label>
                        <span className="ReviewLabel">
                          {sslcCerficates[7].Ot_Path !== null
                            ? sslcCerficates[7].original_file_path
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {type !== "4" && (
            <div className="row">
              {techQualification.length > 0 &&
                !(
                  techQualification[0].name === "" &&
                  techQualification[0].level === "" &&
                  techQualification[0].lang === "" &&
                  techQualification[0].Cnum === "" &&
                  techQualification[0].grade === "" &&
                  techQualification[0].year === "" &&
                  techQualification[0].file === ""
                ) &&
                techQualification.map((el, index) => (
                  <div
                    className={
                      techQualification.length === 1
                        ? "col-md-12 "
                        : "col-md-12 "
                    }
                    key={index}
                  >
                    <section className=" sectionSix">
                      <div className="row box ">
                        <div className="col-md-12 ">
                          <p className="StepTitle">
                            Technical Qualification{" "}
                            {techQualification.length === 1
                              ? " "
                              : `- ${el.id}`}
                          </p>

                          <div className="row mb-3">
                            <div className="col-md-3">
                              <div className="forms ">
                                <label
                                  htmlFor="inputName"
                                  className="form-label text-muted "
                                >
                                  Technicnal Course
                                </label>
                                <span className="ReviewLabel">
                                  {el.name || "-"}
                                </span>
                              </div>
                            </div>
                            {el.name !== "NA" && (
                              <div className="col-md-3">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Typewriting Level
                                  </label>
                                  <span className="ReviewLabel">
                                    {el.level || "-"}
                                  </span>
                                </div>
                              </div>
                            )}
                            {el.name !== "NA" && (
                              <div className="col-md-3">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Language
                                  </label>
                                  <span className="ReviewLabel">
                                    {el.lang || "-"}
                                  </span>
                                </div>
                              </div>
                            )}

                            {el.name !== "NA" && (
                              <div className="col-md-3">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Certificate Number
                                  </label>
                                  <span className="ReviewLabel">
                                    {el.Cnum || "-"}
                                  </span>
                                </div>
                              </div>
                            )}
                            {el.name !== "NA" && (
                              <div className="col-md-3">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Grade
                                  </label>
                                  <span className="ReviewLabel">
                                    {el.grade || "-"}
                                  </span>
                                </div>
                              </div>
                            )}

                            {el.name !== "NA" && (
                              <div className="col-md-3">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Year of Passing
                                  </label>
                                  <span className="ReviewLabel">
                                    {el.year !== "" ? el.year : "-"}
                                  </span>
                                </div>
                              </div>
                            )}

                            {el.name !== "NA" && (
                              <div className="col-lg-3 col-md-6">
                                <div className="forms ">
                                  <label
                                    htmlFor="inputName"
                                    className="form-label text-muted "
                                  >
                                    Upload Certificate
                                    {el.file && (
                                      <a
                                        target="_blank"
                                        href={`${ImageUrlPrefix}/${el.file}`}
                                      >
                                        {" "}
                                        <Download className="download_icon" />
                                      </a>
                                    )}
                                  </label>
                                  <span className="ReviewLabel">
                                    {el.original_file_path || "-"}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                ))}
            </div>
          )}

          {type !== "2" && type !== "4" && (
            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">
                    {type === "4" && "Model Exam Enrollment"}
                    {type === "5" && "Mock Interview Enrollment"}{" "}
                    {type === "0" && "Program Enrollment"}
                    {type === "1" && "Test Batch Enrollment"}
                    {type === "3" && "Special class Enrollment"}
                  </p>

                  <div className="row mb-3">
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Category
                        </label>
                        <span className="ReviewLabel">
                          {" "}
                          {list.category || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Course
                        </label>
                        <span className="ReviewLabel">
                          {list.CouresName !== ""
                            ? list.CouresName
                            : couName !== ""
                            ? couName.coueName
                            : "-"}
                        </span>
                      </div>
                    </div>
                    {/* {type === "4" && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            District
                          </label>
                          <span className="ReviewLabel">
                            {list.district || "-"}
                          </span>
                        </div>
                      </div>
                    )} */}
                    {/* {type === "4" && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Exam Center
                          </label>
                          <span className="ReviewLabel">
                            {list.examcenter_name !==""
                              ? list.examcenter_name
                              : list.examcenter}
                          </span>
                        </div>
                      </div>
                    )} */}

                    {type === "5" && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Name of the Exam You Passed
                          </label>
                          <span className="ReviewLabel">
                            {list.ExamName_passed !== ""
                              ? list.ExamName_passed
                              : list.ExamName}
                          </span>
                        </div>
                      </div>
                    )}

                    {type !== "4" && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            location
                          </label>
                          <span className="ReviewLabel">
                            {list.location_name !== ""
                              ? list.location_name
                              : list.location}
                          </span>
                        </div>
                      </div>
                    )}

                    {selectedSub.length > 0 &&
                      type !== "4" &&
                      type !== "5" &&
                      type !== "2" && (
                        <div className="col-lg-6 col-md-12">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Subject
                            </label>
                            <span className="ReviewLabel">
                              {selectedSub.length > 0
                                ? selectedSub.map((el) => el.value).join(", ")
                                : null}
                            </span>
                          </div>
                        </div>
                      )}

                    {type !== "4" && type !== "5" && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Medium of Instruction
                          </label>
                          <span className="ReviewLabel">
                            {list.TeachMedium || "-"}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}
          {type === "2" && (
            <section className=" sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">
                    Counselling Awareness Program Enrollment
                  </p>

                  <div className="row mb-3">
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Category
                        </label>
                        <span className="ReviewLabel">
                          {" "}
                          {list.category || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Name of the Exam You Passed
                        </label>
                        <span className="ReviewLabel">
                          {list.ExamName_passed !== ""
                            ? list.ExamName_passed
                            : list.ExamName}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Exam Registration Number
                        </label>
                        <span className="ReviewLabel">
                          {" "}
                          {list.ExamRegNo || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Location
                        </label>
                        <span className="ReviewLabel">
                          {" "}
                          {list.location_name || "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {type === "2" && (
            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Exam Result Info</p>

                  <div className="row mb-3">
                    {list.writtenexammark !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Written Exam Mark
                          </label>
                          <span className="ReviewLabel">
                            {list.writtenexammark || "-"}
                          </span>
                        </div>
                      </div>
                    )}
                    {list.Interviewmarks !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Interview marks
                          </label>
                          <span className="ReviewLabel">
                            {list.Interviewmarks || "-"}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row mb-3">
                    {list.overallrank !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Overall rank
                          </label>
                          <span className="ReviewLabel">
                            {list.overallrank || "-"}
                          </span>
                        </div>
                      </div>
                    )}
                    {list.communalrank !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Communal Rank
                          </label>
                          <span className="ReviewLabel">
                            {list.communalrank || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.markPaperone !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Mark uptained in Paper I
                          </label>
                          <span className="ReviewLabel">
                            {list.markPaperone || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.markPaperTwo !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Mark uptained in Paper II
                          </label>
                          <span className="ReviewLabel">
                            {list.markPaperTwo || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.markpaperThree !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Mark uptained in Paper III
                          </label>
                          <span className="ReviewLabel">
                            {list.markpaperThree || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.markPaperFour !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Mark uptained in Paper IV
                          </label>
                          <span className="ReviewLabel">
                            {list.markPaperFour || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.markPaperFive !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Mark uptained in Paper V
                          </label>
                          <span className="ReviewLabel">
                            {list.markPaperFive || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.markPaperSix !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Mark uptained in Paper VI
                          </label>
                          <span className="ReviewLabel">
                            {list.markPaperSix || "-"}
                          </span>
                        </div>
                      </div>
                    )}
                    {list.markPaperSeven !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Mark uptained in Paper VII
                          </label>
                          <span className="ReviewLabel">
                            {list.markPaperSeven || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.markPaperEight !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Mark uptained in Paper VIII
                          </label>
                          <span className="ReviewLabel">
                            {list.markPaperEight || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.markPaperNine !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Mark uptained in Paper VIII
                          </label>
                          <span className="ReviewLabel">
                            {list.markPaperNine || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.markUpdatedMains !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Total marks uptained in mains
                          </label>
                          <span className="ReviewLabel">
                            {list.markUpdatedMains || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.markUpdatedOral !== null && (
                      <div className="col-md-4">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Total marks uptained marked uptained in oral test
                          </label>
                          <span className="ReviewLabel">
                            {list.markUpdatedOral || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.StenoRank !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Steno Rank
                          </label>
                          <span className="ReviewLabel">
                            {list.StenoRank || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.WindowRank !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Widow Rank
                          </label>
                          <span className="ReviewLabel">
                            {list.WindowRank || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.tamilrank !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Marks in Tamil
                          </label>
                          <span className="ReviewLabel">
                            {list.tamilrank || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.typistrank !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Typist Rank
                          </label>
                          <span className="ReviewLabel">
                            {list.typistrank || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.PSTMRank !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            PSTM Rank
                          </label>
                          <span className="ReviewLabel">
                            {list.PSTMRank || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.DRank !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Disabled Rank
                          </label>
                          <span className="ReviewLabel">
                            {list.DRank || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.MarksOne !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Marks in Tier I
                          </label>
                          <span className="ReviewLabel">
                            {list.MarksOne || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.MarksTwo !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Marls in Tier II
                          </label>
                          <span className="ReviewLabel">
                            {list.MarksTwo || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {list.tamilrank !== null && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Rank in Tamil Medium Reservation
                          </label>
                          <span className="ReviewLabel">
                            {list.tamilrank || "-"}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Preferred Post
                        </label>
                        <span className="ReviewLabel">
                          {" "}
                          {list.preferred_post || "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {Object.keys(batchDetails).length > 0 &&
            batchDetails[0] !== null &&
            type !== "4" &&
            type !== "5" &&
            type !== "2" && (
              <section className=" sectionSix">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Batch Details</p>
                    {batchDetails.map((el, index) => (
                      <div className="row" key={index}>
                        <div className="col-md-12 mt-3 mb-3">
                          <div className="card  text-dark">
                            <div className="card-body BatchImage">
                              <div className="row">
                                <div className="col-lg-2 col-md-6 col-sm-12">
                                  <img
                                    src={`${ImageUrlPrefix}/${el.img_url}`}
                                    id={el.id}
                                    alt="Imge"
                                  />
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 d-flex flex-column ImageText  justify-content-center">
                                  <span className="ReviewLabels">
                                    {el.course_name || ""} -{" "}
                                    {el.batch_name || ""}
                                  </span>
                                  <span className="ReviewLabel">
                                    {el.batch_details || "-"}
                                  </span>
                                </div>
                                <div className="col-lg-6 col-md-10 col-sm-12 col-xl-6 mt-2 ms-5 d-flex flex-column justify-content-center">
                                  <div className="row">
                                    <div className="col-md-12 col-sm-12 ">
                                      <div className="row">
                                        {type != "2" && (
                                          <div className="col-md-3">
                                            <li className="IconsImage">
                                              <span className="material-icons">
                                                laptop_chromebook
                                              </span>
                                              <div className="d-flex flex-column">
                                                <p className="title ms-1">
                                                  Class Mode
                                                </p>
                                                <span className="ReviewLabelss">
                                                  {el.class_mode || "-"}
                                                </span>
                                              </div>
                                            </li>
                                          </div>
                                        )}

                                        <div className="col-md-3">
                                          <li className="IconsImage">
                                            <span className="material-icons">
                                              event
                                            </span>
                                            <div className="d-flex flex-column ">
                                              <p className="title ms-1">
                                                Starts On
                                              </p>
                                              <span className="ReviewLabelss">
                                                {el.start_on !== ""
                                                  ? moment
                                                      .utc(el.start_on)
                                                      .format("DD/MM/YYYY")
                                                  : ""}
                                              </span>
                                            </div>
                                          </li>
                                        </div>
                                        {type != "2" && (
                                          <div className="col-md-3">
                                            <li className="IconsImage">
                                              <span className="material-icons-outlined">
                                                timer
                                              </span>
                                              <div className="d-flex flex-column">
                                                <p className="title ms-1">
                                                  Duration
                                                </p>
                                                <span className="ReviewLabelss">
                                                  {el.duration || "-"}
                                                </span>
                                              </div>
                                            </li>
                                          </div>
                                        )}

                                        <div className="col-md-3">
                                          <li className="IconsImage">
                                            <span className="material-icons">
                                              currency_rupee
                                            </span>
                                            <div className="d-flex flex-column">
                                              <p className="title ms-1">
                                                Course Fee
                                              </p>
                                              <span className="ReviewLabelss">
                                                {el.act_c_fee || "-"}
                                              </span>
                                            </div>
                                          </li>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            )}
          {/* ----------------------------New Added -(Batch Details (M1) type Design --------------) */}

          {/* ----------------------------New Added -(Batch Details (M2) type Design --------------) */}

          {/* ---------Over--------- */}

          {Object.keys(batchDetails).length > 0 &&
            batchDetails[0] !== null &&
            type === "4" && (
              // <section className="sectionSix">
              //   <div className="row box">
              //     <div className="col-md-12">
              //       <p className="StepTitle">Batch Details</p>
              //       {batchDetails
              //         .filter((item) => {
              //           if (item.is_selected === true) {
              //             item.exam_details = item.exam_details.filter(
              //               (el) => el.is_selected_exam === true
              //             );
              //             return item;
              //           }
              //         })
              //         .map((el, index) => (
              //           <div className="row mt-3 mb-3" key={index}>
              //             <div className="col-md-12">
              //               <div className="card  text-dark" key={index}>
              //                 <div className="card-body BatchImage">
              //                   <div className="row">
              //                     <div className="col-md-5 d-flex flex-column align-items-center">
              //                       <div className="row">
              //                         <div className="col-lg-12 col-md-12 col-sm-12 ">
              //                           <img
              //                             className="mt-3"
              //                             src={`${ImageUrlPrefix}/${el.img_url}`}
              //                             id={el.batch_id}
              //                             alt="Imge"
              //                           />
              //                           <p className="title m-0  mt-3">
              //                             {el.course_name || "-"} {"-"}{" "}
              //                             {el.batch_name || "-"}
              //                           </p>
              //                         </div>
              //                       </div>
              //                     </div>
              //                     <div className="col-lg-7 mt-3 mb-2 col-md-10 col-sm-12 col-xl-6">
              //                       {el.exam_details.map((items, index) => (
              //                         <>
              //                           <div
              //                             className="row mt-2 mb-2"
              //                             id={
              //                               el.exam_details.length !== index + 1
              //                                 ? "BorderBathc"
              //                                 : ""
              //                             }
              //                           >
              //                             <div className="col-lg-11">
              //                               <div className="row">
              //                                 <div className="col-md-12 col-sm-12 ">
              //                                   <div className="row">
              //                                     <div className="col-md-4">
              //                                       <li className="IconsImage">
              //                                         <span className="material-icons">
              //                                           format_shapes
              //                                         </span>
              //                                         <div className="d-flex flex-column">
              //                                           <p className="title ms-1">
              //                                             Exam Title
              //                                           </p>
              //                                           <p className="subtitle ms-1">
              //                                             {items.exam_title}
              //                                           </p>
              //                                         </div>
              //                                       </li>
              //                                     </div>
              //                                     <div className="col-md-4">
              //                                       <li className="IconsImage">
              //                                         <span className="material-icons">
              //                                           assignment
              //                                         </span>
              //                                         <div className="d-flex flex-column ">
              //                                           <p className="title ms-1">
              //                                             Syllabus
              //                                           </p>
              //                                           <a
              //                                             href={`${ImageUrlPrefix}/${items.syllabus_url}`}
              //                                             className="subTet  ms-1"
              //                                           >
              //                                             Click here to view
              //                                             syllabus
              //                                           </a>
              //                                         </div>
              //                                       </li>
              //                                     </div>
              //                                     <div className="col-md-4">
              //                                       <li className="IconsImage">
              //                                         <span className="material-icons">
              //                                           event
              //                                         </span>
              //                                         <div className="d-flex flex-column ">
              //                                           <p className="title ms-1">
              //                                             Date
              //                                           </p>
              //                                           <p className="subtitle ms-1">
              //                                             {moment
              //                                               .utc(items.start_on)
              //                                               .format(
              //                                                 "YYYY-MM-DD"
              //                                               )}
              //                                           </p>
              //                                         </div>
              //                                       </li>
              //                                     </div>
              //                                   </div>
              //                                 </div>
              //                                 {/* <span className="border mt-3"></span> */}
              //                                 <div className="col-md-12 col-sm-12">
              //                                   <div className="row mt-3">
              //                                     <div className="col-md-6">
              //                                       <li className="IconsImage">
              //                                         <span class="material-icons">
              //                                           timer
              //                                         </span>
              //                                         <div className="d-flex flex-column">
              //                                           <p className="title ms-1">
              //                                             Time
              //                                           </p>
              //                                           <p className="subtitle ms-1">
              //                                             {items.batch_time}
              //                                           </p>
              //                                         </div>
              //                                       </li>
              //                                     </div>
              //                                     <div className="col-md-6">
              //                                       <li className="IconsImage">
              //                                         <span className="material-icons">
              //                                           timer
              //                                         </span>
              //                                         <div className="d-flex flex-column">
              //                                           <p className="title ms-1">
              //                                             Duration
              //                                           </p>
              //                                           <p className="subtitle ms-1">
              //                                             {items.duration_hrs}{" "}
              //                                             Hours
              //                                           </p>
              //                                         </div>
              //                                       </li>
              //                                     </div>
              //                                   </div>
              //                                 </div>
              //                               </div>
              //                             </div>
              //                           </div>
              //                         </>
              //                       ))}
              //                     </div>
              //                   </div>
              //                 </div>
              //               </div>
              //             </div>
              //           </div>
              //         ))}
              //     </div>
              //   </div>
              // </section>
              <>
                <StudentConfirmType4
                  paper_type={list.paper_type ? list.paper_type : 0}
                  batchdetails={batchDetails}
                  type={1} // means transcation history  --- lalitha changed
                />
              </>
            )}

          {Object.keys(batchDetails).length > 0 &&
            batchDetails[0] !== null &&
            batchDetails[0].panel_details !== undefined &&
            batchDetails[0].panel_details !== null &&
            Object.keys(batchDetails[0].panel_details).length > 0 &&
            type === "5" && (
              <section className="sectionSix">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Batch Details</p>
                    {batchDetails
                      .filter((item) => item.is_selected === true)
                      .map((el, index) => (
                        <div className="row" key={index}>
                          <div className="col-md-12 mt-3 mb-3">
                            <div className="card  text-dark">
                              <div className="card-body BatchImage">
                                <div className="row">
                                  <div className="col-md-4 d-flex align-item-center justify-content-center">
                                    <div className="row">
                                      <div className="col-lg-12 col-md-12 text-center col-sm-12">
                                        <img
                                          // src={`${ImageUrlPrefix}/${el.img_url}`}
                                          src="https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg"
                                          id={el.id}
                                          alt="Imge"
                                        />
                                        <p className="ReviewLabels text-center ms-0">
                                          {el.course_name || "-"}
                                          {"-"}
                                          {el.batch_name || "-"}
                                        </p>
                                      </div>
                                      {/* <div className="col-lg-8 col-md-8 col-sm-12 ImageText ">
                                       

                                        <p className="subtitle mb-1 mt-4  ms-5">
                                          Interview Fee
                                        </p>
                                        <div className="row mt-1 d-flex ms-5 flex-column-reverse">
                                          <div className="col-md-4">
                                            <span
                                              class="material-icons"
                                              id="mockinmcurr"
                                            >
                                              currency_rupee
                                            </span>
                                          </div>
                                          <div className="col-md-11 p-0">
                                            <p className="subtitle mt-1 mb-0">
                                              {el.exam_fees.map((mm) =>
                                                mm.fees_count ===
                                                el.panel_details.filter(
                                                  (panel) =>
                                                    panel.is_selected_panel &&
                                                    mm.batch_id ===
                                                      panel.batch_id
                                                ).length
                                                  ? mm.fees
                                                  : "100"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>

                                  <div className="col-lg-8 mt-3 mb-2 col-md-10 col-sm-12 col-xl-8">
                                    {el.panel_details.map((items, index) => (
                                      <>
                                        <div className="row">
                                          <div className="col-md-12 col-sm-12 ">
                                            <div className="row">
                                              <div className="col-md-4">
                                                <li className="IconsImage">
                                                  <span className="material-icons me-3">
                                                    format_shapes
                                                  </span>
                                                  <div className="d-flex flex-column">
                                                    <p className="title ms-1">
                                                      Panel Name
                                                    </p>
                                                    <p className="subtitle ms-1">
                                                      {items.panel_name}
                                                    </p>
                                                  </div>
                                                </li>
                                              </div>
                                              <div className="col-md-4">
                                                <li className="IconsImage">
                                                  <span className="material-icons me-3">
                                                    event
                                                  </span>
                                                  <div className="d-flex flex-column ">
                                                    <p className="title ms-1">
                                                      Date
                                                    </p>
                                                    <p
                                                      className="subtitle ms-1"
                                                      style={{ width: "auto" }}
                                                    >
                                                      {/* {items.available_dates !=null && items.available_dates
                                                        .length > 0
                                                        ? items.available_dates.join(
                                                            ", "
                                                          ) // Join the formatted dates back into a comma-separated string
                                                        : ""} */}

                                                      {items.available_dates !=
                                                      null
                                                        ? items.available_dates
                                                        : "_"}
                                                    </p>
                                                  </div>
                                                </li>
                                              </div>
                                            </div>
                                          </div>
                                          {index !== 0 && (
                                            <span className="border mt-3 mb-3"></span>
                                          )}
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </section>
            )}

          {Object.keys(batchDetails).length > 0 &&
            batchDetails[0] !== null &&
            batchDetails[0].panel_details !== undefined &&
            batchDetails[0].panel_details !== null &&
            Object.keys(batchDetails[0].panel_details).length > 0 &&
            type === "2" && (
              <section className=" sectionSix">
                <div className="row box">
                  <div className="col-md-12">
                    <p className="StepTitle">Batch Details</p>
                    {batchDetails
                      .filter((item) => item.is_selected === true)
                      .map((sdsd, index) => (
                        <div className="row" key={index}>
                          <div className="col-md-12 mt-3 mb-3">
                            <div className="card  text-dark">
                              <div className="card-body BatchImage">
                                <div className="row">
                                  <div className="col-md-4 d-flex align-item-center justify-content-center">
                                    <div className="row">
                                      <div className="col-lg-12 col-md-12 text-center col-sm-12">
                                        <img
                                          src={`${ImageUrlPrefix}/${sdsd.img_url}`}
                                          // src="https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg"
                                          id={sdsd.course_name}
                                          alt="Imge"
                                        />
                                        <p className="ReviewLabels text-center ms-0">
                                          {sdsd.course_name || "-"}
                                          {"-"}
                                          {sdsd.batch_name || "-"}
                                        </p>
                                      </div>
                                      {/* <div className="col-lg-8 col-md-8 col-sm-12 ImageText ">
                                        <p className="subtitle mb-1 mt-4  ms-5">
                                          Interview Fee
                                        </p>
                                        <div className="row mt-1 d-flex ms-5 flex-column-reverse">
                                          <div className="col-md-4">
                                            <span
                                              class="material-icons"
                                              id="mockinmcurr"
                                            >
                                              currency_rupee
                                            </span>
                                          </div>
                                          <div className="col-md-11 p-0">
                                            <p className="subtitle mt-1 mb-0">
                                              {sdsd.exam_fees.map((mm) =>
                                                mm.fees_count ===
                                                sdsd.panel_details.filter(
                                                  (panel) =>
                                                    panel.is_selected_panel &&
                                                    mm.batch_id ===
                                                      panel.batch_id
                                                ).length
                                                  ? mm.fees
                                                  : "100"
                                              )}   
                                            </p>
                                          </div>
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>

                                  <div className="col-lg-8 mt-3 mb-2 col-md-10 col-sm-12 col-xl-8">
                                    {sdsd.panel_details.map((items, index) => (
                                      <>
                                        <div className="row">
                                          <div className="col-md-12 col-sm-12 ">
                                            <div className="row">
                                              <div className="col-md-4">
                                                <li className="IconsImage">
                                                  <span className="material-icons me-3">
                                                    format_shapes
                                                  </span>
                                                  <div className="d-flex flex-column">
                                                    <p className="title ms-1">
                                                      Panel Name
                                                    </p>
                                                    <p className="subtitle ms-1">
                                                      {items.panel_name}
                                                    </p>
                                                  </div>
                                                </li>
                                              </div>
                                              <div className="col-md-4">
                                                <li className="IconsImage">
                                                  <span className="material-icons me-3">
                                                    event
                                                  </span>
                                                  <div className="d-flex flex-column ">
                                                    <p className="title ms-1">
                                                      Date
                                                    </p>
                                                    <p className="subtitle ms-1">
                                                      {moment(
                                                        items.date
                                                      ).format("Do MMM YYYY")}
                                                    </p>
                                                  </div>
                                                </li>
                                              </div>
                                              <div className="col-md-4">
                                                <li className="IconsImage">
                                                  <span className="material-icons-outlined me-3">
                                                    timer
                                                  </span>
                                                  <div className="d-flex flex-column ">
                                                    <p className="title ms-1">
                                                      Time
                                                    </p>
                                                    <p className="subtitle ms-1">
                                                      {items.event_time}
                                                    </p>
                                                  </div>
                                                </li>
                                              </div>
                                            </div>
                                          </div>
                                          {index !== 0 && (
                                            <span className="border mt-3 mb-3"></span>
                                          )}
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </section>
            )}

          {type === "4" && (
            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">
                    Did you receive your hall ticket from the recruitment board?
                  </p>

                  <div className="row mb-3">
                    {type === "4" && (
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted  oneline"
                          >
                            Did you receive your hall ticket from the
                            recruitment board?
                          </label>
                          <span className="ReviewLabel mt-2">
                            {list.hallticket || "-"}
                          </span>
                        </div>
                      </div>
                    )}
                    {type === "4" && list.hallticket === "Yes" && (
                      <div className="col-md-5">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted  oneline"
                          >
                            Upload Hall Ticket (Issued by the recruitment board)
                            {list.hall_upload_path && (
                              <a
                                target="_blank"
                                href={`${ImageUrlPrefix}/${list.hall_upload_path}`}
                              >
                                {" "}
                                <Download className="download_icon" />
                                <Visibility className="download_icon" />
                              </a>
                            )}
                          </label>
                          <span className="ReviewLabel">
                            {list.hallticket === "Yes"
                              ? list.hall_upload_original_path !== null &&
                                list.hall_upload_original_path !== ""
                                ? list.hall_upload_original_path
                                : ""
                              : ""}
                          </span>
                        </div>
                      </div>
                    )}
                    {type === "4" && list.hallticket === "Yes" && (
                      <div className="col-md-3">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Registration Number of the Exam
                          </label>
                          <span className="ReviewLabel mt-2">
                            {list.ExamRegNo ? list.ExamRegNo : ""}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}

          {type !== "4" && type !== "5" && (
            <section className=" sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">
                    Have You Already Studied for Competitive Examination?
                  </p>

                  <div className="row mb-3 mt-2">
                    {type !== "4" && type !== "5" && (
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted  oneline"
                          >
                            Have You Already Studied for Competitive
                            Examination?
                          </label>
                          <span className="ReviewLabel mt-2">
                            {list.CompetitiveExamination || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {type !== "4" &&
                      type !== "5" &&
                      list.CompetitiveExamination === "Yes" && (
                        <div className="col-lg-3 col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Name of the Examination
                            </label>
                            <span className="ReviewLabel">
                              {list.CompetitiveExamName || "-"}
                            </span>
                          </div>
                        </div>
                      )}

                    {type !== "4" &&
                      type !== "5" &&
                      list.CompetitiveExamination === "Yes" && (
                        <div className="col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              Name of the college / university
                            </label>
                            <span className="ReviewLabel">
                              {list.Institute || "-"}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </section>
          )}

          {type !== "4" && (
            <section className=" sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">
                    Have You Cleared Any Exam Previously?
                  </p>

                  <div className="row mb-3">
                    {type !== "4" && (
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted  oneline"
                          >
                            Have You Cleared Any Exam Previously?
                          </label>
                          <span className="ReviewLabel mt-2">
                            {list.ExamPreviously || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {type !== "4" && list.ExamPreviously === "Yes" && (
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted  oneline"
                          >
                            How many exams have you cleared in the past?
                          </label>
                          <span className="ReviewLabel mt-2">
                            {list.ExamWrite || "-"}
                          </span>
                        </div>
                      </div>
                    )}
                    {type !== "4" && list.ExamPreviously === "Yes" && (
                      <div className="col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted  oneline"
                          >
                            Exam details
                          </label>
                          <span className="register_app_val mb-3">
                            {list.ExamDetailsRes || "-"}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}
          {type !== "4" && (
            <section className="sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Are You a Government Employee?</p>

                  <div className="row mb-3">
                    {type !== "4" && (
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted  oneline"
                          >
                            Are You a Government Employee?
                          </label>
                          <span className="ReviewLabel">
                            {list.workingRes || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {type !== "4" && list.workingRes === "Yes" && (
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Designation
                          </label>
                          <span className="ReviewLabel">
                            {list.Designation || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {type !== "4" && list.workingRes === "Yes" && (
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Department
                          </label>
                          <span className="ReviewLabel">
                            {list.Department || "-"}
                          </span>
                        </div>
                      </div>
                    )}

                    {type !== "4" && list.workingRes === "Yes" && (
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Joining Date of Government Job
                          </label>
                          <span className="ReviewLabel">
                            {list.workingRes === "Yes" ? list.JoinGovt : ""}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}

          <section className=" sectionSix">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Are You an AIASA Student?</p>
                <div className="row mb-3">
                  <div className="col-lg-3 col-md-6">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted  oneline"
                      >
                        Are You an AIASA Student?
                      </label>
                      <span className="ReviewLabel">
                        {list.AIASAStudent || "-"}
                      </span>
                    </div>
                  </div>

                  {list.AIASAStudent === "Yes" && (
                    <>
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Year
                          </label>
                          <span className="ReviewLabel">
                            {list.Year || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            category
                          </label>
                          <span className="ReviewLabel">
                            {list.CategoryAias || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            Course
                          </label>
                          <span className="ReviewLabel">
                            {list.AIASAStudent === "Yes" ? list.CourseAias : ""}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted "
                          >
                            ID Number
                          </label>
                          <span className="ReviewLabel">
                            {list.IDNumber || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="forms ">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted  oneline"
                          >
                            Upload ID Card
                            {list.IDPhoto && (
                              <a
                                target="_blank"
                                href={`${ImageUrlPrefix}/${list.IDPhoto}`}
                              >
                                {" "}
                                <Download className="download_icon" />
                              </a>
                            )}
                          </label>
                          <span className="ReviewLabel">
                            {list.original_aiasa_id_path || "-"}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className=" sectionSix">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Admission Details</p>

                <div className="row mb-3">
                  <div className="col-md-3 ">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Admission ID
                      </label>
                      <span className="ReviewLabel">
                        {" "}
                        {list.admissionID || "-"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Applied On
                      </label>
                      <span className="ReviewLabel">
                        {list.appliedOn
                          ? moment(list.appliedOn).format("DD/MM/YYYY")
                          : "_"}
                      </span>
                    </div>
                  </div>
                  {type !== "4" && (
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Fees Paid
                        </label>
                        <span className="ReviewLabel">
                          <span class="current_rip align-middle">₹</span>
                          {list.paidDate}
                        </span>
                      </div>
                    </div>
                  )}

                  {/* <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Balance Fee
                      </label>
                      <span className="ReviewLabel"><span class="current_rip align-middle">₹</span>{list.BalanceFees}</span>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          {type !== "4" && (
            <section className=" sectionSix">
              <div className="row box">
                <div className="col-md-12">
                  <p className="StepTitle">Payment Option</p>

                  <div className="row mb-3">
                    <div className="col-md-3 ">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Payment Option
                        </label>
                        <span className="ReviewLabel">
                          {list.PPayment === 1
                            ? "Partial Payment"
                            : "Full Payment"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Fee Paid
                        </label>
                        {/* <span class="material-icons current_rip">currency_rupee</span>  */}

                        <span className="ReviewLabel">
                          <span class="current_rip align-middle">₹</span>
                          {list.paid_amount}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Balance Fee
                        </label>

                        <span className="ReviewLabel">
                          {" "}
                          <span class="current_rip align-middle">₹</span>
                          {list.BalanceFees}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {list.PPayment === 1 &&
            batchDetails &&
            batchDetails.map((el, index) => {
              return (
                <section className=" sectionSix">
                  <div className="row box">
                    <div className="col-md-12">
                      <p className="StepTitle">
                        Partial Payment Schedule Details
                      </p>

                      <div className="row mb-3">
                        <div className="col-md-6 ">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              2nd Payment Date (On or Before)
                            </label>
                            <span className="ReviewLabel">
                              {el.pay_2_duedate
                                ? moment(
                                    el.pay_2_duedate,
                                    "DD/MM/YYYY",
                                    true
                                  ).isValid()
                                  ? moment(
                                      el.pay_2_duedate,
                                      "DD/MM/YYYY"
                                    ).format("YYYY-MM-DD")
                                  : moment(el.pay_2_duedate).format(
                                      "YYYY-MM-DD"
                                    )
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              2nd Payment Amount{" "}
                              {el.pay_2_perc
                                ? `( ${el.pay_2_perc}% of Final Course Fee)`
                                : ""}
                            </label>
                            <span className="ReviewLabel  mt-sm-4 mt-md-0">
                              <span class="current_rip">₹</span>
                              {(
                                list.total_amount *
                                (el.pay_2_perc / 100)
                              ).toFixed(2)}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              3rd Payment Date (On or Before)
                            </label>
                            <span className="ReviewLabel">
                              {" "}
                              {el.pay_3_duedate
                                ? moment(
                                    el.pay_3_duedate,
                                    "DD/MM/YYYY",
                                    true
                                  ).isValid()
                                  ? moment(
                                      el.pay_3_duedate,
                                      "DD/MM/YYYY"
                                    ).format("YYYY-MM-DD")
                                  : moment(el.pay_3_duedate).format(
                                      "YYYY-MM-DD"
                                    )
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="forms ">
                            <label
                              htmlFor="inputName"
                              className="form-label text-muted "
                            >
                              3rd Payment Amount{" "}
                              {el.pay_3_perc
                                ? list.old_stud_amount_applied !== 0
                                  ? `( ${el.pay_3_perc} % of Final Course Fee - Old Student Discount)`
                                  : `(${el.pay_3_perc}% of Final Course Fee)`
                                : ""}
                            </label>
                            <span className="ReviewLabel mt-sm-4 mt-md-0">
                              <span class="current_rip">₹</span>

                              {list.old_stud_amount_applied !== 0
                                ? (
                                    list.total_amount * (el.pay_3_perc / 100) -
                                    list.old_stud_amount
                                  ).toFixed(2)
                                : (
                                    list.total_amount *
                                    (el.pay_3_perc / 100)
                                  ).toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              );
            })}

          <section className="sectionSix">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Review by Admin</p>

                <div className="row mb-3">
                  <div className="col-md-3">
                    <div className="forms ">
                      <label
                        htmlFor="inputName"
                        className="form-label text-muted "
                      >
                        Application Status
                      </label>
                      <span className="ReviewLabel">
                        {" "}
                        {list.AdminStatus === 0 && "Wait for approval"}
                        {list.AdminStatus === 1 && "Approved"}
                        {list.AdminStatus === 2 && "Hold"}
                        {list.AdminStatus === 3 && "Rejected"}
                      </span>
                    </div>
                  </div>
                  {list.AdminStatus === 1 && type !== "4" && (
                    <div className="col-md-3">
                      <div className="forms ">
                        <label
                          htmlFor="inputName"
                          className="form-label text-muted "
                        >
                          Section
                        </label>
                        <span className="ReviewLabel">
                          {list.section || "-"}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="sectionSix">
            <div className="row box">
              <div className="col-md-12">
                <p className="StepTitle">Student Communication</p>
                <div className="row mb-4">
                  {commentSec.length > 0 ? (
                    commentSec.map((el) => (
                      <div className="col-md-12">
                        <div className="forms">
                          <label
                            htmlFor="inputName"
                            className="form-label text-muted"
                          >
                            {el.user_type == 0 && "Comment by Admin"}{" "}
                            {el.user_type == 1 && "Reply By Student"}{" "}
                            {el.updated_dt !== null &&
                              `- ${moment
                                .utc(el.updated_dt)
                                .format("DD/MM/YYYY hh:mm:ss A")}`}
                          </label>
                          <span className="ReviewLabel">
                            {el.comment !== null || el.comment !== ""
                              ? el.comment
                              : "None"}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <span className="ReviewLabel mt-4">None</span>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
