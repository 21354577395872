import React from "react";
import LatexProcessorComponent from "../../../../../utils/LatexProcessorComponent";
import ImageDisplay from "../../StudentTestWorkflow/ImageDisplay";

export default function ExplanationQuery(props) {
  const TamilSplit = props.tm_expla.split("\n");
  const EnglishSplit = props.en_explan.split("\n");
  const imageRegex = /<img>(https?:\/\/[^\s]+)<\/img>/;
  return (
    <div className="row box explanationQ mt-1 ">
      <div className="col-md-12">
        <p className="StepTitle">Explanation</p>
        <div className={props.margin_yes === 1 ? `row` : `row`}>
          <div className="col-md-12 ">
            {(props.lang_type === 1 || props.lang_type === 2) &&
              EnglishSplit.length > 0 &&
              EnglishSplit.map((line, index) => {
                // Split the line into parts using the regex
                const parts = line.split(imageRegex); // Split by the <img> tag

                return (
                  <div className="col-md-12" key={index}>
                    <p >
                      {parts.map((part, partIndex) => {
                        // Check if the part is an image URL
                        if (part.startsWith("http")) {
                          return (
                            <ImageDisplay
                              key={partIndex} // Use partIndex as key for unique identification
                              className="imgtnss"
                              ImgUrl={part} // Use the extracted URL for the image
                            />
                          );
                        } else {
                          return (
                            <span className="question_labels_english" key={partIndex}>
                              {/* Use span to keep text inline */}
                              <LatexProcessorComponent originalString={part} />
                            </span>
                          );
                        }
                      })}
                    </p>
                  </div>
                );
              })}

            {(props.lang_type === 0 || props.lang_type === 2) &&
              TamilSplit.length > 0 &&
              TamilSplit.map((line, lineIndex) => {
                const parts = line.split(imageRegex); // Split the line using the regex

                return (
                  <div className="col-md-12" key={lineIndex}>
                    <p >
                      {parts.map((part, partIndex) => {
                        if (part.startsWith("http")) {
                          // Check if the part is a URL
                          return (
                            <ImageDisplay
                              key={partIndex} // Use partIndex as key for unique identification
                              className="imgtnss"
                              ImgUrl={part} // Use the extracted URL here
                            />
                          );
                        } else {
                          return (
                            <span className="question_labels_tamil" key={partIndex}>
                              {" "}
                              {/* Use span to keep text inline */}
                              <LatexProcessorComponent originalString={part} />
                            </span>
                          );
                        }
                      })}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
