import "./Headerpage.css";

import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import logo from "../../../img/AIASA.png";
import WhiteLogo from "../../../img/WhiteLogo.png";
import RedLogo from "../../../img/Redlogo.png";
import { UserContext } from "../../../context/UserDetails";
import Subnav from "./Subnav";
import FB from "../../../img/socialicons/facebook.png";
import INS from "../../../img/socialicons/insta.png";
import WHATS from "../../../img/socialicons/whastapp.png";
import TWITT from "../../../img/socialicons/twitter.png";
import LINKD from "../../../img/socialicons/linkedin.png";
import YOUTUBE from "../../../img/socialicons/youtube.png";
import { useEffect } from "react";
import { AxiosInstance } from "../../../axios";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
function HeaderHome() {
  const location = useLocation();

  const [dropdownClicked, setDropdownClicked] = useState(false);

  const { user, logout } = useContext(UserContext);

  // logout function call

  const Logout = () => {
    logout();
  };

  const handleLogo = () => {
    navigate("/");
  };
  const [isOpen, setIsOpen] = useState(false);

  const [isOpens, setIsOpens] = useState(false);

  const [sideOpen, setSideOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleNav = (navItem, type) => {
    navigate(`${navItem}`);
    // if (type === 0) {
    sessionStorage.setItem("stateRefreshs", true);
    localStorage.setItem("stateRefreshs", true);
    // }
    // if (type === 1) {
    sessionStorage.setItem("stateRefreshs_test", true);
    localStorage.setItem("stateRefreshs_test", true);
    // }
    // if (type === 3) {
    sessionStorage.setItem("stateRefreshs_spl", true);
    localStorage.setItem("stateRefreshs_spl", true);
    // }
    // if (type === 4) {
    sessionStorage.setItem("stateRefreshs_model", true);
    localStorage.setItem("stateRefreshs_model", true);
    // }
    // if (type === 5) {
    sessionStorage.setItem("stateRefreshs_mock", true);
    localStorage.setItem("stateRefreshs_mock", true);
    // }
    // if (type === 2) {
    sessionStorage.setItem("stateRefreshs_coun", true);
    localStorage.setItem("stateRefreshs_coun", true);
    // }
  };

  const ToggleOpen = (item) => {
    navigate(item);
    setSideOpen(!sideOpen);
    const modal_close = document.querySelector("#MobileMenuClose");
    if (modal_close) {
      modal_close.click();
    }
    sessionStorage.setItem("stateRefreshs", true);
    localStorage.setItem("stateRefreshs", true);
    sessionStorage.setItem("stateRefreshs_test", true);
    localStorage.setItem("stateRefreshs_test", true);
    sessionStorage.setItem("stateRefreshs_spl", true);
    localStorage.setItem("stateRefreshs_spl", true);
    sessionStorage.setItem("stateRefreshs_model", true);
    localStorage.setItem("stateRefreshs_model", true);
    sessionStorage.setItem("stateRefreshs_mock", true);
    localStorage.setItem("stateRefreshs_mock", true);
    sessionStorage.setItem("stateRefreshs_coun", true);
    localStorage.setItem("stateRefreshs_coun", true);
  };

  const toggleDropdowns = () => {
    setIsOpens(!isOpens);
  };

  // get user id

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;
  let role_id = null;
  let user_type = null;
  let staff_status = null;
  let menuData = null;
  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
      role_id = user.role_id;
      user_type = user.user_type;
      menuData = user.menu_list;
      staff_status = user.staff_status;
    }
  } else {
    console.log("User Id cannot available in session storage");
  }

  const [menuList, setMenuList] = useState(menuData);
  const [tPer, setTPer] = useState([]);

  const navigate = useNavigate();

  // get batch for user

  useEffect(() => {
    if (userid) {
      AxiosInstance.post("api/batch/list/byUser", {
        user_id: userid,
      })
        .then((res) => {
          if (res.data.status === true) {
            let df = res.data.data;

            setTPer(df);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }, []);

  // test performance menu click

  const handleTeamClick = (batch_id) => {
    navigate(`/TestPerformance/${batch_id}`);
    const modal_close = document.querySelector("#MobileMenuClose");
    if (modal_close) {
      modal_close.click();
    }
  };

  // view schedule menu click

  const handleSchedule = (batch_id, batch_type) => {
    navigate(`/TestViewSchedule/${batch_id}/${batch_type}`);
    const modal_close = document.querySelector("#MobileMenuClose");
    if (modal_close) {
      modal_close.click();
    }
  };

  const handleSocialIcons = (nam) => {
    if (nam === "Fac") {
      window.open(
        "https://www.facebook.com/annatnpsccenter?mibextid=ZbWKwL",
        "_blank"
      );
    } else if (nam === "You") {
      window.open(
        "https://youtube.com/@annaiasacademy2010?si=ttrQQPx9QGmBUzxB",
        "_blank"
      );
    } else if (nam === "Ins") {
      window.open(
        "https://instagram.com/annaiasacademykanchipuram?igshid=NzZlODBkYWE4Ng==",
        "_blank"
      );
    } else if (nam === "Wha") {
      window.open(
        "https://whatsapp.com/channel/0029VaBYURMIXnlmlHyKao1o",
        "_blank"
      );
    } else if (nam === "Lin") {
      window.open(
        "https://www.linkedin.com/in/anna-ias-academy-8b9b662a3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        "_blank"
      );
    } else if (nam === "Twitt") {
      window.open(
        "https://x.com/academy_an52468?t=PUh_SyGgAGYp4SjwZZ22xw&s=09",
        "_blank"
      );
    }
  };

  // console.log(tPer, "tPer");
  return (
    <>
      {/* desktop  navabr   */}
      <div className="HeaderPage MobileRespo ">
        <div className="row ">
          <div className=" head ">
            <div className="row">
              <div className="col-md-4 col-lg-6 ">
                <img
                  src={FB}
                  alt="facebook"
                  id="socialIcons"
                  onClick={() => handleSocialIcons("Fac")}
                />
                <span className="border" id="border"></span>
                <img
                  src={INS}
                  alt="insta"
                  id="socialIcons"
                  onClick={() => handleSocialIcons("Ins")}
                />
                <span className="border" id="border"></span>
                <img
                  src={WHATS}
                  alt="whastapp"
                  id="socialIcons"
                  onClick={() => handleSocialIcons("Wha")}
                />
                <span className="border" id="border"></span>
                <img
                  src={TWITT}
                  alt="twitter"
                  id="socialIcons"
                  onClick={() => handleSocialIcons("Twitt")}
                />
                <span className="border" id="border"></span>
                <img
                  src={LINKD}
                  alt="Linkedin"
                  id="socialIcons"
                  onClick={() => handleSocialIcons("Lin")}
                />
                <span className="border" id="border"></span>
                <img
                  src={YOUTUBE}
                  alt="youtube"
                  id="socialIcons"
                  onClick={() => handleSocialIcons("You")}
                />
                <span className="border" id="border"></span>
              </div>
              <div className="col-md-4 col-lg-3"></div>
              <div className="col-md-4 col-lg-3 d-flex justify-content-end">
                <span className="material-icons fw-bold" id="icon">
                  favorite
                </span>
                <span id="number">Wishlist</span>
                <span className="border" id="border"></span>
                <span className="material-icons fw-bold ms-2" id="icon">
                  shopping_cart
                </span>
                <span id="number">Cart</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-12  d-flex p-0">
            <div className="col-md-2 col-xl-2 col-sm-6" id="brand">
              <div className="brand ">
                <img src={WhiteLogo} alt="LogoImage" onClick={handleLogo} />
                {/* <div className="contents">
                  <h2 className="title ms-2 mt-3">ANNA</h2>
                  <h2 className="Subtitle mb-3 ms-2">IAS ACADEMY</h2>
                </div> */}
              </div>
            </div>
            <div className="col-md-10 col-xl-10 col-sm-6">
              <div className="content ">
                <div className="firstHeader">
                  <ul>
                    <li
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="material-icons fw-bold icon">star</span>
                      Test Batches
                    </li>
                    <span className="border"></span>
                    <li
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="material-icons fw-bold icon">star</span>
                      Online Courses
                    </li>
                    <span className="border"></span>
                    <li
                      className="dropdown-toggle "
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Classroom Courses
                    </li>
                    <span className="border"></span>
                    <li
                      className="dropdown-toggle "
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Current Affairs
                    </li>
                    <span className="border"></span>
                    <li>Articles</li>
                    <span className="border"></span>
                    <li>Announcements</li>
                  </ul>
                </div>
                <div className="secondHeader ">
                  <ul>
                    <li>Home</li>
                    <span className="border"></span>
                    <li
                      className="dropdown-toggle "
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Our Institute
                    </li>
                    <span className="border"></span>
                    <li
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Admission
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            onClick={() =>
                              handleNav(
                                user_type !== 2
                                  ? "/ListViewStudentConfirm/0"
                                  : "/Admission/Course",
                                0
                              )
                            }
                            to="javascript:void(0)"
                            // {
                            //   user_type !== 2 ? "/ListViewStudentConfirm/0" : "/Admission/Course"}
                          >
                            Courses
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() =>
                              handleNav(
                                user_type !== 2
                                  ? "/ListViewStudentConfirm/1"
                                  : "/Admission/TestBatch",
                                1
                              )
                            }
                            to="javascript:void(0)"

                            // to={
                            //   user_type !== 2 ? "/ListViewStudentConfirm/1" : "/Admission/TestBatch"}
                          >
                            Test Batches
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() =>
                              handleNav(
                                user_type !== 2
                                  ? "/ListViewStudentConfirm/3"
                                  : "/Admission/SpecialClasses",
                                3
                              )
                            }
                            to="javascript:void(0)"
                            // to={
                            //   user_type !== 2 ? "/ListViewStudentConfirm/3" : "/Admission/SpecialClasses"}
                          >
                            Special Classes
                          </Link>
                        </li>
                        {/* {user_type !== 2 && ( */}
                        <>
                          <li>
                            <Link
                              onClick={() =>
                                handleNav(
                                  user_type !== 2
                                    ? "/ListViewStudentConfirm/4"
                                    : "/Admission/Modelexam",
                                  4
                                )
                              }
                              to="javascript:void(0)"
                              // to={
                              //   user_type !== 2 ? "/ListViewStudentConfirm/4" : "/Admission/Modelexam"}
                            >
                              Model Exams
                            </Link>
                          </li>
                          {/* <li>
                              <Link
                                onClick={() =>
                                  handleNav(
                                    user_type !== 2
                                      ? "/ListViewStudentConfirm/5"
                                      : "/Admission/Mockinterview",
                                    5
                                  )
                                }
                                to="javascript:void(0)"
                                // to={
                                //   user_type !== 2 ? "/ListViewStudentConfirm/5" : "/Admission/Mockinterview"}
                              >
                                Mock Interviews
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() =>
                                  handleNav(
                                    user_type !== 2
                                      ? "/ListViewStudentConfirm/2"
                                      : "/Admission/Counselling",
                                    2
                                  )
                                }
                                to="javascript:void(0)"
                                // to={
                                //   user_type !== 2 ? "/ListViewStudentConfirm/2" : "/Admission/Counselling"}
                              >
                                Counsellings
                              </Link>
                            </li> */}
                        </>
                        {/* )} */}
                      </ul>
                    </li>

                    <span className="border"></span>
                    <li
                      className="dropdown-toggle "
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Study Materials
                    </li>
                    <span className="border"></span>
                    <li
                      className="dropdown-toggle "
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Micro Learning
                    </li>
                    <span className="border"></span>
                    <li
                      className="dropdown-toggle "
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Achievements
                    </li>
                    <span className="border"></span>
                    <li
                      className="dropdown-toggle "
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Gallery
                    </li>
                    <span className="border"></span>
                    <li>Contact us</li>
                    <span className="border"></span>
                    {user.user_id ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip" className="tool_eve">
                            {user.full_name}
                            <br />
                            {user.Email}
                          </Tooltip>
                        }
                      >
                        <li className="buttonStyle dropdown">
                          <div className="dropdown-menu" id="menulist">
                            {menuList !== null &&
                              menuList.map((el, index) => {
                                if (el.name === "purchase_history") {
                                  return (
                                    <p key={index}>
                                      <Link to="/TransactionHistory">
                                        Transaction History
                                      </Link>
                                    </p>
                                  );
                                }
                                // else if (el.name === "event") {
                                //   return (
                                //     <p key={index}>
                                //       <Link to="">
                                //         Event
                                //       </Link>
                                //     </p>
                                //   );
                                // }
                                // else if (el.name === "salary") {
                                //   return (
                                //     <p key={index}>
                                //       <Link to="">
                                //         Salary
                                //       </Link>
                                //     </p>
                                //   );
                                // }
                                // else if (el.name === "coupon") {
                                //   return (
                                //     <p key={index}>
                                //       <Link to="">
                                //         Coupon
                                //       </Link>
                                //     </p>
                                //   );
                                // }
                                // else if (el.name === "payment_invoice") {
                                //   return (
                                //     <p key={index}>
                                //       <Link to="Invoice">Payment Invoice</Link>
                                //     </p>
                                //   );
                                // }
                                else if (el.name === "batch_backoffice") {
                                  return (
                                    <p key={index}>
                                      <Link to="ViewCourseBatch">
                                        Create Batch
                                      </Link>
                                    </p>
                                  );
                                } else if (el.name === "admission_list") {
                                  return (
                                    <p key={index}>
                                      <Link to="ListViewStudentConfirm">
                                        Admission List
                                      </Link>
                                    </p>
                                  );
                                } else if (
                                  el.name === "test_list" &&
                                  el.category === "admin"
                                ) {
                                  return (
                                    <p key={index}>
                                      <Link to="/TestList">Test List</Link>
                                    </p>
                                  );
                                } else if (
                                  el.name === "test_list" &&
                                  el.category === "student"
                                ) {
                                  return (
                                    <p key={index}>
                                      <Link to="TestPerformanceListView">
                                        Test Performance
                                      </Link>
                                    </p>
                                  );
                                }
                                // else if (el.name === "class_backoffice") {
                                //   return (
                                //     <p key={index}>
                                //       <Link to="classedit">Create Classes</Link>
                                //     </p>
                                //   );
                                // }
                                else if (el.name === "faculty ") {
                                  return (
                                    <p key={index}>
                                      <Link to="Faculties">Faculty</Link>
                                    </p>
                                  );
                                } else if (el.name === "register_user") {
                                  return (
                                    <p key={index}>
                                      <Link to="/RegisteredUser">
                                        Register User
                                      </Link>
                                    </p>
                                  );
                                }
                                // else if (
                                //   el.name === "profile_setting") {
                                //   return (
                                //     <p key={index}>
                                //       <Link to="ProfileSetting">
                                //         My Profile Setting
                                //       </Link>
                                //     </p>
                                //   );
                                // }
                                else if (
                                  el.name === "Admission" &&
                                  el.category === "staff"
                                ) {
                                  return (
                                    <p key={index}>
                                      <Link to="ProfileFacultyView">
                                        View Faculty
                                      </Link>
                                    </p>
                                  );
                                } else if (el.name === "registered_users") {
                                  return (
                                    <p key={index}>
                                      <Link to="RegisteredUser">
                                        Registered User
                                      </Link>
                                    </p>
                                  );
                                }
                                // else if (el.name === "question_upload") {
                                //   return (
                                //     <p key={index}>
                                //       <Link to="TestList">
                                //         Question Upload
                                //       </Link>
                                //     </p>
                                //   );
                                // }
                                else if (
                                  el.name === "faculty" &&
                                  el.category === "teaching"
                                ) {
                                  return (
                                    <p key={index}>
                                      <Link to="facultyProfile">
                                        Faculty profile
                                      </Link>
                                    </p>
                                  );
                                } else if (
                                  el.name === "faculty" &&
                                  el.category === "non_teaching"
                                ) {
                                  return (
                                    <p key={index}>
                                      <Link to="NonFacultyProfile">
                                        Faculty profile
                                      </Link>
                                    </p>
                                  );
                                } else if (
                                  el.name === "faculty" &&
                                  el.category === "panel_member"
                                ) {
                                  return (
                                    <p key={index}>
                                      <Link to="NonFacultyProfile">
                                        Faculty profile
                                      </Link>
                                    </p>
                                  );
                                } else if (el.name === "view_Schedule") {
                                  return (
                                    <div
                                      className="dropdowns  mt-3  mb-3"
                                      key={index}
                                    >
                                      <a
                                        class="dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        View Schedule
                                      </a>{" "}
                                      {tPer.length > 0 ? (
                                        <ul id="SideLists">
                                          {tPer.map((el) => (
                                            <li key={el.batch_id}>
                                              <Link
                                                onClick={() =>
                                                  handleSchedule(
                                                    el.batch_id,
                                                    el.batch_type
                                                  )
                                                }
                                                className="nav-link"
                                                to="javascript:void(0)"
                                              >
                                                {el.batch_name}
                                              </Link>
                                            </li>
                                          ))}
                                        </ul>
                                      ) : (
                                        <ul id="SideLists">
                                          <li>
                                            <Link
                                              className="nav-link"
                                              to="javascript:void(0)"
                                            >
                                              No Batch found
                                            </Link>
                                          </li>
                                        </ul>
                                      )}
                                    </div>
                                  );
                                }

                                return null; // Render nothing if the condition is not met
                              })}

                            {user_type === 4 && staff_status === 4 && (
                              <p>
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() => ToggleOpen(`/CashPayment`)}
                                >
                                  Cash Payments
                                </Link>
                              </p>
                            )}
                            {/* Announcements portion */}
                            {user_type === 1 && (
                              <p>
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() => ToggleOpen(`/Announcement`)}
                                >
                                  Announcements
                                </Link>
                              </p>
                            )}

                            {(user_type === 4 ||
                              user_type === 3 ||
                              user_type === 5) && (
                              <p>
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() => ToggleOpen(`/ModelExamCenter`)}
                                >
                                  Exam Centers
                                </Link>
                              </p>
                            )}
                            {(user_type === 2 || user_type === 1) && (
                              <p>
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() =>
                                    ToggleOpen(`/DownloadHallTicket`)
                                  }
                                >
                                  Download Hall Ticket
                                </Link>
                              </p>
                            )}
                            {(user_type === 4 || user_type === 3) && (
                              <p>
                                <Link to="/StudentsQuery">Students Query</Link>
                              </p>
                            )}
                            <p>
                              <Link onClick={Logout}>Logout</Link>
                            </p>
                          </div>
                        </li>
                      </OverlayTrigger>
                    ) : (
                      <li className="buttonStyles">
                        <Link className=" nav-link" to="/login">
                          Login
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile navabr /tablet  */}
      <nav className="navbar shadow mobile_nav d-none d-lg-none d-xl-none">
        <div className="mobile_svv">
          <a className="navbar-brand" href="#">
            <img
              src={RedLogo}
              onClick={handleLogo}
              alt="mobile_logo"
              className="mobile_logo"
            />
            {/* <span className="d-inline-block align-middle"></span> */}
          </a>
          <span className=" material-icons Icons">favorite</span>
          <span className="border"></span>
          <span className=" material-icons Icons">shopping_cart</span>
          <span className="border"></span>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebarNav"
            aria-controls="sidebarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className=" material-icons navbarIcon">menu</span>
          </button>

          <div
            className={`offcanvas offcanvas-start ${sideOpen ? "hiding" : ""}`}
            tabIndex="-1"
            id="sidebarNav"
            aria-label="sidebarNavLabel"
          >
            <div className="offcanvas-header">
              <div className="sidebar_logo">
                <img
                  src={WhiteLogo}
                  alt="sidebar_logo"
                  onClick={handleLogo}
                  className="sidebar_logo"
                />
                {/* <div className="d-flex flex-column">
                  <h5 className="text-white ms-2">ANNA</h5>
                  <h6 className="text-white ms-2">IAS ACADEMY</h6>
                </div> */}
              </div>
              <button
                type="button"
                id="MobileMenuClose"
                className="btn-close text-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body ">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="javascript:void(0)"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link className="nav-link" to="javascript:void(0)">
                    {/* <span className="material-icons fw-bold icon">star</span> */}
                    Test Batches
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link className="nav-link" to="javascript:void(0)">
                    {/* <span className="material-icons fw-bold icon">star</span> */}
                    Online Courses
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link className="nav-link" to="javascript:void(0)">
                    Classroom Courses
                  </Link>
                </li>

                <li className="nav-item ">
                  <Link className="nav-link" to="javascript:void(0)">
                    Current Affairs
                  </Link>
                </li>

                <li className="nav-item ">
                  <Link className="nav-link" to="javascript:void(0)">
                    Articles
                  </Link>
                </li>

                <li className="nav-item ">
                  <Link className="nav-link" to="javascript:void(0)">
                    Announcements
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link" href="#">
                    Our Institute
                  </Link>
                </li>

                <li
                  className={`dropdown-toggle ${isOpen ? "open" : ""}`}
                  onClick={toggleDropdown}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Admission
                  <span
                    class="material-icons dropdown_span"
                    onClick={toggleDropdown}
                  >
                    expand_more
                  </span>
                  <ul className={`dropdown-menu ${isOpen ? "open" : ""}`}>
                    <li>
                      <Link
                        onClick={() =>
                          ToggleOpen(
                            `${
                              user_type !== 2
                                ? "/ListViewStudentConfirm/0"
                                : "/Admission/Course"
                            }`
                          )
                        }
                        to="javascript:void(0)"
                      >
                        Courses
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() =>
                          ToggleOpen(
                            `${
                              user_type !== 2
                                ? "/ListViewStudentConfirm/1"
                                : "/Admission/TestBatch"
                            }`
                          )
                        }
                        to="javascript:void(0)"
                      >
                        Test Batches
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() =>
                          ToggleOpen(
                            `${
                              user_type !== 2
                                ? "/ListViewStudentConfirm/3"
                                : "/Admission/SpecialClasses"
                            }`
                          )
                        }
                        to="javascript:void(0)"
                      >
                        Special Classes
                      </Link>
                    </li>
                    {/* {user_type !== 2 && ( */}
                    {/* <> */}
                    <li>
                      <Link
                        onClick={() =>
                          ToggleOpen(
                            `${
                              user_type !== 2
                                ? "/ListViewStudentConfirm/4"
                                : "/Admission/Modelexam"
                            }`
                          )
                        }
                        to="javascript:void(0)"
                      >
                        Model Exams
                      </Link>
                    </li>
                    {/* <li>
                          <Link
                            onClick={() =>
                              ToggleOpen(
                                `${
                                  user_type !== 2
                                    ? "/ListViewStudentConfirm/5"
                                    : "/Admission/Mockinterview"
                                }`
                              )
                            }
                            to="javascript:void(0)"
                          >
                            Mock Interviews
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() =>
                              ToggleOpen(
                                `${
                                  user_type !== 2
                                    ? "/ListViewStudentConfirm/2"
                                    : "/Admission/Counselling"
                                }`
                              )
                            }
                            to="javascript:void(0)"
                          >
                            Counsellings
                          </Link>
                        </li> */}
                    {/* </> */}
                    {/* )} */}
                  </ul>
                </li>

                <li className="nav-item dropdown">
                  <a className="nav-link" href="#">
                    Study Materials
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link" href="#">
                    Micro Learning
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link" href="#">
                    Achievements
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link" href="#">
                    Gallery
                  </a>
                </li>
                <li className="nav-item ">
                  <a className="nav-link" href="#">
                    Contact
                  </a>
                </li>

                {user.user_id ? (
                  <>
                    {menuList !== null &&
                      menuList.map((el, index) => {
                        if (el.name === "purchase_history") {
                          return (
                            <li className="nav-item " key={index}>
                              <Link
                                className="nav-link"
                                onClick={() =>
                                  ToggleOpen(`/TransactionHistory`)
                                }
                                to="javascript:void(0)"
                              >
                                Transaction History
                              </Link>
                            </li>
                          );
                        }

                        // else if (el.name === "event") {
                        //   return (
                        //     <li className="nav-item " key={index}>
                        //       <Link
                        //         className="nav-link"
                        //         to=""
                        //       >
                        //         Event
                        //       </Link>
                        //     </li>
                        //   );
                        // }
                        // else if (el.name === "salary") {
                        //   return (
                        //     <li className="nav-item " key={index}>
                        //       <Link
                        //         className="nav-link"
                        //         to=""
                        //       >
                        //         Salary
                        //       </Link>
                        //     </li>
                        //   );
                        // }
                        // else if (el.name === "coupon") {
                        //   return (
                        //     <li className="nav-item " key={index}>
                        //       <Link
                        //         className="nav-link"
                        //         to=""
                        //       >
                        //         Coupon
                        //       </Link>
                        //     </li>
                        //   );
                        // }
                        //  else if (el.name === "payment_invoice") {
                        //   return (
                        //     <li className="nav-item " key={index}>
                        //       <Link className="nav-link" to="Invoice">
                        //         Payment Invoice
                        //       </Link>
                        //     </li>
                        //   );
                        // }
                        // else if (el.name === "class_backoffice") {
                        //   return (
                        //     <li className="nav-item " key={index}>
                        //       <Link className="nav-link" to="classedit">
                        //         Create Classes
                        //       </Link>
                        //     </li>
                        //   );
                        // }
                        else if (el.name === "batch_backoffice") {
                          return (
                            <li className="nav-item " key={index}>
                              <Link
                                className="nav-link"
                                onClick={() => ToggleOpen(`ViewCourseBatch`)}
                                to="javascript:void(0)"
                              >
                                Create Batch
                              </Link>
                            </li>
                          );
                        } else if (el.name === "admission_list") {
                          return (
                            <li className="nav-item " key={index}>
                              <Link
                                onClick={() =>
                                  ToggleOpen(`ListViewStudentConfirm`)
                                }
                                to="javascript:void(0)"
                                className="nav-link"
                              >
                                Admission List
                              </Link>
                            </li>
                          );
                        } else if (
                          el.name === "test_list" &&
                          el.category === "admin"
                        ) {
                          return (
                            <li className="nav-item ">
                              <Link
                                className="nav-link"
                                onClick={() => ToggleOpen(`TestList`)}
                                to="javascript:void(0)"
                              >
                                Test List
                              </Link>
                            </li>
                          );
                        } else if (
                          el.name === "test_list" &&
                          el.category === "student"
                        ) {
                          return (
                            <li className="nav-item ">
                              <Link
                                className="nav-link"
                                onClick={() =>
                                  ToggleOpen(`TestPerformanceListView`)
                                }
                                to="javascript:void(0)"
                              >
                                Test Performance
                              </Link>
                            </li>
                          );
                        } else if (el.name === "faculty ") {
                          return (
                            <li className="nav-item " key={index}>
                              <Link
                                className="nav-link"
                                onClick={() => ToggleOpen(`Faculties`)}
                                to="javascript:void(0)"
                              >
                                Faculty
                              </Link>
                            </li>
                          );
                        }

                        // else if (el.name === "profile_setting") {
                        //   return (
                        //     <li className="nav-item " key={index}>
                        //       <Link className="nav-link"
                        //         onClick={() => ToggleOpen(`ProfileSetting`)}
                        //         to='javascript:void(0)'
                        //       >
                        //         My Profile Setting
                        //       </Link>
                        //     </li>
                        //   );
                        // }
                        else if (
                          el.name === "Admission" &&
                          el.category === "staff"
                        ) {
                          return (
                            <li className="nav-item " key={index}>
                              <Link
                                onClick={() => ToggleOpen(`ProfileFacultyView`)}
                                to="javascript:void(0)"
                                className="nav-link"
                              >
                                View Faculty
                              </Link>
                            </li>
                          );
                        } else if (el.name === "registered_users") {
                          return (
                            <li className="nav-item " key={index}>
                              <Link
                                onClick={() => ToggleOpen(`RegisteredUser`)}
                                to="javascript:void(0)"
                                className="nav-link"
                              >
                                Registered User
                              </Link>
                            </li>
                          );
                        }
                        // else if (el.name === "question_upload") {
                        //   return (
                        //     <p key={index}>
                        //       <Link to="TestList">
                        //         Question Upload
                        //       </Link>
                        //     </p>
                        //   );
                        // }
                        else if (
                          el.name === "faculty" &&
                          el.category === "teaching"
                        ) {
                          return (
                            <li className="nav-item " key={index}>
                              <Link
                                className="nav-link"
                                onClick={() => ToggleOpen(`facultyProfile`)}
                                to="javascript:void(0)"
                              >
                                Faculty profile
                              </Link>
                            </li>
                          );
                        } else if (
                          el.name === "faculty" &&
                          el.category === "non_teaching"
                        ) {
                          return (
                            <li className="nav-item " key={index}>
                              <Link
                                className="nav-link"
                                onClick={() => ToggleOpen(`NonFacultyProfile`)}
                                to="javascript:void(0)"
                              >
                                Faculty profile
                              </Link>
                            </li>
                          );
                        } else if (
                          el.name === "faculty" &&
                          el.category === "panel_member"
                        ) {
                          return (
                            <li className="nav-item " key={index}>
                              <Link
                                className="nav-link"
                                onClick={() => ToggleOpen(`NonFacultyProfile`)}
                                to="javascript:void(0)"
                              >
                                Faculty profile
                              </Link>
                            </li>
                          );
                        } else if (el.name === "view_Schedule") {
                          return (
                            <li
                              key={index}
                              className={`dropdown-toggle mt-3 ${
                                isOpens ? "open" : ""
                              }`}
                              onClick={toggleDropdowns}
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              View Schedule{" "}
                              <span
                                class="material-icons dropdown_spansss"
                                onClick={toggleDropdowns}
                              >
                                expand_more
                              </span>
                              {tPer.length > 0 ? (
                                <ul
                                  className={`dropdown-menu mt-3 ${
                                    isOpens ? "open" : ""
                                  }`}
                                >
                                  {tPer.map((el) => (
                                    <li key={el.batch_id}>
                                      <Link
                                        onClick={() =>
                                          handleSchedule(
                                            el.batch_id,
                                            el.batch_type
                                          )
                                        }
                                        to="javascript:void(0)"
                                      >
                                        {el.batch_name}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <ul className="dropdown-menu">
                                  <li>
                                    <Link
                                      className="nav-link"
                                      to="javascript:void(0)"
                                    >
                                      No Batch found
                                    </Link>
                                  </li>
                                </ul>
                              )}
                            </li>
                          );
                        }
                        // else if (el.name === "register_user") {
                        //   return (
                        //     <li className="nav-item " key={index}>
                        //       <Link className="nav-link" to="/RegisteredUser">
                        //         Register User
                        //       </Link>
                        //     </li>
                        //   );
                        // }

                        return null; // Render nothing if the condition is not met
                      })}
                    {user_type === 4 && staff_status === 4 && (
                      <li className="nav-item ">
                        <Link
                          className="nav-link"
                          to="javascript:void(0)"
                          onClick={() => ToggleOpen(`/CashPayment`)}
                        >
                          Cash Payments
                        </Link>
                      </li>
                    )}
                    {(user_type === 4 ||
                      user_type === 3 ||
                      user_type === 5) && (
                      <li className="nav-item ">
                        <Link
                          className="nav-link"
                          to="javascript:void(0)"
                          onClick={() => ToggleOpen(`/ModelExamCenter`)}
                        >
                          Exam Centers
                        </Link>
                      </li>
                    )}
                    {(user_type === 2 || user_type === 1) && (
                      <li className="nav-item ">
                        <Link
                          className="nav-link"
                          to="javascript:void(0)"
                          onClick={() => ToggleOpen(`/DownloadHallTicket`)}
                        >
                          Download Hall Ticket
                        </Link>
                      </li>
                    )}

                    {(user_type === 4 || user_type === 3) && (
                      <li className="nav-item ">
                        <Link className="nav-link" to="/StudentsQuery">
                          Students Query
                        </Link>
                      </li>
                    )}

                    <li className="nav-item ">
                      <Link className="nav-link" onClick={Logout}>
                        Logout
                      </Link>
                    </li>
                  </>
                ) : (
                  <li className="buttonStyles">
                    <Link className=" nav-link" to="/login">
                      Login
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <Subnav record={location} />
    </>
  );
}

export default HeaderHome;
