import React from "react";
import styles from "./PaginationTop10.module.css";
import { Link, useNavigate } from "react-router-dom";

const PaginationTop10 = ({
  totCount,
  currentPage,
  hPrev,
  hNext,
  onPageChange,
  showViewAllButton,
}) => {
  const isPrevDisabled = currentPage === 0;
  const isNextDisabled = currentPage === totCount.length - 1;
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-between mt-3">
      {/* Pagination Slider */}
      <div className={`${styles.Slide_button_active} d-flex`}>
        {totCount.map((_, index) => (
          <li key={index} className="mr-1">
            <button
              className={
                currentPage === index
                  ? styles.list_active
                  : styles.list_nonactive
              }
              aria-label={`Go to page ${index + 1}`}
              onClick={() => onPageChange(index)}
            />
          </li>
        ))}
      </div>

      {/* Previous & Next buttons */}
      <div className={`${styles.arrow_btn} d-flex`}>
        <div className={`${styles.arrow_btn_prev}`}>
          <button
            className={`${styles.btn} ${
              isPrevDisabled ? styles.btn_disabled : ""
            }`}
            aria-label="Previous page"
            onClick={hPrev}
            disabled={isPrevDisabled}
          >
            <i
              className={`fa-solid fa-arrow-left icon ${
                isPrevDisabled ? styles.icon_disabled : styles.icon_enabled
              }`}
            ></i>
          </button>
        </div>

        <div>
          <button
            className={`${styles.btn} ${
              isNextDisabled ? styles.btn_disabled : ""
            }`}
            aria-label="Next page"
            onClick={hNext}
            disabled={isNextDisabled}
          >
            <i
              className={`fa-solid fa-arrow-right icon ${
                isNextDisabled ? styles.icon_disabled : styles.icon_enabled
              }`}
            ></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaginationTop10;
