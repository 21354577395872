import "./index.css";
import "material-icons/iconfont/material-icons.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.min.css";
import "intl-tel-input/build/css/intlTelInput.css"; // Import the library styles
import "./img/css/font-family.css";

import App from "./App";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { Toaster } from "react-hot-toast";
import ActiveTab from "./shared/Component/BackOffice/StudentTestWorkflow/pages/ActiveTab";
//--------start-------------
// Function to reset the button click count in sessionStorage
const resetButtonClickCount = () => {
  sessionStorage.setItem("buttonClickCount", 0);
  localStorage.setItem("buttonClickCount", 0);
};

// Add event listener for tab close or refresh
window.addEventListener("beforeunload", resetButtonClickCount);

//----------end-----
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <div>
    <Provider store={store}>
      <App />
      {/* <ActiveTab /> */}
    </Provider>
    {/* <Toaster position="top-right" toastOptions={{ duration: 3000 }} /> */}
    <Toaster
      position="top-right"
      toastOptions={{
        duration: 8000,
        success: {
          style: {
            padding: "16px 24px",
            background: "#041134",
            color: "#fff",
            border: "5px solid #61d345",
            width: "100%",
          },
        },
        warning: {
          style: {
            padding: "16px 24px",
            background: "#041134",
            color: "#fff",
            border: "5px solid #c0272d",
            width: "100%",
          },
        },

        error: {
          style: {
            padding: "16px 24px",
            background: "#041134",
            color: "#fff",
            border: "5px solid #c0272d",
            width: "100%",
          },
        },
      }}
      containerStyle={{
        top: 140,
        right: 40,
      }}
    />
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
