import React from "react";
import "./Question.css";
import parse from "html-react-parser";
import LatexProcessorComponent from "../../../../utils/LatexProcessorComponent";

//display type 4 means -   mcq design
// sample design
// Which one of the following best describes the concept of ‘Small Farmer Large Field’?
//  (A) Resettlement of a large number of people, uprooted from their countries due to war, by giving them a large cultivable, land which they cultivate collectively and share the produce
//  (B) Many marginal farmers in an area organize themselves into groups and synchronize and harmonize selected agricultural operations.
//  (C) Many marginal farmers in an area together make a contract with a corporate body and surrender their land to the corporate body for a fixed term for which the corporate body makes a payment of agreed amount to the farmers
//  (D) A company extends loans, technical knowledge and material inputs to a number of small farmers in an area so that they produce the agricultural commodity required by the company for its manufacturing process and commercial production

function QtypeFive(props) {
  const { index } = props;

  const userJSON = sessionStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user && user.user_id) {
      userid = user.user_id;
    }
  } else {
    console.log("User Id cannot avaliable in session storage");
  }
  const optionType = props.list.options_type;

  const generateOptionsEnglish = (optionType, index, props) => {
    const options = ["A", "B", "C", "D", "E"];

    const elements = [];

    for (let i = 0; i < optionType; i++) {
      const option = options[i];
      console.log();
      const valueKey = `options_en_${option}`;
      const checked = props.userInput.some(
        (item) => item.pk === props.list.pk && item.UserOptionSec === option
      );
      const optionValue = props.list[`options_en_${options[i]}`];
      const parts = optionValue.split(" - ").map((part) => part.trim());
      const length = parts.length;
      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className=" forms_input d-flex">
            <input
              type="radio"
              className="radioInput"
              name={`q_answer_${props.index}_en`}
              checked={checked}
              value={props.list[valueKey]}
              onChange={(e) =>
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  1,
                  option,
                  props.list.subject_type
                )
              }
            />{" "}
            <span
              className="answer_label"
              onClick={(e) => {
                console.log("Span clicked for option:", options[i]);
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  1,
                  option,
                  props.list.subject_type
                );
              }}
            >
              ({option})
            </span>
          </div>
          <div className="col-md-9 m-0">
            <div className="row">
              {parts.map((item, index) => (
                <div className="col-md-6 col-6" key={index}>
                  <span
                    className="answer_label"
                    onClick={(e) => {
                      console.log("Span clicked for option:", options[i]);
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        1,
                        option,
                        props.list.subject_type
                      );
                    }}
                  >
                    {/* {parse(item)} */}
                    <LatexProcessorComponent originalString={item} />
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    return elements;
  };

  const generateOptionsTamil = (optionType, index, props) => {
    const options = ["A", "B", "C", "D", "E"];

    const elements = [];

    for (let i = 0; i < optionType; i++) {
      const option = options[i];
      console.log();
      const valueKey = `options_tm_${option}`;
      const checked = props.userInput.some(
        (item) => item.pk === props.list.pk && item.UserOptionSec === option
      );
      const optionValue = props.list[`options_tm_${options[i]}`];
      const parts = optionValue.split(" - ").map((part) => part.trim());
      const length = parts.length;
      elements.push(
        <div
          key={i}
          className={`${
            props.list.option_visibility == 1 ? "col-md-6" : "col-md-12"
          } mt-1 d-flex`}
        >
          <div className="forms_input d-flex">
            <input
              type="radio"
              className="radioInput"
              name={`q_answer_${props.index}_tm`}
              checked={checked}
              value={props.list[valueKey]}
              onChange={(e) =>
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                )
              }
            />{" "}
            <span
              className="answer_label"
              onClick={(e) => {
                console.log("Span clicked for option:", options[i]);
                props.handleOptionChange(
                  e,
                  props.list.pk,
                  props.list.batch_id,
                  props.list.event_pk,
                  userid,
                  0,
                  option,
                  props.list.subject_type
                );
              }}
            >
              ({option})
            </span>
          </div>
          <div className="col-md-9 container m-0">
            <div className="row">
              {parts.map((item, index) => (
                <div className="col-md-6 col-6" key={index}>
                  <span
                    className="answer_label"
                    onClick={(e) => {
                      console.log("Span clicked for option:", options[i]);
                      props.handleOptionChange(
                        e,
                        props.list.pk,
                        props.list.batch_id,
                        props.list.event_pk,
                        userid,
                        0,
                        option,
                        props.list.subject_type
                      );
                    }}
                  >
                    {/* {parse(item)} */}
                    <LatexProcessorComponent originalString={item} />
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    return elements;
  };

  return (
    <div className=" Question_temp">
      {props.list.en_question !== null &&
        props.list.en_question !== "" &&
        (props.list.lang_type === 1 ||
          (props.list.lang_type === 2 &&
            (props.Lang === 1 || props.Lang === 2))) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_english">
                {/* {parse(props.list.en_question)} */}
                <LatexProcessorComponent
                  originalString={props.list.en_question}
                />
              </span>
            </div>
            <div className="col-md-12  mt-1">
              <div className="row">
                {generateOptionsEnglish(optionType, index, props)}
              </div>
            </div>
          </div>
        )}
      {props.list.tm_question !== null &&
        props.list.tm_question !== "" &&
        (props.list.lang_type === 0 ||
          (props.list.lang_type === 2 &&
            (props.Lang === 0 || props.Lang === 2))) && (
          <div className="row tamilQues">
            <div className="col-md-12">
              <span className="question_labels_tamil">
                {/* {parse(props.list.tm_question)} */}
                <LatexProcessorComponent
                  originalString={props.list.tm_question}
                />
              </span>
            </div>
            <div className="col-md-12 mt-1">
              <div className="row">
                {generateOptionsTamil(optionType, index, props)}
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default QtypeFive;
